import React from "react";
import { NavContent } from "primary-navbar!sofe";
import styles from "./submit-survey.styles.css";

export default function SurveyAllDone(props) {
  return (
    <NavContent
      style={{ marginLeft: "0px", marginRight: "0px", width: "100%" }}
      hasTopnavSecondary={false}
    >
      <div
        className={`cps-card cps-padding-32 cps-fixed-focus ${styles.allDoneWrapper}`}
      >
        <div className={`${styles.container}`}>
          <img
            className="done__img"
            src="https://s3-us-west-2.amazonaws.com/cdn.canopytax.com/static/empty-states/es_thumbs_up.svg"
            alt=""
          />
          <div className={`${styles.boldQuestion}`}>You're all done!</div>
          <div className={`${styles.explanation}`}>
            Please contact us if any of your information changes.
          </div>
        </div>
      </div>
    </NavContent>
  );
}
