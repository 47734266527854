import React from "react";
import FederalTaxesDue from "./report-utils/federal-taxes-due.component.js";
import BigPrimary from "./report-utils/big-primary-text.component.js";
import AlertList from "./alert-list.component.js";
import styles from "./reports.styles.css";
import Row from "./report-utils/row.component.js";

const valueWidth = { width: "25%" };
const rows = [
  {
    caption: "Description",
    columns: [
      { ...valueWidth, value: "Streamlined" },
      { ...valueWidth, value: "Non-streamlined" },
    ],
    isHeader: true,
  },
  {
    caption: "Monthly Income",
    columns: [
      { ...valueWidth, reportKey: "ocelot" },
      { ...valueWidth, reportKey: "pika" },
    ],
  },
  {
    caption: "Monthly Expenses",
    columns: [
      { ...valueWidth, reportKey: "quoll" },
      { ...valueWidth, reportKey: "rhea" },
    ],
  },
  {
    caption: "Net Monthly Income",
    columns: [
      { ...valueWidth, reportKey: "bulbasaur" },
      { ...valueWidth, reportKey: "ivysaur" },
    ],
  },
  {
    boldText: true,
    caption: "Monthly Payment",
    columns: [
      { ...valueWidth, reportKey: "slothbear" },
      { ...valueWidth, reportKey: "tapir" },
    ],
  },
  {
    caption: "Term",
    columns: [
      { ...valueWidth, reportKey: "uakari" },
      { ...valueWidth, reportKey: "vaquita" },
    ],
    notCurrency: true,
  },
];

export default function PaymentReport(props) {
  return (
    <div>
      <div className={`${styles.nonRoundedTop} cps-card`}>
        <div className={`cps-card__body ${styles.headerBox}`}>
          <FederalTaxesDue
            updateAnswer={props.updateAnswer}
            updateReduxAnswer={props.updateReduxAnswer}
            answer={props.answers.FederalTaxesDue}
          />
          <BigPrimary
            smeMode={props.smeMode}
            reportKey={"margay"}
            value={props.report["margay"]}
            caption={"Best Possible Payment"}
          />
          <BigPrimary
            smeMode={props.smeMode}
            reportKey={"narwhal"}
            value={props.report["narwhal"]}
            caption={"Term"}
          />
        </div>
        <div
          className="cps-well cps-padding-16"
          style={{ margin: "-8px 24px 24px 24px" }}
        >
          <strong>
            <sup>*</sup>Notes
          </strong>
          <p>
            Expanded criteria for streamlined processing will be applied to
            installment agreement requests submitted to SB/SE Campus Collection
            Operations, this includes the Automated Collection System (ACS).
            Expanded criteria will NOT be applied to installment agreement
            requests submitted to W&amp;I Accounts Management, SB/SE Field
            Collection or through the Online Payment Agreement application.
          </p>
          <p>
            <a
              href="https://www.irs.gov/businesses/small-businesses-self-employed/streamlined-processing-of-installment-agreements"
              target="_blank"
              rel="noreferrer"
            >
              https://www.irs.gov/businesses/small-businesses-self-employed/streamlined-processing-of-installment-agreements
            </a>
          </p>
        </div>
      </div>
      <div className={`cps-card cps-margin-top-16`}>
        <div className={`${styles.alertBox}`}>
          <AlertList
            report={props.report}
            smeMode={props.smeMode}
            client={props.client}
            isIndividual={true}
            activeReport={"plan"}
          />
        </div>
      </div>
      <div className={`cps-card-table cps-card cps-margin-top-16`}>
        <div className={`cps-subheader cps-card__header`}>Payment Plan</div>
        {rows.map((row, rowIndex) => (
          <Row
            key={rowIndex}
            report={props.report}
            smeMode={props.smeMode}
            {...row}
          />
        ))}
      </div>
    </div>
  );
}
