import React from "react";
import CprMask from "cpr-mask";
import { always } from "kremling";
import { get, noop, isEmpty } from "lodash";
import Cancelable from "react-disposable-decorator";
import { CpButton, CpSelectSingle } from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";

import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import { validateWithXsdRegex } from "./input.helper";
import { getCafs } from "../../resources/users.resource";

@CanopyInput()
@Cancelable
export default class CAF extends React.Component {
  state = {
    localAnswer: this.props.answer,
    invalidMsg: "CAF needs 9 digits",
    cafs: [],
  };

  inputRef = React.createRef();

  focused = false;

  componentDidMount() {
    this.getCafsList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer && !this.focused) {
      this.updateLocalAnswer(this.props.answer);
    }

    if (
      this.getPractitionerId(this.props.answers?.serverAnswers) !==
      this.getPractitionerId(prevProps.answers?.serverAnswers)
    ) {
      this.getCafsList();
    }
  }

  render() {
    const {
      question,
      inTable,
      className,
      style = {},
      datax,
      datay,
      onPaste = noop,
    } = this.props;
    const { cafs, localAnswer, invalidMsg } = this.state;
    const cafValue = cafs?.find((caf) => caf.caf_number === localAnswer);

    return (
      <>
        {!isEmpty(cafs) ? (
          <CpSelectSingle
            data={cafs}
            renderTrigger={({ toggle, ref }) => (
              <CpButton
                btnType="secondary"
                style={{ backgroundColor: "var(--cp-color-select-list-bg)" }}
                onClick={toggle}
                ref={ref}
                dropdown
              >
                {cafValue?.caf_number.replace(/(\d{4})(\d{5})/, "$1-$2") ||
                  "Select CAF"}
              </CpButton>
            )}
            onChange={(caf) => {
              this.updateAnswer(caf?.caf_number || "");
            }}
            value={cafValue}
            transformData={(caf) => ({
              ...caf,
              name: caf?.caf_number.replace(/(\d{4})(\d{5})/, "$1-$2"),
            })}
            contentWidth="sm"
          />
        ) : (
          <CprMask
            ref={this.inputRef}
            inputClass={always(
              `cps-form-control ${styles["source-form-200"]} ${question.name} ${
                className || ""
              }`
            )
              .maybe(fixedTableStyles.fixedTableInput, inTable)
              .toString()}
            invalidClass="cps-has-error"
            nonValidMsgClass="cps-error-block"
            nonValidMsg={invalidMsg}
            initialValue={localAnswer}
            filler=" "
            onBlur={(value) => this.updateAnswer(this.removeWhitespace(value))}
            onFocus={this.focus}
            onChange={(value) =>
              this.updateLocalAnswer(this.removeWhitespace(value))
            }
            masks={[
              {
                condition: () => true,
                pattern: "1111-11111",
              },
            ]}
            validateMethod={(value) =>
              this.validateMethod(this.removeWhitespace(value))
            }
            inputProps={{
              autoComplete: "off",
              style,
              datax,
              datay,
              onPaste,
            }}
          />
        )}
      </>
    );
  }

  removeWhitespace = (value) => value?.replace(/\s/g, "");

  getCafsList = () => {
    const { serverAnswers } = this.props.answers;
    const practitionerId = this.getPractitionerId(serverAnswers);

    if (!practitionerId) return;

    this.props.cancelWhenUnmounted(
      getCafs(practitionerId).subscribe((cafNumbers = []) => {
        const cafs = cafNumbers.filter(
          (cafObj) => !!cafObj.caf_number && !cafObj.deleted
        );
        if (!this.state.localAnswer && cafs?.length === 1) {
          this.updateAnswer(cafs[0].caf_number);
        }
        this.setState({ cafs });
      }, handleError)
    );
  };

  getPractitionerId = (serverAnswers = {}) => {
    // object property name prefix for practitioner id changes based on form - ie. Appointee.Practitioner or Representative.Practitioner
    // so this dynamically finds what property name is being used for the practitioner id for the form they are on
    const practitionerKey = Object.keys(serverAnswers).find(
      (key) => !!key.toLowerCase().includes("practitioner")
    );
    // now that we have the correct object property name, we can get the value on that property
    return serverAnswers[practitionerKey];
  };

  validateMethod = (value) => {
    const xsdType = get(this.props, "xsdType");

    if (value && xsdType) {
      return validateWithXsdRegex(xsdType, value);
    }

    if (value && value.length < 9) {
      return false;
    }

    return true;
  };

  updateLocalAnswer = (value) => {
    const xsdType = get(this.props, "xsdType");
    let invalidMsg = "Must be a valid CAF";
    this.props.validate(value);

    if (value && xsdType) {
      const isValid = validateWithXsdRegex(xsdType, value);

      if (!isValid && xsdType.customDescription) {
        invalidMsg = xsdType.customDescription;
      } else if (!isValid && xsdType.irsDescription) {
        invalidMsg = xsdType.irsDescription;
      }
    } else if (value && value.length < 9) {
      invalidMsg = "CAF needs 9 digits";
    }

    this.setState({
      localAnswer: value,
      invalidMsg,
    });
  };

  focus = () => (this.focused = true);

  focusInput = () => {
    const input = get(this, "inputRef.current.input");
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  updateAnswer = (answer) => {
    this.props.validate(answer);
    this.focused = false;
    this.props.updateAnswer(this.props.question, answer);
  };
}
