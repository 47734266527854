import { map } from "rxjs/operators";
import { getSourceFormGroups } from "../source-forms/source-form-groups.resource.js";
import { fetchSubsection } from "src/source-forms/sections/source-form-subsections.resource.js";
import {
  getClientSurveys,
  putClientSurvey,
  patchClientSurvey,
} from "./resolution-case-client-survey.resource.js";
import { getSurveysForClient } from "./client-survey.resource.js";
import { getUsersFromClient } from "src/clients/client.resource.js";
import { handleError } from "src/handle-error";
import { shameSmes } from "src/common/shame-sme.helpers.js";
import { noop, isEmpty } from "lodash";
import { requestIsForCurrentRoute } from "../source-forms/answers/answer.helpers.js";
import { resetSourceFormSection } from "../source-forms/sections/section.actions.js";
import { resetSourceFormLayout } from "../source-forms/source-form-layout.actions.js";

import { newAnswersRetrieved } from "src/source-forms/answers/answer.actions.js";

import * as types from "./client-survey.types.js";
import * as answerOrderActions from "src/resolution-cases/answer-order/answer-order.actions.js";

export function getClientSurveySourceFormId(
  clientId,
  resolutionCaseId,
  versionId,
  revisionId,
  clientSurveyName,
  groupName
) {
  return (dispatch) => {
    dispatch({
      type: types.GETTING_CLIENT_SURVEY_SOURCE_FORM_ID,
    });

    getSourceFormGroups.apply(null, arguments).subscribe(
      (groups) => {
        const clientSurveyGroup = groups.find(
          (group) => group.name === groupName
        );

        if (!clientSurveyGroup) {
          shameSmes(
            `Cannot load client survey because there is no top-level source form group in the SME tool called '${groupName}'`
          );
          return;
        }

        const clientSurveySourceForm = clientSurveyGroup.forms.find(
          (sourceForm) => sourceForm.name === clientSurveyName
        );
        if (!clientSurveySourceForm) {
          shameSmes(
            `Cannot load client survey because in the '${groupName}' group there is no top-level source form called '${clientSurveyName}'`
          );
          return;
        }

        const clientSurveySourceFormId = clientSurveySourceForm.meta.formId;

        if (!clientSurveySourceFormId) {
          throw new Error(
            `Somehow the client survey source form does not have a '.meta.formId'`
          );
        }
        dispatch({
          type: types.SET_CLIENT_SURVEY_SOURCE_FORM_ID,
          clientSurveySourceFormId,
        });
      },

      handleError
    );
  };
}

export function patchClientSurveyAction(
  clientSurveyId,
  propertyName,
  propertyValue,
  callback = noop,
  queryParameters = {}
) {
  return patchSurveyProperties(
    clientSurveyId,
    { [propertyName]: propertyValue },
    callback,
    queryParameters
  );
}

export function patchSurveyProperties(
  clientSurveyId,
  newProperties,
  callback = noop,
  queryParameters = {}
) {
  return (dispatch, getState) => {
    const params = {
      clients: getState().reduxClient.id,
      resolutionCases: getState().resolutionCase.id,
      surveys: clientSurveyId,
    };

    patchClientSurvey(params, newProperties, queryParameters).subscribe(
      (newSurvey) => {
        dispatch({
          type: types.GOT_SURVEY,
          survey: newSurvey,
        });
        callback();
      },
      handleError
    );
  };
}

function putClientSurveyAction(clientSurvey) {
  return (dispatch, getState) => {
    const params = {
      clients: getState().reduxClient.id,
      resolutionCases: getState().resolutionCase.id,
      surveys: clientSurvey.id,
    };

    putClientSurvey(params, clientSurvey).subscribe((newSurvey) => {
      dispatch({
        type: types.GOT_SURVEY,
        survey: newSurvey,
      });
    }, handleError);
  };
}

export { putClientSurveyAction };

export function clearClientSurveySourceFormId() {
  return {
    type: types.CLEAR_CLIENT_SURVEY_SOURCE_FORM_ID,
  };
}

export function getSubsection(
  clientId,
  resolutionCaseId,
  sourceFormId,
  sectionId,
  subsectionIndex,
  versionId,
  revisionId,
  answerOrder
) {
  const args = arguments;
  return (dispatch) => {
    dispatch({
      type: types.GETTING_CLIENT_SURVEY_SUBSECTION,
    });

    fetchSubsection(...args).subscribe(
      (result) => {
        if (
          requestIsForCurrentRoute({
            sections: sectionId,
            subsections: subsectionIndex,
          })
        ) {
          newAnswersRetrieved(result)(dispatch);
        }

        dispatch({
          type: types.GOT_CLIENT_SURVEY_SUBSECTION,
        });
      },

      handleError
    );
  };
}

export function getClientSurveyAction(clientId, resolutionCaseId, callback) {
  return (dispatch, getState) => {
    if (getState().clientSurvey.gettingSurvey) {
      return;
    }

    dispatch({
      type: types.GETTING_CLIENT_SURVEY,
    });
    getClientSurveys(clientId, resolutionCaseId).subscribe((surveys) => {
      if (surveys.length > 0) {
        dispatch({ type: types.GOT_SURVEY, survey: surveys[0] });
        if (callback) callback(surveys[0]);
      } else {
        dispatch({ type: types.GOT_SURVEY, survey: { notCreated: true } });
        if (callback) callback({ notCreated: true });
      }
    }, handleError);
  };
}

export function openSurveyOptions() {
  return (dispatch, getState) => {
    const resolutionCaseAnswerOrder = getState().resolutionCaseAnswerOrder;

    if (
      !resolutionCaseAnswerOrder.defaultOrder &&
      !resolutionCaseAnswerOrder.gettingDefaultOrder
    ) {
      dispatch(answerOrderActions.getDefaultAnswerOrder(dispatchNewOrder));
    } else {
      dispatchNewOrder(resolutionCaseAnswerOrder.defaultOrder);
    }

    dispatch({
      type: types.OPEN_SURVEY_OPTIONS,
    });

    function dispatchNewOrder(answerOrder) {
      dispatch(setSurveyOptionsAnswerOrder(answerOrder));
    }
  };
}

export function cancelSurveyOptions() {
  return {
    type: types.CANCEL_SURVEY_OPTIONS,
  };
}

export function goToSurveyOption(optionName) {
  return {
    type: types.GO_TO_SURVEY_OPTION,
    optionName,
  };
}

export function setClientAccessSurveyOption(newValue) {
  return {
    type: types.SET_CLIENT_ACCESS_SURVEY_OPTION,
    hasAccess: newValue,
  };
}

export function setClientAccessAutoRemove(newValue) {
  return {
    type: types.SET_CLIENT_ACCESS_AUTO_REMOVE,
    removeOnSubmit: newValue,
  };
}

export function saveSurveyOptions() {
  return (dispatch, getState) => {
    const surveyOptions = getState().clientSurvey.surveyOptions;

    dispatch(
      patchClientSurveyAction(
        surveyOptions.survey.id,
        "clientAccess",
        surveyOptions.survey.clientAccess
      )
    );

    if (!isEmpty(surveyOptions.answerOrder)) {
      dispatch(
        answerOrderActions.updateDefaultAnswerOrder(surveyOptions.answerOrder)
      );
    }
    dispatch(cancelSurveyOptions());
  };
}

export function setSurveyOptionsAnswerOrder(answerOrder) {
  return {
    type: types.SET_SURVEY_OPTIONS_ANSWER_ORDER,
    answerOrder,
  };
}

export function resetClientSurvey() {
  return (dispatch) => {
    dispatch({ type: types.RESET_CLIENT_SURVEY });
    dispatch(resetSourceFormSection());
    dispatch(resetSourceFormLayout());
  };
}

export function fetchSurveysForClient(clientId) {
  return (dispatch) => {
    if (!clientId) {
      throw new Error(`clientId must be defined`);
    }
    getSurveysForClient(clientId).subscribe(
      (surveys) => {
        dispatch({
          type: types.NEW_SURVEYS_FOR_CLIENT,
          surveys,
        });
      },

      handleError
    );
  };
}

export function resetSurveysForClient() {
  return {
    type: types.RESET_SURVEYS_FOR_CLIENT,
  };
}

export function getPossibleClients(clientId) {
  return (dispatch) => {
    getUsersFromClient(clientId)
      .pipe(map((users) => users.filter((user) => user.role === "Client")))
      .subscribe(
        (users) => dispatch({ type: types.GOT_CLIENT_USERS, load: users }),
        handleError
      );
  };
}
