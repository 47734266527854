import React from "react";
import { UserTenantProps } from "cp-client-auth!sofe";
import { CpTooltip, CpIcon } from "canopy-styleguide!sofe";
import { Link, withRouter } from "react-router-dom";

import styles from "./cssf-subsection-progress.styles.css";
import { navigate } from "../../source-forms/sections/section.helper";

@UserTenantProps()
export class SubsectionProgress extends React.Component {
  render() {
    const { props } = this;
    return (
      <div ref={this.createRef.bind(this)}>
        <CpTooltip
          text={props.subsection.name}
          disabled={!this.shouldShowTooltip.call(this)}
        >
          <Link
            to={props.subLink}
            style={{ textDecoration: "none", outline: "none" }}
            className={styles.subsectionLink}
            onClick={(e) =>
              navigate(
                props.surveyId,
                e,
                () => props.history.push(props.subLink),
                props.loggedInUser
              )
            }
          >
            <div className={styles.subsection}>
              {props.subsection.completed ? (
                <CpIcon
                  name="checkmark-large"
                  fill="var(--cp-color-app-success-text)"
                />
              ) : (
                <CpIcon
                  name="shape-circle-open"
                  fill="var(--cp-color-button-secondary-border)"
                  style={{ height: "1.6rem", width: "1.6rem" }}
                />
              )}
            </div>
            <span
              className={`cp-ellipsis ${
                props.active ? "cp-color-app-primary" : ""
              }`}
            >
              {props.subsection.name}
            </span>
          </Link>
        </CpTooltip>
      </div>
    );
  }

  shouldShowTooltip() {
    if (this.nameSpan) {
      // this detects if cp-ellipsis is actually causing ellipsis to be shown
      return this.nameSpan.scrollWidth > this.nameSpan.offsetWidth;
    } else {
      return false;
    }
  }

  createRef(el) {
    if (!el) {
      return;
    }
    this.nameSpan = el.querySelector(`.cp-ellipsis`);
  }
}

export default withRouter(SubsectionProgress);
