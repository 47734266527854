import React from "react";
import styles from "./async-loader.component.style.css";
import { CpLoader } from "canopy-styleguide!sofe";

export default function AsyncLoader(props) {
  const customStyle = props.customStyle || {};
  const customClass = props.customClass || "";
  const page = props.page || false;

  return (
    <div>
      {doneWaiting(props.asyncChecks, props) ? (
        props.children
      ) : (
        <div
          className={`${styles.loader} ${customClass}`}
          style={customStyle}
          data-testid="async-loader"
        >
          <CpLoader page={page} />
        </div>
      )}
    </div>
  );
}

// For testing purposes
export { doneWaiting };

function doneWaiting(asyncChecks, props) {
  return asyncChecks.every((check) => check(props));
}
