import React from "react";

export default class AnimatedTr extends React.Component {
  render() {
    const { rowIndex, rowBaseStyle, answers, show } = this.props;

    return (
      show && (
        <tr key={rowIndex} style={rowBaseStyle}>
          {answers.map((summary, columnIndex) => (
            <td key={rowIndex + columnIndex} style={rowBaseStyle}>
              {summary}
            </td>
          ))}
          <td
            key="end"
            style={{
              ...rowBaseStyle,
              paddingRight: 0,
              textAlign: "right",
            }}
          >
            {this.props.children}
          </td>
        </tr>
      )
    );
  }
}
