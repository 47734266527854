import React from "react";
import { always } from "kremling";
import { get } from "lodash";
import { CprDatepicker } from "canopy-styleguide!sofe";
import { DateTime } from "luxon";

import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";

@CanopyInput()
export default class DateInput extends React.Component {
  constructor(props) {
    super();
    this.state = {
      localAnswer: props.answer,
    };
    this.inputRef = React.createRef();
  }

  focusInput = () => {
    const input = get(this, "inputRef.current.el");
    if (input) {
      if (input.querySelector("input")) input.querySelector("input").focus();
      else console.warn("no input to focus on!");
    } else console.warn("no ref to focus on!");
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer && !this.focused) {
      this.setState({ localAnswer: this.props.answer });
      this.props.validate(this.props.answer);
    }
  }

  render() {
    return (
      <CprDatepicker
        id={this.props.question.name}
        ref={this.inputRef}
        removeDateOption={true}
        removeDateText="Remove Date"
        orientation="bottom right"
        date={
          this.state.localAnswer
            ? this.convertDateToIso(this.state.localAnswer)
            : ""
        }
        inputClass={always(
          `cps-form-control ${styles["source-form-104"]} ${this.props.question.name}`
        )
          .maybe(fixedTableStyles.fixedTableInput, this.props.inTable)
          .toString()}
        events={{
          datechange: this.updateLocalAnswer,
          blur: this.blur,
        }}
      />
    );
  }

  convertDateToIso = (date) => {
    if (typeof date === "string") {
      return DateTime.fromFormat(date, "MM/dd/yyyy")
        .startOf("day")
        .toISO({ includeOffset: false });
    }

    return DateTime.fromMillis(this.state.localAnswer, { zone: "utc" })
      .startOf("day")
      .toISO({ includeOffset: false });
  };

  updateLocalAnswer = (e) => {
    let answer = "";
    if (e.detail) {
      const d = DateTime.fromJSDate(e.detail)
        .startOf("day")
        .toISO({ includeOffset: false });
      answer = DateTime.fromISO(d, { zone: "utc" }).toMillis();
    }
    this.setState({
      localAnswer: answer,
    });
    this.props.updateAnswer(this.props.question, answer);
    this.props.validate(answer);
  };

  blur = (_) => {
    this.props.validate(this.state.localAnswer);
  };
}
