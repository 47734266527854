import { isEmpty } from "lodash";

const STRING = "string";

export const fieldsByPage = (formFieldData) =>
  !isEmpty(formFieldData)
    ? formFieldData.fields &&
      Object.keys(formFieldData.fields).reduce((sorted, field) => {
        const value = formFieldData.fields[field];
        if (!value.overridable) {
          return sorted;
        }
        const pgNum = `page-${value.page}`;

        return {
          ...sorted,
          [pgNum]: sorted[pgNum] ? [...sorted[pgNum], value] : [value],
        };
      }, {})
    : {};

export const pixToPerc = (pixels, dimension) =>
  Math.abs((pixels / dimension) * 100);

export const alignmentStyle = (alignment) => {
  switch (alignment) {
    case "left":
      return "flex-end";
    case "center":
      return "center";
    default:
      return "space-between";
  }
};

export const actuallyANumber = (value) => {
  const parsed = parseInt(value, 10);
  return typeof value === STRING && value === `${parsed}` ? parsed : value;
};
