// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-source-forms-questions-quick-look-styles__quickLook {
  margin-top: 22px;
  display: inline-flex;
  align-items: center;
  line-height: 1.5;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

.src-source-forms-questions-quick-look-styles__maxWidth {
  max-width: 670px;
}
`, "",{"version":3,"sources":["webpack://./src/source-forms/questions/quick-look.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[":local(.quickLook) {\n  margin-top: 22px;\n  display: inline-flex;\n  align-items: center;\n  line-height: 1.5;\n  text-align: left;\n  font-size: 13px;\n  font-weight: 600;\n  cursor: pointer;\n}\n\n:local(.maxWidth) {\n  max-width: 670px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quickLook": `src-source-forms-questions-quick-look-styles__quickLook`,
	"maxWidth": `src-source-forms-questions-quick-look-styles__maxWidth`
};
export default ___CSS_LOADER_EXPORT___;
