import React from "react";
import { CpTooltip } from "canopy-styleguide!sofe";
import { clamp } from "../../common/multi-line-truncation-helper";
import styles from "./fixed-table.style.css";

export default class FixedTableText extends React.Component {
  constructor() {
    super();
    this.textRef = React.createRef();
  }

  state = {
    disableTooltip: true,
  };

  componentDidMount() {
    if (!this.props.isHeader) {
      clamp(this.textRef.current, {
        clamp: 2.1,
        onTruncate: this.enableTooltip,
        useNativeClamp: false,
      });
    }
  }

  render() {
    const { isHeader, text } = this.props;
    return (
      <CpTooltip text={text} disabled={this.state.disableTooltip} delay={600}>
        <div
          ref={this.textRef}
          className={isHeader ? styles.header : styles.text}
        >
          {text}
        </div>
      </CpTooltip>
    );
  }

  enableTooltip = () => {
    this.setState({ disableTooltip: false });
  };
}
