import React from "react";
import Alert from "./report-utils/alert.component.js";
import SmeWrapper from "./report-utils/sme-view-wrapper.component.js";

import { issues as individualIssues } from "./issues/individual-issues.js";
import { issues as businessIssues } from "./issues/business-issues.js";
import { includes, partial } from "lodash";

export default function AlertList({
  client,
  isIndividual,
  activeReport,
  smeMode,
  report,
}) {
  const toElement = partial(toAlert, client, activeReport, smeMode);

  return (
    <div>
      {isIndividual
        ? individualIssues
            .filter((issue) => report[issue.reportKey] || smeMode)
            .map(toElement)
        : businessIssues
            .filter((issue) => report[issue.reportKey] || smeMode)
            .map(toElement)}
    </div>
  );
}

export { toAlert };

function toAlert(client, activeReport, smeMode, issue, index) {
  return (
    includes(issue.tabs, activeReport) && (
      <div key={issue.reportKey || index}>
        <SmeWrapper smeMode={smeMode} reportKey={issue.reportKey}>
          <Alert
            title={issue.title}
            description={issue.description.replace("FIRST_NAME", client.name)}
            priority={issue.priority}
          />
        </SmeWrapper>
      </div>
    )
  );
}
