export const formSetMap = {
  federal: "1",
  alabama: "2",
  alaska: "3",
  arizona: "4",
  arkansas: "5",
  california: "6",
  colorado: "7",
  connecticut: "8",
  delaware: "9",
  florida: "10",
  georgia: "11",
  hawaii: "12",
  idaho: "13",
  illinois: "14",
  indiana: "15",
  iowa: "16",
  kansas: "17",
  kentucky: "18",
  louisiana: "19",
  maine: "20",
  maryland: "21",
  massachusetts: "22",
  michigan: "23",
  minnesota: "24",
  mississippi: "25",
  missouri: "26",
  montana: "27",
  nebraska: "28",
  nevada: "29",
  "new hampshire": "30",
  "new jersey": "31",
  "new mexico": "32",
  "new york": "33",
  "north carolina": "34",
  "north dakota": "35",
  ohio: "36",
  oklahoma: "37",
  oregon: "38",
  pennsylvania: "39",
  "rhode island": "40",
  "south carolina": "41",
  "south dakota": "42",
  tennessee: "43",
  texas: "44",
  utah: "45",
  vermont: "46",
  virginia: "47",
  washington: "48",
  "west virginia": "49",
  wisconsin: "50",
  wyoming: "51",
};
