import { toMaskToValue } from "src/source-forms/inputs/currency.component.js";

export function handleValue(value, prefix = "", smeMode, currency) {
  if (typeof value === "undefined") {
    if (currency) return "$0.00";
    if (smeMode) return "Value here";
  }
  if (typeof value === "number")
    return prefix + toMaskToValue(String(value)).mask;
  return value;
}
