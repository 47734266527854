import React from "react";
import styles from "./report-utils.styles.css";
import { successToast } from "toast-service!sofe";

export default class SmeViewWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      copying: false,
      hovered: false,
    };
  }
  render() {
    return (
      <div
        onMouseOver={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        style={
          this.props.smeMode && this.state.hovered
            ? {
                border: "3px solid var(--cps-color-primary)",
                position: "relative",
                ...this.props.divStyles,
              }
            : this.props.divStyles
        }
      >
        {this.props.children}
        {this.props.smeMode && this.state.hovered ? (
          <div
            onClick={this.copyReportKey.bind(this)}
            style={{ top: "calc(100% - 3px)" }}
            className={styles.smeWrapperReport}
          >
            {`Report Key: ${this.props.reportKey}`}
            {this.state.copying ? (
              <input
                ref={(input) => (this.copyInput = input)}
                readOnly={true}
                value={this.props.reportKey}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
  copyReportKey() {
    this.setState(
      {
        copying: true,
      },
      () => {
        this.copyInput.focus();
        this.copyInput.setSelectionRange(0, this.props.reportKey.length);
        document.execCommand("copy");
        this.setState({
          copying: false,
        });
        successToast(`Copied ${this.props.reportKey} to the clipboard`);
      }
    );
  }
}
