// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-options-survey-options-style__button {
  text-transform: inherit;
}

.src-client-survey-options-survey-options-style__grid {
  display: flex;
  flex-direction: row;
  height: 372px;
}

.src-client-survey-options-survey-options-style__leftColumn {
  padding-top: 8px;
  padding-left: 0;
  padding-right: 0;
  min-width: 150px;
  border-right: 1px solid var(--cps-color-silver);
}

.src-client-survey-options-survey-options-style__leftMenu {
  list-style: none;
  padding-left: 0;
}

.src-client-survey-options-survey-options-style__leftMenu li {
  padding: 0 24px 0 24px;
  font-size: 13px;
  height: 40px;
}

.src-client-survey-options-survey-options-style__leftMenu li > * {
  line-height: 40px;
}

.src-client-survey-options-survey-options-style__leftMenu li:hover {
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
  background-color: var(--cps-color-chrome);
}

.src-client-survey-options-survey-options-style__rightColumn {
  width: 100%; /* IE is weird and needs this */
  padding-top: 8px;
}

.src-client-survey-options-survey-options-style__active {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/options/survey-options.style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,+CAA+C;AACjD;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,yCAAyC;AAC3C;;AAEA;EACE,WAAW,EAAE,+BAA+B;EAC5C,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".button {\n  text-transform: inherit;\n}\n\n.grid {\n  display: flex;\n  flex-direction: row;\n  height: 372px;\n}\n\n.leftColumn {\n  padding-top: 8px;\n  padding-left: 0;\n  padding-right: 0;\n  min-width: 150px;\n  border-right: 1px solid var(--cps-color-silver);\n}\n\n.leftMenu {\n  list-style: none;\n  padding-left: 0;\n}\n\n.leftMenu li {\n  padding: 0 24px 0 24px;\n  font-size: 13px;\n  height: 40px;\n}\n\n.leftMenu li > * {\n  line-height: 40px;\n}\n\n.leftMenu li:hover {\n  padding-left: 24px;\n  padding-right: 24px;\n  cursor: pointer;\n  background-color: var(--cps-color-chrome);\n}\n\n.rightColumn {\n  width: 100%; /* IE is weird and needs this */\n  padding-top: 8px;\n}\n\n.active {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `src-client-survey-options-survey-options-style__button`,
	"grid": `src-client-survey-options-survey-options-style__grid`,
	"leftColumn": `src-client-survey-options-survey-options-style__leftColumn`,
	"leftMenu": `src-client-survey-options-survey-options-style__leftMenu`,
	"rightColumn": `src-client-survey-options-survey-options-style__rightColumn`,
	"active": `src-client-survey-options-survey-options-style__active`
};
export default ___CSS_LOADER_EXPORT___;
