import React from "react";
import { connect } from "react-redux";

import { getAndFetchVersion } from "sme-version!sofe";

import * as resolutionCaseActions from "../resolution-cases/resolution-cases.actions.js";
import * as versionActions from "../source-forms/version.actions.js";

@connect((state) => ({}))
export default class ResolutionCasesMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      smeVersionDisposable: null,
    };
  }

  componentDidMount() {
    if (!this.props.location.pathname.includes("survey")) {
      this.props.dispatch(
        resolutionCaseActions.fetchResolutionCase(
          this.props.match.params.clientId,
          this.props.match.params.resolutionCaseId
        )
      );
    }

    const smeVersionDisposable = getAndFetchVersion("taxRes").subscribe(
      (smeVersion) => {
        this.props.dispatch(
          versionActions.newSmeVersion(smeVersion.version, smeVersion.revision)
        );
      }
    );

    this.setState({ smeVersionDisposable });
  }

  componentWillUnmount() {
    if (this.state.smeVersionDisposable)
      this.state.smeVersionDisposable.unsubscribe();
    this.props.dispatch(resolutionCaseActions.resetResolutionCase());
  }

  render() {
    const { children } = this.props;
    return <div>{children}</div>;
  }
}
