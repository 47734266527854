import React from "react";
import { get, uniqueId } from "lodash";
import { handleError } from "src/handle-error";
import { getSvgPages } from "./svg-pages.resource.js";
import { CpLoader } from "canopy-styleguide!sofe";
import { infoToast, warningToast } from "toast-service!sofe";
import Cancelable from "react-disposable-decorator";
import TaxFormViewPortOrchestrator from "./tax-form-view-port-orchestrator.component.js";

@Cancelable
export default class TaxForm extends React.Component {
  state = {
    svgPages: null,
    error: null,
    errorId: null,
  };

  loading = false;

  componentDidMount() {
    this.fetchSvgPages();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.formName !== prevProps.formName || !this.state.svgPages) &&
      !this.loading
    ) {
      this.fetchSvgPages();
    }
  }

  fetchSvgPages = () => {
    this.loading = true;

    const {
      userIsSME,
      clientId,
      resolutionCaseId,
      versionId,
      revisionId,
      formName,
    } = this.props;

    this.props.cancelWhenUnmounted(
      getSvgPages(
        clientId,
        resolutionCaseId,
        versionId,
        revisionId,
        formName
      ).subscribe(
        (resp) => {
          this.setState({ svgPages: resp.pages }, () => {
            if (this.props.onLoad) this.props.onLoad(resp);
          });
          this.loading = false;
        },
        (error) => {
          if (userIsSME) {
            infoToast(
              `We encountered an error with your logic on form ${formName}! Please check the error message on the page!`,
              "Go to error",
              () => {
                const el = document.getElementById(this.state.errorId);

                if (el) {
                  el.scrollIntoView();
                  document.body.parentElement.scrollTop =
                    document.body.parentElement.scrollTop - 300;
                }
              },
              12000
            );
            this.setState({
              error: JSON.stringify(get(error, "data.errors"), null, "  "),
              errorId: uniqueId(),
            });
          } else {
            warningToast(`We encountered an error rendering the tax form`);
            this.setState({
              error: "error",
              errorId: uniqueId(),
            });
            handleError(error);
          }
        }
      )
    );
  };

  render() {
    const {
      clientId,
      resolutionCaseId,
      versionId,
      revisionId,
      formName,
      userIsSME,
    } = this.props;

    const { error, errorId } = this.state;

    if (error)
      return (
        <div id={errorId}>
          <div
            style={{ paddingLeft: 26, marginTop: 64, overflow: "auto" }}
            className="cps-card"
          >
            <h4 className="cps-warning">
              We encountered an error when rendering {formName}
            </h4>
            {error !== "error" && <pre>{error}</pre>}
          </div>
        </div>
      );

    return (
      <div>
        {this.state.svgPages ? (
          <TaxFormViewPortOrchestrator
            formId={formName}
            pages={this.state.svgPages}
            clientId={clientId}
            resolutionCaseId={resolutionCaseId}
            versionId={versionId}
            revisionId={revisionId}
            isSme={userIsSME}
          />
        ) : (
          <div style={{ height: 100, paddingTop: 45 }} className="cps-card">
            <CpLoader />
          </div>
        )}
      </div>
    );
  }
}
