// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./client-access.style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-options-survey-data-styles__root {
  padding-bottom: 172px;
}

.src-client-survey-options-survey-data-styles__pushGrid {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.src-client-survey-options-survey-data-styles__pushExplanation {
  margin-bottom: 16px;
  padding-right: 16px;
}

.src-client-survey-options-survey-data-styles__note {
  color: var(--cps-color-primary-text);
}

.src-client-survey-options-survey-data-styles__toggle {
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/options/survey-data.styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;AAEA","sourcesContent":[".root {\n  padding-bottom: 172px;\n}\n\n.pushGrid {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.pushExplanation {\n  margin-bottom: 16px;\n  padding-right: 16px;\n}\n\n.note {\n  color: var(--cps-color-primary-text);\n}\n\n.toggle {\n  composes: toggle from \"./client-access.style.css\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `src-client-survey-options-survey-data-styles__root`,
	"pushGrid": `src-client-survey-options-survey-data-styles__pushGrid`,
	"pushExplanation": `src-client-survey-options-survey-data-styles__pushExplanation`,
	"note": `src-client-survey-options-survey-data-styles__note`,
	"toggle": `src-client-survey-options-survey-data-styles__toggle ${___CSS_LOADER_ICSS_IMPORT_0___.locals["toggle"]}`
};
export default ___CSS_LOADER_EXPORT___;
