import React from "react";
import styles from "./inputs.style.css";
import { get, noop } from "lodash";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import { always } from "kremling";
import { validateWithXsdRegex } from "./input.helper";

@CanopyInput()
export default class Decimal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localAnswer: props.answer || "",
      showError: false,
      invalidMsg: "This field requires a valid number",
    };
    this.reg = new RegExp(
      `^-?(\\d+)?(\\.([0-9]{0,${props.question.meta.precision}})?)?$`
    );
    this.inputRef = React.createRef();
  }

  static defaultProps = {
    answer: "",
  };

  focusInput = () => {
    const input = get(this, "inputRef.current");
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer && !this.focused) {
      this.setState({ localAnswer: this.props.answer || "" });
      this.props.validate(this.props.answer);
    }
  }

  onChange = (evt) => {
    const value = evt.target.value;
    if (this.reg.test(value) && this.validate(value)) {
      this.setState({ localAnswer: value, showError: false });
      this.props.validate(value);
    }
  };

  onBlur = () => {
    const value = this.state.localAnswer
      ? Number(this.state.localAnswer).toFixed(
          this.props.question.meta.precision
        )
      : "";

    if (value !== "NaN") {
      this.setState({ localAnswer: value }, () => {
        this.props.updateAnswer(this.props.question, value);
      });
      this.props.validate(value);
    } else {
      this.setState({ localAnswer: "", showError: true }, () => {
        this.props.updateAnswer(this.props.question, "");
      });
      this.props.validate("");
    }
  };

  validate = (value) => {
    const xsdType = get(this.props, "xsdType");

    if (value && xsdType) {
      return validateWithXsdRegex(xsdType, value);
    }

    return true;
  };

  render() {
    return (
      <div
        className={`${this.state.showError ? "cps-has-error" : ""} ${
          this.props.className || ""
        }`}
      >
        <input
          type="text"
          ref={this.inputRef}
          className={always(
            `cps-form-control ${styles["source-form-200"]} ${this.props.question.name}`
          ).maybe(fixedTableStyles.fixedTableInput, this.props.inTable)}
          value={this.state.localAnswer}
          style={this.props.style || {}}
          onPaste={this.props.onPaste || noop}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder={Number(0).toFixed(this.props.question.meta.precision)}
          datax={this.props.datax}
          datay={this.props.datay}
          autoComplete="off"
        />
        {this.state.showError && (
          <span className="cps-error-block">{this.state.invalidMsg}</span>
        )}
      </div>
    );
  }
}
