import React from "react";
import { useCss, k } from "kremling";
import { CpButton, CpTooltip } from "canopy-styleguide!sofe";

export default function ({ label = "", link, linkIcon, linkText }) {
  const scoped = useCss(css);

  const openLinkInWindow = () => {
    const dualScreenTop =
      window.screenTop != undefined ? window.screenTop : screen.top;
    const screenWidth =
      window.screenWidth != undefined ? window.screenWidth : screen.width;

    const windowFeatures = `top=${dualScreenTop},left=${screenWidth},width=800,height=800,popup,rel=noreferrer`;
    const handleOpenWindow = window.open(link, "", windowFeatures);

    if (!handleOpenWindow) {
      // The window wasn't allowed to open
      // This is likely caused by built-in popup blockers.
      window.open(link, "", "rel=noreferrer");
    }
  };

  if (label && label.trim()) {
    return (
      <div {...scoped} className="block-subheader">
        <div className="cp-mr-4">{label}</div>
        {link && linkIcon && (
          <CpTooltip text="Help">
            <CpButton
              className="icon-help-button"
              onClick={openLinkInWindow}
              icon="help-circle-open-small"
              aria-label="Help link"
            />
          </CpTooltip>
        )}
        {link && !linkIcon && linkText && (
          <a className="help-button" onClick={openLinkInWindow}>
            {linkText}
          </a>
        )}
      </div>
    );
  } else {
    return <div className="cp-p-8" />;
  }
}

const css = k`
  .block-subheader {
    display: flex;
    align-items: center;
    color: var(--cp-color-app-primary-text);
    font-size: 1.6rem;
    padding-top: 2.4rem;
    width: calc(100% - 2.4rem);
    border-bottom: 0.1rem solid var(--cps-color-silver);
    margin-left: 2.4rem;
    padding-right: 2.4rem;
  }

  .icon-help-button {
    width: 2.4rem !important;
    height: 2.4rem !important;
  }

  .help-button {
    margin-left: 0.8rem;
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 0.4rem;
  }
`;
