// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-files-survey-file-practitioner-upload-styles__containerLink {
  width: 283px;
  border-left: solid 2px var(--cps-color-bumble);
}

.src-client-survey-files-survey-file-practitioner-upload-styles__fileLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: inherit;
  text-decoration: none !important;
  outline: none;
  padding-left: 24px !important;
  height: 40px;
}

.src-client-survey-files-survey-file-practitioner-upload-styles__containerLink:hover {
  background-color: var(--cps-color-chrome);
}

.src-client-survey-files-survey-file-practitioner-upload-styles__selected {
  border-color: var(--cps-color-primary);
  color: var(--cps-color-primary);
}

.src-client-survey-files-survey-file-practitioner-upload-styles__unselected:hover {
  border-color: var(--cps-color-chrome);
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/files/survey-file-practitioner-upload.styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,8CAA8C;AAChD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,gCAAgC;EAChC,aAAa;EACb,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,sCAAsC;EACtC,+BAA+B;AACjC;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".containerLink {\n  width: 283px;\n  border-left: solid 2px var(--cps-color-bumble);\n}\n\n.fileLink {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  color: inherit;\n  text-decoration: none !important;\n  outline: none;\n  padding-left: 24px !important;\n  height: 40px;\n}\n\n.containerLink:hover {\n  background-color: var(--cps-color-chrome);\n}\n\n.selected {\n  border-color: var(--cps-color-primary);\n  color: var(--cps-color-primary);\n}\n\n.unselected:hover {\n  border-color: var(--cps-color-chrome);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerLink": `src-client-survey-files-survey-file-practitioner-upload-styles__containerLink`,
	"fileLink": `src-client-survey-files-survey-file-practitioner-upload-styles__fileLink`,
	"selected": `src-client-survey-files-survey-file-practitioner-upload-styles__selected`,
	"unselected": `src-client-survey-files-survey-file-practitioner-upload-styles__unselected`
};
export default ___CSS_LOADER_EXPORT___;
