import { fetchAsObservable } from "fetcher!sofe";
import { pluck, map } from "rxjs/operators";
import { reduce } from "lodash";

export function getClientSurveys(clientId, resolutionCaseId) {
  return fetchAsObservable(
    `/clients/${clientId}/resolution_cases/${resolutionCaseId}/surveys`
  ).pipe(pluck("surveys"));
}

export function postClientSurvey(
  clientId,
  resolutionCaseId,
  surveyMetaData,
  status,
  notification,
  hasAccess = true
) {
  const survey = {
    ...defaultSurvey(
      notification ||
        defaultNotification(window.loggedInUser.name, surveyMetaData)
    ),
    clientAccess: {
      removeOnSubmit: true,
      hasAccess,
    },
    clientId,
    resolutionCaseId,
    status,
    ...surveyMetaData,
  };

  return fetchAsObservable(
    `/clients/${clientId}/resolution_cases/${resolutionCaseId}/surveys`,
    {
      method: "POST",
      body: {
        surveys: survey,
      },
    }
  ).pipe(
    pluck("surveys"),
    map((surveyWithId) => {
      return {
        ...survey,
        ...surveyWithId,
      };
    })
  );
}

export function putClientSurvey(parameters, survey) {
  return fetchAsObservable(
    `/clients/${parameters.clients}/resolution_cases/${parameters.resolutionCases}/surveys/${parameters.surveys}`,
    {
      method: "PUT",
      body: {
        surveys: {
          ...survey,
          clientId: Number(survey.clientId),
          resolutionCaseId: Number(survey.resolutionCaseId),
        },
      },
    }
  ).pipe(pluck("surveys"));
}

export function patchClientSurvey(parameters, survey, queryParameters) {
  const queryString = "";
  let prefix = "?";
  const queryParamString = reduce(
    queryParameters,
    (queryString, value, key) => {
      const query = prefix + key + "=" + value;
      prefix = "&";
      return queryString + query;
    },
    ""
  );

  return fetchAsObservable(
    `/clients/${parameters.clients}/resolution_cases/${parameters.resolutionCases}/surveys/${parameters.surveys}${queryParamString}`,
    {
      method: "PATCH",
      body: {
        surveys: survey,
      },
    }
  ).pipe(pluck("surveys"));
}

export function deleteClientSurvey(clientId, resolutionCaseId, surveyId) {
  return fetchAsObservable(
    `/clients/${clientId}/resolution_cases/${resolutionCaseId}/surveys/${surveyId}`,
    { method: "DELETE" }
  ).pipe(pluck("surveys"));
}

export function defaultNotification(practitionerName, surveyMetaData) {
  return {
    header: defaultSurveyProperties[surveyMetaData.surveyType].header(),
    body: defaultSurveyProperties[surveyMetaData.surveyType].body(),
    closing:
      defaultSurveyProperties[surveyMetaData.surveyType].closing(
        practitionerName
      ),
  };
}

export function defaultSurvey(notification) {
  return {
    notification,
    previousClientRecipients: [],
    answerSet: "clientSurvey",
  };
}

const defaultSurveyProperties = {
  taxRes: {
    header: () => "Welcome! This is going to be easy!",
    body: () =>
      "We want you to have the best possible experience as you work with us for your tax needs. With that in mind we've prepared this survey to simplify and streamline the gathering of important information that we will need from you. This survey paints a full picture of your financial and tax scenario for us. It's easy to fill out, and we're always happy to answer any questions you might have.",
    closing: (practitionerName) => `Regards,\n${practitionerName}`,
  },
  taxPrep: {
    header: () => undefined,
    body: () => `To better serve you, we need you to do two things:
1. Answer some tax-related questions
2. Upload your tax documents

We’ll walk you through each step, and please include any questions or concerns in the areas for comments.`,
    closing: () => undefined,
  },
};
