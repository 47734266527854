// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-source-forms-summary-table-summary-table-blocks-styles__questionBlock {
  margin: 2.4rem 0rem;
  border: 1px solid var(--cps-color-athens);
  border-radius: 5px;
  background-color: var(--cps-color-ash);
}
`, "",{"version":3,"sources":["webpack://./src/source-forms/summary-table/summary-table-blocks.styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yCAAyC;EACzC,kBAAkB;EAClB,sCAAsC;AACxC","sourcesContent":[".questionBlock {\n  margin: 2.4rem 0rem;\n  border: 1px solid var(--cps-color-athens);\n  border-radius: 5px;\n  background-color: var(--cps-color-ash);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionBlock": `src-source-forms-summary-table-summary-table-blocks-styles__questionBlock`
};
export default ___CSS_LOADER_EXPORT___;
