// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1024px) {
  .src-source-forms-questions-question-styles__label {
    width: 20%;
    max-width: 26rem;
  }
}

.src-source-forms-questions-question-styles__input {
  max-width: 32rem;
  min-width: 24rem;
}

.src-source-forms-questions-question-styles__inputBox {
  height: 100%;
  width: 100%;
}

.src-source-forms-questions-question-styles__inputBox div .cp-multi-selector {
  width: 32rem;
  z-index: inherit;
}
.src-source-forms-questions-question-styles__inputBox
  div
  .cp-multi-selector
  .cp-multi-selector__main-input {
  z-index: inherit;
}

.src-source-forms-questions-question-styles__formGroup {
  padding: 12px 40px 0 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.src-source-forms-questions-question-styles__formGroupWithAnswerSet {
  padding-left: 0;
}

.src-source-forms-questions-question-styles__answerSetButtonIcon {
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/source-forms/questions/question.styles.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;IACV,gBAAgB;EAClB;AACF;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;;;;EAKE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["@media (min-width: 1024px) {\n  :local(.label) {\n    width: 20%;\n    max-width: 26rem;\n  }\n}\n\n:local(.input) {\n  max-width: 32rem;\n  min-width: 24rem;\n}\n\n.inputBox {\n  height: 100%;\n  width: 100%;\n}\n\n:global :local(.inputBox) div .cp-multi-selector {\n  width: 32rem;\n  z-index: inherit;\n}\n:global\n  :local(.inputBox)\n  div\n  .cp-multi-selector\n  .cp-multi-selector__main-input {\n  z-index: inherit;\n}\n\n.formGroup {\n  padding: 12px 40px 0 0;\n  margin-bottom: 0;\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n}\n\n.formGroupWithAnswerSet {\n  padding-left: 0;\n}\n\n.answerSetButtonIcon {\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `src-source-forms-questions-question-styles__label`,
	"input": `src-source-forms-questions-question-styles__input`,
	"inputBox": `src-source-forms-questions-question-styles__inputBox`,
	"formGroup": `src-source-forms-questions-question-styles__formGroup`,
	"formGroupWithAnswerSet": `src-source-forms-questions-question-styles__formGroupWithAnswerSet`,
	"answerSetButtonIcon": `src-source-forms-questions-question-styles__answerSetButtonIcon`
};
export default ___CSS_LOADER_EXPORT___;
