import { updateSourceFormSectionAnswers } from "./sections/source-form-sections.resource.js";
import * as types from "./source-form-layout.types.js";
import { generalToast } from "toast-service!sofe";
import * as sentryErrorLogging from "error-logging!sofe";
import { handleError } from "src/handle-error";
import {
  newAnswersRetrieved,
  answerAsObservable,
  answerLoadingAsObservable,
} from "./answers/answer.actions.js";
import { newSectionMessage } from "./ui-forms.actions.js";
import { requestIsForCurrentRoute } from "./answers/answer.helpers.js";
import { find, noop, includes } from "lodash";

export function getSourceForm(
  paramsObj,
  errCallback = noop,
  successCallback = noop,
  taxpayerTypeRequired = true
) {
  return (dispatch) => {
    if (taxpayerTypeRequired && !paramsObj.TaxpayerType) return;
    answerLoadingAsObservable.next(true);

    /* We do a POST instead of a GET, to ensure the rules have been run properly */
    updateSourceFormSectionAnswers({
      ...paramsObj,
      version: { id: paramsObj.versionId },
      answers: { TaxpayerType: paramsObj.TaxpayerType },
    }).subscribe(
      (result) => {
        answerAsObservable.next(result);
        answerLoadingAsObservable.next(false);

        // this is where the answers come in
        if (result.section && !result.section.id) {
          const msg = `There are no sections in this source form`;
          generalToast(msg);
          sentryErrorLogging.captureMessage(msg);
        }

        if (requestIsForCurrentRoute(paramsObj)) {
          dispatch(newAnswersRetrieved(result));
        }
        successCallback();
      },

      (error) => {
        handleError(error);
        errCallback(error);
      }
    );
  };
}

export function savingAnswer() {
  return (dispatch, getState) => {
    dispatch({
      type: types.SAVING_ANSWER,
    });

    dispatch(newSectionMessage("Saving..."));
  };
}

export function doneSavingAnswer() {
  return {
    type: types.DONE_SAVING_ANSWER,
  };
}

export function newSourceFormLayout(sourceFormLayout) {
  let sourceFormPath = getActiveSourceFormFromUrl(window.location.href);

  return {
    type: types.NEW_SOURCE_FORM_LAYOUT,
    sourceFormLayout: includes(sourceFormPath, "-")
      ? getNestedForm({ sections: [sourceFormLayout] }, sourceFormPath)
      : sourceFormLayout,
  };
}

export function resetSourceFormLayout() {
  return {
    type: types.NEW_SOURCE_FORM_LAYOUT,
    sourceFormLayout: null,
  };
}

function getActiveSourceFormFromUrl(url) {
  const indexOfSourceforms = window.location.href.indexOf("sourceforms");
  const partial = window.location.href.substring(indexOfSourceforms + 12);

  return indexOfSourceforms !== -1
    ? partial.substring(0, partial.indexOf("/"))
    : "";
}

function getNestedForm(layout, path) {
  if (!path.length) return layout;

  const splits = path.split("-");
  let part = splits.splice(0, 1)[0];

  if (part.includes(":")) part = part.substring(0, part.indexOf(":"));

  return getNestedForm(
    find(layout.sections, { formId: part }),
    splits.join("-")
  );
}
