// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-options-selectable-card-style__card {
  width: 100%;
  height: 56px;
  border: 1px solid var(--cps-color-athens);
  border-radius: 5px;
  cursor: pointer;
}

.src-client-survey-options-selectable-card-style__cardChecked {
  border: 2px solid var(--cps-color-primary);
}

.src-client-survey-options-selectable-card-style__icon {
  margin-left: 16px;
  margin-right: 8px;
}

.src-client-survey-options-selectable-card-style__grid {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.src-client-survey-options-selectable-card-style__gridLeft {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.src-client-survey-options-selectable-card-style__description {
  font-size: 13px;
  color: var(--cps-color-primary-text);
}

.src-client-survey-options-selectable-card-style__checkmark {
  margin-right: 20px; /* The cps-icon class adds in some padding, we want to make it a total of 24px */
  color: var(--cps-color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/options/selectable-card.style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yCAAyC;EACzC,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,kBAAkB,EAAE,gFAAgF;EACpG,+BAA+B;AACjC","sourcesContent":[".card {\n  width: 100%;\n  height: 56px;\n  border: 1px solid var(--cps-color-athens);\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.cardChecked {\n  border: 2px solid var(--cps-color-primary);\n}\n\n.icon {\n  margin-left: 16px;\n  margin-right: 8px;\n}\n\n.grid {\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.gridLeft {\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.description {\n  font-size: 13px;\n  color: var(--cps-color-primary-text);\n}\n\n.checkmark {\n  margin-right: 20px; /* The cps-icon class adds in some padding, we want to make it a total of 24px */\n  color: var(--cps-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `src-client-survey-options-selectable-card-style__card`,
	"cardChecked": `src-client-survey-options-selectable-card-style__cardChecked`,
	"icon": `src-client-survey-options-selectable-card-style__icon`,
	"grid": `src-client-survey-options-selectable-card-style__grid`,
	"gridLeft": `src-client-survey-options-selectable-card-style__gridLeft`,
	"description": `src-client-survey-options-selectable-card-style__description`,
	"checkmark": `src-client-survey-options-selectable-card-style__checkmark`
};
export default ___CSS_LOADER_EXPORT___;
