import * as actionTypes from "./redux-client.types.js";

const defaultState = {
  id: null,
};

export default function reduce(state = defaultState, action) {
  if (action.type === actionTypes.SET_REDUX_CLIENT) {
    return action.reduxClient;
  }

  if (action.type === actionTypes.RESET_REDUX_CLIENT) {
    return {
      ...defaultState,
    };
  }

  return state;
}
