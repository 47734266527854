import React from "react";
import { NavContent } from "primary-navbar!sofe";
import { UserTenantProps } from "cp-client-auth!sofe";
import { CpLoader } from "canopy-styleguide!sofe";
import { capitalize } from "lodash";

import CollectionAnalyticsHeader from "./collection-analytics-header.component.js";
import ReportInspector from "./report-inspector.component.js";
import ProgramSectionStatus from "../resolution-cases/program-sections/status.component.js";
import {
  getSectionFromResolutionCase,
  getAvailableReports,
} from "./collection-analytics.helpers.js";
import styles from "./collection-analytics.styles.css";

@UserTenantProps({ permissions: { hasSmePermissions: "sme" } })
export default class CollectionAnalytics extends React.Component {
  componentDidMount() {
    checkReportStatus(this.props);
    checkFetchProgramSection(this.props);
  }

  componentDidUpdate() {
    checkFetchProgramSection(this.props);
    checkReportStatus(this.props);
  }

  componentWillUnmount() {
    this.props.actions.resetAnalyticsState();
    this.props.actions.toggleSmeMode(false);
  }

  render() {
    const isSme = this.props.permissions.hasSmePermissions;
    const section = this.props.resolutionCaseProgramSection.activeSection;

    const CurrentReport = (
      getAvailableReports(
        (report) => report.urlName === this.props.match.params.report
      ) || {}
    ).component;
    return (
      <NavContent
        className={`wide-menu`}
        hasTopnavSecondary={false}
        clientMenuPossible={true}
      >
        <div className={`cps-flexible-focus`}>
          <div className={`cps-card ${styles.topRow}`}>
            <div>
              <span className="cps-subheader">Analytics</span>
              {isSme && (
                <span style={{ paddingLeft: "24px" }}>
                  Report Name: Collection Analytics{" "}
                  {capitalize(this.props.resolutionCase.taxpayer_type)} - 48
                  report values
                </span>
              )}
            </div>
            <div className={styles.controls}>
              {isSme && (
                <ReportInspector
                  toggleSmeMode={this.props.actions.toggleSmeMode}
                  smeMode={this.props.smeMode}
                />
              )}
              <ProgramSectionStatus
                clientId={this.props.match.params.clientId}
                resolutionCase={this.props.resolutionCase}
                programSlug="collections"
                section={section}
                clientCollaborators={this.props.reduxClient.users}
                actions={this.props.actions}
              />
            </div>
          </div>
          <CollectionAnalyticsHeader params={this.props.match.params} />
          {!this.props.analytics.defaultState && CurrentReport ? (
            <CurrentReport
              updateAnswer={(name, answer) => {
                const params = {
                  clients: Number(this.props.match.params.clientId),
                  resolutionCases: Number(
                    this.props.match.params.resolutionCaseId
                  ),
                  versions: this.props.version.id,
                };
                this.props.actions.updateResolutionCaseAnswer(
                  params,
                  name,
                  answer,
                  () => {
                    this.props.actions.getReportAction(
                      this.props.match.params.clientId,
                      this.props.match.params.resolutionCaseId,
                      this.props.version,
                      this.props.revision,
                      getReportName(this.props.resolutionCase.taxpayer_type)
                    );
                  }
                );
              }}
              updateReduxAnswer={this.props.actions.updateAnalyticReduxAnswer}
              answers={this.props.answers}
              smeMode={this.props.smeMode}
              client={this.props.reduxClient}
              report={this.props.report}
            />
          ) : (
            <div className={`${styles.loader}`}>
              <CpLoader />
            </div>
          )}
        </div>
      </NavContent>
    );
  }
}

function checkFetchProgramSection(props) {
  const sectionSummary = getSectionFromResolutionCase(props.resolutionCase);
  const sectionId = sectionSummary && sectionSummary.id;
  if (
    sectionId &&
    props.resolutionCaseProgramSection.activeSection.id !== sectionId &&
    props.resolutionCaseProgramSection.sectionBeingFetched !== sectionId
  ) {
    props.actions.getProgramSection(
      props.match.params.clientId,
      props.match.params.resolutionCaseId,
      sectionId
    );
  }
}

function checkReportStatus(props) {
  if (!props.analytics.gettingReport && props.analytics.defaultState) {
    let { clientId, resolutionCaseId } = props.match.params;
    props.actions.getReportAction(
      clientId,
      resolutionCaseId,
      props.version,
      props.revision,
      getReportName(props.resolutionCase.taxpayer_type)
    );
    props.actions.getFederalTaxesDue({
      clients: Number(props.match.params.clientId),
      resolutionCases: Number(props.match.params.resolutionCaseId),
    });
  }
}

function getReportName(taxpayerType) {
  return `collectionAnalytics${capitalize(taxpayerType)}`;
}
