import React, { Component } from "react";
import { object, string, array, bool } from "prop-types";
import Cancelable from "react-disposable-decorator";
import { get } from "lodash";
import { Scoped, k } from "kremling";
import { CpButton } from "canopy-styleguide!sofe";

@Cancelable
export default class SpecialAllocationsButton extends Component {
  static propTypes = {
    question: object,
    clientId: string,
    resolutionCaseId: string,
    version: string,
    revision: string,
    sourceFormId: string,
    folderIds: array,
    taskId: string,
    actions: object,
    inTable: bool,
  };
  state = {
    drawerComponent: null,
    drawerIsOpen: false,
  };

  render() {
    const {
      question,
      answers,
      clientId,
      resolutionCaseId,
      version,
      revision,
      sourceFormId,
      actions,
    } = this.props;
    const Drawer = this.state.drawerComponent || (() => <div />);
    const hasBeenAllocated = get(answers, `serverAnswers.${question.name}`);

    return (
      <Scoped css={css}>
        <div>
          <CpButton
            btnType="tertiary"
            className={hasBeenAllocated ? "has-been-allocated" : ""}
            onClick={this.toggleSpecialAllocations}
          >
            {hasBeenAllocated ? "Allocated" : question.actionText}
          </CpButton>

          <Drawer
            isOpen={this.state.drawerIsOpen}
            onClose={this.toggleSpecialAllocations}
            onSave={() => {}}
            question={question}
            clientId={clientId}
            resolutionCaseId={resolutionCaseId}
            version={version}
            revision={revision}
            answers={answers.serverAnswers}
            sourceFormId={sourceFormId}
            hasBeenAllocated={hasBeenAllocated}
            actions={actions}
          />
        </div>
      </Scoped>
    );
  }

  toggleSpecialAllocations = () => {
    if (!this.state.drawerIsOpen) {
      import(
        /* webpackChunkName: "specialAllocations" */ "src/special-allocations/special-allocations.component"
      ).then((module) => {
        this.setState({ drawerComponent: module.default }, () => {
          // wait till the next event loop to turn on the drawer
          setTimeout(() => {
            this.setState({ drawerIsOpen: true });
          }, 0);
        });
      });
    } else {
      this.setState({ drawerIsOpen: false });
    }
  };
}

const css = k`
  .has-been-allocated {
    color: var(--cps-color-vibrant-ocean);
    font-weight: 700;
  }
`;
