// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-submit-submit-survey-styles__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.src-client-survey-submit-submit-survey-styles__container > * {
  margin-bottom: 1.6rem;
}

.src-client-survey-submit-submit-survey-styles__container > *:last-child {
  margin-bottom: 0px;
}

.src-client-survey-submit-submit-survey-styles__boldQuestion {
  font-weight: bold;
  font-size: 2.4rem;
}

.src-client-survey-submit-submit-survey-styles__explanation {
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
}

.src-client-survey-submit-submit-survey-styles__section {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  height: 4rem;
  margin-right: 0.2rem;
  cursor: default;
}

.src-client-survey-submit-submit-survey-styles__sectionHeader {
  border-radius: 1.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.6rem;
}

.src-client-survey-submit-submit-survey-styles__incompleteSectionList {
  align-self: flex-start;
}

.src-client-survey-submit-submit-survey-styles__subsectionWrapper {
  margin-left: 2.4rem;
  width: 76rem;
  padding: 3.2rem;
}

@media only screen and (max-width: 599px) and (orientation: portrait),
  screen and (max-height: 599px) and (orientation: landscape) {
  .src-client-survey-submit-submit-survey-styles__subsectionWrapper {
    margin-left: 0;
    margin-top: 2.4rem;
    width: unset;
  }

  .src-client-survey-submit-submit-survey-styles__allDoneWrapper {
    width: unset;
  }
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/submit/submit-survey.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,qBAAqB;EACrB,YAAY;EACZ,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;;AAEA;;EAEE;IACE,cAAc;IACd,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.container > * {\n  margin-bottom: 1.6rem;\n}\n\n.container > *:last-child {\n  margin-bottom: 0px;\n}\n\n.boldQuestion {\n  font-weight: bold;\n  font-size: 2.4rem;\n}\n\n.explanation {\n  width: 100%;\n  font-size: 1.6rem;\n  line-height: 2.4rem;\n  text-align: center;\n}\n\n.section {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  color: inherit;\n  text-decoration: none;\n  height: 4rem;\n  margin-right: 0.2rem;\n  cursor: default;\n}\n\n.sectionHeader {\n  border-radius: 1.5rem;\n  width: 2rem;\n  height: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 1.6rem;\n}\n\n.incompleteSectionList {\n  align-self: flex-start;\n}\n\n.subsectionWrapper {\n  margin-left: 2.4rem;\n  width: 76rem;\n  padding: 3.2rem;\n}\n\n@media only screen and (max-width: 599px) and (orientation: portrait),\n  screen and (max-height: 599px) and (orientation: landscape) {\n  .subsectionWrapper {\n    margin-left: 0;\n    margin-top: 2.4rem;\n    width: unset;\n  }\n\n  .allDoneWrapper {\n    width: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-client-survey-submit-submit-survey-styles__container`,
	"boldQuestion": `src-client-survey-submit-submit-survey-styles__boldQuestion`,
	"explanation": `src-client-survey-submit-submit-survey-styles__explanation`,
	"section": `src-client-survey-submit-submit-survey-styles__section`,
	"sectionHeader": `src-client-survey-submit-submit-survey-styles__sectionHeader`,
	"incompleteSectionList": `src-client-survey-submit-submit-survey-styles__incompleteSectionList`,
	"subsectionWrapper": `src-client-survey-submit-submit-survey-styles__subsectionWrapper`,
	"allDoneWrapper": `src-client-survey-submit-submit-survey-styles__allDoneWrapper`
};
export default ___CSS_LOADER_EXPORT___;
