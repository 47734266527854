import * as types from "./context.types.js";

export function newLoggedInUser(loggedInUser) {
  return {
    type: types.SET_LOGGED_IN_USER,
    loggedInUser,
  };
}

export function newTenant(tenant) {
  return {
    type: types.SET_TENANT,
    tenant,
  };
}
