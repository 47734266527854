import * as types from "./answer-order.types.js";
import {
  GETDefaultAnswerOrder,
  POSTDefaultAnswerOrder,
} from "./answer-order.resource.js";
import { handleError } from "src/handle-error";

export function getDefaultAnswerOrder(callback) {
  return (dispatch, getState) => {
    const clientId = getState().reduxClient.id;
    const resolutionCaseId = getState().resolutionCase.id;

    if (!clientId)
      throw new Error(
        `Cannot get default answer order without the active client object`
      );
    if (!resolutionCaseId)
      throw new Error(
        `Cannot get default answer order without the active resolution case object`
      );

    dispatch(gettingDefaultOrder());

    GETDefaultAnswerOrder(clientId, resolutionCaseId).subscribe(
      (answerOrder) => {
        dispatch(gotDefaultAnswerOrder(answerOrder));
        if (callback) {
          callback(answerOrder);
        }
      },
      (error) => {
        dispatch({
          type: types.CANCEL_GETTING_DEFAULT_ANSWER_ORDER,
        });
        handleError(error);
      }
    );
  };
}

export function updateDefaultAnswerOrder(newAnswerOrder) {
  return (dispatch, getState) => {
    const clientId = getState().reduxClient.id;
    const resolutionCaseId = getState().resolutionCase.id;

    if (!clientId)
      throw new Error(
        `Cannot get default answer order without the active client object`
      );
    if (!resolutionCaseId)
      throw new Error(
        `Cannot get default answer order without the active resolution case object`
      );

    POSTDefaultAnswerOrder(
      newAnswerOrder,
      clientId,
      resolutionCaseId
    ).subscribe((answerOrder) => {
      dispatch(gotDefaultAnswerOrder(answerOrder));
    }, handleError);
  };
}

export function gotDefaultAnswerOrder(answerOrder) {
  return {
    type: types.GOT_ENGAGEMENT_DEFAULT_ANSWER_ORDER,
    defaultOrder: answerOrder,
  };
}

export function gettingDefaultOrder() {
  return {
    type: types.GETTING_ENGAGEMENT_DEFAULT_ANSWER_ORDER,
  };
}
