import { partial, property, findIndex, findLastIndex } from "lodash";
import { dismissClientWelcome } from "src/client-survey/client-portal/client-portal-welcome.helpers.js";

export const getNextSectionUrl = partial(getNewSectionUrl, "next");
export const getPrevSectionUrl = partial(getNewSectionUrl, "prev");

function getNewSectionUrl(direction, sections, oldSectionId, oldSubsectionId) {
  /* Sections have ids and indices.
   * Subsections only have indices.
   * Sections have visibility.
   * Subsections have visibility.
   */
  let oldSection;
  let oldSectionIndex;
  if (oldSectionId) {
    oldSection = sections.find(id(oldSectionId));
    oldSectionIndex = sections.findIndex(id(oldSectionId));
  } else {
    if (
      sections[sections.length - 1].visible &&
      (!sections[sections.length - 1].subSections ||
        sections[sections.length - 1].subSections.some(property("visible")))
    ) {
      oldSection = sections[sections.length - 1].id;
      oldSectionIndex = sections.length - 1;
    } else {
      oldSection = sections[sections.length - 2].id;
      oldSectionIndex = sections.length - 2;
    }
  }

  const getNewIndex = partial(getNewSectionIndex, direction);
  const switchToNewSection =
    direction === "prev"
      ? isFirstVisibleSubsection(oldSubsectionId, oldSection)
      : isLastVisibleSubsection(oldSubsectionId, oldSection);
  const indexDifference = direction === "prev" ? -1 : 1;

  const newSectionIndex = switchToNewSection
    ? getNewIndex(oldSectionIndex + indexDifference, sections)
    : oldSectionIndex;

  const newSection = sections[newSectionIndex];
  const newSectionId = newSection.id;
  const stayingInSection = newSection === oldSection;
  const searchSubsectionInNewSection = beginSubsectionSearch(
    direction,
    stayingInSection,
    newSection,
    oldSubsectionId
  );
  const newSubsectionId = getNewIndex(
    searchSubsectionInNewSection,
    newSection.subSections
  );

  return `/sections/${newSectionId}/subsections/${newSubsectionId}`;
}
function getNewSectionIndex(direction, searchIndex, sections) {
  const searchFunc = direction === "prev" ? findLastIndex : findIndex;
  return searchFunc(sections, foundSection, searchIndex);

  function foundSection(section) {
    const subsectionsVisible =
      !section.subSections || section.subSections.some(property("visible"));
    return section.visible && subsectionsVisible;
  }
}

function isFirstVisibleSubsection(subsectionIndex, section) {
  return (
    subsectionIndex === findIndex(section.subSections, property("visible"))
  );
}

function isLastVisibleSubsection(subsectionIndex, section) {
  return (
    subsectionIndex === findLastIndex(section.subSections, property("visible"))
  );
}

export function id(id) {
  return function (obj) {
    return obj.id === id;
  };
}

function beginSubsectionSearch(
  direction,
  remainingInSection,
  newSection,
  oldSubsectionId
) {
  let result;
  if (remainingInSection) {
    result = direction === "prev" ? oldSubsectionId - 1 : oldSubsectionId + 1;
  } else {
    result = direction === "prev" ? newSection.subSections.length - 1 : 0;
  }

  return result;
}

export const hasPrevious = partial(hasNew, "prev");
export const hasNext = partial(hasNew, "next");

function hasNew(direction, sections, sectionIndex, subsectionIndex) {
  const searchFunc = direction === "prev" ? findLastIndex : findIndex;
  return searchFunc(sections, foundSection, sectionIndex) >= 0;

  function foundSection(section) {
    const remainingInSection = sections[sectionIndex] === section;
    const subsectionSearchIndex = beginSubsectionSearch(
      direction,
      remainingInSection,
      section,
      subsectionIndex
    );
    const validSubsectionSearchIndex =
      subsectionSearchIndex >= 0 &&
      subsectionSearchIndex < section.subSections.length;
    const subsectionsVisible =
      validSubsectionSearchIndex &&
      searchFunc(
        section.subSections,
        property("visible"),
        subsectionSearchIndex
      ) >= 0;

    return section.visible && subsectionsVisible;
  }
}

export function navigate(surveyId, evt, historyNavigate, loggedInUser) {
  evt.preventDefault();
  if (loggedInUser.role === "Client") {
    dismissClientWelcome(surveyId);
  }
  historyNavigate();
}
