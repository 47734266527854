import React from "react";
import Cancelable from "react-disposable-decorator";
import { Scoped, always } from "kremling";
import { fromEvent } from "rxjs";
import { filter, throttleTime } from "rxjs/operators";
import { handleError } from "src/handle-error";
import { CpLoader } from "canopy-styleguide!sofe";
import Triangle from "./triangle.component";
import { pixToPerc, actuallyANumber } from "./overrides.helper";

@Cancelable
export default class OverrideDialog extends React.Component {
  state = {
    overrideValue: this.props.override,
    spinner: false,
  };

  componentDidMount() {
    if (this.field) {
      this.field.focus();
      this.props.cancelWhenUnmounted(
        fromEvent(this.field, "keydown")
          .pipe(filter((e) => e.key === "Enter" || e.key === "Escape"))
          .subscribe((e) =>
            e.key === "Enter"
              ? this.patchNewOverrideValue()
              : this.props.onCompleted()
          ),
        fromEvent(window, "click")
          .pipe(
            throttleTime(500),
            filter(
              (e) =>
                e.target !== this.props.icon &&
                e.target.className.indexOf("aligner") < 0 &&
                e.target !== this.modal &&
                !this.modal.contains(e.target)
            )
          )
          .subscribe((e) => this.props.onCompleted())
      );
    }
  }

  render() {
    const left = pixToPerc(this.props.x, this.props.width);
    return (
      <Scoped css={css}>
        <Triangle edge={left >= 90} />
        <div
          className={always("cps-form-group modal")
            .maybe("horizontal", left < 65)
            .maybe("vertical", left >= 65)
            .maybe("edge", left >= 90)}
          ref={(modal) => (this.modal = modal)}
        >
          <input
            ref={(field) => (this.field = field)}
            className="cps-form-control"
            placeholder="value"
            onChange={(e) => this.setState({ overrideValue: e.target.value })}
            value={this.state.overrideValue}
          />
          <button
            onClick={this.patchNewOverrideValue}
            className="cps-btn +primary override"
          >
            {this.state.spinner ? <CpLoader /> : "Override"}
          </button>
          <button onClick={this.deleteOverride} className="cps-btn ghost">
            <i className="cps-icon cps-icon-refresh" />
            Revert
          </button>
        </div>
      </Scoped>
    );
  }

  deleteOverride = () => {
    const {
      clientId,
      resolutionCaseId,
      noticeId,
      version,
      form,
      cancelWhenUnmounted,
      onCompleted,
      fieldName,
      getOverrides,
      deleteOverrides,
    } = this.props;

    cancelWhenUnmounted(
      deleteOverrides(
        clientId,
        resolutionCaseId,
        version,
        form,
        fieldName,
        noticeId
      ).subscribe(() => {
        getOverrides(
          form,
          clientId,
          resolutionCaseId,
          version,
          onCompleted,
          noticeId
        );
      }, handleError)
    );
  };

  spinner = () => this.setState({ spinner: true });

  patchNewOverrideValue = () => {
    const {
      clientId,
      resolutionCaseId,
      version,
      form,
      cancelWhenUnmounted,
      patchOverrides,
      onCompleted,
      x,
      y,
      fieldName,
      getOverrides,
      noticeId,
    } = this.props;

    cancelWhenUnmounted(
      patchOverrides(
        clientId,
        resolutionCaseId,
        version,
        form,
        {
          [fieldName]: actuallyANumber(this.state.overrideValue),
        },
        noticeId
      ).subscribe((overrides) => {
        this.spinner();
        getOverrides(
          form,
          clientId,
          resolutionCaseId,
          version,
          onCompleted,
          noticeId
        );
      })
    );
  };
}

const css = `
  & .modal {
    margin-top: 10px;
    z-index: 150;
    display: flex;
    background-color: var(--cps-color-cool-gray);
    border-radius: 5px;
    padding: 15px;
    position: absolute;
  }

  & .horizontal {
    justify-content: space-between;
    min-width: 384px;
    min-height: 60px;
  }

  & .vertical {
    flex-direction: column;
    max-width: 108px;
    align-items: center;
    justify-content: space-between;
    min-height: 177px;
  }

  & .edge {
    margin-left: -25px;
    margin-top: 15px;
  }

  & .override-input .horizontal {
    max-width: 174px;
  }
  & .override-input .vertical {
    max-width: 78px;
  }
  & .ghost {
    background-color: transparent;
    border: 1px solid var(--cps-color-slate);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    max-width: 100px;
  }

  & .vertical .cps-btn {
    max-width: 80px;
    margin-left: 0;
  }

  & .vertical .override {
    padding: 4px 12px;
  }

  & .horizontal .cps-btn {
    margin-left: 10px;
  }
`;
