import * as types from "./version.types.js";

export function newSmeVersion(version, revision) {
  return {
    type: types.GOT_VERSION,
    version,
    revision,
  };
}

export function setFormSet(formSet) {
  return {
    type: "UPDATED_FORM_SET",
    formSet,
  };
}

export function updateSequenceFilter(sequence) {
  sequence = sequence || "";

  localStorage.setItem("cp:sequenceFilter", sequence);

  return {
    type: "UPDATED_SEQUENCE_FILTER",
    sequenceFilter: sequence,
  };
}
