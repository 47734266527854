import { capitalize } from "lodash";

export function questionHasAnotherDataSource(question, overrideAnswerSet) {
  const isActuallyASingleQuestion = question.type === "QUESTION";
  const hasAnotherDataSource =
    question.isOverridden ||
    (question.answerSet && question.answerSet !== overrideAnswerSet);
  return isActuallyASingleQuestion && hasAnotherDataSource;
}

export function getAnswerSetName(answerSet, sentenceCase) {
  const tooltips = {
    clientSurveys: "Client Survey Value",
    crm: "Client Record Value",
    taxForms: "Tax Form Value",
  };

  if (tooltips[answerSet]) {
    return sentenceCase ? capitalize(tooltips[answerSet]) : tooltips[answerSet];
  } else {
    throw new Error(
      `Cannot create tooltip text for clipboard icon in source forms - unknown answer set '${answerSet}'`
    );
  }
}
