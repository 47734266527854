import React from "react";
import { getAvailableReports } from "./collection-analytics.helpers.js";
import styles from "./collection-analytics-header.styles.css";

const activeStyle = {
  fontWeight: 600,
  borderBottom: ".2rem solid var(--cps-color-primary)",
  color: "var(--cps-color-primary-text)",
};
const noStyle = {};
export default function CollectionAnalyticsHeader(props) {
  return (
    <div
      className={`cps-card cps-card-nonrounded-bottom cps-card-bottom-line ${styles.header}`}
    >
      {getAvailableReports().map((report) => {
        return (
          <a
            style={{ color: "inherit", textDecoration: "none" }}
            href={`#/forms/clients/${props.params.clientId}/resolution-cases/${props.params.resolutionCaseId}/collections/analytics/${report.urlName}`}
            key={report.urlName}
          >
            <div
              className={`${styles.headerItems}`}
              style={
                report.urlName === props.params.report ? activeStyle : noStyle
              }
            >
              {report.name}
            </div>
          </a>
        );
      })}
    </div>
  );
}
