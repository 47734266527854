import React from "react";
import ReactDOM from "react-dom";
import mountComponent from "disposable-component";
import { Scoped, maybe } from "kremling";
import {
  patchOverrides,
  deleteOverrides,
} from "./tax-form-overrides.resource.js";
import OverrideDialog from "./override-dialog.component.js";
import { pixToPerc, alignmentStyle } from "./overrides.helper.js";

export default class OverrideFields extends React.Component {
  state = {
    activeDialog: false,
  };

  overridableTypes = ["text"];

  render() {
    const { height, width, fields = [] } = this.props;
    const overrideCss = `
      & .cps-icon-edit{
        cursor: pointer;
        color: var(--cps-color-vibrant-ocean);
        opacity: 0;
        border-radius: 3px;
        font-size: 2rem;
        background-color: transparent;
        max-height: 16px;
      }
      & .override-field:hover .cps-icon-edit {
        opacity: 1;
      }
      & .override-text {
        font-size: 10px;
        max-height: 13px;
        background-color: var(--cps-color-bumble);
      }
    `;

    return (
      <div className="overrides">
        {fields.map((field) => {
          return field.overridable &&
            this.overridableTypes.includes(field.type) ? (
            <Scoped
              css={overrideCss}
              namespace={field.name.toLowerCase()}
              key={field.name}
            >
              <div
                ref={(f) => (this[field.name] = f)}
                style={{
                  bottom: `${
                    pixToPerc(field.y, height) -
                    pixToPerc(field.height, height) +
                    0.2
                  }%`,
                  left: `${pixToPerc(field.x, width)}%`,
                  width: `${pixToPerc(field.width, width + 10)}%`,
                  height: `${pixToPerc(field.height, height - 0.8)}%`,
                  position: "absolute",
                  color: "#881AE9",
                }}
                className={maybe("override-field", !this.state.activeDialog)}
              >
                <div
                  className="aligner"
                  style={{
                    display: "flex",
                    justifyContent: alignmentStyle(field.alignment),
                    overflow: "hidden",
                  }}
                >
                  <i
                    ref={(icon) => (this[`i${field.name}`] = icon)}
                    onClick={() =>
                      this.showOverrideDialog(
                        field.x,
                        field.y,
                        field.name,
                        field.override
                      )
                    }
                    className="cps-icon cps-icon-edit"
                  />
                </div>
              </div>
            </Scoped>
          ) : null;
        })}
      </div>
    );
  }
  showOverrideDialog = (x, y, fieldName, override) => {
    if (this.state.activeDialog) {
      return null;
    } else {
      this.setState({ activeDialog: true });
    }

    let tack, el;
    return mountComponent(
      (onNext, onCompleted, onError) => {
        const overrideProps = {
          patchOverrides,
          deleteOverrides,
          clientId: this.props.clientId,
          resolutionCaseId: this.props.resolutionCaseId,
          noticeId: this.props.noticeId,
          version: this.props.version,
          form: this.props.form,
          x,
          y,
          fieldName,
          onCompleted,
          getOverrides: this.props.getOverrides,
          width: this.props.width,
          override,
          icon: this[`i${fieldName}`],
        };

        el = this[fieldName];
        tack = document.createElement("div");
        (tack.style.display = "flex"),
          (tack.style.flexDirection = "column"),
          (tack.style.alignItems = "center");

        el.appendChild(tack);

        ReactDOM.render(<OverrideDialog {...overrideProps} />, tack);
      },
      () => {
        tack &&
          ReactDOM.unmountComponentAtNode(tack) &&
          tack.parentNode.removeChild(tack);
        this.setState({ activeDialog: false });
      }
    ).subscribe(() => {});
  };
}
