import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function getResolutionCase(clientId, resolutionCaseId) {
  if (!clientId) {
    throw new Error(`Cannot fetch resolution case without client id`);
  }

  if (!resolutionCaseId) {
    throw new Error(`Cannot fetch resolution case without resolution case id`);
  }

  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}`
  ).pipe(pluck("resolution_cases"));
}

export function patchResolutionCase({ clientId, resolutionCaseId, data }) {
  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}`,
    {
      method: "PATCH",
      body: {
        resolution_cases: data,
      },
    }
  ).pipe(pluck("resolution_cases"));
}
