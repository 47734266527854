import PropTypes from "prop-types";

export const SUMMARY_TABLE_ANSWER_LIST = PropTypes.arrayOf(PropTypes.shape({}));

export const SUMMARY_TABLE_ANSWER_SUMMARY = PropTypes.arrayOf(
  PropTypes.arrayOf(PropTypes.any.isRequired)
);

export const SUMMARY_TABLE_ANSWER = PropTypes.shape({
  answers: SUMMARY_TABLE_ANSWER_LIST,
  editingIndex: PropTypes.number,
  summary: SUMMARY_TABLE_ANSWER_SUMMARY,
});
