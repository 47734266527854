import * as types from "./collection-analytics.types.js";
import { getReport } from "./collection-analytics.resource.js";
import {
  sendResolutionCaseAnswer,
  getResolutionCaseAnswers,
} from "src/resources/resolution-case-answers.resource.js";
import { successToast, generalToast } from "toast-service!sofe";
import { partial, noop } from "lodash";
import * as sentryErrorLogging from "error-logging!sofe";
import { handleError } from "src/handle-error";

export function getReportAction(
  clients,
  resolutionCases,
  version,
  revision,
  name
) {
  return function (dispatch) {
    dispatch({
      type: types.GETTING_REPORT,
    });
    getReport(
      Number(clients),
      Number(resolutionCases),
      version.id,
      revision.id,
      name
    ).subscribe((reports) => {
      dispatch({
        type: types.LOADED_REPORT,
        load: reports.values,
      });
    }, handleError);
  };
}

export function toggleSmeMode(bool) {
  return function (dispatch) {
    dispatch({
      type: types.TOGGLED_SME_MODE,
      load: bool,
    });
  };
}

export function updateResolutionCaseAnswer(
  requestObj,
  name,
  answer,
  callback = noop
) {
  return function (dispatch) {
    sendResolutionCaseAnswer(requestObj, name, answer).subscribe(
      (answer) => {
        successToast("Amount due successfully updated!");
        callback();
        if (window.localStorage.getItem("justforjoel...")) {
          setTimeout(partial(successToast, "biznatch!"), 3500);
        }
      },
      (error) => {
        generalToast("We were unable to update amount due at this time.");
        sentryErrorLogging.captureException(error);
        if (window.localStorage.getItem("justforjoel...")) {
          setTimeout(partial(generalToast, "Sucks to be you."), 3500);
        }
      }
    );
  };
}

export function getFederalTaxesDue(requestObj) {
  return function (dispatch) {
    getResolutionCaseAnswers({
      ...requestObj,
      answers: "FederalTaxesDue",
    }).subscribe((answer) => {
      dispatch({
        type: types.GOT_FEDERAL_TAXES,
        load: answer,
      });
    }, handleError);
  };
}

export function updateAnalyticReduxAnswer(name, answer) {
  return {
    type: types.UPDATED_ANALYTICS_ANSWER,
    load: { [name]: answer },
  };
}

export function resetAnalyticsState() {
  return {
    type: types.RESET_ANALYTICS_STATE,
  };
}
