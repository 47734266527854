import * as types from "./version.types.js";

const defaultState = {
  version: null,
  revision: null,
  formSet: "federal",
  sequenceFilter: localStorage.getItem("cp:sequenceFilter") || "",
};

export default function smeVersionReducer(state = defaultState, action) {
  if (action.type === types.GOT_VERSION) {
    return {
      ...state,
      version: action.version,
      revision: action.revision,
    };
  }

  if (action.type === "UPDATED_FORM_SET") {
    return {
      ...state,
      formSet: action.formSet,
    };
  }

  if (action.type === "UPDATED_SEQUENCE_FILTER") {
    return {
      ...state,
      sequenceFilter: action.sequenceFilter,
    };
  }

  return state;
}
