import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./client-portal-welcome.styles.css";
import { checkFetchSubsection } from "../source-form/cssf-subsection.helpers.js";
import { partial } from "lodash";
import { dismissClientWelcome } from "./client-portal-welcome.helpers.js";

const sectionIdToFetch = "0";
const subsectionIndexToFetch = 0;

@connect((state) => ({
  clientSurvey: state.clientSurvey,
  sourceFormLayout: state.sourceFormLayout,
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
}))
export default class ClientPortalWelcome extends React.Component {
  componentDidMount() {
    const fetchSection = true;
    checkFetchSubsection(
      this.props,
      this.props,
      fetchSection,
      sectionIdToFetch,
      subsectionIndexToFetch
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      const fetchSection = false;
      checkFetchSubsection(
        prevProps,
        this.props,
        fetchSection,
        sectionIdToFetch,
        subsectionIndexToFetch
      );
    }
  }
  render() {
    const { props } = this;
    let {
      clientSurvey: { survey },
    } = props;
    if (survey === "default") {
      survey = {
        notification: {
          header: "",
          body: "",
          closing: "",
        },
      };
    }
    return (
      <div className={`${styles.welcomeWrapper} cps-card cps-fixed-focus`}>
        <div className={`${styles.notification}`}>
          <div className={`${styles.welcomeText} cps-color-primary`}>
            {survey.notification.header}
          </div>
          <div className={`${styles.body}`}>
            {(survey.notification.body || "")
              .split("\n")
              .map((line, i) =>
                line ? <div key={i}>{line}</div> : <br key={i} />
              )}
          </div>
          <div className={`${styles.closing}`}>
            {survey.notification.closing &&
              survey.notification.closing
                .split("\n")
                .map((line, index) => <div key={index}>{line}</div>)}
          </div>
        </div>
        <div className={`${styles.footer}`}>
          <Link
            to={`${props.baseUrl}/sections/0/subsections/0`}
            onClick={partial(goToSurvey, survey.id)}
          >
            <button className={`cps-btn cps-bg-color-primary`}>
              Get started
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

function goToSurvey(surveyId, evt) {
  dismissClientWelcome(surveyId);
}
