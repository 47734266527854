import React from "react";
import PropTypes from "prop-types";
import { get, sortBy, groupBy, values } from "lodash";
import styles from "./fixed-table.style.css";

import Question from "../questions/question.component.js";
import QuickLook from "../questions/quick-look.component";
import FixedTableText from "./fixed-table-text.component.js";
import { getWidth, isInputBox } from "./fixed-table.helper.js";

FixedTable.propTypes = {
  clientId: PropTypes.string.isRequired,
  answers: PropTypes.object.isRequired,
  parentName: PropTypes.string.isRequired,
  blockIndex: PropTypes.number.isRequired,
  questionIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string,
  question: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default function FixedTable(props) {
  const {
    question,
    answers,
    questionIndex,
    blockIndex,
    actions,
    clientId,
    resolutionCaseId,
    noticeId,
    version,
    revision,
    sourceFormId,
    folderIds,
    taskId,
    parentServerAnswers,
  } = props;

  const coords = sortBy(question.coordinates, (v) => v.x);

  // Turn cells into rows
  const rows = sortBy(
    values(groupBy(coords, (coord) => coord.y)),
    (row) => row[0].y
  );
  const firstCoord = getFirstCoord(rows);
  const headerPadding = 16;
  const shouldHideTable = () => {
    // if all of the rows are hidden from show/hide logic, then hide the table
    const rowVisibilities = get(question, "rowVisibilities");
    if (!rowVisibilities) return false;
    const numRows =
      new Set(question.coordinates.map((coord) => coord.y)).size - 1;
    const visibleRows = rowVisibilities.filter((row) => row.visible === true);

    if (numRows === rowVisibilities.length && visibleRows.length === 0) {
      return true;
    }
    return false;
  };
  const shouldHideRow = (row, question) => {
    // hide the row if the rowVisibilities.visible is false
    const rowVisibilities = get(question, "rowVisibilities");
    let hideRow = false;

    if (rowVisibilities) {
      const rowY = get(row[0], "y");
      const rowVisibility = rowVisibilities.find((row) => row.y === rowY);
      // rowVisibility.visible will be either true or false, if it doesn't exist then show row by default
      if (rowVisibility) hideRow = !get(rowVisibility, "visible");
    }

    return hideRow;
  };

  return (
    <div className={`${styles.table} ${shouldHideTable() ? styles.hide : ""}`}>
      {rows.map((row, i) => {
        const firstRow = i === 0;
        const addPadding = row.some(isInputBox);
        return (
          <div
            key={i}
            className={`${firstRow ? styles.headerRow : styles.row} ${
              shouldHideRow(row, question) ? styles.hide : ""
            }`}
            style={{ padding: firstRow ? `0 ${headerPadding}px` : "6px 0" }}
          >
            {row.map((coord, index) => {
              const s = {
                width:
                  getWidth(
                    coord.width,
                    firstRow,
                    index,
                    row.length,
                    headerPadding
                  ) + "px",
                borderBottom: firstRow
                  ? ".1rem solid var(--cps-color-silver)"
                  : 0,
                display: "flex",
                paddingTop: addPadding && !isInputBox(coord) ? "8px" : "0px",
              };
              const isFirstColumn = coord.x === 0;
              if (isFirstColumn && coord.y) {
                s.paddingLeft = "16px";
              }
              return (
                <div
                  key={index}
                  style={s}
                  className={`${styles.element} ${styles.textCenter}`}
                >
                  {coord.coordType === "question" ? (
                    <Question
                      {...props}
                      questionIndex={
                        shouldFocusFirstElement(
                          firstCoord,
                          coord,
                          blockIndex,
                          questionIndex
                        )
                          ? 0
                          : -1
                      }
                      inTable={true}
                      question={coord.question}
                      noLabel={true}
                      serverAnswer={answers.serverAnswers[coord.question.name]}
                      parentServerAnswers={parentServerAnswers}
                    />
                  ) : coord.coordType === "quicklook" ? (
                    <QuickLook
                      question={coord.question}
                      clientId={clientId}
                      resolutionCaseId={resolutionCaseId}
                      noticeId={noticeId}
                      version={version}
                      revision={revision}
                      sourceFormId={sourceFormId}
                      folderIds={folderIds}
                      taskId={taskId}
                      actions={actions}
                      inTable={true}
                    />
                  ) : (
                    <FixedTableText isHeader={!coord.y} text={coord.text} />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function getFirstCoord(rows) {
  return rows.reduce((found, row) => {
    if (found) return found;
    return row.reduce((found, coord) => {
      if (found) return found;
      if (coord.coordType === "question") return coord;
    }, null);
  }, null);
}

function shouldFocusFirstElement(firstCoord, coord, blockIndex, questionIndex) {
  return firstCoord === coord && blockIndex === 0 && questionIndex === 0;
}
