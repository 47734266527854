import * as types from "./client-survey.types.js";
import { cloneDeep, merge } from "lodash";

const defaultState = {
  clientSurveySourceFormId: null,
  gettingSourceFormId: false,
  gettingSubsection: false,
  survey: "default",
  clientUsers: [],
  sentSurvey: false,
  surveyOptions: {
    isOpen: false,
    selectedMenuItem: "clientAccess",
    survey: "default",
    answerOrder: [],
  },
  surveysForClient: "default",
};

export default function clientSurveyReducer(state = defaultState, action) {
  if (action.type === types.GETTING_CLIENT_SURVEY_SOURCE_FORM_ID) {
    return {
      ...state,
      gettingSourceFormId: true,
    };
  }

  if (action.type === types.SET_CLIENT_SURVEY_SOURCE_FORM_ID) {
    return {
      ...state,
      clientSurveySourceFormId: action.clientSurveySourceFormId,
      gettingSourceFormId: false,
    };
  }

  if (action.type === types.CLEAR_CLIENT_SURVEY_SOURCE_FORM_ID) {
    return {
      ...state,
      clientSurveySourceFormId: null,
      gettingSourceFormId: false,
      gettingSubsection: false,
    };
  }

  if (action.type === types.GETTING_CLIENT_SURVEY_SUBSECTION) {
    return {
      ...state,
      gettingSubsection: true,
    };
  }

  if (action.type === types.GOT_CLIENT_SURVEY_SUBSECTION) {
    return {
      ...state,
      gettingSubsection: false,
    };
  }

  if (action.type === types.GETTING_CLIENT_SURVEY) {
    return {
      ...state,
      gettingClientSurvey: true,
    };
  }

  if (action.type === types.GOT_SURVEY) {
    return {
      ...state,
      gettingClientSurvey: false,
      survey: action.survey,
    };
  }

  if (action.type === types.UPDATE_SURVEY) {
    return {
      ...state,
      survey: {
        ...state.survey,
        ...action.load,
      },
    };
  }

  if (action.type === types.GOT_CLIENT_USERS) {
    return { ...state, clientUsers: action.load };
  }

  if (action.type === types.RESET_CLIENT_SURVEY) {
    return defaultState;
  }

  if (action.type === types.OPEN_SURVEY_OPTIONS) {
    return merge({}, state, {
      surveyOptions: {
        isOpen: true,
        selectedMenuItem: "clientAccess",
        survey: cloneDeep(state.survey), // When survey options are open, we might need to cancel any changes the user makes
      },
    });
  }

  if (action.type === types.CANCEL_SURVEY_OPTIONS) {
    return merge({}, state, {
      surveyOptions: {
        answerOrder: [],
        isOpen: false,
      },
    });
  }

  if (action.type === types.GO_TO_SURVEY_OPTION) {
    return merge({}, state, {
      surveyOptions: {
        selectedMenuItem: action.optionName,
      },
    });
  }

  if (action.type === types.SET_CLIENT_ACCESS_SURVEY_OPTION) {
    return merge({}, state, {
      surveyOptions: {
        survey: {
          clientAccess: {
            hasAccess: action.hasAccess,
          },
        },
      },
    });
  }

  if (action.type === types.SET_CLIENT_ACCESS_AUTO_REMOVE) {
    return merge({}, state, {
      surveyOptions: {
        survey: {
          clientAccess: {
            removeOnSubmit: action.removeOnSubmit,
          },
        },
      },
    });
  }

  if (action.type === types.SET_SURVEY_OPTIONS_ANSWER_ORDER) {
    return {
      ...state,
      surveyOptions: {
        ...state.surveyOptions,
        answerOrder: action.answerOrder,
      },
    };
  }

  if (action.type === types.SENT_CLIENT_SURVEY) {
    return {
      ...state,
      sentSurvey: true,
      clientUsers: [],
    };
  }

  if (action.type === types.GOT_ALL_CLIENTS) {
    return {
      ...state,
      allClients: action.load,
    };
  }

  if (action.type === types.RESET_SURVEYS_FOR_CLIENT) {
    return {
      ...state,
      surveysForClient: "default",
    };
  }

  if (action.type === types.NEW_SURVEYS_FOR_CLIENT) {
    return {
      ...state,
      surveysForClient: action.surveys,
    };
  }

  return state;
}
