import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get, throttle } from "lodash";
import { parse } from "query-string";
import { UserTenantProps } from "cp-client-auth!sofe";

import { NavContent } from "primary-navbar!sofe";
import TaxFormHeader from "./tax-form-header.component.js";
import TaxForm from "./tax-form.component.js";
import FormViewSwitch from "../source-forms/form-view-switch.component.js";
import styles from "./tax-form-for-source-form.styles.css";

import * as uiFormsActions from "../source-forms/ui-forms.actions.js";

@connect((state) => ({
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
  loggedInUser: state.context.loggedInUser,
  uiFormsState: state.uiForms,
  taxForm: state.taxForm,
  sourceFormLayout: state.sourceFormLayout,
}))
@UserTenantProps({ permissions: { hasSmePermissions: "sme" } })
export default class TaxFormForSourceForm extends React.Component {
  state = {
    title: "",
  };
  windowResize = throttle(windowResize.bind(this), 10);
  componentDidMount() {
    if (this.el) {
      window.addEventListener("resize", this.windowResize);
      window.addEventListener("scroll", this.windowResize);
      this.windowResize();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResize);
    window.removeEventListener("scroll", this.windowResize);
  }
  render() {
    const { version, revision, permissions, uiFormsState, sourceFormLayout } =
      this.props;
    const { clientId, resolutionCaseId, taxFormId, sourceFormId } =
      this.props.match.params;
    const { sectionId } = parse(this.props.location.search);
    const actions = {
      ...bindActionCreators(uiFormsActions, this.props.dispatch),
    };
    const isTaxPrep = get(this.props, "version.product") === "taxPrep";

    const versionId = version ? version.id : null;
    const revisionId = revision ? revision.id : null;

    return (
      <NavContent
        className={`wide-menu ${styles.mainContent}`}
        clientMenuPossible={true}
        hasTopnavSecondary={false}
      >
        <div className={"cps-fixed-focus"}>
          <div className={styles.taxFormHeader}>
            <TaxFormHeader
              clientId={clientId}
              resolutionCaseId={resolutionCaseId}
              taxFormId={taxFormId}
              uiFormsState={uiFormsState}
              versionId={versionId}
              formName={this.state.title}
              revisionId={revisionId}
            />
          </div>
          <div ref={(el) => (this.el = el)}>
            {versionId && revisionId && (
              <div>
                <TaxForm
                  formName={taxFormId}
                  onLoad={(taxForm) =>
                    this.setState({ title: taxForm.title }, this.windowResize)
                  }
                  userIsSME={permissions.hasSmePermissions}
                  clientId={clientId}
                  resolutionCaseId={resolutionCaseId}
                  versionId={versionId}
                  revisionId={revisionId}
                  actions={actions}
                />
                {taxFormId && (
                  <FormViewSwitch
                    formSwitchLeft={uiFormsState.formSwitchLeft}
                    isTaxPrep={isTaxPrep}
                    formView={"taxForm"}
                    resolutionCaseId={resolutionCaseId}
                    clientId={clientId}
                    taxFormId={taxFormId}
                    sourceFormId={sourceFormId}
                    sourceFormName={sourceFormLayout.name}
                    sectionId={sectionId || 0}
                    userIsSME={permissions.hasSmePermissions}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </NavContent>
    );
  }
}

function windowResize() {
  if (this.el) {
    const rect = this.el.getBoundingClientRect();
    this.props.dispatch(uiFormsActions.newCardPosition(rect.left, rect.width));
  }
}
