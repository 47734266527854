export const issues = [
  {
    reportKey: "jigglypuff",
    title: "Additional CNC Factors",
    description:
      "Additional CNC eligibility factors may be considered by the IRS, such as equity in assets and whether seizing such assets would create a hardship (See IRM 5.16.1)",
    priority: "info",
    tabs: ["cnc"],
  },
  {
    reportKey: "kirby",
    title: "Open Bankruptcy",
    description: "FIRST_NAME is in open bankruptcy.",
    priority: "danger",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "dedede",
    title: "Federal Tax Deposits",
    description: "FIRST_NAME has not made all required federal tax deposits.",
    priority: "danger",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "metaknight",
    title: "Estimated Tax Payments",
    description: "FIRST_NAME has not made all required estimated tax payments.",
    priority: "danger",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "cloud",
    title: "Unfiled Tax Returns",
    description: "FIRST_NAME has unfiled tax returns.",
    priority: "danger",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "mewtwo",
    title: "Food Standards",
    description:
      "The amount claimed for food, clothing, and miscellaneous expenses exceeds the IRS standards.",
    priority: "warning",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "samus",
    title: "Vehicle Ownership Standards",
    description:
      "The amount claimed for vehicle ownership expenses exceeds the IRS standards.",
    priority: "warning",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "link",
    title: "Vehicle Operating Expenses",
    description:
      "The amount claimed for vehicle operating expenses exceeds the IRS standards.",
    priority: "warning",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "ness",
    title: "Public Transportation Standards",
    description:
      "The amount claimed for public transportation expenses exceeds the IRS standards.",
    priority: "warning",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "lucas",
    title: "Vehicle Count ",
    description: "FIRST_NAME has more vehicles than typically permitted.",
    priority: "warning",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "marth",
    title: "Housing Standards",
    description:
      "The amount claimed for housing & utilities exceeds the IRS standards.",
    priority: "warning",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "roy",
    title: "Out-of-pocket Health Care Standards",
    description:
      "The amount claimed for out-of-pocket medical expenses exceeds the IRS standards.",
    priority: "warning",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "ike",
    title: "Low Income Certification",
    description: "FIRST_NAME qualifies for a low-income waiver.",
    priority: "info",
    tabs: ["oic"],
  },
  {
    reportKey: "fox",
    title: "Offer amount exceeds balance due",
    description: "The minimum offer amount exceeds the amount of tax debt.",
    priority: "danger",
    tabs: ["oic"],
  },
  {
    reportKey: "donkeykong",
    title: "Offer Amount",
    description: "Offer amounts must be more than zero ($0).",
    priority: "danger",
    tabs: ["oic"],
  },
  {
    reportKey: "pikachu",
    title: "Offer Amount",
    description: "Offer amount displayed reflects practitioner override.",
    priority: "danger",
    tabs: ["oic"],
  },
];
