import { getSubsection } from "../client-survey.actions.js";
import { asyncChecks } from "./cssf-subsection.component.js";

export const SURVEY_ANSWER_SET = "clientSurveys";

/* Look at client survey answer set, if one doesn't exist for a
 * question then fall back to crm answer set.
 */
export const SURVEY_ANSWER_ORDER = "clientSurveys,crm";

function isSubsectionStale(props) {
  // the async checks are used to determine if the subsection itself should
  // render within the subsection component. It reduces to true when it can render,
  // so we are testing if it reduces to false, which means the subsection is stale
  return (
    !asyncChecks.reduce(
      (isNotStale, check) => !isNotStale || check(props)
      // should only be executed on the section/subsection route
    ) &&
    props.match.params.sectionId &&
    props.match.params.subsectionIndex
  );
}

export function checkFetchSubsection(
  oldProps,
  newProps,
  componentMounting = false,
  overrideSectionId,
  overrideSubsectionId
) {
  const neverRetrievedSubsection =
    newProps.clientSurvey.clientSurveySourceFormId !==
    newProps.sourceFormLayout.formId;
  const newSectionId =
    oldProps.match.params.sectionId !== newProps.match.params.sectionId;
  const newSubsectionIndex =
    oldProps.match.params.subsectionIndex !==
    newProps.match.params.subsectionIndex;
  const subsectionStale = isSubsectionStale(newProps);

  const needToFetch =
    componentMounting ||
    neverRetrievedSubsection ||
    newSectionId ||
    newSubsectionIndex ||
    subsectionStale;

  const isFetching = newProps.clientSurvey.gettingSubsection;

  const canFetch =
    newProps.clientSurvey.clientSurveySourceFormId &&
    newProps.version.id &&
    newProps.revision.id;

  if (needToFetch && !isFetching && canFetch) {
    newProps.dispatch(
      getSubsection(
        newProps.clientId,
        newProps.resolutionCaseId,
        newProps.clientSurvey.clientSurveySourceFormId,
        overrideSectionId || newProps.match.params.sectionId,
        typeof overrideSubsectionId === "number"
          ? overrideSubsectionId
          : Number(newProps.match.params.subsectionIndex),
        newProps.version.id,
        newProps.revision.id,
        SURVEY_ANSWER_ORDER
      )
    );
  }
}
