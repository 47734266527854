import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get, partial, capitalize, isEqual } from "lodash";
import { UserTenantProps } from "cp-client-auth!sofe";

import { CpLoader } from "canopy-styleguide!sofe";
import { NavContent } from "primary-navbar!sofe";
import Section from "./sections/section.component.js";
import SectionHeaderForSourceForm from "./sections/section-header-for-source-form.component.js";
import FormViewSwitch from "./form-view-switch.component.js";
import Subheader from "./sections/subheader.component.js";
import SectionFooter from "./sections/section-footer.component.js";
import blockStyles from "./blocks/block.styles.css";
import styles from "./source-form-for-tax-form.styles.css";

import * as uiFormsActions from "./ui-forms.actions.js";
import * as answerActions from "./answers/answer.actions.js";
import * as summaryTableActions from "./summary-table/summary-table.actions.js";
import * as sectionActions from "./sections/section.actions.js";
import { getSourceForm } from "./source-form-layout.actions.js";

const ID_OF_FIRST_SECTION = 1;

@connect((state) => ({
  answers: state.answers,
  clientSurvey: state.clientSurvey,
  formSet: state.smeVersion.formSet,
  loggedInUser: state.context.loggedInUser,
  questionsToShowRequiredWarnings:
    state.section.questionsToShowRequiredWarnings,
  resolutionCase: state.resolutionCase,
  revision: state.smeVersion.revision,
  scrollJump: state.section.scrollJump,
  section: state.section.activeSection,
  sourceFormLayout: state.sourceFormLayout,
  summaryTable: state.summaryTable,
  tenant: state.context.tenant,
  uiFormsState: state.uiForms,
  version: state.smeVersion.version,
}))
@UserTenantProps({ permissions: { hasSmePermissions: "sme" } })
export default class SourceFormForTaxForm extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;
    this.actions = {
      ...bindActionCreators(uiFormsActions, dispatch),
      ...bindActionCreators(answerActions, dispatch),
      ...bindActionCreators(summaryTableActions, dispatch),
      ...bindActionCreators(sectionActions, dispatch),
    };

    this.state = {
      fetchingSection: false,
      lastFetchTime: new Date().getTime(),
      lastFetchParams: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.match.params.sectionId &&
      !window.location.hash.endsWith("/sections/0")
    ) {
      // We don't know which section to show. Show section 0 (the first section in the source form).
      prevProps.dispatch(sectionActions.resetSourceFormSection());
      window.location.hash += "/sections/0";
      return false;
    }

    let activeSectionLoaded =
      this.props.match.params.sectionId &&
      this.props.match.params.sectionId === this.props.section.id &&
      this.props.match.params.sourceFormId ===
        prevProps.match.params.sourceFormId;

    if (
      this.props.section.id &&
      this.props.match.params.sectionId === "0" &&
      this.props.match.params.sourceFormId ===
        prevProps.match.params.sourceFormId
    ) {
      activeSectionLoaded = true;
    }

    if (
      (!activeSectionLoaded &&
        this.props.version &&
        this.props.revision &&
        !this.state.fetchingSection &&
        !this.props.resolutionCase.isDefaultResolutionCase) ||
      prevProps.revision?.id !== this.props.revision?.id ||
      prevProps.version?.id !== this.props.version?.id
    ) {
      this.mounted &&
        this.setState({
          fetchingSection: true,
        });
      this.fetchSection(this.props);
    }

    if (this.state.fetchingSection && activeSectionLoaded) {
      this.mounted &&
        this.setState(
          {
            fetchingSection: false,
          },
          () => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }
        );
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.props.dispatch(sectionActions.resetSourceFormSection());
  }

  fetchSection = (props) => {
    const params = {
      clientId: props.match.params.clientId,
      resolutionCaseId: props.match.params.resolutionCaseId,
      versionId: props.version.id,
      revisionId: props.revision.id,
      sourceFormId: props.match.params.sourceFormId,
      sectionId: props.match.params.sectionId || "0",
      TaxpayerType: capitalize(props.resolutionCase.taxpayer_type),
    };

    if (
      isEqual(params, this.state.lastFetchParams) &&
      new Date().getTime() - this.state.lastFetchTime < 1000
    ) {
      /* No need to retrieve the same source form more than once in a row
       */
      return;
    }

    this.mounted &&
      this.setState({
        fetchingSection: true,
        lastFetchParams: params,
        lastFetchTime: new Date().getTime(),
      });

    props.dispatch(
      getSourceForm(params, this.fetchSectionErr(), this.fetchSectionSuccess())
    );
  };

  fetchSectionErr = (err) => {
    this.mounted &&
      this.setState({
        fetchingSection: false,
      });
  };

  fetchSectionSuccess = () => {
    this.mounted &&
      this.setState({
        fetchingSection: false,
      });
  };

  getSection = (sectionId) => {
    // Section hasn't loaded yet
    if (!this.props.section.name || this.state.fetchingSection) {
      return (
        <div className={`${blockStyles.loaderWrapper}`}>
          <CpLoader />
        </div>
      );
    }

    const hasClientSurveyMetadata =
      this.props.clientSurvey.survey === "default";
    const alternateAnswerSets = hasClientSurveyMetadata
      ? ["clientSurveys", "crm"]
      : null;
    const overrideAnswerSet = hasClientSurveyMetadata ? "taxForms" : null;

    return (
      <div>
        <Subheader name={this.props.section.name} />
        <Section
          actions={{
            ...this.actions,
            updateAnswer: partial(this.actions.updateAnswer, {
              answers: this.props.answers,
              clientId: this.props.match.params.clientId,
              resolutionCaseId: this.props.match.params.resolutionCaseId,
              revisionId: this.props.revision ? this.props.revision.id : null,
              sectionId,
              sourceFormId: this.props.match.params.sourceFormId,
              versionId: this.props.version ? this.props.version.id : null,
            }),
          }}
          activeSection={this.props.section}
          alternateAnswerSets={alternateAnswerSets}
          answers={this.props.answers}
          clientId={this.props.match.params.clientId}
          formName={this.props.sourceFormLayout.name}
          overrideAnswerSet={overrideAnswerSet}
          questionsToShowRequiredWarnings={
            this.props.questionsToShowRequiredWarnings
          }
          resolutionCaseId={this.props.match.params.resolutionCaseId}
          scrollJump={this.props.scrollJump}
          sections={this.props.sourceFormLayout.sections}
          sourceFormId={this.props.match.params.sourceFormId}
          summaryTable={this.props.summaryTable}
          taxFormId={this.props.sourceFormLayout.taxForm}
        />
        <SectionFooter
          actions={this.actions}
          activeSection={this.props.section}
          clientId={this.props.match.params.clientId}
          resolutionCaseId={this.props.match.params.resolutionCaseId}
          sections={this.props.sourceFormLayout.sections}
          sourceFormId={this.props.match.params.sourceFormId}
          taxFormId={this.props.sourceFormLayout.taxForm}
        />
      </div>
    );
  };

  render() {
    const formView = "sourceForm";
    const sectionId = this.props.activeSection
      ? this.props.section.id
      : this.props.match.params.sectionId;
    const isTaxPrep = get(this.props, "version.product") === "taxPrep";

    return (
      <NavContent
        className={`wide-menu ${styles.mainContent}`}
        hasTopnavSecondary={false}
        clientMenuPossible={true}
      >
        <div className="cps-fixed-focus">
          {(this.props.sourceFormLayout.taxForm || isTaxPrep) && (
            <FormViewSwitch
              isTaxPrep={isTaxPrep}
              formSwitchLeft={this.props.uiFormsState.formSwitchLeft}
              formView={formView}
              resolutionCaseId={this.props.match.params.resolutionCaseId}
              clientId={this.props.match.params.clientId}
              taxFormId={this.props.sourceFormLayout.taxForm}
              sourceFormId={this.props.match.params.sourceFormId}
              sectionId={sectionId}
              userIsSME={this.props.permissions.hasSmePermissions}
            />
          )}
          {this.props.sourceFormLayout.name ? (
            <SectionHeaderForSourceForm
              clientMenuPossible={true}
              width={this.props.uiFormsState.cardWidth}
              left={this.props.uiFormsState.cardLeft}
              formName={this.props.sourceFormLayout.name}
              formView={formView}
              sectionMessage={this.props.uiFormsState.sectionMessage}
            />
          ) : null}
          <div className={`cps-card +thin`}>{this.getSection(sectionId)}</div>
        </div>
      </NavContent>
    );
  }
}
