import { get, findIndex, isEmpty } from "lodash";

const defaultSelect = [
  {
    value: "Select",
    key: 0,
  },
];

export const generateOptionsList = (dataSource, instance) => {
  if (dataSource.summary) {
    /*
     dataSource.summary is a list of lists.
     example: ["name", "1/1/2020", "892-23-8929"]

     The nested lists are the values of the summary
     table row - in an order that corresponds with
     the summary table columns.
     For each row, create an option that matches the column
     format determined by the user;
     filter out the deleted
    */
    return dataSource.summary.reduce(
      (acc, curr, index) =>
        !dataSource.answers[index]._isDeleted
          ? [
              ...acc,
              createOption(
                curr,
                instance.columns,
                instance.displayNames,
                dataSource.answers[index]
              ),
            ]
          : acc,
      defaultSelect
    );
  } else {
    /*
    Multiforms. Iterate through the list of form instances
    and create an option list using the desired column format
  */
    return [
      ...defaultSelect,
      ...dataSource.map((answerSet) => ({
        value: instance.columns
          .map((column) => answerSet.answers[column.value])
          .join(" ")
          .trim(),
        key: answerSet.sourceFormId,
      })),
    ];
  }
};

const createOption = (summaryItem, columns, displayNames, answer) => ({
  value: columns
    .map(
      (column) =>
        summaryItem[
          findIndex(displayNames, (name) => name.label === column.value)
        ]
    )
    .join(" ")
    .trim(),
  key: answer._rowId,
});

/*
  determines if the dynamic data in the store corresponds
  with a question and returns the value if so
*/

export const resolveDynamicData = (propData, serverAnswer) => {
  const {
    sourceFormId,
    question,
    sectionId,
    dynamicData,
    parentName,
    answers,
  } = propData;
  /*
    dynamicData is from the redux store.
    It's an object with keys for the dynamic list
    sets labeled formname-sectionId
    example:
    {
      form-1:
      form-2:
    }
  */
  return dynamicData
    ? Object.keys(dynamicData).reduce((acc, dataKey) => {
        const [form, section] = dataKey.split("-");

        const relevantSection =
          form === sourceFormId &&
          (section === sectionId || (section === 1 && sectionId === 0));

        const relevantSummaryTable =
          form === parentName && answers.serverAnswers._rowId === section;

        // if the dynamic data relates to this form and section
        if (relevantSection || relevantSummaryTable) {
          /*
            iterate through the question mappings to find the source data
          */
          return dynamicData[dataKey].mappings.reduce((acc, curr) => {
            return question.name === curr.destination
              ? get(
                  dynamicData,
                  `['${dataKey}'].answerData['${curr.source}']`
                ) ||
                  get(
                    dynamicData,
                    `['${dataKey}'].answerData.answers['${curr.source}']`
                  )
              : acc;
          }, acc);
        } else {
          return acc;
        }
      }, serverAnswer)
    : serverAnswer;
};

export const findGroup = (groups, id) =>
  groups.reduce((found, group) => {
    if (group.id === id) {
      return group;
    } else if (!found && !isEmpty(group.groups)) {
      return findGroup(group.groups, id);
    } else {
      return found;
    }
  }, null);
