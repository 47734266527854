import React from "react";
import {
  CpNameChip,
  CpModal,
  CpButton,
  CpCheckbox,
} from "canopy-styleguide!sofe";
import { Scoped, k } from "kremling";

export default class StatusDialog extends React.Component {
  state = {
    notifiees: [],
  };

  componentDidMount() {
    const {
      clientId,
      resolutionCase,
      programSlug,
      section,
      newStatus,
      actions,
      clientCollaborators,
    } = this.props;
    if (!clientCollaborators.find(this.notClient)) {
      // don't show the dialog if there is no one to select, just change the status
      this.changeStatus(
        clientId,
        resolutionCase,
        programSlug,
        section,
        newStatus,
        actions
      );
    }
  }

  changeStatus = (notifiees) => {
    const {
      clientId,
      resolutionCase,
      programSlug,
      section,
      newStatus,
      actions,
    } = this.props;
    actions.updateProgramSectionStatus(
      clientId,
      resolutionCase,
      programSlug,
      section.id,
      section,
      newStatus,
      notifiees
    );
    actions.cancelDialog();
  };

  checkboxChanged = (practitionerId, checked) => {
    if (checked) {
      this.setState((prevState) => {
        return { notifiees: prevState.notifiees.concat(practitionerId) };
      });
    } else {
      this.setState((prevState) => {
        return {
          notifiees: prevState.notifiees.filter(
            (notifiee) => notifiee !== practitionerId
          ),
        };
      });
    }
  };

  notClient = (user) => {
    return !user.permissions.client;
  };

  render() {
    const { actions, clientCollaborators } = this.props;
    const { notifiees } = this.state;
    const practitionerCollaborators = clientCollaborators.filter(
      this.notClient
    );

    return (
      <CpModal
        show={practitionerCollaborators.length > 0}
        onClose={actions.cancelDialog}
      >
        <CpModal.Header title="Notify Collaborators" />
        <CpModal.Body>
          <Scoped css={css}>
            {practitionerCollaborators.map((practitioner) => {
              return (
                <div
                  className="status-change-team-member"
                  key={practitioner.id}
                >
                  <CpNameChip
                    name={practitioner.name}
                    imgSrc={practitioner?.profile_picture_url}
                  />
                  <div className="team-member-name">
                    <span>{practitioner.name}</span>
                  </div>
                  <CpCheckbox
                    className="team-member-checkbox"
                    onChange={(checked) =>
                      this.checkboxChanged(practitioner.id, checked)
                    }
                    checked={notifiees.includes(practitioner.id)}
                  />
                </div>
              );
            })}
          </Scoped>
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            btnType="primary"
            onClick={() => this.changeStatus(notifiees)}
            disabled={notifiees.length === 0}
          >
            Notify people
          </CpButton>
          <CpButton
            className="cp-ml-8"
            btnType="flat"
            onClick={() => this.changeStatus()}
          >
            Don't notify anyone
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    );
  }
}

const css = k`
  .status-change-team-member {
    padding-top: 1.6rem;
    height: 6rem;
    border-bottom: 0.1rem solid var(--cps-color-silver);
    position: relative;
  }

  .status-change-team-member > div {
    display: inline-block;
  }

  .team-member-name > span {
    position: absolute;
    top: 2.1rem;
    padding-left: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
  }

  .team-member-checkbox {
    float: right;
    margin-top: 8px;
  }
`;
