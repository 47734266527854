import React from "react";
import CprMask from "cpr-mask";
import { get, noop } from "lodash";
import { always } from "kremling";

import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import { validateWithXsdRegex } from "./input.helper";

@CanopyInput()
export default class SingleLineText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localAnswer: props.answer,
      characterLimit: null,
      invalidMsg: "Input does not meet requirements or has invalid characters",
    };
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { answer } = this.props;
    const { localAnswer } = this.state;

    if (
      this.props.characterLimit &&
      prevState.localAnswer !== localAnswer &&
      (!answer || !localAnswer || answer.length <= this.props.characterLimit)
    ) {
      // dont use character limit if an old return already has value outside limit until that field reaches blank or if new return
      this.setState({ characterLimit: this.props.characterLimit });
    }

    if (prevProps.answer !== answer && !this.focused) {
      this.updateLocalAnswer(answer);
    }
  }

  render() {
    const { characterLimit, localAnswer, invalidMsg } = this.state;

    return (
      <CprMask
        ref={this.inputRef}
        inputClass={always(
          `cps-form-control ${styles["source-form-200"]} ${
            this.props.question.name
          } ${this.props.className || ""}`
        )
          .maybe(fixedTableStyles.fixedTableInput, this.props.inTable)
          .toString()}
        initialValue={localAnswer}
        nonValidMsgClass="cps-error-block"
        invalidClass="cps-has-error"
        onChange={this.updateLocalAnswer}
        onBlur={this.blur}
        onFocus={this.onFocus}
        validateMethod={this.validateMethod}
        nonValidMsg={invalidMsg}
        inputProps={{
          autoComplete: "off",
          style: this.props.style ? this.props.style : {},
          datax: this.props.datax,
          maxLength: characterLimit || "",
          datay: this.props.datay,
          onPaste: this.props.onPaste || noop,
        }}
      />
    );
  }

  blur = (answer) => {
    this.focused = false;
    this.props.validate(answer);
    this.props.updateAnswer(this.props.question, answer);
  };

  focusInput = () => {
    const input = get(this, "inputRef.current.input");
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  onFocus = () => {
    this.focused = true;
  };

  validateMethod = (value) => {
    const { characterLimit } = this.props;
    const xsdType = get(this.props, "xsdType");

    if (characterLimit && value && value.length > characterLimit) {
      return false;
    }

    if (value && xsdType) {
      return validateWithXsdRegex(xsdType, value);
    }

    return true;
  };

  updateLocalAnswer = (value) => {
    const { characterLimit } = this.props;
    const xsdType = get(this.props, "xsdType");
    let invalidMsg =
      "Input does not meet requirements or has invalid characters";

    this.props.validate(value);

    if (characterLimit && value && value.length > characterLimit) {
      invalidMsg = `Can\'t be more than ${characterLimit} characters`;
    } else if (value && xsdType) {
      const isValid = validateWithXsdRegex(xsdType, value);

      if (!isValid && xsdType.customDescription) {
        invalidMsg = xsdType.customDescription;
      } else if (!isValid && xsdType.irsDescription) {
        invalidMsg = xsdType.irsDescription;
      }
    }

    this.setState({
      localAnswer: value,
      invalidMsg,
    });
  };
}
