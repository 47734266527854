import React from "react";
import CprMask from "cpr-mask";
import { get, noop } from "lodash";
import { always } from "kremling";

import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import { validateWithXsdRegex } from "./input.helper";

@CanopyInput()
export default class URI extends React.Component {
  constructor(props) {
    super();
    this.state = {
      localAnswer: props.answer,
      invalidMsg: "This field requires a valid url",
    };
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer) {
      this.updateLocalAnswer(this.props.answer);
    }
  }

  render() {
    const { question } = this.props;
    const { localAnswer, invalidMsg } = this.state;
    return (
      <CprMask
        ref={this.inputRef}
        inputClass={always(
          `cps-form-control ${styles["source-form-200"]} ${question.name} ${
            this.props.className || ""
          }`
        )
          .maybe(fixedTableStyles.fixedTableInput, this.props.inTable)
          .toString()}
        invalidClass="cps-has-error"
        nonValidMsgClass="cps-error-block"
        nonValidMsg={invalidMsg}
        initialValue={localAnswer}
        onChange={this.updateLocalAnswer}
        onBlur={this.blur}
        validateMethod={this.validateMethod}
        inputProps={{
          autoComplete: "off",
          style: this.props.style ? this.props.style : {},
          datax: this.props.datax,
          datay: this.props.datay,
          onPaste: this.props.onPaste || noop,
        }}
      />
    );
  }

  focusInput = () => {
    const input = get(this, "inputRef.current.input");
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  updateLocalAnswer = (value) => {
    const xsdType = get(this.props, "xsdType");
    let invalidMsg = "This field requires a valid url";
    this.props.validate(value);

    if (value && xsdType) {
      const isValid = validateWithXsdRegex(xsdType, value);

      if (!isValid && xsdType.customDescription) {
        invalidMsg = xsdType.customDescription;
      } else if (!isValid && xsdType.irsDescription) {
        invalidMsg = xsdType.irsDescription;
      }
    }

    this.setState({
      localAnswer: value,
      invalidMsg,
    });
  };

  blur = (answer) => {
    this.props.validate(answer);

    if (this.validateMethod(answer)) {
      this.props.updateAnswer(this.props.question, answer);
    }
  };

  validateMethod = (value) => {
    const xsdType = get(this.props, "xsdType");

    if (value && xsdType) {
      return validateWithXsdRegex(xsdType, value);
    }

    const urlRegex =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    if (value && !urlRegex.test(value)) {
      return false;
    }

    return true;
  };
}
