import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserTenantProps } from "cp-client-auth!sofe";
import styles from "./get-started.styles.css";
import { patchSurveyProperties } from "../client-survey.actions.js";
import { createFlowSurvey } from "./create-survey.actions.js";
import { handleError } from "src/handle-error";
import { defaultNotification } from "../resolution-case-client-survey.resource.js";
import { tenantIsCrmHierarchyComplete } from "../../common/crm-hierarchy.helper.js";

@withRouter
@connect((state) => ({
  clientSurvey: state.clientSurvey,
}))
@UserTenantProps()
export default class GetStarted extends React.Component {
  constructor(props) {
    super(props);
    this.boundPatchSurveyProperties = bindActionCreators(
      patchSurveyProperties,
      this.props.dispatch
    );
  }

  componentDidUpdate() {
    const { baseUrl } = this.props;
    const { survey } = this.props.clientSurvey;

    if (this.props.surveyMetaData.surveyType === "taxPrep") {
      this.props.history.replace(`${baseUrl}/sections`);
    }

    if (survey.id && survey.status !== "creating") {
      if (survey.status === "unsent") {
        this.props.history.replace(`${baseUrl}/sections`);
      } else {
        this.props.history.replace(`${baseUrl}/create-survey/sent-success`);
      }
    }
  }

  render() {
    const { props } = this;

    return (
      <div className={`${styles.getStarted} cps-card cps-fixed-focus`}>
        <div className={`${styles.icon} cps-margin-bottom-16`}>
          <i className={`cps-icon cps-icon-survey cps-color-primary`} />
        </div>
        <div className={`cps-wt-bold cps-subheader cps-margin-bottom-16`}>
          Get started with the Client Survey
        </div>
        <div className={`${styles.textBody} cps-margin-bottom-16`}>
          With Canopy&rsquo;s Client Survey you can send an easy-to-fill-out
          survey to gather all the data you need to understand and evaluate your
          client&rsquo;s eligibility for Offer in Compromise, Payment Plans,
          Current Not Collectible, Spouse Relief, etc.
        </div>
        <a
          onClick={this.sendSurvey.bind(this)}
          className={`cps-btn +primary cps-margin-bottom-16`}
        >
          Send to your client
        </a>
        <a
          href={`#${props.baseUrl}/sections`}
          className={`cps-link ${styles.skipThis}`}
        >
          Skip this, I&rsquo;d like to fill it out myself
        </a>
      </div>
    );
  }

  sendSurvey() {
    const { props } = this;
    const shouldUseNewHierarchy = tenantIsCrmHierarchyComplete(props.tenant);
    let modalProps;
    const onInviteSuccess = (newUsers, surveyNotification) => {
      props.dispatch(
        createFlowSurvey(
          props.clientId,
          props.resolutionCaseId,
          props.surveyMetaData,
          (survey) => {
            // Update the notification and send the survey
            this.boundPatchSurveyProperties(
              survey.id,
              {
                notification: surveyNotification,
                status: "sent",
              },
              () => {},
              {
                clientNotifiees: newUsers.join(","),
              }
            );
          },
          surveyNotification
        )
      );
    };
    const defaultNotif = defaultNotification(
      props.loggedInUser.name,
      props.surveyMetaData
    );

    SystemJS.import("clients-ui!sofe")
      .then(({ showInviteClientModal }) => {
        // Open the invite client modal to choose who to send the survey to
        if (shouldUseNewHierarchy) {
          modalProps = [
            {
              clientId: props.clientId,
              onInviteSuccess,
              infoText:
                "The survey will be sent to the client portal of the selected contacts below. If a selected client does not have a client portal login they will be sent an invite to create one.",
              disableNotification: true,
              isSurveyNotification: true,
              defaultNotification: defaultNotif,
            },
          ];
        } else {
          modalProps = [
            props.clientId,
            onInviteSuccess,
            "Choose or invite the people you would like to send a notification to, and we'll notify them that you're ready for them to start working on the survey.",
            true, // Show existing client users in the list
            true, // Don't send invite notification since we'll send a custom survey notification
            true, // Survey notification experience
            undefined, // close button text
            undefined, // close callback
            defaultNotif,
          ];
        }
        showInviteClientModal(...modalProps);
      })
      .catch(handleError);
  }
}
