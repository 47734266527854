import React from "react";
import CSSF_Progress from "./cssf-progress.component.js";
import { NavContent } from "primary-navbar!sofe";
import styles from "./cssf-sections.styles.css";

function Container(props) {
  const {
    children,
    hasTopnavSecondary,
    clientMenuPossible,
    displayingInClientPortal,
  } = props;
  if (displayingInClientPortal) {
    return (
      <div className="page-content no-bottom-nav" style={{ maxWidth: "unset" }}>
        {children}
      </div>
    );
  } else {
    return (
      <NavContent
        hasTopnavSecondary={hasTopnavSecondary}
        clientMenuPossible={clientMenuPossible}
        style={{ marginLeft: "80px", width: "1072px", marginTop: "56px" }}
      >
        {children}
      </NavContent>
    );
  }
}

export default function CSSF_Sections(props) {
  return (
    <Container
      hasTopnavSecondary={props.loggedInUser.role !== "Client"}
      displayingInClientPortal={props.displayingInClientPortal}
    >
      <div className={`${styles.centeredColumns}`}>
        <CSSF_Progress
          clientId={props.clientId}
          getSubLink={(sectionId, subIndex) =>
            getSubLink(props.baseUrl, sectionId, subIndex)
          }
          actions={props.actions}
          activeSection={props.match.params.sectionId}
          activeSubsection={props.match.params.subsectionIndex}
          currentSection={props.match.params.sectionId}
          baseUrl={props.baseUrl}
          clientSurvey={props.clientSurvey}
          resolutionCaseId={props.resolutionCaseId}
          fileId={props.match.params.fileId}
          sections={props.sections}
          surveyMetaData={props.surveyMetaData}
          taskName={props.taskName}
          displayingInClientPortal={props.displayingInClientPortal}
        />
        <div>{props.children}</div>
      </div>
    </Container>
  );
}

//At section and subsection it will be called with sectionId and subsectionIndex.
function getSubLink(baseUrl, sectionId, subsectionIndex) {
  return `${baseUrl}/sections/${sectionId}/subsections/${subsectionIndex}`;
}
