import React from "react";
import FederalTaxesDue from "./report-utils/federal-taxes-due.component.js";
import BigPrimary from "./report-utils/big-primary-text.component.js";
import styles from "./reports.styles.css";
import AlertList from "./alert-list.component.js";
import Row from "./report-utils/row.component.js";

const valueWidth = { width: "30%" };
const rows = [
  {
    caption: "Description",
    columns: [{ ...valueWidth, value: "Amount" }],
    isHeader: true,
  },
  {
    caption: "Monthly Income",
    columns: [{ ...valueWidth, reportKey: "yapok" }],
  },
  {
    caption: "Monthly Expenses",
    columns: [{ ...valueWidth, reportKey: "zebu" }],
  },
  {
    caption: "Total Remaining Income",
    columns: [{ ...valueWidth, reportKey: "anhinga" }],
  },
  {
    boldText: true,
    caption: "Monthly Payment",
    columns: [{ ...valueWidth, reportKey: "binturong" }],
  },
  {
    caption: "Term",
    columns: [{ ...valueWidth, reportKey: "caracal" }],
    notCurrency: true,
  },
];

export default function CurrentlyReport(props) {
  return (
    <div>
      <div className={`${styles.nonRoundedTop} cps-card`}>
        <div className={`cps-card__body ${styles.headerBox}`}>
          <FederalTaxesDue
            updateAnswer={props.updateAnswer}
            updateReduxAnswer={props.updateReduxAnswer}
            answer={props.answers.FederalTaxesDue}
          />
          <BigPrimary
            notCurrency={true}
            smeMode={props.smeMode}
            reportKey={"whelk"}
            value={props.report["whelk"]}
            caption={"Eligible for CNC based on income and expenses?"}
          />
          <BigPrimary
            smeMode={props.smeMode}
            reportKey={"xantus"}
            value={props.report["xantus"]}
            caption={"Monthly Payment"}
          />
        </div>
      </div>
      <div className={`cps-card cps-margin-top-16`}>
        <div className={`${styles.alertBox}`}>
          <AlertList
            report={props.report}
            smeMode={props.smeMode}
            client={props.client}
            isIndividual={true}
            activeReport={"cnc"}
          />
        </div>
      </div>
      <div className={`cps-card-table cps-card cps-margin-top-16`}>
        <div className={`cps-subheader cps-card__header`}>Status Breakdown</div>
        {rows.map((row, rowIndex) => (
          <Row
            key={rowIndex}
            report={props.report}
            smeMode={props.smeMode}
            {...row}
          />
        ))}
      </div>
    </div>
  );
}
