import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function getQuestion(
  clientId,
  resolutionCaseId,
  versionId,
  revisionId,
  questionName
) {
  return fetchAsObservable(
    `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${versionId}-${revisionId}/questions/${questionName}`
  ).pipe(pluck("questions"));
}
