// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-source-form-cssf-section-progress-styles__sectionProgress {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  height: 4rem;
  margin-right: 0.2rem;
}

.src-client-survey-source-form-cssf-section-progress-styles__sectionProgress:hover .src-client-survey-source-form-cssf-section-progress-styles__sectionProgress:active,
.src-client-survey-source-form-cssf-section-progress-styles__sectionProgress:visited {
  text-decoration: none;
}

.src-client-survey-source-form-cssf-section-progress-styles__sectionProgressHeader {
  border-radius: 1.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.6rem;
}

.src-client-survey-source-form-cssf-section-progress-styles__subsectionsList {
  margin-left: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/source-form/cssf-section-progress.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,qBAAqB;EACrB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;;EAEE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".sectionProgress {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  color: inherit;\n  text-decoration: none;\n  height: 4rem;\n  margin-right: 0.2rem;\n}\n\n.sectionProgress:hover .sectionProgress:active,\n.sectionProgress:visited {\n  text-decoration: none;\n}\n\n.sectionProgressHeader {\n  border-radius: 1.5rem;\n  width: 2rem;\n  height: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 1.6rem;\n}\n\n.subsectionsList {\n  margin-left: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionProgress": `src-client-survey-source-form-cssf-section-progress-styles__sectionProgress`,
	"sectionProgressHeader": `src-client-survey-source-form-cssf-section-progress-styles__sectionProgressHeader`,
	"subsectionsList": `src-client-survey-source-form-cssf-section-progress-styles__subsectionsList`
};
export default ___CSS_LOADER_EXPORT___;
