import React from "react";
import styles from "./survey-secondary-nav.style.css";
import { TopnavSecondary } from "primary-navbar!sofe";

export default function SurveySecondaryNav(props) {
  if (window.loggedInUser.role === "Client") {
    // Clients don't see the secondary nav
    return null;
  }

  const { clientId, resolutionCaseId } = props;

  return (
    <div>
      <TopnavSecondary>
        <div
          className="cps-topnav-secondary__content"
          style={{ marginLeft: "80px", width: "100%" }}
        >
          <ul className="cps-topnav-secondary__content__menu padding-top-12">
            <li className={`${styles.leftLi}`}>
              <a
                className={`${styles.link} cps-link`}
                href={`#/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/summary`}
              >
                <span className={`cps-icon cps-icon-arrow-left`} />
                <span className={`${styles.linkText}`}>Exit client survey</span>
              </a>
            </li>
          </ul>
        </div>
      </TopnavSecondary>
    </div>
  );
}
