import { get, isEmpty, findLastKey, mapValues, last } from "lodash";
import { fromEvent } from "rxjs";
import { filter, delay } from "rxjs/operators";

export const containsTabularSupportedQuestion = (blocks) => {
  let hasOne = false;
  let iterations = 0;

  while (!hasOne && iterations <= blocks.length) {
    let questionList = get(blocks, `[${iterations}].questions`);

    if (questionList) {
      for (let i = 0; i < questionList.length; ++i) {
        if (
          questionList &&
          allowedTypes.includes(get(questionList, `[${i}].meta.type`))
        ) {
          hasOne = true;
        }
      }
    }

    ++iterations;
  }
  return hasOne;
};

export const allowedTypes = [
  "SINGLE_LINE_TEXT",
  "AMOUNT",
  "NUMBER",
  "SELECT_LIST",
  "SSN",
  "STATE",
  "COUNTRY",
  "PERCENTAGE",
  "EIN",
  "PHONE_NUMBER",
  "ZIP",
  "COUNTY",
  "DECIMAL",
  "OTHER_COUNTRIES",
  "MULTIPLE_CHOICE",
  "PARAGRAPH_TEXT",
];

export const bretRows = (data) => {
  const text = data.getData("Text");

  if (text.indexOf("\r") > -1) {
    return text.split("\r");
  }

  return text.split("\n");
};

export const summaryQuestions = (blocks) =>
  blocks.reduce(
    (acc, block) => ({
      ...acc,
      ...block.questions.reduce(
        (qMap, question) => ({
          ...qMap,
          [question.meta.question]: question.name,
        }),
        {}
      ),
    }),
    {}
  );

// possibly alphabetize the columns?  Figure out how to keep this consistent (API has to be very deterministic; Always ordered the same)
// request both column names and UI type (and required)
export const matrixify = (rows, x, y, columns, init = {}) => {
  const cells = rows.map((row, j) => {
    let words = typeof row === "string" ? row.split(/[\t]/) : row; // at some point, trim words possibly
    let insertData = modfWords(words).map((val, i) => {
      return {
        fieldValue: val,
        xCoor: i + x,
        yCoor: j + y,
        columnName: get(columns, `[${i + x}].label`),
      };
    });
    /*
     * Based on the x coordinate of the paste, we need to offset the pasted data
     * by that many cells
     */
    let paddingArray = Array(x).fill();
    let frontPadded = [
      ...objTemp(paddingArray, 0, j + y, columns),
      ...insertData,
    ];
    let lengthDiff = columns.length - frontPadded.length;

    /*
     * if the the pasted data's rows are of insufficient length, we need
     * to pad the back of the row with the proper cells (offsetted by the x,y coordinates)
     */
    if (lengthDiff > 0) {
      return [
        ...frontPadded,
        ...objTemp(
          Array(lengthDiff).fill(),
          x + frontPadded.length,
          j + y,
          columns
        ),
      ];
    }

    return frontPadded;
  });

  return matrixObject(cells, y);
};

const modfWords = (words) => {
  return words.map((word) => {
    return word === "" ? "BLANK" : word;
  });
};

/*
 * creates an object like this:
 *  {
 *   row0: [
 *     {
 *       fieldValue: null,
 *       xCoor: 0,
 *       yCoor: 0,
 *     },
 *     {
 *       fieldValue: null,
 *       xCoor: 1,
 *       yCoor: 0,
 *     }
 *   ],
 *   row1: [
 *     {
 *       fieldValue: null,
 *       xCoor: 0,
 *       yCoor: 1
 *     },
 *     {
 *       fieldValue: null,
 *       xCoor: 1,
 *       yCoor: 1
 *     }
 *   ]
 * }
 *
 */
export const matrixObject = (rows, y) =>
  rows.reduce(
    (carry, current, iteration) => ({
      ...carry,
      [`row${iteration + y}`]: current,
    }),
    {}
  );

const objTemp = (arr, offset, y, columns) => {
  return arr.map((item, i) => ({
    fieldValue: null,
    xCoor: i + offset,
    yCoor: y,
    columnName: get(columns, `[${i + offset}].label`),
  }));
};

/*
 * since a question can have multiple or no blocks, we need to take the
 * column-heading data and flatten it out into an array of strings
 */
export const flattenQuestions = (probject) => {
  let blocks = get(probject, "question.blocks");
  let columns = get(probject, "question.summary.columns");

  // for now, we are only doing questions that are of the single line text and amount question type
  return !isEmpty(blocks)
    ? blocks.reduce(
        (acc, curr) => [
          ...acc,
          ...curr.questions.reduce(
            (ac, cur) =>
              get(cur, "meta.question") &&
              allowedTypes.includes(get(cur, "meta.type"))
                ? ac.concat({
                    characterLimit: get(cur, "meta.characterLimit"),
                    xsdType: get(cur, "meta.xsdType"),
                    roundCurrency: get(cur, "meta.roundCurrency"),
                    label: cur.meta.question,
                    questionName: cur.name,
                    type: cur.meta.type,
                    options: get(cur, "meta.options")
                      ? get(cur, "meta.options").map((opt) => ({
                          key: opt.value,
                          value: opt.label,
                        }))
                      : [],
                  })
                : ac,
            []
          ),
        ],
        []
      )
    : columns.reduce(
        (acc, curr) => (curr.label ? acc.concat(curr.label) : acc),
        []
      );
};

export const coalesceMatrices = (oldMatrix, newMatrix) => {
  const lastRowNumber = compareLasts(oldMatrix, newMatrix);
  return compareAndBuild(oldMatrix, newMatrix, 0, lastRowNumber, {});
};

const compareAndBuild = (mat1, mat2, counter, lastId, carry) => {
  if (counter > lastId) {
    return carry;
  } else {
    return compareAndBuild(mat1, mat2, counter + 1, lastId, {
      ...carry,
      [`row${counter}`]: determineWinner(mat1, mat2, `row${counter}`),
    });
  }
};

// newly pasted blank values should override the older cell
const determineWinner = (mat1, mat2, rowKey) => {
  if (!mat1[rowKey] && mat2[rowKey]) {
    // this is a new row
    return mat2[rowKey];
  } else if (!mat2[rowKey] && mat1[rowKey]) {
    // this is an old row with non matching new row
    return mat1[rowKey];
  } else {
    // Both sets contain data on this row so we need to determine the true cells
    let oldCells = mat1[rowKey];
    let newCells = mat2[rowKey];

    return oldCells.map((cell, i) =>
      !isEmpty(newCells[i]) && newCells[i].fieldValue ? newCells[i] : cell
    );
  }
};

const compareLasts = (ob1, ob2) => {
  const last1 = getLastRowNumber(ob1);
  const last2 = getLastRowNumber(ob2);

  return last1 > last2 ? last1 : last2;
};

const getLastRowNumber = (obj) =>
  parseInt(findLastKey(obj).match(/\d+/)[0], 10);

export const updateMatrix = (matrix, newValue, x, y, columns) => {
  const newMatrix = mapValues(matrix, (row) =>
    row.map((cell) =>
      cell.xCoor == x && cell.yCoor == y
        ? { ...cell, fieldValue: newValue }
        : cell
    )
  );

  if (
    getLastRowNumber(matrix) === y &&
    last(Object.values(newMatrix)).find((input) => input.fieldValue)
  ) {
    return addRowToMatrix(newMatrix, columns);
  } else {
    return newMatrix;
  }
};

export const createRowsFromDataset = (answers, columns, questionMap) =>
  answers.map((answer) =>
    columns
      .map((columnKey) => (answer ? answer[questionMap[columnKey.label]] : ""))
      .join("\t")
  );

export const addRowToMatrix = (matrix, columns) => {
  const newRowNumber = getLastRowNumber(matrix) + 1;

  return {
    ...matrix,
    [`row${newRowNumber}`]: columns.map((column, i) => ({
      fieldValue: null,
      xCoor: i,
      yCoor: newRowNumber,
      columnName: column.label,
    })),
  };
};

//on tab this is called to determine if it needs to focus on the first input of the added row to the matrix
export const focusLastInput = (e, matrix) => {
  const matrixArray = Object.values(matrix);
  const secondToLastRow = matrixArray[matrixArray.length - 2];
  if (secondToLastRow && secondToLastRow.find((input) => input.fieldValue)) {
    const lastInput = last(Object.values(secondToLastRow));
    const x = get(e, "target.attributes.datax.value");
    const y = get(e, "target.attributes.datay.value");
    if (lastInput && lastInput.xCoor == x && lastInput.yCoor == y) {
      const lastRowFirstInput = last(Object.values(last(matrixArray)));
      const input = document.querySelector(
        `.paste-input-0-${lastRowFirstInput.yCoor}`
      );
      input.focus();
    }
  }
};

//this checks to make sure tabbing works when tabbing from the last row in the matrix
export const tabObservable = fromEvent(document, "keydown").pipe(
  filter((e) => {
    const char = e.which || e.keyCode;
    const wasTabPressed = char === 9;
    const wasShiftPressedAlso = e.shiftKey;
    return wasTabPressed && !wasShiftPressedAlso;
  }),
  delay(100)
);
