import React from "react";
import styles from "./informational-text.styles.css";

export default function InformationalText({ question }) {
  const draftjsTextObject = JSON.parse(question.text || `{"blocks":[]}`);

  return (
    <div className={`${styles.root} cps-color-secondary-text`}>
      {draftjsTextObject.blocks.map((block) => {
        let spanList = toSpanList(block, arguments[0]);
        return <div key={block.key}>{spanList.length ? spanList : <br />}</div>;
      })}
    </div>
  );
}

function toSpanList(block, props) {
  let textArr = block.text.split("").map((char) => {
    return { char, style: {} };
  });
  block.inlineStyleRanges.forEach((range) => {
    for (let i = range.offset; i < range.length + range.offset; i++) {
      textArr[i].style = {
        ...textArr[i].style,
        ...getStyle(range.style),
        ...(props[range.style] ? props[range.style] : {}),
      };
    }
  });
  return textArr.map((span, index) => {
    return (
      <span key={block.key + index} style={span.style}>
        {span.char}
      </span>
    );
  });
}

function getStyle(style) {
  if (style === "BOLD") return { fontWeight: "bold" };
  if (style === "ITALIC") return { fontStyle: "italic" };
  if (style === "UNDERLINE") return { textDecoration: "underline" };
}
