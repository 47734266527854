import React from "react";
import styles from "./sent-success.styles.css";

export default class SentSuccess extends React.Component {
  render() {
    const { baseUrl } = this.props;

    return (
      <div className={`cps-card cps-fixed-focus`}>
        <div className={`${styles.container}`}>
          <svg
            width="46px"
            height="46px"
            viewBox="0 0 46 46"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Send-Message-Now"
                transform="translate(-489.000000, -232.000000)"
              >
                <g id="Group" transform="translate(490.000000, 233.000000)">
                  <circle
                    id="Oval-172"
                    className={styles.circle}
                    strokeWidth="2"
                    cx="22"
                    cy="22"
                    r="22"
                  />
                  <g
                    id="Triangle-170-+-Rectangle-906-Copy-9"
                    transform="translate(14.000000, 14.000000)"
                    className={styles.triangle}
                  >
                    <polygon
                      id="Triangle-170"
                      points="0 16 2 10.0168457 13.9969482 8 2 6.00140381 3.55271368e-15 0 20 8"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <div
            className={`${styles.readyToSend}`}
            style={{ padding: "0 272px", textAlign: "center" }}
          >
            Your survey was sent!
          </div>
          <center>
            <a className="cps-caption" href={`#${baseUrl}/sections`}>
              View survey
            </a>
          </center>
        </div>
      </div>
    );
  }
}
