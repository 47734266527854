import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CSSF_Subsection from "./cssf-subsection.component.js";
import * as uiFormsActions from "src/source-forms/ui-forms.actions.js";
import * as answerActions from "src/source-forms/answers/answer.actions.js";
import * as sectionActions from "src/source-forms/sections/section.actions.js";
import * as summaryTableActions from "src/source-forms/summary-table/summary-table.actions.js";
import { checkFetchSubsection } from "./cssf-subsection.helpers.js";

@connect((state) => ({
  clientSurvey: state.clientSurvey,
  uiForms: state.uiForms,
  sourceFormLayout: state.sourceFormLayout,
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
  subsection: state.section.activeSection,
  questionsToShowRequiredWarnings:
    state.section.questionsToShowRequiredWarnings,
  scrollJump: state.section.scrollJump,
  answers: state.answers,
  summaryTable: state.summaryTable,
}))
export default class CSSF_SubsectionWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.boundActions = {
      ...bindActionCreators(uiFormsActions, this.props.dispatch),
      ...bindActionCreators(answerActions, this.props.dispatch),
      ...bindActionCreators(sectionActions, this.props.dispatch),
      ...bindActionCreators(summaryTableActions, this.props.dispatch),
    };
  }
  componentDidMount() {
    const componentMounting = false;
    checkFetchSubsection(this.props, this.props, componentMounting);
  }
  componentDidUpdate(prevProps) {
    const componentMounting = false;
    checkFetchSubsection(prevProps, this.props, componentMounting);
  }

  render() {
    return <CSSF_Subsection {...this.props} actions={this.boundActions} />;
  }
}
