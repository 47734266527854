import React from "react";
import { get } from "lodash";
import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";

@CanopyInput()
export default class Checkbox extends React.Component {
  state = {
    localAnswer: this.props.answer,
  };
  optionRefs = {};

  focusInput = () => {
    const value = get(this, "props.question.meta.options[0].value");
    const input = this.optionRefs[value];
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  render() {
    const { question } = this.props;
    const { localAnswer } = this.state;

    if (!question.meta.options || !question.meta.options.length) {
      return (
        <div>
          <b className="cps-warning">
            The question {question.name} has no options!
          </b>
        </div>
      );
    }

    const optionsAreStacked = question.meta.options.length > 2;
    return (
      <div
        className={`cps-checkbox-container ${
          optionsAreStacked ? styles.itemsStacked : styles.itemsFlat
        }`}
      >
        {question.meta.options.map((option) => {
          return (
            <label
              className={`cps-checkbox ${styles.radioCheck}`}
              key={option.value}
              style={{ paddingBottom: "4px" }}
            >
              <input
                ref={(input) => (this.optionRefs[option.value] = input)}
                type="checkbox"
                className={`cps-form-control ${question.name}`}
                onChange={this.saveNewValue}
                onBlur={this.props.validateAnswer}
                name={question.name}
                value={option.value}
                checked={
                  localAnswer ? localAnswer.indexOf(option.value) >= 0 : false
                }
              />
              <span className={styles.checkboxLabel}>
                <div>{option.label}</div>
              </span>
            </label>
          );
        })}
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.answer !== this.props.answer) {
      this.setState({ localAnswer: this.props.answer });
      this.props.validate(this.props.answer);
    }
  }

  saveNewValue = (e) => {
    let value = this.props.question.meta.options
      .filter((option) => this.optionRefs[option.value].checked)
      .map((option) => option.value);

    this.setState({ localAnswer: value });
    this.props.validate(value);
    this.props.updateAnswer(this.props.question, value);
  };
}
