export function scrollToBlock(block) {
  const blockId = typeof block === "string" ? block : block.id;

  const blockElement = document.querySelector(`[data-block-id="${blockId}"]`);

  if (blockElement) {
    blockElement.dispatchEvent(
      new CustomEvent("end-user-forms-ui:block-focus")
    );
    scrollToElement(blockElement);
  } else {
    console.warn("Block not found:", block);
  }
}

export function scrollToElement(scrollToElement) {
  const sectionHeaderEl = document.getElementById(
    "section-header-for-source-form"
  );
  const defaultOffset = 250; // For when for some reason there is no section header on the page we just guess where the beginning of the source form begins on the page
  const scrollOffset = sectionHeaderEl
    ? sectionHeaderEl.getBoundingClientRect().top +
      sectionHeaderEl.getBoundingClientRect().height
    : defaultOffset;

  const scrollValue =
    scrollToElement.getBoundingClientRect().top - scrollOffset;

  if (isChrome()) {
    window.scrollBy({
      top: scrollValue,
      left: 0,
      behavior: "smooth",
    });
  } else {
    window.scrollBy(0, scrollValue);
  }
}

function isChrome() {
  let isChromium = window.chrome;
  let winNav = window.navigator;
  let vendorName = winNav.vendor;
  let isOpera = typeof window.opr !== "undefined";
  let isIEedge = winNav.userAgent.indexOf("Edge") > -1;
  let isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    return true;
  } else if (
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true;
  } else {
    return false;
  }
}
