// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-files-survey-file-upload-styles__uploadDocsWell {
  border: dashed 2px var(--cps-color-athens);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 102px;
}

.src-client-survey-files-survey-file-upload-styles__uploadAnotherDocsWell {
  border: dashed 2px var(--cps-color-athens);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.src-client-survey-files-survey-file-upload-styles__fileCard {
  margin-right: 0 !important;
}

.src-client-survey-files-survey-file-upload-styles__emptyWell {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.src-client-survey-files-survey-file-upload-styles__emptyText {
  width: 271px;
}

.src-client-survey-files-survey-file-upload-styles__subsectionWrapper {
  margin-left: 21px;
}

@media only screen and (max-width: 599px) and (orientation: portrait),
  screen and (max-height: 599px) and (orientation: landscape) {
  .src-client-survey-files-survey-file-upload-styles__subsectionWrapper {
    margin-left: 0;
    margin-top: 21px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/files/survey-file-upload.styles.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,0CAA0C;EAC1C,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE;IACE,cAAc;IACd,gBAAgB;EAClB;AACF","sourcesContent":[".uploadDocsWell {\n  border: dashed 2px var(--cps-color-athens);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 56px 102px;\n}\n\n.uploadAnotherDocsWell {\n  border: dashed 2px var(--cps-color-athens);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.fileCard {\n  margin-right: 0 !important;\n}\n\n.emptyWell {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.emptyText {\n  width: 271px;\n}\n\n.subsectionWrapper {\n  margin-left: 21px;\n}\n\n@media only screen and (max-width: 599px) and (orientation: portrait),\n  screen and (max-height: 599px) and (orientation: landscape) {\n  .subsectionWrapper {\n    margin-left: 0;\n    margin-top: 21px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadDocsWell": `src-client-survey-files-survey-file-upload-styles__uploadDocsWell`,
	"uploadAnotherDocsWell": `src-client-survey-files-survey-file-upload-styles__uploadAnotherDocsWell`,
	"fileCard": `src-client-survey-files-survey-file-upload-styles__fileCard`,
	"emptyWell": `src-client-survey-files-survey-file-upload-styles__emptyWell`,
	"emptyText": `src-client-survey-files-survey-file-upload-styles__emptyText`,
	"subsectionWrapper": `src-client-survey-files-survey-file-upload-styles__subsectionWrapper`
};
export default ___CSS_LOADER_EXPORT___;
