export default function indexInSequence(i, sequence) {
  const index = parseFloat(i, 10);

  if (!sequence) return true;

  const sections = sequence.split(",").map((section) => section.trim());

  return sections.reduce((found, section) => {
    if (found) return found;

    if (section.includes("-")) {
      const range = section.split("-").map((range) => range.trim());
      if (range.length > 2)
        throw new Error("You cannot have more than one range (-)");
      else {
        return (
          index >= parseFloat(range[0], 10) && index <= parseFloat(range[1], 10)
        );
      }
    } else {
      return index === parseFloat(section, 10);
    }
  }, false);
}
