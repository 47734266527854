// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-analytics-reports-reports-styles__nonRoundedTop {
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}

.src-analytics-reports-reports-styles__headerBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.src-analytics-reports-reports-styles__alertBox {
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/analytics/reports/reports.styles.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".nonRoundedTop {\n  border-top-right-radius: 0rem;\n  border-top-left-radius: 0rem;\n}\n\n.headerBox {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.alertBox {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nonRoundedTop": `src-analytics-reports-reports-styles__nonRoundedTop`,
	"headerBox": `src-analytics-reports-reports-styles__headerBox`,
	"alertBox": `src-analytics-reports-reports-styles__alertBox`
};
export default ___CSS_LOADER_EXPORT___;
