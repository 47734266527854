// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-source-forms-source-form-for-tax-form-styles__mainContent {
  padding-right: 21rem;
  padding-left: 2.4rem;
  min-width: 1000px;
}
`, "",{"version":3,"sources":["webpack://./src/source-forms/source-form-for-tax-form.styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,iBAAiB;AACnB","sourcesContent":[".mainContent {\n  padding-right: 21rem;\n  padding-left: 2.4rem;\n  min-width: 1000px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContent": `src-source-forms-source-form-for-tax-form-styles__mainContent`
};
export default ___CSS_LOADER_EXPORT___;
