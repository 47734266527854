import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export const getTasksByResolutionCaseId = (resolutionCaseId) => {
  return fetchAsObservable(
    `/api/resolution_cases/${resolutionCaseId}/tasks`
  ).pipe(pluck("task"));
};

export const addFilesToTask = (taskId, files) => {
  return fetchAsObservable(`/api/tasks/${taskId}/files`, {
    method: "POST",
    body: {
      related_files: {
        file_ids: files,
      },
    },
  });
};

export const getDocsToUpload = (
  clientId,
  resolutionCaseId,
  versionId,
  returnType = "1040"
) => {
  return fetchAsObservable(
    `/wg/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${versionId}/reports/${returnType.replaceAll(
      "-",
      ""
    )}Organizer`
  ).pipe(pluck("reports", "values"));
};

export const getSurveyDownloadTitle = (resolutionCaseId) => {
  return fetchAsObservable(
    `/api/tasks?resolution_case_id=${resolutionCaseId}&service_type=tax_return`
  ).pipe(pluck("tasks"));
};
