import React from "react";
import { generalToast } from "toast-service!sofe";

export default class NotFound extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    generalToast(questionToMessage(this.props.question));
  }

  render() {
    const { question } = this.props;
    return <h5 className="cps-has-error">{questionToMessage(question)}</h5>;
  }
}

function questionToMessage(question) {
  const questionName =
    question.meta.type || question.type || question.name || "";
  return `An error has occurred: unable to display question ${questionName}`;
}
