import React from "react";
import styles from "./inputs.style.css";
import { get, isArray, isEmpty, throttle, isEqual } from "lodash";
import CanopyInput from "./canopy-input.decorator.js";
import { getScratchPad } from "../../common/scratchpad/scratch-pad.resource";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import { always } from "kremling";
import { existsOrZero } from "src/source-forms/answers/answer.helpers.js";
import { validateWithXsdRegex } from "./input.helper";

@CanopyInput()
export default class Currency extends React.Component {
  constructor(props) {
    super(props);
    let mask = "";
    let value = "";

    if (existsOrZero(props.answer)) {
      let obj = toMaskToValue(props.answer, props.roundCurrency);
      mask = obj.mask;
      value = obj.value;
    }

    this.state = {
      formId: null,
      value,
      mask,
      focused: false,
      localAnswer: props.answer,
      roundCurrency: props.roundCurrency,
      scratchPadDrawer: false,
      scratchPad: [],
      scratchPadComponent: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.answer !== this.state.localAnswer) {
      if (!this.props.answer && this.props.answer !== 0) {
        this.setState({
          mask: "",
          value: "",
          localAnswer: this.props.answer,
        });
      } else if (!this.isUsingScratchPad()) {
        const { mask, value } = toMaskToValue(
          String(this.props.answer),
          this.state.roundCurrency
        );
        this.setState({
          mask,
          value,
          localAnswer: this.props.answer,
        });
      }
    }

    // if currency saved in db is not rounded but it needs to be, round it
    if (
      prevProps.answer !== this.props.answer &&
      !isNaN(this.props.answer) &&
      this.state.roundCurrency &&
      Number(this.props.answer) % 1 !== 0
    ) {
      this.props.updateAnswer(
        this.props.question,
        Math.round(this.props.answer)
      );
    }

    if (this.shouldGetScratchPad(prevProps)) {
      const scratchPad = getScratchPad(
        this.props.question,
        this.props.summaryTableIndex,
        this.props.answers.serverAnswers,
        this.props.updateAnswer
      );
      const { mask, value } = toMaskToValue(
        String(this.props.answer),
        this.state.roundCurrency
      );
      this.setState({
        scratchPad,
        mask,
        value,
        localAnswer: this.props.answer,
      });
    }
  }

  render() {
    const { mask, value } = this.state;
    const ScratchPad = this.state.scratchPadComponent || (() => <div />);
    const questionName =
      this.props.question &&
      this.props.question.meta &&
      this.props.question.meta.question;
    const extraStyles = this.props.style ? this.props.style : {};
    const scratchPadValue = this.getScratchPadValue(this.state.scratchPad);

    return (
      <span
        className={always("scratchpad-wrapper")
          .toggle(
            fixedTableStyles.fixedTableSpan,
            styles.inputWrapper,
            this.props.inTable
          )
          .toString()}
      >
        <input
          data-testid="scratch-pad-input"
          className={always(
            `cps-form-control ${styles["source-form-200"]} ${
              this.props.question.name
            } ${this.props.className || ""}`
          )
            .maybe(fixedTableStyles.fixedTableInput, this.props.inTable)
            .toString()}
          style={{ textAlign: "right", ...extraStyles }}
          ref={(input) => (this.inputRef = input)}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          value={this.state.focused ? this.state.value : this.state.mask}
          onPaste={this.handlePaste.bind(this)}
          disabled={this.isUsingScratchPad()}
          datax={this.props.datax}
          datay={this.props.datay}
          autoComplete="off"
        />
        {!this.props.onPaste &&
          (this.isUsingScratchPad() || this.state.focused) && (
            <button
              tabIndex={-1}
              onMouseDown={this.toggleScratchPad}
              className={`scratch-pad-btn ${styles.scratchPadBtn} ${
                this.isUsingScratchPad() ? styles.scratchPadBtnActive : ""
              }`}
              data-testid="scratchPadBtnActive"
            >
              <i className="cps-icon cps-icon-calculator" />
            </button>
          )}
        <ScratchPad
          value={(value && value.toString()) || ""}
          mask={mask}
          questionName={questionName || ""}
          isOpen={this.state.scratchPadDrawer}
          onClose={this.toggleScratchPad}
          onSave={this.saveScratchPad}
          onChange={this.updateScratchPad}
          data={this.state.scratchPad}
          roundCurrency={this.state.roundCurrency}
          resetScratchPad={this.resetScratchPad}
        />
      </span>
    );
  }

  focusInput = () => this.inputRef.focus();

  getScratchPadValue = (data) => {
    if (!data || data.length <= 1) return "";

    return data
      .reduce((total, next) => {
        return total + parseFloat(next.amount || 0);
      }, 0)
      .toFixed(2);
  };

  handleBlur() {
    let currencyValue = this.state.value;
    if (currencyValue && this.state.roundCurrency)
      currencyValue = Math.round(this.state.value);
    const { value, mask } = toMaskToValue(
      String(currencyValue),
      this.state.roundCurrency
    );
    this.props.updateAnswer(this.props.question, value);
    this.setState({
      focused: false,
      mask,
    });
  }

  handleChange(e) {
    let val = e.target.value;
    const { mask, value } = toMaskToValue(
      String(val),
      this.state.roundCurrency
    );
    if (reg.test(val) && this.validate(val)) {
      this.setState({
        value: val,
        mask,
      });
    }
  }

  validate = (value) => {
    const xsdType = get(this.props, "xsdType");

    if (value && xsdType) {
      return validateWithXsdRegex(xsdType, value);
    }

    return true;
  };

  resetScratchPad = () => {
    this.setState({ scratchPad: [{ description: "", amount: "" }] }, () => {
      const { scratchPad, roundCurrency } = this.state;
      const { question } = this.props;
      let currencyValue = this.state.value;
      if (this.state.value && roundCurrency) {
        currencyValue = Math.round(this.state.value);
      }
      const { value } = toMaskToValue(String(currencyValue), roundCurrency);
      this.props.updateAnswer(
        question,
        value,
        {},
        {},
        { [`${question.name}_sp`]: scratchPad }
      );
    });
  };

  handleFocus() {
    setTimeout(() => {
      if (this.inputRef) {
        const selectionStart = this.inputRef.selectionStart;
        const selectionEnd = this.inputRef.selectionEnd;
        this.setState(
          {
            focused: true,
          },
          () => {
            if (this.inputRef) {
              let cursorMove = this.state.mask
                .split("")
                .slice(0, selectionStart)
                .reduce((total, curr) => {
                  if (curr === "," || curr === "$") return total + 1;
                  else return total;
                }, 0);
              this.inputRef.setSelectionRange(
                selectionStart - cursorMove,
                selectionEnd - cursorMove
              );
            }
          }
        );
      }
    });
  }

  handlePaste(e) {
    if (this.props.onPaste) {
      this.props.onPaste(e);
    } else {
      let value = Number(e.clipboardData.getData("Text").replace(/\$|,/g, ""));
      e.target.value = value ? value.toFixed(2) : "";
      this.handleChange(e);
    }
  }

  isUsingScratchPad = () => {
    return (
      this.state.scratchPad.reduce((count, next) => {
        if (next.description || next.amount) return count + 1;
        return count;
      }, 0) > 0 || this.state.scratchPadDrawer
    );
  };

  saveScratchPad = throttle((scratchPad) => {
    const { question, answers } = this.props;
    if (!isEqual(answers.serverAnswers[`${question.name}_sp`], scratchPad)) {
      const scratchPadValue = this.getScratchPadValue(scratchPad);
      const { value } = toMaskToValue(
        scratchPadValue ? String(Math.round(scratchPadValue)) : scratchPadValue,
        this.state.roundCurrency
      );
      this.props.updateAnswer(
        question,
        value,
        {},
        {},
        { [`${question.name}_sp`]: scratchPad }
      );
    }
  }, 1000);

  updateScratchPad = (scratchPad) => {
    const scratchPadValue = this.getScratchPadValue(scratchPad);
    const { mask, value } = toMaskToValue(
      scratchPadValue ? String(Math.round(scratchPadValue)) : scratchPadValue,
      this.state.roundCurrency
    );
    this.setState({ scratchPad, mask, value });
  };

  toggleScratchPad = () => {
    const { roundCurrency, scratchPadComponent, scratchPadDrawer, scratchPad } =
      this.state;

    if (!scratchPadDrawer && !scratchPadComponent) {
      import(
        /* webpackChunkName: "scratchpad" */ "../../common/scratchpad/scratch-pad.component"
      ).then((module) => {
        this.setState(
          {
            scratchPadComponent: module.default,
            scratchPad: isEmpty(scratchPad)
              ? [{ description: "", amount: "" }]
              : scratchPad,
          },
          () => {
            // wait till the next event loop to turn on the drawer
            setTimeout(() => {
              this.setState({ scratchPadDrawer: true, focused: false });
            }, 0);
          }
        );
      });
    } else {
      this.setState((prevState) => {
        return {
          scratchPadDrawer: !prevState.scratchPadDrawer,
          focused: false,
        };
      });
    }
  };

  shouldGetScratchPad = (prevProps) => {
    const { scratchPad, scratchPadDrawer } = this.state;
    const { question } = this.props;
    const didServerAnswersComeIn =
      isEmpty(scratchPad) &&
      !isEmpty(get(this.props, "answers.serverAnswers", {}));
    const didWeMoveToANewSection = !isEqual(
      get(prevProps, "answers.serverAnswers", {})[`${question.name}_sp`],
      get(this.props, `answers.serverAnswers`, {})[`${question.name}_sp`]
    );
    return (
      !scratchPadDrawer && (didServerAnswersComeIn || didWeMoveToANewSection)
    );
  };
}

const reg = new RegExp(`^-?\\$?([0-9,]+)?(\\.([0-9]{0,2})?)?$`);

export function toMaskToValue(val, roundCurrency) {
  const amount =
    typeof val === "string" ? parseFloat(val.replace(/\$|,/g, ""), 10) : val;

  const isNegative = amount < 0;

  if (val === "" || isArray(val)) {
    return {
      value: "",
      mask: "",
    };
  } else if (amount === 0) {
    return {
      value: amount,
      mask: "$0",
    };
  } else if (!amount) {
    return {
      value: null,
      mask: "",
    };
  } else if (amount >= 1000 || amount * -1 >= 1000) {
    if (roundCurrency) {
      return {
        value: amount,
        mask: isNegative
          ? `-$${commaUpNotFixed(amount * -1)}`
          : `$${commaUpNotFixed(amount)}`,
      };
    }

    return {
      value: amount,
      mask: isNegative ? `-$${commaUp(amount * -1)}` : `$${commaUp(amount)}`,
    };
  } else {
    if (roundCurrency) {
      return {
        value: amount,
        mask: `$${amount.toFixed(0)}`,
      };
    }

    return {
      value: amount,
      mask: `$${amount.toFixed(2)}`,
    };
  }
}

const commaUp = (num) =>
  num.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

const commaUpNotFixed = (num) =>
  num.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
