import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function GETDefaultAnswerOrder(clientId, resolutionCaseId) {
  return fetchAsObservable(
    `/clients/${clientId}/resolution_cases/${resolutionCaseId}/answer-orders`
  ).pipe(pluck("answer-orders"));
}

export function POSTDefaultAnswerOrder(
  answerOrder,
  clientId,
  resolutionCaseId
) {
  return fetchAsObservable(
    `/clients/${clientId}/resolution_cases/${resolutionCaseId}/answer-orders`,
    {
      method: "POST",
      body: {
        "answer-orders": answerOrder,
      },
    }
  ).pipe(pluck("answer-orders"));
}
