import React from "react";

import { get } from "lodash";
import QuestionType from "../questions/question-type.component.js";
import Subheader from "./subheader.component.js";
import styles from "./block.styles.css";
import { questionHasAnotherDataSource } from "../questions/answer-set-viewer.helpers.js";
import { sectionMountedObservable } from "../answers/answer.actions.js";

export default class Block extends React.Component {
  constructor() {
    super();
    this.blockRef = React.createRef();
    this.firstInputRef = null;
  }

  componentDidMount() {
    if (this.props.blockIndex === 0) {
      this.focusFirstInputInBlock();
    }

    this.blockRef.current.addEventListener(
      "end-user-forms-ui:block-focus",
      this.focusFirstInputInBlock
    );
  }

  componentWillUnmount() {
    this.blockRef.current.removeEventListener(
      "end-user-forms-ui:block-focus",
      this.focusFirstInputInBlock
    );
  }

  render() {
    const { props } = this;
    const {
      parentName,
      blockIndex,
      sectionId,
      block,
      answers,
      summaryTable,
      formName,
      clientId,
      alternateAnswerSets,
      overrideAnswerSet,
      questionsToShowRequiredWarnings,
      actions,
      resolutionCaseId,
      noticeId,
      version,
      revision,
      folderIds,
      taskId,
      summaryTableIndex,
      taxPrep,
      sourceFormId,
      dynamicData,
      numBlocks,
      parentServerAnswers,
    } = props;

    return (
      <div
        ref={this.blockRef}
        className={styles.block}
        data-block-id={block.id}
      >
        <Subheader
          label={block?.name}
          link={block?.link}
          linkIcon={block?.link_icon}
          linkText={block?.link_text}
        />
        <div className={styles.blockContent}>
          {(block.questions || [])
            .filter((question) => question.visible)
            .map((question, questionIndex) => {
              const fullName = getFullName(formName, question);
              return (
                <div
                  className={
                    questionHasAnotherDataSource(question, overrideAnswerSet) &&
                    overrideAnswerSet &&
                    alternateAnswerSets
                      ? styles.anotherDataSource
                      : styles.noOtherDataSource
                  }
                  key={question.name || questionIndex}
                >
                  <QuestionType
                    parentName={parentName}
                    summaryTableIndex={summaryTableIndex}
                    blockIndex={blockIndex}
                    questionIndex={questionIndex}
                    sectionId={sectionId}
                    question={question}
                    serverAnswer={answers.serverAnswers[fullName]}
                    answers={answers}
                    key={question.name}
                    summaryTable={summaryTable}
                    fullName={fullName}
                    clientId={clientId}
                    sectionResize={props.sectionResize}
                    alternateAnswerSets={alternateAnswerSets}
                    actions={actions}
                    overrideAnswerSet={overrideAnswerSet}
                    questionsToShowRequiredWarnings={
                      questionsToShowRequiredWarnings
                    }
                    setFirstInputRef={this.setFirstInputRef}
                    resolutionCaseId={resolutionCaseId}
                    noticeId={noticeId}
                    version={version}
                    revision={revision}
                    sourceFormId={sourceFormId}
                    folderIds={folderIds}
                    taskId={taskId}
                    taxPrep={taxPrep}
                    dynamicData={dynamicData}
                    totalQuestions={block.questions.length}
                    numBlocks={numBlocks}
                    parentServerAnswers={parentServerAnswers}
                    formName={formName}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  focusFirstInputInBlock = () => {
    setTimeout(() => {
      const focusInput =
        get(this, "firstInputRef.el.focusInput") ||
        get(this, "firstInputRef.el.el.focusInput");
      if (focusInput) focusInput();
      else if (this.firstInputRef && this.firstInputRef.focus) {
        this.firstInputRef.focus();
      }
      //this setTimeout is necessary for the focus logic to work correctly in Safari
      setTimeout(() => {
        sectionMountedObservable.next();
      });
    }, 300);
  };

  setFirstInputRef = (ref) => (this.firstInputRef = ref);
}

function getFullName(formName, question) {
  return question.name;
}
