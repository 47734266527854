import React from "react";
import { findIndex, cloneDeep } from "lodash";
import { Scoped, k } from "kremling";
import { CpButton } from "canopy-styleguide!sofe";

export default function OverlaySectionFooter({
  taxFormId,
  activeSection,
  sections,
  updateSection,
  showTaxForm,
  sourceFormLayout,
}) {
  if (!activeSection.id) return null;

  let activeIndex = findIndex(
    sections,
    (section) => section.id === activeSection.id
  );

  if (activeIndex === -1) return null;

  let first = sections.length
    ? sections[0].id === activeSection.id ||
      allPreviousSectionsInvisible(activeSection.id, sections)
    : true;
  let last = sections.length
    ? sections[sections.length - 1].id === activeSection.id ||
      allSubsequentSectionsInvisible(activeSection.id, sections)
    : true;

  return (
    <Scoped css={css}>
      <div className="overlay-section-footer">
        {!first && (
          <div style={{ marginRight: 16 }}>
            <CpButton
              btnType="secondary"
              disabled={sourceFormLayout.savingAnswer}
              onClick={() => {
                updateSection(getNextSection(activeIndex, -1, sections));
              }}
            >
              Previous section
            </CpButton>
          </div>
        )}
        {!last && (
          <CpButton
            btnType="primary"
            disabled={sourceFormLayout.savingAnswer}
            onClick={() => {
              updateSection(getNextSection(activeIndex, 1, sections));
            }}
          >
            Next section
          </CpButton>
        )}
        {last && taxFormId && (
          <CpButton btnType="primary" onClick={showTaxForm}>
            View tax form
          </CpButton>
        )}
      </div>
    </Scoped>
  );
}

function allPreviousSectionsInvisible(curSectionId, sections) {
  return (
    sections.find((section) => section.id === curSectionId || section.visible)
      .id === curSectionId
  );
}

function allSubsequentSectionsInvisible(curSectionId, sections) {
  return (
    cloneDeep(sections)
      .reverse()
      .find((section) => section.id === curSectionId || section.visible).id ===
    curSectionId
  );
}

function getNextSection(index, direction, sections) {
  if (sections[index + direction].visible) return sections[index + direction];
  return getNextSection(index + direction, direction, sections);
}

const css = k`
  .overlay-section-footer {
    height: 64px;
    width: 100%;
    background: var(--cps-color-medium-well);
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid var(--cps-color-border);
    border-width: 0 1px 1px 1px;
    box-shadow: 0px 10px 30px -24px rgba(75, 78, 83, 0.4);
  }
`;
