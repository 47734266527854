import { handleError } from "src/handle-error";

const localStorageItemName = "cp:dismissed-survey-messages";

export function shouldShowClientWelcome(surveyId) {
  return getDismissedSurveyMessages().indexOf(surveyId) < 0;
}

export function dismissClientWelcome(surveyId) {
  if (typeof surveyId !== "string" || surveyId.length <= 0) {
    handleError(
      Error(`Cannot dismiss client welcome message of survey '${surveyId}'`)
    );
  }
  const alreadyDismissedMessages = getDismissedSurveyMessages();
  if (alreadyDismissedMessages.indexOf(surveyId) < 0) {
    // We need to add this survey to the dismissed surveys
    localStorage.setItem(
      localStorageItemName,
      JSON.stringify(alreadyDismissedMessages.concat(surveyId))
    );
  }
}

function getDismissedSurveyMessages() {
  let dismissedInvitations;
  try {
    const localStorageValue = JSON.parse(
      localStorage.getItem(localStorageItemName)
    );
    if (Array.isArray(localStorageValue)) {
      dismissedInvitations = localStorageValue;
    } else {
      dismissedInvitations = [];
    }
  } catch (err) {
    dismissedInvitations = [];
  }

  return dismissedInvitations;
}
