export const SET_CLIENT_SURVEY_SOURCE_FORM_ID =
  "SET_CLIENT_SURVEY_SOURCE_FORM_ID";
export const CLEAR_CLIENT_SURVEY_SOURCE_FORM_ID =
  "CLEAR_CLIENT_SURVEY_SOURCE_FORM_ID";
export const GETTING_CLIENT_SURVEY_SOURCE_FORM_ID =
  "GETTING_CLIENT_SURVEY_SOURCE_FORM_ID";
export const GETTING_CLIENT_SURVEY_SUBSECTION =
  "GETTING_CLIENT_SURVEY_SUBSECTION";
export const GOT_CLIENT_SURVEY_SUBSECTION = "GOT_CLIENT_SURVEY_SUBSECTION";

export const GETTING_CLIENT_SURVEY = "GETTING_CLIENT_SURVEY";
export const GOT_SURVEY = "GOT_SURVEY";
export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const RESET_CLIENT_SURVEY = "RESET_CLIENT_SURVEY";
export const SENT_CLIENT_SURVEY = "SENDING_CLIENT_SURVEY";

export const GOT_CLIENT_USERS = "GOT_CLIENT_USERS";
export const GOT_ALL_CLIENTS = "GOT_ALL_CLIENTS";

// Survey options types
export const OPEN_SURVEY_OPTIONS = "OPEN_SURVEY_OPTIONS";
export const CANCEL_SURVEY_OPTIONS = "CANCEL_SURVEY_OPTIONS";
export const GO_TO_SURVEY_OPTION = "GO_TO_SURVEY_OPTION";
export const SET_CLIENT_ACCESS_SURVEY_OPTION =
  "SET_CLIENT_ACCESS_SURVEY_OPTION";
export const SET_CLIENT_ACCESS_AUTO_REMOVE = "SET_CLIENT_ACCESS_AUTO_REMOVE";
export const SET_SURVEY_OPTIONS_ANSWER_ORDER =
  "SET_SURVEY_OPTIONS_ANSWER_ORDER";

//Client portal types
export const NEW_SURVEYS_FOR_CLIENT = "NEW_SURVEYS_FOR_CLIENT";
export const RESET_SURVEYS_FOR_CLIENT = "RESET_SURVEYS_FOR_CLIENT";
