import fetcher, {
  fetchWithSharedCache,
  forceBustCache,
  fetchAsObservable,
} from "fetcher!sofe";
import { from } from "rxjs";
import { map, pluck } from "rxjs/operators";

export function getUsersFromClient(clientId) {
  return fetchWithSharedCache(
    `/api/clients/${clientId}?include=users`,
    `clients/${clientId}`
  ).pipe(map((json) => json.clients.users));
}

export function removeUserFromClient(clientId, userId) {
  return from(
    fetcher(`/api/clients/${clientId}/users/${userId}`, {
      method: "DELETE",
    }).then((resp) => {
      if (!resp.ok)
        throw new Error(
          `Server could not remove user from client, responded with ${resp.status}`
        );
    })
  );
}

export function clientObservable(clientId) {
  if (!clientId) {
    throw new Error(`Cannot fetch client without being given a client id`);
  }

  return fetchWithSharedCache(
    `/api/clients/${clientId}?include=users`,
    `clients/${clientId}`
  ).pipe(map((json) => json.clients));
}

export function clientUsersChanged(clientId) {
  forceBustCache(`/api/clients/${clientId}?include=users`);
}

export function getClientById(clientId) {
  return fetchAsObservable(`api/clients/${clientId}`).pipe(pluck("clients"));
}
