// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-source-forms-questions-informational-text-styles__root {
  padding: 1.6rem 0;
  font-size: 1.3rem;
}
`, "",{"version":3,"sources":["webpack://./src/source-forms/questions/informational-text.styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[":local(.root) {\n  padding: 1.6rem 0;\n  font-size: 1.3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `src-source-forms-questions-informational-text-styles__root`
};
export default ___CSS_LOADER_EXPORT___;
