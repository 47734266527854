import React from "react";
import styles from "./inputs.style.css";
import { get, noop } from "lodash";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import { always } from "kremling";
import { validateWithXsdRegex } from "./input.helper";

@CanopyInput()
export default class Paragraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: null,
      localAnswer: props.answer,
      characterLimit: null,
      characterLimitExceeded: false,
    };
    this.focused = false;
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { answer } = this.props;
    const { characterLimitExceeded, localAnswer } = this.state;

    if (
      !characterLimitExceeded &&
      this.props.characterLimit &&
      localAnswer &&
      prevState.localAnswer !== localAnswer &&
      localAnswer.length > this.props.characterLimit
    ) {
      // if characterLimitExceeded is false and the the character limit is exceeded then set to true
      this.setState({ characterLimitExceeded: true });
    }

    if (
      this.props.characterLimit &&
      prevState.localAnswer !== localAnswer &&
      (!answer || !localAnswer || answer.length <= this.props.characterLimit)
    ) {
      // dont use character limit if an old return already has value outside limit until that field reaches blank or if new return
      this.setState({ characterLimit: this.props.characterLimit });
    }

    if (prevProps.answer !== answer && !this.focused) {
      clearInterval(this.state.interval);
      this.setState({ localAnswer: answer });
      this.props.validate(answer);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    const extraStyles = this.props.style ? this.props.style : {};
    const { characterLimit, characterLimitExceeded, localAnswer } = this.state;

    return (
      <>
        <textarea
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          value={localAnswer || ""}
          onChange={this.handleChange.bind(this)}
          ref={this.inputRef}
          maxLength={characterLimit || ""}
          style={{ resize: "vertical", height: "6.4rem", ...extraStyles }}
          onPaste={this.props.onPaste || noop}
          className={always(
            `cps-form-control ${styles["source-form-400"]} ${
              this.props.question.name
            } ${this.props.className || ""}`
          )
            .maybe(fixedTableStyles.fixedTableTextArea, this.props.inTable)
            .maybe(styles.hasError, characterLimitExceeded && !this.focused)}
          autoComplete="off"
          datax={this.props.datax}
          datay={this.props.datay}
        />
        {this.props.characterLimit && (
          <div
            className={always(
              `${styles.characterCount} ${styles["source-form-400"]}`
            ).maybe(styles.characterCountError, characterLimitExceeded)}
          >
            {characterLimitExceeded && <div>Current input is too long</div>}
            <div>{`${localAnswer ? localAnswer.length : 0}/${
              this.props.characterLimit
            }`}</div>
          </div>
        )}
        {this.renderValidationError(localAnswer)}
      </>
    );
  }

  renderValidationError = (value) => {
    const { characterLimit } = this.props;
    const xsdType = get(this.props, "xsdType");

    if (characterLimit && this.state.characterLimitExceeded) {
      return;
    }

    if (value && xsdType) {
      const isValid = validateWithXsdRegex(xsdType, value);

      if (!isValid && xsdType.customDescription) {
        return (
          <div style={{ color: "var(--cps-color-mandy)" }}>
            {xsdType.customDescription}
          </div>
        );
      } else if (!isValid && xsdType.irsDescription) {
        return (
          <div style={{ color: "var(--cps-color-mandy)" }}>
            {xsdType.irsDescription}
          </div>
        );
      } else if (!isValid) {
        return (
          <div style={{ color: "var(--cps-color-mandy)" }}>
            Input does not meet requirements or has invalid characters
          </div>
        );
      }
    }
  };

  focusInput = () => {
    const input = get(this, "inputRef.current");
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  handleChange(e) {
    const answer = e.target.value;
    this.props.validate(answer);
    this.setState((state) => {
      if (
        state.characterLimitExceeded &&
        answer.length <= this.props.characterLimit
      ) {
        return { characterLimitExceeded: false, localAnswer: answer };
      }

      return { localAnswer: answer };
    });
  }

  handleFocus(event) {
    this.focused = true;
    this.setState({
      interval: setInterval(
        () => this.props.updateAnswer(this.props.question, this.props.answer),
        4000
      ),
    });
  }

  handleBlur(event) {
    const answer = this.state.localAnswer;
    this.focused = false;
    clearInterval(this.state.interval);
    this.props.validate(answer);
    this.props.updateAnswer(this.props.question, answer);
  }

  validate = (value) => {
    const { xsdType } = this.props;

    if (value && xsdType) {
      return validateWithXsdRegex(xsdType, value);
    }

    return true;
  };
}
