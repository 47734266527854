import React from "react";
import { Scoped } from "kremling";
import { isEmpty, partial, valuesIn, mapValues, get } from "lodash";
import { connect } from "react-redux";
import { CpButton } from "canopy-styleguide!sofe";
import DrawerTitle from "../../common/drawer/drawer-title.component";
import DrawerContainer from "../../common/drawer/drawer-container.component";
import {
  bretRows,
  matrixify,
  updateMatrix,
  flattenQuestions,
  coalesceMatrices,
  summaryQuestions,
  createRowsFromDataset,
  addRowToMatrix,
  tabObservable,
  focusLastInput,
} from "./clipboard-matrix-helper";
import {
  buildList,
  generateHeader,
  defaultRows,
  emptyRow,
  allEmpty,
} from "./paste-table-helper";
import { updateMultiple } from "src/source-forms/answers/answer.actions";

@connect((state) => {})
export default class PasteColumnForm extends React.Component {
  constructor(props) {
    super(props);
    const columnsFromProps = flattenQuestions(props);
    const questionMap = summaryQuestions(props.question.blocks);

    if (!isEmpty(props.initialAnswers)) {
      const rowsFromSet = [
        ...createRowsFromDataset(
          props.initialAnswers,
          columnsFromProps,
          questionMap
        ),
        emptyRow(columnsFromProps),
      ];

      this.state = {
        columns: columnsFromProps,
        rows: rowsFromSet,
        templateMatrix: matrixify(rowsFromSet, 0, 0, columnsFromProps),
        summaryQuestions: questionMap,
      };
    } else {
      const emptyRows = defaultRows(columnsFromProps);

      this.state = {
        columns: columnsFromProps,
        rows: emptyRows,
        templateMatrix: matrixify(emptyRows, 0, 0, columnsFromProps),
        summaryQuestions: questionMap,
      };
    }
  }

  componentDidUpdate(prevP, prevS) {
    const tableChanged =
      get(prevP, "serverAnswer.summary") !==
      get(this.props, "serverAnswer.summary");
    const answersChanged =
      prevP.initialAnswers !== this.props.initialAnswers &&
      this.props.initialAnswers.length &&
      !prevP.initialAnswers;

    if (tableChanged || answersChanged) {
      // if server answers arrive, we need to populate the grid
      const initialRows = [
        ...createRowsFromDataset(
          this.props.initialAnswers,
          prevS.columns,
          prevS.summaryQuestions
        ),
        emptyRow(prevS.columns),
      ];

      this.setState(
        {
          rows: initialRows,
          templateMatrix: matrixify(initialRows, 0, 0, prevS.columns),
        },
        this.focusLast
      );
    }
  }

  componentDidMount() {
    this.focusLast();
    this.tabObservable = tabObservable.subscribe((e) => {
      focusLastInput(e, this.state.templateMatrix);
    });
  }

  componentWillUnmount() {
    saveForm(
      this.state.templateMatrix,
      this.props,
      this.state.summaryQuestions,
      true
    );
    this.tabObservable.unsubscribe();
  }

  focusLast() {
    const y = this.state.rows.length - 1;
    const targetCell = document.getElementById(`0-${y}`);
    targetCell && targetCell.focus();
  }

  onPasteChildren = (e) => {
    e.preventDefault();
    e.target.blur();
    const xCoor = parseInt(e.target.getAttribute("datax"), 10);
    const yCoor = parseInt(e.target.getAttribute("datay"), 10);
    const newRows = bretRows(e.clipboardData || window.clipboardData);
    const matrix = matrixify(newRows, xCoor, yCoor, this.state.columns);

    this.setState(function (previousState, props) {
      return {
        templateMatrix: addRowToMatrix(
          coalesceMatrices(previousState.templateMatrix, matrix),
          previousState.columns
        ),
      };
    });
  };

  updateCell = (newValue, x, y) => {
    this.setState(function (previousState, props) {
      return {
        templateMatrix: updateMatrix(
          previousState.templateMatrix,
          newValue,
          x,
          y,
          previousState.columns
        ),
      };
    });
  };

  render() {
    const { columns, templateMatrix, summaryQuestions } = this.state;
    const { isScratchPad, open, toggle, question, context } = this.props;
    const children = buildList(
      templateMatrix,
      partial(this.onPasteChildren),
      columns,
      partial(this.updateCell)
    );

    const columnHeader = generateHeader(columns);
    const drawerLabel = get(question, "summary.buttonText");

    const component = (
      <ParentForm
        matrix={templateMatrix}
        columns={columns}
        summaryQuestions={summaryQuestions}
        {...this.props}
      >
        {columnHeader}
        <div>{children}</div>
      </ParentForm>
    );

    return isScratchPad ? (
      component
    ) : (
      <DrawerContainer
        isOpen={open}
        onClose={toggle.bind(this, false)}
        styleOverride={{
          maxWidth: "1200px",
          overflow: "hidden",
        }}
        pastepocalypseWidth={1200}
        pastepocalypseInQuickLook={context ? context.quickLookOpen : null}
      >
        <DrawerTitle
          isDrawerOpen={open}
          title={drawerLabel}
          onClose={toggle.bind(this, false)}
          isTabularViewDrawer={true}
        />
        {component}
      </DrawerContainer>
    );
  }
}

const ParentForm = (props) => (
  <Scoped css={css}>
    <div className="render-container">
      <form className="rows-form">
        <div className="cps-inset-table">{props.children}</div>
      </form>
      <div className="footer-wrapper">
        <hr />
        <div className="form-footer">
          <CpButton
            btnType="flat"
            onClick={
              props.isScratchPad
                ? props.switchToSummaryTable
                : () => props.toggle(false)
            }
          >
            Single entry
          </CpButton>
        </div>
      </div>
    </div>
  </Scoped>
);

const saveForm = (matrix, props, summaryQuestions, onUnmount = false) => {
  const answers = valuesIn(
    mapValues(matrix, (row) =>
      row.reduce((answer, cell) => {
        const questionName = summaryQuestions[cell.columnName];
        return {
          ...answer,
          [questionName]: cell.fieldValue === "BLANK" ? "" : cell.fieldValue,
        };
      }, {})
    )
  ).reduce(
    (answersList, answer, i) =>
      allEmpty(answer)
        ? answersList
        : [
            ...answersList,
            props.initialAnswers[i]
              ? { ...answer, _rowId: props.initialAnswers[i]._rowId }
              : answer,
          ],
    []
  );

  const requestObj = {
    clientId: props.clientId,
    resolutionCaseId: props.resolutionCaseId,
    sectionId: props.sectionId,
    versionId: props.version,
    revisionId: props.revision,
    sourceFormId: props.sourceFormId,
    answers: {
      reduxAnswers: {
        [props.fullName]: answers,
      },
      serverAnswers: props.serverAnswer,
    },
  };

  updateMultiple(
    requestObj,
    props.question,
    answers,
    props.dispatch,
    onUnmount
  );

  if (!onUnmount) {
    if (props.isScratchPad) {
      props.context.closeQuickLook();
    } else {
      props.toggle(false);
    }
  }
};

const css = `
  & .render-container {
    padding: 1.5rem 2.4rem 0 2rem;
    display: flex;
    height: calc(100vh - 330px);
    flex-direction: column;
    align-items: stretch;
  }
  & .rows-form {
    overflow: auto;
    flex: 1;
  }

  & .form-footer {
    padding: 16px 0px;
  }

  & .footer-wrapper {
    background: var(--cps-color-bumble);
  }
`;
