export function getSectionById(sections, id) {
  if (!sections || sections.length <= 0) {
    return null;
  }

  // Section ids are never "0", but it is a magical number that indicates "the first section"
  if (id === "0") {
    return sections[0];
  } else {
    return sections.find((section) => section.id === id);
  }
}
