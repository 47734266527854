import React from "react";
import styles from "./report-inspector.styles.css";

let fly;
export default function ReportInspector(props) {
  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={`cps-pull-right`}
    >
      Report Inspector {props.smeMode ? "On" : "Off"}
      <div
        ref={(ref) => (fly = ref)}
        aria-label="Inspector toggle"
        style={
          props.smeMode &&
          typeof window !== "undefined" &&
          window.localStorage &&
          window.localStorage.getItem("superSme")
            ? goTime(fly)
            : {}
        }
        role="button"
        className={`${styles.switch} ${
          !props.smeMode ? "cps-bg-gray-10" : "cps-bg-color-primary"
        }`}
        onClick={() => {
          document.body.removeEventListener("mousemove", getWorried);
          props.toggleSmeMode(!props.smeMode);
        }}
      >
        <div
          style={{
            float: props.smeMode ? "right" : "left",
          }}
          className={`${styles.switchCircle}`}
        />
      </div>
      {props.smeMode &&
      typeof window !== "undefined" &&
      window.localStorage &&
      window.localStorage.getItem("superSme") ? (
        <div style={{ opacity: 0 }} className={`${styles.switch}`} />
      ) : null}
    </div>
  );
}

let junkState;
const speed = 6;

function goTime(element) {
  let rect = element.getBoundingClientRect();
  prepare();
  junkState = { top: rect.top, left: rect.left };
  return {
    position: "fixed",
    top: rect.top,
    left: rect.left - 14,
    zIndex: 1000000000,
  };
}

function prepare() {
  setTimeout(() => {
    document.body.addEventListener("mousemove", getWorried);
  }, 5000);
}

function getWorried(e) {
  if (
    Math.abs(junkState.top - e.clientY) < 120 &&
    Math.abs(junkState.left - e.clientX) < 150
  )
    runnnn({ x: e.clientX, y: e.clientY });
}

function runnnn(enemy) {
  if (junkState.top <= 0 || junkState.top >= window.innerHeight - 30) {
    return dodgeLeftRight(enemy, junkState.top <= 0);
  }
  if (junkState.left <= 0 || junkState.left >= window.innerWidth - 80) {
    return dodgeUpDown(enemy, junkState.left <= 0);
  }

  if (enemy.x > junkState.left) {
    mooove("left", -1);
  } else if (enemy.x < junkState.left) {
    mooove("left", 1);
  }

  if (enemy.y > junkState.top) {
    mooove("top", -1);
  } else if (enemy.y < junkState.top) {
    mooove("top", 1);
  }
}

function dodgeLeftRight(enemy, start) {
  fly.style.top = (start ? 0 : window.innerHeight - 30) + "px";
  junkState.top = start ? 0 : window.innerHeight - 30;
  if (enemy.x > junkState.left) {
    sprint("left", -6, 200, enemy);
    sprint("top", start ? 9 : -9, 200, enemy);
  } else if (enemy.x < junkState.left) {
    sprint("left", 6, 200, enemy);
    sprint("top", start ? 9 : -9, 200, enemy);
  }
}

function dodgeUpDown(enemy, start) {
  fly.style.left = (start ? 0 : window.innerWidth - 80) + "px";
  junkState.left = start ? 0 : window.innerWidth - 80;
  if (enemy.y > junkState.top) {
    sprint("top", -6, 200, enemy);
    sprint("left", start ? 9 : -9, 200, enemy);
  } else if (enemy.y < junkState.top) {
    sprint("top", 6, 200, enemy);
    sprint("left", start ? 9 : -9, 200, enemy);
  }
}

function mooove(dir, amount) {
  fly.style[dir] = junkState[dir] + amount * speed + "px";
  junkState[dir] += amount * speed;
}

function sprint(dir, amount, distance, enemy) {
  let interval = setTimeout(() => {
    distance--;
    mooove(dir, amount);
    if (
      junkState.left <= 0 ||
      junkState.left >= window.innerWidth ||
      junkState.top <= 0 ||
      junkState.top >= window.innerHeight
    ) {
      clearInterval(interval);
      runnnn(enemy);
    }
    if (!distance) {
      clearInterval(interval);
    }
  }, 50);
}
