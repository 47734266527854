import React from "react";
import styles from "./inputs.style.css";
import { get } from "lodash";
import CanopyInput from "./canopy-input.decorator.js";

@CanopyInput()
export default class Radio extends React.Component {
  constructor(props) {
    super();
    this.state = {
      localAnswer: props.answer,
    };
    this.inputRef = React.createRef();
  }

  focusInput = () => {
    const input = get(this, "inputRef.current");
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  render() {
    const { question, parentName, blockIndex, questionIndex } = this.props;
    const { localAnswer } = this.state;

    if (!question.meta.options || !question.meta.options.length) {
      return (
        <div>
          <b className="cps-warning">
            The question {question.name} has no options!
          </b>
        </div>
      );
    }

    const optionsAreStacked = question.meta.options.length > 2;
    return (
      <div
        className={`cps-radio-container ${
          optionsAreStacked ? styles.itemsStacked : styles.itemsFlat
        }`}
      >
        {question.meta.options.map((option, i) => {
          return (
            <label
              className={`${styles.radioCheck} cps-radio`}
              key={option.value}
              style={{ paddingBottom: "4px" }}
            >
              <input
                type="radio"
                ref={i === 0 ? this.inputRef : null}
                value={option.value}
                name={getInputName(
                  parentName,
                  blockIndex,
                  questionIndex,
                  question.name
                )}
                onChange={this.valueChanged}
                className={`cps-form-control ${question.name}`}
                onBlur={this.props.validateAnswer}
                checked={option.value === localAnswer}
              />
              <span style={{ display: "flex" }}>
                <div>{option.label}</div>
              </span>
            </label>
          );
        })}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer) {
      this.setState({ localAnswer: this.props.answer });
      this.props.validate(this.props.answer);
    }
  }

  valueChanged = (e) => {
    const { localAnswer } = this.state;
    let { value } = e.target;

    if (localAnswer === value) value = "";
    this.setState({
      localAnswer: value,
    });
    this.props.validate(value);
    this.props.updateAnswer(this.props.question, value);
  };
}

function getInputName(parentName, blockIndex, questionIndex, questionName) {
  return `${parentName}_${blockIndex}_${questionIndex}_${questionName}`;
}
