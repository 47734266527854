import React from "react";
import { NavContent } from "primary-navbar!sofe";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

@withRouter
@connect((state) => ({
  clientSurvey: state.clientSurvey.survey,
}))
export default class CreateSurveyWrapper extends React.Component {
  componentDidMount() {
    const { baseUrl } = this.props;
    if (window.location.hash.split("?")[0] === `#${baseUrl}`)
      redirect(this.props, baseUrl);
  }
  componentDidUpdate(prevProps) {
    const { baseUrl } = prevProps;

    if (
      prevProps.clientSurvey &&
      window.location.hash.split("?")[0] === `#${baseUrl}`
    )
      redirect(this.props, baseUrl);
  }
  render() {
    return (
      <NavContent
        style={{ marginLeft: "auto", marginRight: "auto" }}
        hasTopnavSecondary={true}
      >
        {this.props.children}
      </NavContent>
    );
  }
}

function redirect(props, baseUrl) {
  props.history.replace(`${baseUrl}/get-started`);
}
