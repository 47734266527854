import OfferReport from "./reports/offer.component.js";
import PaymentReport from "./reports/payment.component.js";
import CurrentlyReport from "./reports/currently.component.js";

export function getSectionFromResolutionCase(resolutionCase) {
  return resolutionCase.program_data.programs.length
    ? resolutionCase.program_data.programs
        .find((program) => program.slug === "collections")
        .sections.find((section) => section.slug === "analytics")
    : null;
}

export const reports = [
  {
    name: "Offer in compromise",
    urlName: "offer",
    component: OfferReport,
  },
  {
    name: "Payment plan",
    urlName: "payment",
    component: PaymentReport,
  },
  {
    name: "Currently not collectible",
    urlName: "currently",
    component: CurrentlyReport,
  },
];

export function getAvailableReports(func) {
  return func ? reports.find(func) : reports;
}
