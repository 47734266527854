import React from "react";
import { useCss, k } from "kremling";
import { CpButton, CpTooltip } from "canopy-styleguide!sofe";

export default function Subheader({ name = "", link, linkIcon, linkText }) {
  const scoped = useCss(css);

  const openLinkInWindow = () => {
    const dualScreenTop =
      window.screenTop != undefined ? window.screenTop : screen.top;
    const screenWidth =
      window.screenWidth != undefined ? window.screenWidth : screen.width;

    const windowFeatures = `top=${dualScreenTop},left=${screenWidth},width=800,height=800,popup,rel=noreferrer`;
    const handleOpenWindow = window.open(link, "", windowFeatures);

    if (!handleOpenWindow) {
      // The window wasn't allowed to open
      // This is likely caused by built-in popup blockers.
      window.open(link, "", "rel=noreferrer");
    }
  };

  return (
    <div
      {...scoped}
      className={`cps-card__header cp-pl-24 cps-bg-gray-4`}
      style={{ fontSize: "1.6rem" }}
    >
      {name}
      {link && linkIcon && (
        <CpTooltip text="Help">
          <CpButton
            className="cp-mb-4"
            onClick={openLinkInWindow}
            icon="help-circle-open-small"
            aria-label="Help link"
          />
        </CpTooltip>
      )}
      {link && !linkIcon && linkText && (
        <a className="help-button" onClick={openLinkInWindow}>
          {linkText}
        </a>
      )}
    </div>
  );
}

const css = k`
  .help-button {
    margin-left: 1.6rem;
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 0.4rem;
  }
`;
