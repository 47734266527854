import React from "react";
import styles from "./client-access.style.css";
import SelectableCard from "./selectable-card.component.js";
import { partial } from "lodash";

export default function ClientAccess(props) {
  const { clientSurvey, actions } = props;

  return (
    <div>
      <div className={`${styles.explanationText}`}>
        When your client has access to the survey, they can make edits to the
        form fields which could influence the results of some reports and forms.
      </div>
      <div className={`${styles.fancyRadioOptions}`}>
        <SelectableCard
          iconName="cps-icon-client-access"
          title="On - Client has access"
          description="Client can access and edit the survey"
          isChecked={clientSurvey.clientAccess.hasAccess}
          clickAction={partial(actions.setClientAccessSurveyOption, true)}
          marginBottom="8px"
        />
        <SelectableCard
          iconName="cps-icon-client-no-access"
          title="Off - Client doesn't have access"
          description="Client can't access or edit the survey"
          isChecked={!clientSurvey.clientAccess.hasAccess}
          clickAction={partial(actions.setClientAccessSurveyOption, false)}
        />
      </div>
      <div className={`${styles.toggleGrid}`}>
        <div className={`${styles.explanationText}`}>
          Automatically remove access when the client has completed the survey.
        </div>
        <label className={`cps-toggle ${styles.toggle}`}>
          <input
            type="checkbox"
            checked={clientSurvey.clientAccess.removeOnSubmit}
            onChange={partial(changeAutoRemove, actions)}
            disabled={!clientSurvey.clientAccess.hasAccess}
          />
          <span />
        </label>
      </div>
    </div>
  );
}

function changeAutoRemove(actions, evt) {
  actions.setClientAccessAutoRemove(evt.target.checked);
}
