import React from "react";
import { CpIcon, CpDropdown, CpButton } from "canopy-styleguide!sofe";

export default class SummaryTableRowKebab extends React.Component {
  render() {
    const { editRow, removeRow, setRowIndex, rowIndex } = this.props;

    return (
      <CpDropdown
        position="bottom-end"
        fixedContent
        renderTrigger={({ toggle }) => (
          <CpButton
            icon="misc-kabob"
            onClick={() => {
              toggle();
              setRowIndex(rowIndex);
            }}
            aria-label="Edit"
          />
        )}
        renderContent={() => (
          <div className="cp-select-list">
            <button onClick={editRow}>
              <CpIcon
                className="cp-select-list__icon-left"
                name="crud-pencil"
              />
              Edit
            </button>
            <button onClick={removeRow}>
              <CpIcon
                className="cp-select-list__icon-left"
                name="crud-trash-large"
              />
              Delete
            </button>
          </div>
        )}
      />
    );
  }
}
