import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AsyncLoader from "src/common/async-loader.component.js";
import SecondaryNav from "./secondarynav/survey-secondary-nav.component.js";
import * as clientSurveyActions from "./client-survey.actions.js";
import { get } from "lodash";
import { shouldShowClientWelcome } from "./client-portal/client-portal-welcome.helpers.js";
import { UserTenantProps } from "cp-client-auth!sofe";

const asyncChecks = [
  function (props) {
    return props.version;
  },
  function (props) {
    return props.revision;
  },
  function (props) {
    return !props.resolutionCase.isDefaultResolutionCase;
  },
];

@UserTenantProps()
@withRouter
@connect((state) => ({
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
  resolutionCase: state.resolutionCase,
  clientSurvey: state.clientSurvey,
  clientUsers: state.clientSurvey.clientUsers,
  previousClientRecipients: state.clientSurvey.survey.previousClientRecipients,
}))
export default class ClientSurveyWrapper extends React.Component {
  constructor(props) {
    super(props);

    const { clientId, dispatch } = props;
    dispatch(clientSurveyActions.getPossibleClients(clientId));
    this.boundActions = {
      ...bindActionCreators(clientSurveyActions, dispatch),
    };
  }

  componentDidUpdate() {
    const {
      baseUrl,
      history,
      clientSurvey,
      hashRouting = true,
      loggedInUser,
      displayingInClientPortal,
    } = this.props;
    const { gettingClientSurvey } = this.props.clientSurvey;
    const { survey } = this.props.clientSurvey;

    const isClient = loggedInUser.role === "Client";
    const location = hashRouting
      ? window.location.hash
      : window.location.pathname;

    if (isClient) {
      if (
        get(
          this.props,
          "clientSurvey.survey.clientAccess.hasAccess",
          "not-yet-retrieved"
        ) === false
      ) {
        const newUrl = `${baseUrl}/all-done`;
        if (!location.includes(newUrl)) {
          history.replace(newUrl);
          return;
        }
      }

      if (survey !== "default" && survey.id && !displayingInClientPortal) {
        if (
          shouldShowClientWelcome(survey.id) &&
          get(survey, "clientAccess.hasAccess")
        ) {
          const newUrl = `${baseUrl}/welcome`;
          if (!location.includes(newUrl)) {
            history.replace(newUrl);
            return;
          }
        }
      }
    }

    const modifiedBaseUrl = hashRouting ? `#${baseUrl}` : baseUrl;

    if (
      clientSurvey.survey !== "default" &&
      !gettingClientSurvey &&
      location.split("?")[0] === modifiedBaseUrl
    ) {
      if (
        !clientSurvey.survey ||
        clientSurvey.survey.notCreated ||
        clientSurvey.survey.status === "creating"
      )
        history.replace(`${baseUrl}/create-survey`);
      else if (clientSurvey.survey.status === "unsent")
        history.replace(`${baseUrl}/create-survey/get-started`);
      else {
        // this will catch both "sent" and "submitted"
        if (isClient || clientSurvey.survey.surveyType === "taxPrep") {
          history.replace(`${baseUrl}/sections/0/subsections/0`);
        } else {
          history.replace(`${baseUrl}/create-survey/sent-success`);
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clientSurveyActions.resetClientSurvey());
  }

  render() {
    const {
      clientId,
      resolutionCaseId,
      shouldShowSecondaryNav,
      clientUsers,
      clientSurvey,
    } = this.props;

    return (
      <div>
        {shouldShowSecondaryNav && (
          <SecondaryNav
            clientId={clientId}
            resolutionCaseId={resolutionCaseId}
            clientSurvey={clientSurvey}
            actions={this.boundActions}
            clientUsers={clientUsers}
          />
        )}
        <AsyncLoader {...this.props} asyncChecks={asyncChecks}>
          {this.props.children}
        </AsyncLoader>
      </div>
    );
  }
}
