import { fetchWithSharedCache, fetchAsObservable } from "fetcher!sofe";
import { map, pluck } from "rxjs/operators";

export function getAllClients() {
  return fetchWithSharedCache(
    `/wg/users?role=Client`,
    untilNotOnClientSurvey
  ).pipe(map((json) => json.users));
}

function untilNotOnClientSurvey() {
  return window.location.hash.indexOf("survey") < 0;
}

export function getCafs(userId) {
  return fetchAsObservable(`/wg/users/${userId}/cafs`).pipe(pluck("cafs"));
}
