// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-analytics-report-inspector-styles__switch {
  margin: 0 15px;
  border-radius: 15px;
  width: 50px;
  height: 30px;
  cursor: pointer;
}

.src-analytics-report-inspector-styles__switchCircle {
  background: var(--cps-color-bumble);
  height: 28px;
  width: 28px;
  border-radius: 50%;
  margin: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/analytics/report-inspector.styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mCAAmC;EACnC,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".switch {\n  margin: 0 15px;\n  border-radius: 15px;\n  width: 50px;\n  height: 30px;\n  cursor: pointer;\n}\n\n.switchCircle {\n  background: var(--cps-color-bumble);\n  height: 28px;\n  width: 28px;\n  border-radius: 50%;\n  margin: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `src-analytics-report-inspector-styles__switch`,
	"switchCircle": `src-analytics-report-inspector-styles__switchCircle`
};
export default ___CSS_LOADER_EXPORT___;
