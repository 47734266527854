import React from "react";
import { connect } from "react-redux";
import { CpLoader, CpButton } from "canopy-styleguide!sofe";
import Block from "../blocks/block.component";
import { summaryTableValidate } from "../questions/required.helpers";
import { SUMMARY_TABLE_ANSWER } from "./summary-table.props";
import {
  summaryUpdateAnswer,
  closeRow,
  closeRowAndCreateNew,
} from "./summary-table.helpers";
import questionStyles from "../questions/question.styles.css";
import blockStyles from "../blocks/block.styles.css";
import styles from "./summary-table-blocks.styles.css";

@connect((state) => ({}))
export default class SummaryTableBlocks extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  static propTypes = {
    answer: SUMMARY_TABLE_ANSWER,
  };

  render() {
    const {
      blocks,
      serverAnswer,
      sectionId,
      fullName,
      parentName,
      clientId,
      alternateAnswerSets,
      overrideAnswerSet,
      questionsToShowRequiredWarnings,
      actions,
      taxPrep,
      resolutionCaseId,
      noticeId,
      version,
      revision,
      dynamicData,
      isEditing,
      parentServerAnswers,
    } = this.props;

    const { summary, editingIndex } = serverAnswer;

    return blocks.length ? (
      <div ref={(well) => (this.well = well)} className={styles.questionBlock}>
        {blocks.map((block, i) => {
          if (block.visible) {
            return (
              <Block
                className="summary-table-block"
                clientId={clientId}
                parentName={parentName}
                blockIndex={i}
                actions={{
                  ...actions,
                  updateAnswer: (...args) => {
                    this.props.dispatch(
                      summaryUpdateAnswer(
                        actions.updateAnswer,
                        fullName,
                        editingIndex,
                        ...args
                      )
                    );
                  },
                }}
                sectionId={sectionId}
                block={block}
                isLastBlock={i === blocks.length - 1}
                summaryTableIndex={editingIndex}
                answers={{
                  serverAnswers:
                    serverAnswer.answers.length < editingIndex
                      ? {}
                      : serverAnswer.answers[editingIndex],
                }}
                alternateAnswerSets={alternateAnswerSets}
                overrideAnswerSet={overrideAnswerSet}
                questionsToShowRequiredWarnings={
                  questionsToShowRequiredWarnings
                }
                key={i}
                resolutionCaseId={resolutionCaseId}
                noticeId={noticeId}
                version={version}
                revision={revision}
                dynamicData={dynamicData}
                parentServerAnswers={parentServerAnswers}
              />
            );
          }
        })}
        <div
          style={{
            paddingLeft: `${taxPrep ? "28px" : "44px"}`,
            paddingTop: "24px",
          }}
          key="actions"
          className="cps-form-group cps-padding-bottom-24 cps-margin-bottom-0"
        >
          <div className={`${questionStyles.input}`}>
            <button
              onClick={this.continueClicked}
              type="button"
              className="cps-btn cps-bg-color-primary"
              style={{ marginRight: "24px" }}
            >
              Done
            </button>
            {!isEditing && (
              <CpButton btnType="flat" onClick={this.addAnother}>
                Add another
              </CpButton>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div className={`${styles.questionBlock} ${blockStyles.loaderWrapper}`}>
        <CpLoader />
      </div>
    );
  }

  addAnother = (_) => {
    let { serverAnswer, parentQuestion, fullName, actions } = this.props;

    summaryTableValidate(() => {
      closeRowAndCreateNew(
        actions.updateAnswer,
        serverAnswer,
        fullName,
        parentQuestion
      );
    });
  };

  continueClicked = (_) => {
    let {
      serverAnswer,
      parentQuestion,
      fullName,
      sectionResize,
      actions,
      setEditing,
    } = this.props;

    setEditing(false);
    summaryTableValidate(() => {
      setTimeout(sectionResize);
      closeRow(actions.updateAnswer, serverAnswer, fullName, parentQuestion);
    }, this.well);
  };
}

SummaryTableBlocks.defaultProps = {
  serverAnswer: {},
};
