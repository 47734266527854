// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-options-client-access-style__toggle {
  margin-top: 4px;
}

.src-client-survey-options-client-access-style__toggleGrid {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.src-client-survey-options-client-access-style__fancyRadioOptions {
  margin-top: 16px;
}

.src-client-survey-options-client-access-style__explanationText {
  font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/options/client-access.style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".toggle {\n  margin-top: 4px;\n}\n\n.toggleGrid {\n  display: flex;\n  flex-direction: row;\n  margin-top: 16px;\n}\n\n.fancyRadioOptions {\n  margin-top: 16px;\n}\n\n.explanationText {\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `src-client-survey-options-client-access-style__toggle`,
	"toggleGrid": `src-client-survey-options-client-access-style__toggleGrid`,
	"fancyRadioOptions": `src-client-survey-options-client-access-style__fancyRadioOptions`,
	"explanationText": `src-client-survey-options-client-access-style__explanationText`
};
export default ___CSS_LOADER_EXPORT___;
