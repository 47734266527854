import * as types from "./answer-order.types.js";

const defaultState = {
  defaultOrder: null,
  gettingDefaultOrder: false,
};

export default function reduceAnswerOrder(state = defaultState, action) {
  if (action.type === types.GETTING_ENGAGEMENT_DEFAULT_ANSWER_ORDER) {
    return {
      ...state,
      gettingDefaultOrder: true,
    };
  }

  if (action.type === types.GOT_ENGAGEMENT_DEFAULT_ANSWER_ORDER) {
    return {
      ...state,
      defaultOrder: action.defaultOrder,
      gettingDefaultOrder: false,
    };
  }

  if (action.type === types.CANCEL_GETTING_DEFAULT_ANSWER_ORDER) {
    return {
      ...state,
      gettingDefaultOrder: false,
    };
  }

  return state;
}
