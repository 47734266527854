// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-source-form-cssf-subsection-progress-styles__subsectionLink {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  height: 32px;
}

.src-client-survey-source-form-cssf-subsection-progress-styles__subsectionLink:hover,
.src-client-survey-source-form-cssf-subsection-progress-styles__subsectionLink:focus,
.src-client-survey-source-form-cssf-subsection-progress-styles__subsectionLink:visited,
.src-client-survey-source-form-cssf-subsection-progress-styles__subsectionLink:active {
  text-decoration: none;
}

.src-client-survey-source-form-cssf-subsection-progress-styles__subsection {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/source-form/cssf-subsection-progress.styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;;;;EAIE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB","sourcesContent":[".subsectionLink {\n  text-decoration: none;\n  color: inherit;\n  display: flex;\n  align-items: center;\n  height: 32px;\n}\n\n.subsectionLink:hover,\n.subsectionLink:focus,\n.subsectionLink:visited,\n.subsectionLink:active {\n  text-decoration: none;\n}\n\n.subsection {\n  width: 24px;\n  height: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subsectionLink": `src-client-survey-source-form-cssf-subsection-progress-styles__subsectionLink`,
	"subsection": `src-client-survey-source-form-cssf-subsection-progress-styles__subsection`
};
export default ___CSS_LOADER_EXPORT___;
