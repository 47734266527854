import React from "react";
import { Provider } from "react-redux";

import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import { capitalize } from "lodash";

import contextReducer from "../bootstrap/context.reducer.js";
import resolutionCaseReducer from "../resolution-cases/resolution-cases.reducer.js";
import reduxClientReducer from "../clients/redux-client.reducer.js";
import layoutReducer from "../source-forms/source-form-layout.reducer.js";
import sectionReducer from "../source-forms/sections/section.reducer.js";
import answerReducer from "../source-forms/answers/answer.reducer.js";
import versionReducer from "../source-forms/version.reducer.js";
import uiFormsReducer from "../source-forms/ui-forms.reducer.js";
import summaryTableReducer from "../source-forms/summary-table/summary-table.reducer.js";
import dynamicDataReducer from "src/source-forms/dynamic-data.reducer.js";
import clientSurveyReducer from "../client-survey/client-survey.reducer.js";
import activeQuestionReducer from "../source-forms/questions/active-question.reducer.js";

import { newLoggedInUser, newTenant } from "../bootstrap/context.actions.js";
import SubsectionWrapper from "src/client-survey/source-form/cssf-subsection.wrapper.js";
import * as versionActions from "../source-forms/version.actions.js";
import * as resolutionCaseActions from "src/resolution-cases/resolution-cases.actions.js";
import * as clientSurveyActions from "src/client-survey/client-survey.actions.js";

export class MobileClientSurvey extends React.Component {
  state = {
    store: null,
  };

  componentDidMount() {
    const { loggedInUser, tenant } = this.props;

    const reducer = combineReducers({
      section: sectionReducer,
      answers: answerReducer,
      sourceFormLayout: layoutReducer,
      context: contextReducer,
      resolutionCase: resolutionCaseReducer,
      reduxClient: reduxClientReducer,
      smeVersion: versionReducer,
      uiForms: uiFormsReducer,
      summaryTable: summaryTableReducer,
      activeQuestion: activeQuestionReducer,
      dynamicData: dynamicDataReducer,
      clientSurvey: clientSurveyReducer,
    });

    const store = createStore(reducer, applyMiddleware(thunk));
    store.dispatch(newLoggedInUser(loggedInUser));
    store.dispatch(newTenant(tenant));
    this.dispatchVersion(store);

    this.setState({ store });
  }

  dispatchVersion(store = this.state.store) {
    const { clientId, resolutionCaseId, version, revision, type, group } =
      this.props;

    store.dispatch(
      resolutionCaseActions.fetchResolutionCase(
        clientId,
        resolutionCaseId,
        (resolutionCase) => {
          const clientSurveyName =
            type || `${capitalize(resolutionCase.taxpayer_type)} Survey`;
          const groupName = group || "Resolution Client Survey";
          store.dispatch(
            clientSurveyActions.getClientSurveySourceFormId(
              clientId,
              resolutionCaseId,
              version,
              revision,
              clientSurveyName,
              groupName
            )
          );
        }
      )
    );

    store.dispatch(
      versionActions.newSmeVersion({ id: version }, { id: revision })
    );
  }

  render() {
    const {
      clientId,
      resolutionCaseId,
      sourceFormId,
      sectionId,
      subsectionId,
    } = this.props;

    return (
      this.state.store && (
        <Provider store={this.state.store}>
          <>
            <SubsectionWrapper
              mobile
              params={{
                clientId,
                sectionId,
                subsectionIndex: subsectionId,
                resolutionCaseId,
              }}
              resolutionCaseId={resolutionCaseId}
              clientId={clientId}
              sourceFormId={sourceFormId}
              sectionId={sectionId}
            />
          </>
        </Provider>
      )
    );
  }
}
