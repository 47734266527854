import React from "react";
import { Provider } from "react-redux";

import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";

import contextReducer from "../bootstrap/context.reducer.js";
import resolutionCaseReducer from "../resolution-cases/resolution-cases.reducer.js";
import reduxClientReducer from "../clients/redux-client.reducer.js";
import layoutReducer from "../source-forms/source-form-layout.reducer.js";
import sectionReducer from "../source-forms/sections/section.reducer.js";
import answerReducer from "../source-forms/answers/answer.reducer.js";
import versionReducer from "../source-forms/version.reducer.js";
import uiFormsReducer from "../source-forms/ui-forms.reducer.js";
import summaryTableReducer from "../source-forms/summary-table/summary-table.reducer.js";
import dynamicDataReducer from "src/source-forms/dynamic-data.reducer.js";
import reportReducer from "../analytics/collection-analytics.reducer.js";
import resolutionCaseProgramSectionReducer from "../resolution-cases/program-sections/program-section.reducer.js";
import clientSurveyReducer from "../client-survey/client-survey.reducer.js";
import activeQuestionReducer from "../source-forms/questions/active-question.reducer.js";
import resolutionCaseAnswerOrderReducer from "../resolution-cases/answer-order/answer-order.reducer.js";

import { newLoggedInUser, newTenant } from "../bootstrap/context.actions.js";
import TaxPrepSection from "./tax-prep-section.component.js";
import * as versionActions from "../source-forms/version.actions.js";

export class TaxPrepSourceForm extends React.Component {
  state = {
    store: null,
  };

  componentDidMount() {
    const { loggedInUser, tenant } = this.props;

    const reducer = combineReducers({
      section: sectionReducer,
      answers: answerReducer,
      sourceFormLayout: layoutReducer,
      context: contextReducer,
      resolutionCase: resolutionCaseReducer,
      reduxClient: reduxClientReducer,
      smeVersion: versionReducer,
      uiForms: uiFormsReducer,
      summaryTable: summaryTableReducer,
      activeQuestion: activeQuestionReducer,
      dynamicData: dynamicDataReducer,
    });

    const store = createStore(reducer, applyMiddleware(thunk));
    store.dispatch(newLoggedInUser(loggedInUser));
    store.dispatch(newTenant(tenant));
    this.dispatchVersion(store);

    this.setState({ store });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.version &&
      (prevProps.version !== this.props.version ||
        prevProps.revision !== this.props.revision)
    ) {
      this.dispatchVersion();
    }
  }

  dispatchVersion(store = this.state.store) {
    store.dispatch(
      versionActions.newSmeVersion(this.props.version, this.props.revision)
    );
  }

  render() {
    const {
      clientId,
      resolutionCaseId,
      sourceFormId,
      sectionId,
      folderIds,
      taskId,
      groupId,
    } = this.props;

    return (
      this.state.store && (
        <Provider store={this.state.store}>
          <TaxPrepSection
            resolutionCaseId={resolutionCaseId}
            clientId={clientId}
            sourceFormId={sourceFormId}
            sectionId={sectionId}
            folderIds={folderIds}
            taskId={taskId}
            groupId={groupId}
          />
        </Provider>
      )
    );
  }
}
