import { fetchAsObservable } from "fetcher!sofe";
import { map, pluck } from "rxjs/operators";

export function getTaxFormsForFolder(
  clientId,
  resolutionCaseId,
  versionId,
  revisionId,
  folderId
) {
  return fetchAsObservable(
    `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${versionId}-${revisionId}/tax-forms?folderId=${folderId}`
  ).pipe(pluck("tax-forms"));
}

export function getCollectionsCaseCount() {
  return fetchAsObservable(`/per-use-licenses/collections`);
}

export function getCollectionsForms(params) {
  const { versionId, revisionId } = params;
  return fetchAsObservable(
    `/wg/versions/${versionId}-${revisionId}/forms?name=Collections`
  ).pipe(pluck("groups"));
}

export function generateFormPdf(
  clientId,
  resolutionCaseId,
  taxFormId,
  versionId,
  revisionId,
  noticeId
) {
  const contentType = "application/pdf";
  let url = `/clients/${clientId}/resolution_cases/${resolutionCaseId}/tax-form-pdfs/${taxFormId}?version=${versionId}-${revisionId}&action=print`;

  if (noticeId) {
    url = `/clients/${clientId}/notices/${noticeId}/tax-form-pdfs/${taxFormId}?version=${versionId}-${revisionId}&action=print`;
  }

  return fetchAsObservable(url, {
    responseType: "blob",
    credentials: "same-origin",
    headers: {
      "Content-Type": contentType,
    },
  }).pipe(
    map((blob) => {
      // Microsoft Edge browser (non Chromium version) doesn't support the `new File` constructor.  As such, appending file info directly on the blob.
      // This is a hack and hopefully one day we can just go back to using:
      // return new File([blob], `${taxFormId}.pdf`, { type: contentType });
      blob.name = `${taxFormId}.pdf`;
      blob.lastModified = Date.now();
      blob.lastModifiedDate = new Date();

      return blob;
    })
  );
}
