import { useState, useEffect } from "react";
import { capitalize } from "lodash";
import { getTasksByResolutionCaseId } from "src/resources/tasks.resource.js";

const surveyTypeMap = {
  taxPrep: "Organizer",
  taxRes: "Resolution Client Survey",
};

export const getSurveyGroup = (surveyType) => {
  return surveyTypeMap[surveyType] || "Resolution Client Survey";
};

export const getSurveySourceForm = (surveyType, surveyData, resolutionCase) => {
  return surveyType === "taxPrep"
    ? surveyData.returnType
    : `${capitalize(resolutionCase.taxpayer_type)} Survey`;
};

export const useSofeService = (serviceName) => {
  const [sofeService, setSofeService] = useState(null);
  useEffect(() => {
    SystemJS.import(serviceName).then((sofeService) => {
      setSofeService(sofeService);
    });
  }, []);
  return sofeService;
};

export const useTaskAndFiles = (resolutionCaseId) => {
  const [task, setTask] = useState(null);
  const [files, setFiles] = useState([]);
  const [fetching, setFetching] = useState(true);
  useEffect(() => {
    const taskSubscription = getTasksByResolutionCaseId(
      resolutionCaseId
    ).subscribe(
      (task) => {
        setTask(task);
        setFiles(task.relationships.files.filter((file) => file.name));
        setFetching(false);
      },
      () => {
        setFetching(false);
      }
    );
    return () => {
      taskSubscription.unsubscribe();
    };
  }, [resolutionCaseId]);
  return [task, files, setFiles, fetching];
};
