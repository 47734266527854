import { fetchAsObservable } from "fetcher!sofe";
import { isString, isPlainObject } from "lodash";

export function trackUsageEvent(category, action, data = {}) {
  if (!isString(category)) {
    throw new Error(`'category' is required`);
  }

  if (!isString(action)) {
    throw new Error(`'action' is required`);
  }

  if (data && !isPlainObject(data)) {
    throw new Error(`'data' must be a stringifiable object, if provided`);
  }

  return fetchAsObservable(`/tracked-usage`, {
    method: "POST",
    body: {
      "tracked-usage": {
        category,
        action,
        optional: data,
      },
    },
  });
}
