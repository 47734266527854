// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-source-form-cssf-sections-styles__centeredColumns {
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 599px) and (orientation: portrait),
  screen and (max-height: 599px) and (orientation: landscape) {
  .src-client-survey-source-form-cssf-sections-styles__centeredColumns {
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/source-form/cssf-sections.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;;EAEE;IACE,cAAc;EAChB;AACF","sourcesContent":[".centeredColumns {\n  display: flex;\n  align-items: flex-start;\n}\n\n@media only screen and (max-width: 599px) and (orientation: portrait),\n  screen and (max-height: 599px) and (orientation: landscape) {\n  .centeredColumns {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centeredColumns": `src-client-survey-source-form-cssf-sections-styles__centeredColumns`
};
export default ___CSS_LOADER_EXPORT___;
