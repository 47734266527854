// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-source-forms-questions-answer-set-viewer-style__buttonIcon a.cps-link .cps-icon {
  color: var(--cps-color-af) !important;
}

.src-source-forms-questions-answer-set-viewer-style__noButtonIcon {
  width: 12px;
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/source-forms/questions/answer-set-viewer.style.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[":global :local(.buttonIcon) a.cps-link .cps-icon {\n  color: var(--cps-color-af) !important;\n}\n\n.noButtonIcon {\n  width: 12px;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonIcon": `src-source-forms-questions-answer-set-viewer-style__buttonIcon`,
	"noButtonIcon": `src-source-forms-questions-answer-set-viewer-style__noButtonIcon`
};
export default ___CSS_LOADER_EXPORT___;
