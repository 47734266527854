import { cloneDeep, assign } from "lodash";
import { of } from "rxjs";

export function rowIsSelected(editingIndex) {
  return typeof editingIndex !== "undefined" && editingIndex !== null;
}

export function addRow(oldAnswer, updateAnswer, fullName, question) {
  if (!oldAnswer) {
    return updateAnswer(
      question,
      {
        answers: [{}],
        editingIndex: 0,
      },
      {
        updateSummaryTable: true,
        specificQuestion: fullName,
        summaryTableAction: "add_row",
      }
    );
  }
  const answer = cloneDeep(oldAnswer);
  answer.answers = [...(answer.answers || []), {}];
  answer.editingIndex = answer.answers.length - 1;
  updateAnswer(oldAnswer, question, answer, {
    updateSummaryTable: true,
    specificQuestion: fullName,
    summaryTableAction: "add_row",
  });
}

export function removeRow(oldAnswer, updateAnswer, fullName, index, question) {
  const answer = cloneDeep(oldAnswer);
  answer.answers[index] = null;
  const summaryTableRowIdName = getSummaryTableRowIdName(oldAnswer, index);
  const summaryTableRowId = {
    [summaryTableRowIdName]: oldAnswer.answers[index][summaryTableRowIdName],
  };
  updateAnswer(oldAnswer, question, answer, {
    updateSummaryTable: true,
    specificQuestion: fullName,
    summaryTableAction: "remove_row",
    summaryTableRowId,
  });
}

export function editRow(
  oldAnswer,
  updateAnswer,
  fullName,
  index,
  question,
  blocks,
  summaryTableValidate,
  summaryTable,
  setEditing
) {
  setEditing(true);

  summaryTableValidate((_) => {
    const answer = cloneDeep(oldAnswer);
    answer.editingIndex = index;
    updateAnswer(oldAnswer, question, answer, {
      specificQuestion: fullName,
      summaryTableAction: "edit_row",
    });
  }, summaryTable);
}

export function summaryUpdateAnswer(
  updateAnswer,
  fullName,
  index,
  oldQuestionAnswer,
  question,
  newQuestionAnswer,
  extraActions,
  requestObjOverrides = {},
  extraAnswers = {}
) {
  //We're not using dispatch we just need getState :(
  return (dispatch, getState) => {
    const oldAnswer = getState().answers.serverAnswers[fullName];
    const answer = cloneDeep(oldAnswer);
    answer.answers[index] = {
      ...answer.answers[index],
      [question.name]: newQuestionAnswer,
      ...extraAnswers,
    };
    const summaryTableRowIdName = getSummaryTableRowIdName(oldAnswer, index);
    const summaryTableRowId = {
      [summaryTableRowIdName]: oldAnswer.answers[index][summaryTableRowIdName],
      ...extraAnswers,
    };
    updateAnswer(
      oldAnswer,
      question,
      answer,
      assign({}, extraActions, {
        specificQuestion: fullName,
        specificAnswer: newQuestionAnswer,
        summaryTableAction: "patch_question",
        summaryTableQuestionToPatch: question.name,
        summaryTableRowId,
        summaryTableActionIndex: index,
      })
    );
  };
}

export function closeRow(updateAnswer, oldServerAnswer, fullName, question) {
  const newServerAnswer = cloneDeep(oldServerAnswer);
  newServerAnswer.editingIndex = null;

  return updateAnswer(oldServerAnswer, question, newServerAnswer, {
    updateSummaryTable: true,
    specificQuestion: fullName,
    summaryTableAction: "edit_row",
  });
}

export function closeRowAndCreateNew(
  updateAnswer,
  oldAnswer,
  fullName,
  question
) {
  return of(closeRow(updateAnswer, oldAnswer, fullName, question)).subscribe(
    (_) => _ && addRow(oldAnswer, updateAnswer, fullName, question)
  );
}

export function restoreRow(
  updateAnswer,
  oldServerAnswer,
  fullName,
  question,
  index
) {
  const newServerAnswer = cloneDeep(oldServerAnswer);
  newServerAnswer.answers[index]._isDeleted = false;

  const summaryTableRowIdName = getSummaryTableRowIdName(
    oldServerAnswer,
    index
  );
  const summaryTableRowId = {
    [summaryTableRowIdName]:
      oldServerAnswer.answers[index][summaryTableRowIdName],
  };
  updateAnswer(oldServerAnswer, question, newServerAnswer, {
    updateSummaryTable: true,
    specificQuestion: fullName,
    summaryTableAction: "restore_row",
    summaryTableRowId,
  });
}

/* Saving summary tables is a tricky beast. There is very nuanced behavior that we must do for the
 * server request but we don't it to mess with required validations, or the other race conditions we've fixed
 * for summary tables. See https://github.com/CanopyTax/form-service/wiki/Source-Form-Summary-Tables
 * https://canopytax.atlassian.net/browse/TRFORMS-478 and https://canopytax.atlassian.net/browse/TRFORMS-480
 */
export function transformTableBeforePatch(answer, extraActions) {
  const transformedAnswer = cloneDeep(answer);

  if (extraActions.summaryTableAction === "add_row") {
    transformedAnswer.answers = {};
  } else if (extraActions.summaryTableAction === "remove_row") {
    transformedAnswer.answers = {
      ...extraActions.summaryTableRowId,
      _isDeleted: true,
    };
  } else if (extraActions.summaryTableAction === "edit_row") {
    transformedAnswer.answers = null;
  } else if (extraActions.summaryTableAction === "patch_question") {
    transformedAnswer.answers = {
      ...extraActions.summaryTableRowId,
      [extraActions.summaryTableQuestionToPatch]:
        answer.answers[extraActions.summaryTableActionIndex][
          extraActions.summaryTableQuestionToPatch
        ],
    };
  } else if (extraActions.summaryTableAction === "restore_row") {
    transformedAnswer.answers = {
      ...extraActions.summaryTableRowId,
      _isDeleted: false,
    };
  } else {
    throw new Error(
      `Unknown summary table action '${extraActions.summaryTableAction}'`
    );
  }

  return transformedAnswer;
}

function getSummaryTableRowIdName(answer, index) {
  for (let property in answer.answers[index]) {
    if (property.indexOf("._rowId") === property.length - "._rowId".length) {
      return property;
    }
  }
}

export function allDeleted(answers) {
  if (!answers) return true;

  let foundUndeleted = false;
  let counter = 0;

  while (!foundUndeleted && counter < answers.length) {
    const answer = answers[counter];

    if (answer && !answer._isDeleted) {
      foundUndeleted = true;
    }

    counter++;
  }

  return !foundUndeleted;
}
