import { generalToast } from "toast-service!sofe";
import { hasAccess } from "cp-client-auth!sofe";

export function shameSmes(msg) {
  const isSme = hasAccess(window.loggedInUser)("sme");

  if (isSme) {
    generalToast(msg, `I apologize for trying`, null, 60000);
  } else {
    throw new Error(msg);
  }
}
