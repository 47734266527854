import {
  postClientSurvey,
  deleteClientSurvey,
  putClientSurvey,
} from "../resolution-case-client-survey.resource.js";
import {
  removeUserFromClient,
  clientUsersChanged,
} from "src/clients/client.resource.js";
import { handleError } from "src/handle-error";
import { cloneDeep } from "lodash";
import * as types from "../client-survey.types.js";
import {
  patchClientSurveyAction,
  resetClientSurvey,
  putClientSurveyAction,
  getClientSurveyAction,
} from "../client-survey.actions.js";
import { getAllClients } from "src/resources/users.resource.js";
import { forkJoin } from "rxjs";

export function createFlowSurvey(
  clientId,
  resolutionCaseId,
  surveyMetaData,
  callback,
  notification
) {
  return (dispatch, getState) => {
    if (getState().clientSurvey.survey.creating) {
      return;
    }

    dispatch({ type: types.GOT_SURVEY, survey: { creating: true } });

    postClientSurvey(
      clientId,
      resolutionCaseId,
      surveyMetaData,
      "creating",
      notification
    ).subscribe((survey) => {
      dispatch({ type: types.GOT_SURVEY, survey });
      if (callback && typeof callback === "function") callback(survey);
    }, handleError);
  };
}

export function createNoAccessClientSurvey(
  clientId,
  resolutionCaseId,
  surveyMetaData
) {
  return (dispatch, getState) => {
    if (getState().clientSurvey.survey.creating) {
      return;
    }

    dispatch({ type: types.GOT_SURVEY, survey: { creating: true } });

    const clientHasAccess = false;
    postClientSurvey(
      clientId,
      resolutionCaseId,
      surveyMetaData,
      "unsent",
      null,
      clientHasAccess
    ).subscribe((survey) => {
      dispatch({ type: types.GOT_SURVEY, survey });
    }, handleError);
  };
}

export function changeStatus(params, status) {
  return (dispatch, getState) => {
    const { id } = getState().clientSurvey.survey;
    dispatch(patchClientSurveyAction(id, "status", status));
  };
}

export function changeClients(params, clients) {
  return (dispatch, getState) => {
    const { id } = getState().clientSurvey.survey;
    dispatch({
      type: types.UPDATE_SURVEY,
      load: { previousClientRecipients: clients },
    });
    dispatch(patchClientSurveyAction(id, "previousClientRecipients", clients));
  };
}

function untilClientIdHasChanged(clientId) {
  const regex = new RegExp(`clients?/${clientId}`);
  return !regex.test(window.location.hash);
}

export function cancelCreateSurvey() {
  return (dispatch, getState) => {
    const { clientSurvey, client, resolutionCase } = getState();
    const clientId = client.id;
    const resolutionCaseId = resolutionCase.id;

    if (!clientSurvey.survey.id) {
      throw new Error(
        `Cannot cancel creation of survey when there is no survey to delete`
      );
    }

    // reset the client survey before the delete completes so that nobody is using the client survey while it's being deleted.
    dispatch(resetClientSurvey());

    deleteClientSurvey(
      clientId,
      resolutionCaseId,
      clientSurvey.survey.id
    ).subscribe((okay) => {
      dispatch(getClientSurveyAction(clientId, resolutionCaseId));
      // resetClientSurvey also resets the possible clients, so we need to go get them again.
      clientUsersChanged(getState().reduxClient.id);

      setTimeout(() => {
        window.location.hash = `#/forms/clients/${clientId}/resolution-cases/${resolutionCaseId}/survey/create-survey/get-started`;
      });
    }, handleError);
  };
}

export function sendClientSurvey(router) {
  return (dispatch, getState) => {
    const survey = getState().clientSurvey.survey;

    const queryParameters =
      survey.previousClientRecipients &&
      survey.previousClientRecipients.length > 0
        ? { clientNotifiees: survey.previousClientRecipients }
        : undefined;

    dispatch(
      patchClientSurveyAction(
        survey.id,
        "status",
        "sent",
        () => {
          dispatch({
            type: types.SENT_CLIENT_SURVEY,
          });

          clientUsersChanged(getState().reduxClient.id);
        },
        queryParameters
      )
    );
  };
}

export function getAllClientsAction() {
  return (dispatch) => {
    getAllClients().subscribe(
      (users) => dispatch({ type: types.GOT_ALL_CLIENTS, load: users }),
      handleError
    );
  };
}

export function removeUserFromClientAction(clientId, resolutionCaseId, user) {
  return (dispatch, getState) => {
    const clientSurvey = cloneDeep(getState().clientSurvey.survey);
    dispatch({
      type: types.GOT_CLIENT_USERS,
      load: getState().clientSurvey.clientUsers.filter(
        (usah) => user.id !== usah.id
      ),
    });
    const userId = user.nonAssignedClient ? user.id : user.id || user;
    clientSurvey.previousClientRecipients =
      clientSurvey.previousClientRecipients.filter((pc) => pc !== userId);
    const clientSurveyObs = putClientSurvey(
      {
        clients: clientId,
        resolutionCases: resolutionCaseId,
        surveys: clientSurvey.id,
      },
      {
        ...clientSurvey,
        previousClientRecipients: clientSurvey.previousClientRecipients,
      }
    );
    //If a user has an id that means it's already an existing user
    //If it's just an email then it needs only be removed from the previousClientRecipients list
    if (user.id) {
      forkJoin(
        removeUserFromClient(clientId, user.id),
        clientSurveyObs
      ).subscribe(() => {
        dispatch({
          type: types.GOT_SURVEY,
          survey: clientSurvey,
        });
      }, handleError);
    } else {
      clientSurveyObs.subscribe(() => {
        dispatch({
          type: types.GOT_SURVEY,
          survey: clientSurvey,
        });
      }, handleError);
    }
  };
}

export function changePreviousClientRecipientEmail(oldEmail, newEmail) {
  return (dispatch, getState) => {
    const survey = cloneDeep(getState().clientSurvey.survey);
    survey.previousClientRecipients = survey.previousClientRecipients.map(
      (user) => (user === oldEmail ? newEmail : user)
    );
    dispatch({
      type: types.UPDATE_SURVEY,
      load: {
        previousClientRecipients: survey.previousClientRecipients,
      },
    });
    dispatch(putClientSurveyAction(survey));
  };
}
