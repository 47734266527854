import * as types from "./answer.types.js";
import { cloneDeep } from "lodash";

const defaultState = {
  serverAnswers: {},
};

export default function answerReducer(state = defaultState, action) {
  if (action.type === types.UPDATED_SUMMARY_TABLE) {
    // We need to update the summary table calculated colums
    // Conditionally we also update the editingIndex and the answers
    // only if they are currently undefined. If we always update them,
    // they might mess with the user.
    const answer = state[action.name];

    return {
      ...state,
    };
  }

  if (action.type === types.NEW_SERVER_ANSWERS) {
    return {
      ...state,
      serverAnswers: cloneDeep(action.answers),
    };
  }

  if (action.type === types.PATCH_SERVER_ANSWER) {
    return {
      ...state,
      serverAnswers: {
        ...state.serverAnswers,
        [action.question]: action.answer,
      },
    };
  }

  return state;
}

function mergeCalculatedValueAnswers(oldAnswers, newAnswers, section) {
  const answersToUpdate = section.blocks
    .reduce(toCalculatedValueNames, [])
    .map((question) => question.name);

  return answersToUpdate.reduce(
    toMergedAnswersObject.bind(null, newAnswers),
    oldAnswers
  );

  function toCalculatedValueNames(calculatedValueNames, block) {
    return [
      ...calculatedValueNames,
      ...(block.questions || []).filter(
        (question) => question.type === "CALCULATED_VALUE"
      ),
      ...(block.questions || []).reduce(toFixedTableCalculatedValueNames, []),
    ];
  }

  function toMergedAnswersObject(newAnswers, oldAnswers, answerKey) {
    return {
      ...oldAnswers,
      [answerKey]: newAnswers[answerKey],
    };
  }

  function toFixedTableCalculatedValueNames(calculatedValueNames, element) {
    if (element.type === "FIXED_TABLE") {
      return [
        ...calculatedValueNames,
        ...element.coordinates
          .filter(
            (coord) =>
              (coord.question && coord.question.type) === "CALCULATED_VALUE"
          )
          .map((coord) => ({ name: coord.question.name })),
      ];
    } else {
      return calculatedValueNames;
    }
  }
}
