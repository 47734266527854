import { fetchAsObservable } from "fetcher!sofe";
import { pluck, map, filter } from "rxjs/operators";
import { isNumber, get, isObject } from "lodash";

let summaryTableRequestCount = 0;
let summaryTableEditingIndex;

export function fetchSubsection(
  clientId,
  resolutionCaseId,
  sourceFormId,
  sectionId,
  subsectionIndex,
  versionId,
  revisionId,
  answerOrder
) {
  if (!clientId) throw new Error(`clientId is required`);
  if (!resolutionCaseId) throw new Error(`resolutionCaseId is required`);
  if (!sectionId) throw new Error(`sectionId is required`);
  if (!isNumber(subsectionIndex))
    throw new Error(`subsectionIndex is required`);
  if (!versionId) throw new Error(`versionId is required`);
  if (!revisionId) throw new Error(`revisionId is required`);

  const queryString = answerOrder ? `?answer-order=${answerOrder}` : "";

  return fetchAsObservable(
    `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${versionId}-${revisionId}/source-forms/${sourceFormId}/sections/${sectionId}/subsections/${subsectionIndex}/answers${queryString}`
  ).pipe(
    pluck("answers"),
    map((answers) => {
      // Add in index, so we know which one we have
      return {
        ...answers,
        subsection: {
          ...answers.subsection,
          index: Number(subsectionIndex),
        },
      };
    })
  );
}

export function updateSourceFormSubsectionAnswers({
  clientId,
  resolutionCaseId,
  versionId,
  revisionId,
  sourceFormId,
  sectionId,
  subsectionIndex,
  answers,
  answerSet,
  answerOrder,
}) {
  if (!clientId) throw new Error(`clientId is required`);
  if (!resolutionCaseId) throw new Error(`resolutionCaseId is required`);
  if (!sectionId) throw new Error(`sectionId is required`);
  if (!isNumber(subsectionIndex))
    throw new Error(`subsectionIndex is required`);
  if (!versionId) throw new Error(`versionId is required`);
  if (!revisionId) throw new Error(`revisionId is required`);
  if (!answers) throw new Error(`answers is required`);

  let queryString = "",
    paramPrefix = "?";

  if (answerSet) {
    queryString += `${paramPrefix}answer-set=${answerSet}`;
    paramPrefix = "&";
  }

  if (answerOrder) {
    queryString += `${paramPrefix}answer-order=${answerOrder}`;
    paramPrefix = "&";
  }

  const firstAnswersValue = answers[Object.keys(answers)[0]];
  const isSummaryTable =
    isObject(firstAnswersValue) && firstAnswersValue.hasOwnProperty("summary");
  const editingIndex = get(firstAnswersValue, "editingIndex");

  if (isSummaryTable) {
    if (summaryTableEditingIndex === editingIndex) {
      summaryTableRequestCount++;
    } else {
      summaryTableEditingIndex = editingIndex;
      summaryTableRequestCount = 1;
    }
  }

  return fetchAsObservable(
    `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${versionId}-${revisionId}/source-forms/${sourceFormId}/sections/${sectionId}/subsections/${subsectionIndex}/answers${queryString}`,
    {
      method: "POST",
      body: {
        answers,
      },
    }
  ).pipe(
    filter(() => {
      //this logic only relates to summary tables
      //without this logic, making subsequent summary table requests before a response comes in causes issues
      if (isSummaryTable && editingIndex === summaryTableEditingIndex) {
        summaryTableRequestCount--;
        if (summaryTableRequestCount === 0) {
          return true;
        } else {
          return false;
        }
      } else if (isSummaryTable) {
        return false;
      }
      return true;
    }),
    pluck("answers"),
    map((answers) => {
      // Add in index, so we know which one we have
      return {
        ...answers,
        subsection: {
          ...answers.subsection,
          index: Number(subsectionIndex),
        },
      };
    })
  );
}
