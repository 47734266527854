import React from "react";
import styles from "./report-utils.styles.css";
import Value from "./value.component.js";

export default function Row(props) {
  return (
    <div
      key={props.caption}
      style={{
        background: props.background ? props.background : "inherit",
        height: props.height || "4.0rem",
      }}
      className={`${styles.row}`}
    >
      <div
        style={{
          color: props.isHeader ? "var(--cps-color-primary-text)" : "inherit",
          fontWeight: props.isHeader || props.boldText ? "bold" : "inherit",
        }}
        className={`${styles.rowCaption} ${styles.twoValue}`}
      >
        {props.caption}
      </div>
      {props.columns.map((column, columnIndex) => (
        <Value
          key={columnIndex}
          boldText={props.boldText}
          isHeader={props.isHeader}
          notCurrency={props.notCurrency}
          smeMode={props.smeMode}
          value={props.report[column.reportKey]}
          {...column}
        />
      ))}
    </div>
  );
}
