import React from "react";
import canopyUrls from "canopy-urls!sofe";
import styles from "./tax-form-preview.style.css";
import OverrideFields from "./override-fields.component.js";
import FormSVG from "./form-svg.component.js";
import { CpLoader } from "canopy-styleguide!sofe";

export class ModularTaxFormPage extends React.Component {
  state = {
    loading: true,
    error: false,
  };
  render() {
    const {
      overrides,
      pageNumber,
      clientId,
      resolutionCaseId,
      noticeId,
      versionId,
      revisionId,
      formId,
      width,
      height,
      isSme,
      imgOnLoad,
      customSlug,
      includeEfile,
      excludeHiddenTaxForms,
    } = this.props;

    const screen = {
      backgroundColor: "var(--cps-color-bumble)",
      opacity: 0.3,
      width,
      height,
      left: 0,
      top: 0,
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    let baseUrl = `${canopyUrls.getAPIUrl()}/clients/${clientId}/resolution_cases/${resolutionCaseId}/tax-forms/${formId}/pages/${pageNumber}?version=${versionId}-${revisionId}`;

    if (noticeId) {
      baseUrl = `${canopyUrls.getAPIUrl()}/clients/${clientId}/notices/${noticeId}/tax-forms/${formId}/pages/${pageNumber}?version=${versionId}-${revisionId}`;
    }

    const includeEfileSegment = includeEfile
      ? `&includeEfile=${includeEfile}`
      : ``;

    const excludeHiddenTaxFormsSegment = `&excludeHiddenTaxForms=${!!excludeHiddenTaxForms}`;

    return (
      <div className={`cps-card ${styles.page}`}>
        {this.state.error && (
          <div className="cps-warning" style={screen}>
            An error has occurred loading this page.
          </div>
        )}
        {this.state.loading && (
          <div style={screen} data-testid="ModularTaxFormPage-loader">
            <CpLoader page={true} />
          </div>
        )}
        <FormSVG
          url={`${baseUrl}${includeEfileSegment}${excludeHiddenTaxFormsSegment}`}
          overrides={overrides}
          customSlug={customSlug}
          cancelLoading={() =>
            this.setState(
              {
                loading: false,
              },
              imgOnLoad && imgOnLoad()
            )
          }
          showError={() => this.setState({ loading: false, error: true })}
        />
        {!this.state.loading && isSme && (
          <OverrideFields
            width={width}
            height={height}
            fields={overrides}
            clientId={clientId}
            resolutionCaseId={resolutionCaseId}
            noticeId={noticeId}
            version={`${versionId}-${revisionId}`}
            form={formId}
            getOverrides={this.props.getOverrides}
          />
        )}
      </div>
    );
  }
}
