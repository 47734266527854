import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ClientPortalSurveys from "./client-portal-surveys.component.js";
import * as clientSurveyActions from "../client-survey.actions.js";

@connect((state) => ({
  surveyList: state.clientSurvey.surveysForClient,
}))
export default class ClientPortalSurveysWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.boundActions = {
      ...bindActionCreators(clientSurveyActions, props.dispatch),
    };
    this.boundActions.fetchSurveysForClient(props.match.params.clientId);
  }

  componentWillUnmount() {
    this.boundActions.resetSurveysForClient();
  }

  render() {
    const props = this.props;

    return <ClientPortalSurveys {...props} />;
  }
}
