import React from "react";
import { func, bool } from "prop-types";
import { primaryNavHeightObs } from "primary-navbar!sofe";
import { handleError } from "src/handle-error";
import Cancelable from "react-disposable-decorator";

import styles from "./drawer.styles.css";

const zIndexScreen = 98;
const secondaryNavHeight = 57;

@Cancelable
export default class DrawerContainer extends React.Component {
  state = {
    navHeight: 0,
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      primaryNavHeightObs.subscribe((navHeight) => {
        this.setState({ navHeight });
      }, handleError)
    );
  }

  render() {
    let inlineStyles = {
      zIndex: zIndexScreen + 2,
      top: this.state.navHeight + secondaryNavHeight,
    };

    if (this.props.styleOverride) {
      inlineStyles = {
        ...inlineStyles,
        ...this.props.styleOverride,
      };
    }

    if (this.props.pastepocalypseInQuickLook) {
      inlineStyles = {
        ...inlineStyles,
        top: 0,
        width: this.props.isOpen ? "100%" : 0,
      };
    }

    return (
      <div>
        <div
          className={`cps-modal__screen ${styles.screen} ${
            this.props.isOpen ? styles.screenOpened : styles.screenClosed
          }`}
          style={{ zIndex: zIndexScreen }}
          onClick={this.props.onClose}
        />
        <div
          className={`${styles.drawerContainer} cps-bg-white ${
            this.props.isOpen ? styles.opened : styles.closed
          }`}
          style={inlineStyles}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

DrawerContainer.propTypes = {
  onClose: func.isRequired,
  isOpen: bool,
};

DrawerContainer.defaultProps = {
  isOpen: false,
};
