import React from "react";
import { get, isNil, isEmpty } from "lodash";
import { Scoped, k, m } from "kremling";
import { CpButton } from "canopy-styleguide!sofe";
import { isRequiredButNotValid } from "../sections/section.reducer";
import { specialConfigAttributes } from "src/source-forms/answers/answer.helpers";

export default function CanopyInputDecorate() {
  return function (WrappedComponent) {
    return class CanopyInput extends React.Component {
      render() {
        const { answers, question } = this.props;
        const canBeOverriden =
          !isEmpty(answers?.serverAnswers) &&
          question.meta.calculatedDefaultNamespace;
        const wrappedComponent = (
          <WrappedComponent
            {...this.props}
            answer={
              !isNil(this.props.answer)
                ? this.props.answer
                : this.getDefaultCalculatedValue() || this.getDefaultValue()
            }
            validate={this.validate}
            validateAnswer={this.validateAnswer}
            ref={(el) => (this.el = el)}
          />
        );

        if (canBeOverriden) {
          const overriden = canBeOverriden
            ? answers.serverAnswers.hasOwnProperty(question.name)
            : false;

          return (
            <Scoped css={css}>
              <div
                className={m(
                  "calculated-default",
                  this.props.question.meta.calculatedDefaultNamespace
                ).m("overriden-calculated-default", overriden)}
              >
                {wrappedComponent}
                {overriden && (
                  <div className="override-controls">
                    <span>Override</span>
                    <span style={{ margin: "0 4px" }}>|</span>
                    <CpButton
                      className="undo-button"
                      btnType="unstyled"
                      onClick={this.undoOverride}
                    >
                      Undo
                    </CpButton>
                  </div>
                )}
              </div>
            </Scoped>
          );
        }

        return wrappedComponent;
      }

      componentDidMount() {
        window.addEventListener(
          "end-user-forms-ui:validate",
          this.validateAnswer
        );
      }

      getDefaultValue = () =>
        get(
          this.props,
          `question.meta.${specialConfigAttributes.DEFAULT_VALUE_NAMESPACE}`,
          ""
        );

      getDefaultCalculatedValue = () => {
        const calcDefaultValueNamespace = get(
          this.props,
          `question.meta.${specialConfigAttributes.CALCULATED_DEFAULT_VALUE_NAMESPACE}`,
          ""
        );

        const value = calcDefaultValueNamespace.length
          ? get(
              this.props,
              `answers.serverAnswers.${calcDefaultValueNamespace}`,
              ""
            ) ||
            get(
              this.props,
              `parentServerAnswers.${calcDefaultValueNamespace}`,
              ""
            )
          : "";

        return value;
      };

      componentWillUnmount() {
        window.removeEventListener(
          "end-user-forms-ui:validate",
          this.validateAnswer
        );
      }

      undoOverride = () => {
        this.props.updateAnswer(this.props.question, null);
      };

      validateAnswer = (e) => {
        if (e && e.detail && e.detail.reset) {
          this.props.setValid();
        } else {
          this.validate(this.el.state.localAnswer);
        }
      };

      validate = (answer) => {
        if (isRequiredButNotValid(this.props.question, answer)) {
          this.props.setInvalid();
        } else {
          this.props.setValid();
        }
      };
    };
  };
}

const css = k`
  
  .override-controls {
    display: flex;
    align-items: center;
    margin-top: .4rem
  }
  
  .calculated-default input {
    color: var(--cps-color-vibrant-ocean);
    font-style: italic;
    font-weight: 600;
  }
  
  .overriden-calculated-default input {
    color: var(--cps-color-mandy);
    font-style: normal;
  }
  
  .undo-button {
    color: var(--cps-color-vibrant-ocean);
    cursor: pointer;
  }
`;
