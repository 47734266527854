import React, { useState, lazy, Suspense } from "react";
import { CpIcon } from "canopy-styleguide!sofe";
import { useTaskAndFiles } from "../client-survey.helper";
import { addFilesToTask } from "src/resources/tasks.resource.js";
import styles from "./survey-file-practitioner-upload.styles.css";
import { useHasAccess } from "cp-client-auth!sofe";

const AddFileButton = lazy(async () => {
  const { AddFileButton } = await SystemJS.import("docs-ui");
  return AddFileButton();
});

export function SurveyFilePractitionerUpload(props) {
  const [task, files, setFiles] = useTaskAndFiles(props.resolutionCaseId);
  const [popupOpen, setPopupOpen] = useState(false);
  const hasChangeFilesAccess = useHasAccess("files_upload_move");

  const handleFiles = (newFiles) => {
    addFilesToTask(
      task.id,
      newFiles.map((file) => file.id)
    ).subscribe(() => {
      setFiles([...files, ...newFiles]);
    });
  };

  return (
    <>
      <div className="cps-padding-left-16 cps-padding-right-16">
        <hr />
      </div>
      {hasChangeFilesAccess && (
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              height: "40px",
              paddingLeft: "24px",
              paddingRight: "24px",
              width: "100%",
            }}
            onClick={() => {
              setPopupOpen(true);
            }}
          >
            <div>Upload Documents</div>
            <CpIcon name="add-circle-open" />
          </div>
          {popupOpen && (
            <Suspense fallback={null}>
              <AddFileButton
                submitButtonText="Done"
                pullLeft={true}
                filePickerProps={{
                  disableClients: !props.clientId,
                  clientId: props.clientId,
                }}
                clientId={props.clientId}
                attachFilesCallback={(files) => handleFiles(files)}
                close={() => setPopupOpen(false)}
              />
            </Suspense>
          )}
        </div>
      )}
      <div
        className="cps-margin-bottom-4"
        style={{ maxHeight: "404px", overflowY: "auto" }}
      >
        {files.map((file, index) => {
          return (
            <div
              className={`${styles.containerLink} ${
                props.fileId === file.id ? styles.selected : styles.unselected
              }`}
              key={index}
            >
              <a
                className={`${styles.fileLink}`}
                href={`${props.getSubLink(0)}/${file.id}`}
              >
                {file.name}
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
}
