import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export const getFormData = (
  clientId,
  resolutionCaseId,
  version,
  form,
  noticeId
) => {
  let url = `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${version}/tax-forms/${form}/svg-data`;

  if (noticeId) {
    url = `/clients/${clientId}/notices/${noticeId}/versions/${version}/tax-forms/${form}/svg-data`;
  }

  return fetchAsObservable(url).pipe(pluck("svg-data"));
};

export const patchOverrides = (
  clientId,
  resolutionCaseId,
  version,
  form,
  overrides,
  noticeId
) => {
  let url = `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${version}/tax-forms/${form}/overrides`;

  if (noticeId) {
    url = `/clients/${clientId}/notices/${noticeId}/versions/${version}/tax-forms/${form}/overrides`;
  }

  return fetchAsObservable(url, {
    method: "PATCH",
    body: JSON.stringify({
      overrides,
    }),
  }).pipe(pluck("overrides"));
};

export const deleteOverrides = (
  clientId,
  resolutionCaseId,
  version,
  form,
  field,
  noticeId
) => {
  let url = `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${version}/tax-forms/${form}/overrides/${field}`;

  if (noticeId) {
    url = `/clients/${clientId}/notices/${noticeId}/versions/${version}/tax-forms/${form}/overrides/${field}`;
  }

  return fetchAsObservable(url, { method: "DELETE" }).pipe(pluck("overrides"));
};
