import { toEndUserFormsObservable } from "../../source-forms/answers/answer.actions";
import { fetchAsObservable } from "fetcher!sofe";
import { mapKeys } from "lodash";

const storageName = "question-scratchpad";

toEndUserFormsObservable.subscribe(({ action, payload }) => {
  if (action === "DELETE_MULTI_FORM") {
    deleteScratchPad(payload);
  }
});

function getTaskAndFormIds() {
  const url = window.location.hash.split("/");
  const params = url.reduce((params, next, index) => {
    if (
      next === "tax-prep-tasks" ||
      next === "source-forms" ||
      next === "engagements" ||
      next === "resolution-cases" ||
      next === "sourceforms"
    ) {
      return [...params, url[index + 1]];
    }
    return params;
  }, []);
  return { taskId: params[0] || "", formId: params[1] || "" };
}

function buildId(taskId, formId, name, index) {
  return `end-user-forms/tasks/${taskId}/forms/${formId}/${storageName}/${name}${
    index ? `?index=${index}` : ""
  }`;
}

function getScratchPad(question, index, answers, updateAnswer) {
  const { taskId, formId } = getTaskAndFormIds();
  const res = window.localStorage.getItem(
    buildId(taskId, formId, question.name, index)
  );
  if (res) {
    const scratchPad = JSON.parse(res).map((item) =>
      mapKeys(item, (value, key) => (key === "text" ? "description" : "amount"))
    );
    deleteScratchPad(formId);
    updateAnswer({ ...question, name: `${question.name}_sp` }, scratchPad);
    return scratchPad;
  } else if (answers[`${question.name}_sp`]) {
    return answers[`${question.name}_sp`];
  }
  return [{ description: "", amount: "" }];
}

function deleteScratchPad(formId) {
  let [currentForm, currentIndex] = formId.split(":");
  currentIndex = parseInt(currentIndex);
  const keys = Object.keys(window.localStorage);
  // delete matching
  keys.forEach((key) => {
    if (key.includes(storageName) && key.includes(formId)) {
      window.localStorage.removeItem(key);
    }
  });
  // restructure
  keys.forEach((key) => {
    if (key.includes(storageName) && key.includes(currentForm)) {
      let index = parseInt(key.split(":")[1][0]);
      if (index > currentIndex) {
        const start = key.slice(0, key.indexOf("/forms/") + 7);
        const end = key.slice(key.indexOf(storageName) - 1);
        const newKey = `${start}${currentForm}:${index - 1}${end}`;
        const value = window.localStorage.getItem(key);
        window.localStorage.removeItem(key);
        window.localStorage.setItem(newKey, value);
      }
    }
  });
}

export { getScratchPad, deleteScratchPad, getTaskAndFormIds };
