import React from "react";
import CanopySelect from "cpr-select";
import "cpr-select/src/select.css";
import { get } from "lodash";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import styles from "./inputs.style.css";
import { toggle, maybe } from "kremling";

@CanopyInput()
export default class SelectList extends React.Component {
  constructor(props) {
    super();
    this.state = {
      localAnswer: props.answer,
    };
    this.inputRef = React.createRef();
  }

  focusInput = () => {
    const input = get(this, "inputRef.current.el");
    if (input) {
      if (input.querySelector("input")) input.querySelector("input").focus();
      else console.warn("no input to focus on!");
    } else console.warn("no ref to focus on!");
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer) {
      this.setState({ localAnswer: this.props.answer });
      this.props.validate(this.props.answer);
    }
  }

  render() {
    const { question, updateAnswer, inTable } = this.props;
    const { localAnswer } = this.state;

    if (!question.meta.options || !question.meta.options.length) {
      return (
        <div>
          <b className="cps-warning">
            The question {question.name} has no options!
          </b>
        </div>
      );
    }

    const options = [{ key: "", value: null }].concat(
      question.meta.options.map((option) => {
        return {
          key: option.value,
          value: option.label === "" ? null : option.label,
        };
      })
    );

    return (
      <CanopySelect
        ref={this.inputRef}
        options={options}
        onBlur={this.props.validateAnswer}
        onChange={(value) => {
          this.setState({ localAnswer: value });
          updateAnswer(question, value);
          this.props.validate(value);
          this.focusInput();
        }}
        selected={localAnswer}
        outerClass={toggle(
          fixedTableStyles.fixedTableSelectOuter,
          styles.selectOuter,
          inTable
        )}
        inputClass={question.name}
        selectClass={maybe(fixedTableStyles.fixedTableSelect, inTable)}
      />
    );
  }
}
