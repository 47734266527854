export const issues = [
  {
    reportKey: "jigglypuff",
    title: "Additional CNC Factors",
    description:
      "Additional CNC eligibility factors may be considered by the IRS, such as equity in assets and whether seizing such assets would create a hardship (See IRM 5.16.1)",
    priority: "info",
    tabs: ["cnc"],
  },
  {
    reportKey: "ganondorf",
    title: "Open Bankruptcy",
    description: "The company is in open bankruptcy",
    priority: "danger",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "captainfalcon",
    title: "Federal Tax Deposits",
    description: "The company has not made all required federal tax deposits.",
    priority: "danger",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "lucina",
    title: "Estimated Tax Payments",
    description:
      "The company has not made all required estimated tax payments.",
    priority: "danger",
    tabs: ["oic", "plan", "cnc"],
  },
  {
    reportKey: "megaman",
    title: "Offer amount exceeds balance due",
    description: "The minimum offer amount exceeds the amount of tax debt.",
    priority: "danger",
    tabs: ["oic"],
  },
  {
    reportKey: "donkeykong",
    title: "Offer Amount",
    description: "Offer amounts must be more than zero ($0).",
    priority: "danger",
    tabs: ["oic"],
  },
  {
    reportKey: "pikachu",
    title: "Offer Amount",
    description: "Offer amount displayed reflects practitioner override.",
    priority: "danger",
    tabs: ["oic"],
  },
];
