import React from "react";
import { CpButton, CpInput } from "canopy-styleguide!sofe";
import styles from "./report-utils.styles.css";
import { toMaskToValue } from "src/source-forms/inputs/currency.component.js";

const questionName = "FederalTaxesDue";

export default class FederalTaxesDue extends React.Component {
  constructor(props) {
    super();
    let mask = "";
    let value = "";
    if (props.answer) {
      let obj = toMaskToValue(String(props.answer));
      mask = obj.mask;
      value = obj.value;
    }
    this.state = {
      value,
      mask,
      showInput: false,
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.answer !== this.props.answer &&
      !this.props.answer &&
      this.props.answer !== 0
    ) {
      this.setState({
        mask: "",
        value: "",
      });
    } else if (
      prevProps.answer !== this.props.answer &&
      this.props.answer !== this.state.value
    ) {
      const { mask, value } = toMaskToValue(String(this.props.answer));
      this.setState({
        mask,
        value,
      });
    }
  }

  handleKeyPress = (e) => {
    const enter = 13;
    if (e.keyCode === enter) {
      this.inputRef.current.blur();
    }
  };

  handleChange = (value) => {
    this.setState(
      {
        value,
      },
      () => {
        this.props.updateReduxAnswer(questionName, value);
      }
    );
  };

  handleBlur = () => {
    if (this.props.answer || this.props.answer === 0) {
      const { value, mask } = toMaskToValue(String(this.props.answer));
      this.props.updateReduxAnswer(questionName, value);
      this.props.updateAnswer(questionName, value);
      this.setState({
        mask,
      });
    } else {
      this.props.updateAnswer(questionName, null);
    }
    this.setState({
      showInput: false,
    });
  };

  render() {
    return (
      <div className={`${styles.bigPrimaryTextBox}`}>
        <div className="cp-mt-8">Federal Taxes Due</div>
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="cp-mt-8"
        >
          {this.state.showInput ? (
            <CpInput
              value={String(this.state.value)}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              placeholder="$0.00"
              onKeyUp={this.handleKeyPress}
              ref={this.inputRef}
            />
          ) : (
            <div
              className="cp-color-app-primary"
              style={{ fontSize: "2rem", fontWeight: 600 }}
            >
              {this.state.mask}
            </div>
          )}
          {!this.state.showInput && (
            <CpButton
              icon="crud-pencil"
              aria-label="Edit Federal Taxes Due"
              onClick={() => this.setState({ showInput: true })}
            />
          )}
        </div>
      </div>
    );
  }
}
