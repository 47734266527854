import React from "react";
import { Scoped } from "kremling";

export default function Triangle({ edge }) {
  const css = `
    & .triangle {
      margin-top: ${edge ? 10 : 5}px;
      display: block;
      border-bottom: 16px solid transparent;
      border-left: 16px solid var(--cps-color-cool-gray);
      overflow: hidden;
      position: absolute;
      transform: rotate(45deg);
    }
  `;
  return (
    <Scoped css={css}>
      <div className="triangle" />
    </Scoped>
  );
}
