import * as types from "./summary-table.types.js";

const defaultState = {
  removeDialogOpened: false,
  closeAction: null,
};

export default function summaryTableReducer(state = defaultState, action) {
  if (action.type === types.REMOVE_TABLE_ROW_DIALOG_OPENED) {
    return {
      ...state,
      removeDialogOpened: action.table,
      closedDialog: false,
      closeAction: action.closeAction,
    };
  }

  if (action.type === types.REMOVE_TABLE_ROW_DIALOG_CLOSED) {
    return {
      ...state,
      removeDialogOpened: false,
      //This is to help shouldComponentUpdate in the block component be more effective
      closedDialog: action.table,
    };
  }

  return state;
}
