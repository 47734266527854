import React from "react";
import styles from "./section.style.css";
import { findIndex, cloneDeep } from "lodash";
import { handleValidate } from "../questions/required.helpers.js";
import { CpButton } from "canopy-styleguide!sofe";
import { withRouter } from "react-router-dom";

export function SectionFooter({
  clientId,
  resolutionCaseId,
  sourceFormId,
  taxFormId,
  activeSection,
  sections,
  history,
}) {
  if (!activeSection.id) return null;

  let activeIndex = findIndex(
    sections,
    (section) => section.id === activeSection.id
  );

  if (activeIndex === -1) return null;

  let first = sections.length
    ? sections[0].id === activeSection.id ||
      allPreviousSectionsInvisible(activeSection.id, sections)
    : true;
  let last = sections.length
    ? sections[sections.length - 1].id === activeSection.id ||
      allSubsequentSectionsInvisible(activeSection.id, sections)
    : true;

  const previousHref = () =>
    `/forms/clients/${clientId}/resolution-cases/${resolutionCaseId}/sourceforms/${sourceFormId}/sections/${getNextSection(
      activeIndex,
      -1,
      sections
    )}`;
  const nextHref = () =>
    `/forms/clients/${clientId}/resolution-cases/${resolutionCaseId}/sourceforms/${sourceFormId}/sections/${getNextSection(
      activeIndex,
      1,
      sections
    )}`;
  const taxFormUrl = () =>
    `/forms/clients/${clientId}/resolution-cases/${resolutionCaseId}/sourceforms/${sourceFormId}/taxforms/${taxFormId}?sectionId=${activeSection.id}`;

  return (
    <div
      className={`${styles.sectionFooter}`}
      style={{ display: "flex", justifyContent: "center" }}
    >
      {!first && (
        <CpButton
          className={`${styles.prevFooterButton}`}
          onClick={(e) => handleValidate(e, () => history.push(previousHref()))}
        >
          Previous section
        </CpButton>
      )}
      {!last && (
        <CpButton
          onClick={(e) => handleValidate(e, () => history.push(nextHref()))}
        >
          Next section
        </CpButton>
      )}
      {last && taxFormId && (
        <CpButton onClick={() => history.push(taxFormUrl())}>
          View tax form
        </CpButton>
      )}
    </div>
  );
}

export default withRouter(SectionFooter);

function allPreviousSectionsInvisible(curSectionId, sections) {
  return (
    sections.find((section) => section.id === curSectionId || section.visible)
      .id === curSectionId
  );
}

function allSubsequentSectionsInvisible(curSectionId, sections) {
  return (
    cloneDeep(sections)
      .reverse()
      .find((section) => section.id === curSectionId || section.visible).id ===
    curSectionId
  );
}

function getNextSection(index, direction, sections) {
  if (sections[index + direction].visible)
    return sections[index + direction].id;
  return getNextSection(index + direction, direction, sections);
}
