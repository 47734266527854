import React from "react";
import styles from "./tax-form-preview.style.css";

export default class FormSVG extends React.Component {
  state = {
    slug: "",
  };
  componentDidMount() {
    this.resetSlug();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.overrides !== this.props.overrides) {
      this.resetSlug();
    }
  }
  render() {
    return (
      <img
        className={styles.image}
        data-testid="form-svg-img"
        src={`${this.props.url}&slug=${
          this.props.customSlug || this.state.slug
        }`}
        onLoad={this.props.cancelLoading}
        onError={this.props.showError}
      />
    );
  }

  resetSlug() {
    this.setState({
      slug: `img${Math.floor(Math.random() * 1000)}`,
    });
  }
}
