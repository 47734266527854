import React from "react";
import { Scoped, k, always } from "kremling";
import { isUndefined } from "lodash";
import { connect } from "react-redux";
import { CpIcon } from "canopy-styleguide!sofe";
import * as sectionActions from "../source-forms/sections/section.actions.js";
import { bindActionCreators } from "redux";

@connect((state) => ({
  section: state.section.activeSection,
  answers: state.answers,
  sourceFormLayout: state.sourceFormLayout,
}))
export default class OverlayLeftNav extends React.Component {
  constructor(props) {
    super(props);

    this.actions = {
      ...bindActionCreators(sectionActions, this.props.dispatch),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      isUndefined(prevProps.sourceFormLayout.formId) &&
      this.props.sourceFormLayout.formId
    ) {
      const activeFormEl = document.getElementById(
        this.props.sourceFormLayout.formId
      );
      activeFormEl.scrollIntoView(false);
    }
  }

  updateSection = (section) => {
    this.props.dispatch(sectionActions.updateSourceFormSection(section));
  };

  updateFormSelection = (form) => {
    if (this.props.selectedForm.id !== form.id) {
      this.props.updateSelectedForm(form);
    }
  };

  render() {
    return (
      <Scoped css={css}>
        <div className="overlay-left-nav">
          {this.props.formList.map((form) => (
            <div key={form.id} id={form.id}>
              <div
                className={always("oln-form-name").m(
                  "form-active",
                  form.id === this.props.sourceFormLayout.formId &&
                    !this.props.showTaxForm
                )}
                onClick={() => this.updateFormSelection(form)}
              >
                <CpIcon
                  name="file-pdf"
                  style={{
                    marginRight: "8px",
                    marginTop: "-4px",
                  }}
                />
                {form.name}
              </div>
              {form.id === this.props.sourceFormLayout.formId &&
                this.props.sourceFormLayout.sections.map((section, index) => {
                  return (
                    <div
                      key={section.id}
                      className={always("oln-form-section")
                        .m(
                          "form-section-active",
                          section.id === this.props.section.id &&
                            !this.props.showTaxForm
                        )
                        .m(
                          "no-hover-state",
                          this.props.sourceFormLayout.savingAnswer
                        )}
                      onClick={() => {
                        if (
                          !this.props.sourceFormLayout.savingAnswer &&
                          section.id !== this.props.section.id
                        ) {
                          this.updateSection(section);
                        }
                      }}
                    >
                      <CpIcon
                        name="file-statement"
                        style={{
                          marginRight: "8px",
                          marginTop: "-4px",
                        }}
                      />
                      {section.name}
                    </div>
                  );
                })}
            </div>
          ))}
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .overlay-left-nav {
    width: 320px;
    max-height: 800px;
    min-height: 35rem;
    height: fit-content;
    overflow: auto;
    background: var(--cps-color-card-background);
    box-shadow: 0px 10px 30px -24px rgba(75, 78, 83, 0.4);
    border: 1px solid rgb(223, 223, 223);
    border-radius: 1px;
    margin-left: 24px;
    margin-top: 24px;
  }

  .oln-form-name {
    padding: 16px;
    border-bottom: 1px solid var(--cps-color-border);
  }

  .oln-form-name:hover {
    background: var(--cps-color-menu-hover-bg);
    cursor: pointer;
  }

  .oln-form-section {
    padding: 16px 16px 16px 32px;
    border-bottom: 1px solid var(--cps-color-border);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .oln-form-section:hover {
    background: var(--cps-color-menu-hover-bg);
    cursor: pointer;
  }

  .form-active {
    background: var(--cps-color-background);
    font-weight: 600;
  }

  .form-section-active {
    font-weight: 600;
  }

  .no-hover-state:hover {
    cursor: default;
    background: var(--cps-color-card-background);
  }
`;
