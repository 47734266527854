import React from "react";
import styles from "./inputs.style.css";

export default class CalculatedValue extends React.Component {
  render() {
    const { inTable, answer } = this.props;

    const newAnswer =
      answer || (inTable ? `\u2014` : `This hasn't been calculated yet...`);

    return (
      <div className={`${styles.calculatedValueWrapper} calculated-value`}>
        <div
          className={`${!answer ? "cps-gray-10" : ""} ${
            inTable ? styles.calculatedValue : ""
          }`}
        >
          {newAnswer}
        </div>
      </div>
    );
  }
}
