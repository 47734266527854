import { questionMetaTypeMap } from "../questions/question.component.js";
import { includes, get } from "lodash";

export function getMaxHeight(coords = []) {
  return (coords || []).reduce(toLargestHeight, 0) + "px";

  function toLargestHeight(height, coord) {
    if (coord.y + coord.height > height) {
      return coord.y + coord.height;
    }

    return height;
  }
}

export function getWidth(width, firstRow, i, length, padding) {
  if (i === 0 || i === length - 1) {
    //the -2 is because of the 1px borders that surround the table and container
    if (firstRow) {
      return width - padding - 2;
    } else {
      return width - 2;
    }
  }
  return width;
}

const inputBoxTypes = Object.keys(questionMetaTypeMap).filter(
  (name) => name !== "MULTIPLE_CHOICE" && name !== "CHECKBOX"
);

export function isInputBox(question) {
  return includes(
    inputBoxTypes,
    get(question, "question.meta.type") || get(question, "question.type")
  );
}
