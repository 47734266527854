import * as actionTypes from "./redux-client.types.js";

export function setReduxClient(reduxClient) {
  return {
    type: actionTypes.SET_REDUX_CLIENT,
    reduxClient,
  };
}

export function resetReduxClient() {
  return {
    type: actionTypes.RESET_REDUX_CLIENT,
  };
}
