import { isEqual, get } from "lodash";

export function propsAreDifferent(nextProps, currProps, list) {
  return list.some((prop) => {
    return !isEqual(get(nextProps, prop), get(currProps, prop));
  });
}

export function questionSpecialCases(
  nextQuestion,
  currQuestion,
  newReduxAnswers,
  oldReduxAnswers
) {
  //Counties could have state references that reach outside of their block
  if (nextQuestion.meta.stateReference)
    return (
      newReduxAnswers[nextQuestion.meta.stateReference] !==
      oldReduxAnswers[nextQuestion.meta.stateReference]
    );
}
