import * as types from "./section.types.js";
import { isNumber, isNil, isEmpty, partial } from "lodash";
import { isRequired } from "../questions/required.helpers.js";

const defaultState = {
  activeSection: {
    isDefaultSection: true,
  },
  scrollJump: {
    now: false,
    summaryTableId: null,
  },
};

export default function sectionReducer(state = defaultState, action) {
  if (action.type === types.SOURCE_FORM_SECTION_UPDATED) {
    if (action.section) {
      return {
        ...state,
        activeSection: action.section,
      };
    } else {
      return defaultState;
    }
  }

  if (action.type === types.SCROLL_JUMP_FINISHED) {
    return {
      ...state,
      scrollJump: {
        now: false,
        summaryTableId: null,
      },
    };
  }

  return state;
}

function blockContainsQuestion(questionName, block) {
  return !!(block.questions || []).find(
    partial(questionContainsQuestion, questionName)
  );
}

function questionContainsQuestion(questionName, question) {
  if (question.type === "SUMMARY_TABLE") {
    return !!question.blocks.find(partial(blockContainsQuestion, questionName));
  }

  if (question.type === "FIXED_TABLE") {
    // Need to implement this
    return false;
  }

  if (question.type === "INFORMATIONAL_TEXT") {
    return false;
  }

  if (question.type === "QUESTION") {
    return question.name === questionName;
  }

  if (question.type === "CALCULATED_VALUE") {
    return false;
  }

  throw new Error(`Unknown question type '${question.type}'`);
}

const questionMetaTypeToValidator = {
  SINGLE_LINE_TEXT: fancyIsAnswered,
  SSN: verifyDigits(9, 9),
  SELECT_LIST: fancyIsAnswered,
  MULTIPLE_CHOICE: fancyIsAnswered,
  CHECKBOX: fancyIsAnswered,
  STATE: fancyIsAnswered,
  COUNTRY: fancyIsAnswered,
  DATE: fancyIsAnswered,
  NUMBER: fancyIsAnswered,
  PERCENTAGE: fancyIsAnswered,
  EIN: verifyDigits(9, 9),
  CAF: verifyDigits(9, 9),
  AMOUNT: fancyIsAnswered,
  PHONE_NUMBER: verifyDigits(10),
  ZIP: verifyDigits(5),
  PARAGRAPH_TEXT: fancyIsAnswered,
  COUNTY: fancyIsAnswered,
  PILLBOX: fancyIsAnswered,
  PRACTITIONER: fancyIsAnswered,
  URI: fancyIsAnswered,
  DECIMAL: fancyIsAnswered,
  SIMPLE_TABLE: fancyIsAnswered,
  TLOOKUP_TABLE: fancyIsAnswered,
  BOX_CODE: fancyIsAnswered,
  OTHER_COUNTRIES: fancyIsAnswered,
  FILE_UPLOAD: fancyIsAnswered,
};

/* We don't need to verify that the masking is working here, just if it has
 * been answered correctly.
 */
function isRequiredButNotValid(question, answer) {
  if (question.type === "QUESTION") {
    const validatorFunc = questionMetaTypeToValidator[question.meta.type];
    if (!validatorFunc) {
      throw new Error(
        `We have not yet implemented a required validator function for questions of type ${question.meta.type}`
      );
    }

    const value =
      answer && answer.summary
        ? answer.answers[answer.editingIndex][question.name]
        : answer;

    return isRequired(question) && !!question.visible
      ? !validatorFunc(value)
      : false;
  } else {
    return false;
  }
}

export { isRequiredButNotValid };

function fancyIsAnswered(value) {
  return !isNil(value) && (isNumber(value) || !isEmpty(value));
}

/* This actually only works with strings.
 * `min` and `max` are integers and are inclusive.
 * `max` is optional and if left off it means there is no upper bound.
 */
function verifyDigits(min = 0, max) {
  return function (value) {
    if (value === null || value === undefined) return false;

    let valid = value.length >= min;

    if (max) {
      valid = valid && value.length <= max;
    }

    return valid;
  };
}
