// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./section.style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-source-forms-sections-subsection-footer-style__root {
}

.src-source-forms-sections-subsection-footer-style__link {
}
`, "",{"version":3,"sources":["webpack://./src/source-forms/sections/subsection-footer.style.css"],"names":[],"mappings":"AAAA;AAEA;;AAEA;AAEA","sourcesContent":[".root {\n  composes: sectionFooter from \"./section.style.css\";\n}\n\n.link {\n  composes: footerButton from \"./section.style.css\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `src-source-forms-sections-subsection-footer-style__root ${___CSS_LOADER_ICSS_IMPORT_0___.locals["sectionFooter"]}`,
	"link": `src-source-forms-sections-subsection-footer-style__link ${___CSS_LOADER_ICSS_IMPORT_0___.locals["footerButton"]}`
};
export default ___CSS_LOADER_EXPORT___;
