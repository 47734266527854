import { get, isNil } from "lodash";

// See https://canopytax.atlassian.net/browse/TRFORMS-512
export function requestIsForCurrentRoute(requestParams) {
  const href = window.location.href;
  if (
    href.includes(`/welcome`) ||
    href.includes(`/submit`) ||
    href.includes(`/upload`) ||
    href.includes(`/file`)
  ) {
    // https://canopytax.atlassian.net/browse/TRFORMS-557
    return true;
  }

  if (
    !isNil(requestParams.subsections) &&
    window.location.href.indexOf(`/subsections/${requestParams.subsections}`) <
      0
  ) {
    return false;
  }

  if (
    !isNil(requestParams.sections) &&
    window.location.href.indexOf(`/sections/${requestParams.sections}`) < 0
  ) {
    return false;
  }

  return true;
}

export const existsOrZero = (val) => val || val === 0;

export const specialConfigAttributes = {
  CALCULATED_DEFAULT_VALUE_NAMESPACE: "calculatedDefaultNamespace",
  DEFAULT_VALUE_NAMESPACE: "defaultValue",
  INITIAL_VALUE_NAMESPACE: "initialValueNamespace",
  INITIAL_TIMESTAMP_NAMESPACE: "initialTimestampNamespace",
};

export function captureSpecialValue(attribute, question, reduxState) {
  if (!attribute || !question || !reduxState) return {};

  let configuredNamespace = getConfiguredNamespaceFromQuestionMeta(
    question,
    attribute
  );
  let serverAnswers = get(reduxState, "answers.serverAnswers", {});
  let questionValue = getAnswerFromServerAnswers(serverAnswers, question.name);
  let configuredQuestionValue = get(
    reduxState,
    `answers.serverAnswers.${configuredNamespace}`,
    ""
  );

  // is a question configured to capture THIS question's value?
  // is there a non-empty answer to THIS question?
  // does the configured question have a non-empty value?
  // if ALL of the above are true then capture the initial value and/or timestamp

  return configuredNamespace.length &&
    questionValue.length &&
    !configuredQuestionValue.length
    ? {
        [configuredNamespace]:
          attribute === specialConfigAttributes.INITIAL_TIMESTAMP_NAMESPACE
            ? Math.round(new Date().getTime() / 1000)
            : questionValue,
      }
    : {};
}

export function getAnswerFromServerAnswers(serverAnswers, questionName) {
  return serverAnswers[questionName] || "";
}

export function getConfiguredNamespaceFromQuestionMeta(question, attribute) {
  return get(question, `meta.${attribute}`, "");
}
