import { fetchAsObservable } from "fetcher!sofe";
import { pluck, map, filter } from "rxjs/operators";
import { get, isObject } from "lodash";

let summaryTableRequestCount = 0;
let summaryTableEditingIndex;

export function updateSourceFormSectionAnswers({
  clientId,
  resolutionCaseId,
  versionId,
  revisionId,
  sourceFormId,
  sectionId,
  answers,
  answerSet,
  answerOrder,
  groupId,
  noticeId,
}) {
  let queryString = "",
    queryPrefix = "?";

  if (answerSet) {
    queryString += `${queryPrefix}answer-set=${answerSet}`;
    queryPrefix = "&";
  }

  if (answerOrder) {
    queryString += `${queryPrefix}answer-order=${answerOrder}`;
    queryPrefix = "&";
  }

  if (groupId) {
    queryString += `${queryPrefix}top-group=${groupId}`;
    queryPrefix = "&";
  }

  const middleUrl = `source-forms/${sourceFormId}`;

  let url = `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${versionId}-${revisionId}/${middleUrl}/sections/${sectionId}/form-answers${queryString}`;

  const body = { "form-answers": answers };

  const firstAnswersValue = answers[Object.keys(answers)[0]];
  const isSummaryTable =
    isObject(firstAnswersValue) && firstAnswersValue.hasOwnProperty("summary");
  const editingIndex = get(firstAnswersValue, "editingIndex");

  if (isSummaryTable) {
    if (summaryTableEditingIndex === editingIndex) {
      summaryTableRequestCount++;
    } else {
      summaryTableEditingIndex = editingIndex;
      summaryTableRequestCount = 1;
    }
  }

  if (noticeId) {
    url = `/clients/${clientId}/notices/${noticeId}/versions/${versionId}-${revisionId}/${middleUrl}/sections/${sectionId}/form-answers${queryString}`;
  }

  return fetchAsObservable(url, { method: "POST", body }).pipe(
    filter(() => {
      //this logic only relates to summary tables
      //without this logic, making subsequent summary table requests before a response comes in causes issues
      if (isSummaryTable && editingIndex === summaryTableEditingIndex) {
        summaryTableRequestCount--;
        if (summaryTableRequestCount === 0) {
          return true;
        } else {
          return false;
        }
      } else if (isSummaryTable) {
        return false;
      }
      return true;
    }),
    pluck("form-answers"),
    map((answers) => {
      answers.sourceFormId = sourceFormId;
      return answers;
    })
  );
}
