import React from "react";
import FederalTaxesDue from "./report-utils/federal-taxes-due.component.js";
import BigPrimary from "./report-utils/big-primary-text.component.js";
import styles from "./reports.styles.css";
import AlertList from "./alert-list.component.js";
import Row from "./report-utils/row.component.js";

const valueWidth = { width: "25%" };
const rows = [
  {
    caption: "Description",
    columns: [
      { ...valueWidth, value: "Lump Sum (x12)" },
      { ...valueWidth, value: "Periodic Payments (x24)" },
    ],
    isHeader: true,
  },
  {
    caption: "Future Remaining Income",
    columns: [
      { ...valueWidth, reportKey: "capybara" },
      { ...valueWidth, reportKey: "dingo" },
    ],
  },
  {
    caption: "Equity in Assets",
    columns: [
      { ...valueWidth, reportKey: "echidna" },
      { ...valueWidth, reportKey: "fossa" },
    ],
    prefix: "+",
  },
  {
    background: "rgba(74, 144, 226, 0.1)",
    caption: "Offer Amount",
    columns: [
      { ...valueWidth, reportKey: "gibbon" },
      { ...valueWidth, reportKey: "hartebeest" },
    ],
  },
  {
    caption: "Less Initial Payment",
    columns: [
      { ...valueWidth, reportKey: "impala" },
      { ...valueWidth, reportKey: "jacana" },
    ],
    prefix: "-",
  },
  {
    caption: "Remaining Offer Balance",
    columns: [
      { ...valueWidth, reportKey: "kinkajou" },
      { ...valueWidth, reportKey: "lionfish" },
    ],
  },
];

export default function OfferReport(props) {
  return (
    <div>
      <div className={`${styles.nonRoundedTop} cps-card`}>
        <div className={`cps-card__body ${styles.headerBox}`}>
          <FederalTaxesDue
            updateAnswer={props.updateAnswer}
            updateReduxAnswer={props.updateReduxAnswer}
            answer={props.answers.FederalTaxesDue}
          />
          <BigPrimary
            smeMode={props.smeMode}
            reportKey={"addax"}
            value={props.report["addax"]}
            caption={"Lowest Offer Amount"}
          />
          <BigPrimary
            smeMode={props.smeMode}
            reportKey={"babirusa"}
            value={props.report["babirusa"]}
            caption={"Potential Savings"}
          />
        </div>
        <div
          className="cps-well cps-padding-16"
          style={{ margin: "-8px 24px 24px 24px" }}
        >
          <strong>Please note:</strong>
          <p>
            Canopy’s analytics system does not take the Collection Statute
            Expiration Date (CSED) into consideration in the outcome information
            below. However, we recommend reviewing the CSED (
            <a href="#/transcripts/list">via transcripts</a>) before taking any
            actions, specifically to determine if the monthly available payment
            will full pay the amount prior to expiration.
          </p>
          <p className="cps-margin-bottom-0">
            Per IRM 5.8.1.2.3(3) - Unless special circumstances exist, offers
            will not be accepted if it is believed that the liability can be
            paid in full as a lump sum, or by installment payments extending
            through the remaining statutory period for collection, or other
            means of collection.
          </p>
        </div>
      </div>
      <div className={`cps-card cps-margin-top-16`}>
        <div className={`${styles.alertBox}`}>
          <AlertList
            report={props.report}
            smeMode={props.smeMode}
            client={props.client}
            isIndividual={true}
            activeReport={"oic"}
          />
        </div>
      </div>
      <div className={`cps-card-table cps-card cps-margin-top-16`}>
        <div className={`cps-subheader cps-card__header`}>Offer Breakdown</div>
        {rows.map((row, rowIndex) => (
          <Row
            key={rowIndex}
            report={props.report}
            smeMode={props.smeMode}
            {...row}
          />
        ))}
      </div>
    </div>
  );
}
