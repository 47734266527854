import React, { Suspense } from "react";
import { connect } from "react-redux";
import { UserTenantProps } from "cp-client-auth!sofe";
import { CpLoader } from "canopy-styleguide!sofe";
import { checkFetchSubsection } from "../source-form/cssf-subsection.helpers.js";
import SurveyFileClientUpload from "./survey-file-client-upload.component.js";
const InlineDocumentPreview = React.lazy(() =>
  SystemJS.import("docs-ui!sofe").then((docsUI) => ({
    default: docsUI.InlineDocumentPreview,
  }))
);

const sectionIdToFetch = "0";
const subsectionIndexToFetch = 0;

@UserTenantProps()
class SurveyFileUpload extends React.Component {
  componentDidUpdate(prevProps) {
    checkFetchSubsection(
      prevProps,
      this.props,
      false,
      sectionIdToFetch,
      subsectionIndexToFetch
    );
  }
  render() {
    return this.props.loggedInUser.role === "Client" ? (
      <SurveyFileClientUpload {...this.props} />
    ) : (
      <Suspense fallback={<CpLoader />}>
        <div style={{ marginLeft: "21px" }}>
          <InlineDocumentPreview
            clientId={this.props.clientId}
            documentId={this.props.match.params.fileId}
            handleClosePreview={() => {}}
          />
        </div>
      </Suspense>
    );
  }
}

export default connect((state) => ({
  clientSurvey: state.clientSurvey,
  sourceFormLayout: state.sourceFormLayout,
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
}))(SurveyFileUpload);
