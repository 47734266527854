import React from "react";
import PropTypes from "prop-types";

import {
  CpTooltip,
  CpDropdown,
  CpButton,
  CpIcon,
} from "canopy-styleguide!sofe";
import "./status.styles.css";
import StatusDialog from "./status-dialog.component.js";

export default class ProgramSectionStatus extends React.Component {
  static propTypes = {
    clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    resolutionCase: PropTypes.object,
    programSlug: PropTypes.string,
    section: PropTypes.object,
    clientCollaborators: PropTypes.array,
    actions: PropTypes.shape({
      updateProgramSectionStatus: PropTypes.func,
    }),
  };

  state = {
    showDialog: false,
  };

  getTooltipText = (status) => {
    const map = {
      in_progress: "In Progress",
      needs_review: "Needs Review",
      complete: "Complete",
    };

    return map[status];
  };

  intendToChangeStatus = (oldStatus, newStatus) => {
    if (oldStatus !== newStatus) {
      this.setState({
        showDialog: true,
        newStatus,
      });
    }
  };

  cancelDialog = () => {
    this.setState({
      showDialog: false,
      newStatus: null,
    });
  };

  getIconColor = (status) => {
    const iconColorClasses = {
      complete: "cps-success",
      needs_review: "cps-warning",
      in_progress: "",
    };

    return iconColorClasses[status] || "";
  };

  render() {
    let {
      clientId,
      resolutionCase,
      programSlug,
      section,
      actions,
      clientCollaborators,
    } = this.props;

    if (!section) {
      // Can't use default parameter in this case, because that doesn't work when section === null
      section = defaultSection;
    }

    const dropdownText = this.getTooltipText(section.status);

    return (
      <div className="cps-inline-block cp-change-status">
        <CpDropdown
          position="bottom-end"
          renderTrigger={({ toggle }) => (
            <CpTooltip text={dropdownText}>
              <CpButton
                icon="shape-circle-dashed"
                onClick={toggle}
                aria-label="Status"
                className={this.getIconColor(section.status)}
              />
            </CpTooltip>
          )}
          renderContent={() => (
            <div className="cp-select-list">
              <button
                onClick={() =>
                  this.intendToChangeStatus(section.status, "in_progress")
                }
              >
                <CpIcon
                  className="cp-mr-4"
                  name="shape-circle-dashed"
                  fill="var(--cp-color-app-secondary-text)"
                />
                In Progress
              </button>
              <button
                onClick={() =>
                  this.intendToChangeStatus(section.status, "needs_review")
                }
              >
                <CpIcon
                  className="cp-mr-4"
                  name="shape-circle-dashed"
                  fill="var(--cp-color-app-warning-text)"
                />
                Needs Review
              </button>
              <button
                onClick={() =>
                  this.intendToChangeStatus(section.status, "complete")
                }
              >
                <CpIcon
                  className="cp-mr-4"
                  name="shape-circle-dashed"
                  fill="#009e38"
                />
                Completed
              </button>
            </div>
          )}
        />
        {this.state.showDialog && (
          <StatusDialog
            clientId={clientId}
            resolutionCase={resolutionCase}
            programSlug={programSlug}
            section={section}
            newStatus={this.state.newStatus}
            actions={{ ...actions, cancelDialog: this.cancelDialog }}
            clientCollaborators={clientCollaborators}
          />
        )}
      </div>
    );
  }
}

ProgramSectionStatus.defaultProps = {
  clientCollaborators: [],
};

const defaultSection = {
  status: "in_progress",
};
