import "./set-public-path.js";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";

import Root, { initialize } from "./root.routes.js";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  childAppName: "end-user-forms-ui",
  featureToggles: [],
  hotload: {
    dev: {
      enabled: true,
      waitForUnmount: false,
    },
  },
});

export const bootstrap = [
  canopyLifecycles.bootstrap,
  formsUIBootstrap,
  reactLifecycles.bootstrap,
];

export {
  toEndUserFormsObservable,
  fromEndUserFormsObservable,
  sectionMountedObservable,
  answerAsObservable,
  answerLoadingAsObservable,
  forceFetchSectionAsObservable,
} from "src/source-forms/answers/answer.actions.js";

export { TaxPrepSourceForm } from "src/tax-prep/tax-prep-source-form.component.js";

export { FormsOverlayContainer } from "src/forms-overlay/forms-overlay-container.component.js";

export { ModularTaxFormPage } from "src/tax-forms/modular-taxform-page.component.js";

export { MobileClientSurvey } from "src/mobile/mobile-client-survey.component.js";

export { ClientSurvey } from "src/client-survey/client-survey.component.js";

export { SubsectionFooter } from "src/source-forms/sections/subsection-footer.component.js";

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

export { scrollToBlock } from "src/common/scroll.helpers.js";

function formsUIBootstrap() {
  return new Promise((resolve, reject) => {
    initialize(window.loggedInUser, window.tenant);
    resolve();
  });
}

function domElementGetter() {
  let el = document.getElementById("end-user-forms-ui");
  if (!el) {
    el = document.createElement("div");
    el.id = "end-user-forms-ui";
    document.body.appendChild(el);
  }
  return el;
}
