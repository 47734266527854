import * as types from "./active-question.types.js";

const defaultState = {
  activeQuestion: null,
  activeQuestionSubscription: null,
};

export default function questionReducer(state = defaultState, action) {
  if (action.type === types.SET_ACTIVE_QUESTION) {
    return {
      ...state,
      activeQuestion: action.question,
      activeQuestionSubscription: null,
    };
  }

  if (action.type === types.CLEAR_ACTIVE_QUESTION) {
    return {
      ...state,
      activeQuestion: null,
      activeQuestionSubscription: null,
    };
  }

  if (action.type === types.SET_ACTIVE_QUESTION_SUBSCRIPTION) {
    return {
      ...state,
      activeQuestionSubscription: action.subscription,
    };
  }

  return state;
}
