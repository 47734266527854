import React from "react";
import CanopySelect from "cpr-select";
import { get } from "lodash";
import { isRequiredButNotValid } from "../sections/section.reducer.js";
import "cpr-select/src/select.css";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import styles from "./inputs.style.css";
import { toggle } from "kremling";

@CanopyInput()
export default class Country extends React.Component {
  constructor(props) {
    super();
    this.state = {
      localAnswer: props.answer,
    };
    this.inputRef = React.createRef();
  }

  focusInput = () => {
    const input = get(this, "inputRef.current.el");
    if (input) {
      if (input.querySelector("input")) input.querySelector("input").focus();
      else console.warn("no input to focus on!");
    } else console.warn("no ref to focus on!");
  };

  render() {
    const baseArray = this.props.variousCountries
      ? [
          { value: null, key: "" },
          { value: "Other Countries", key: "OC" },
        ]
      : [{ value: null, key: "" }];

    return (
      <CanopySelect
        ref={this.inputRef}
        options={[...baseArray, ...countries]}
        placeholder={
          this.props.variousCountries ? "Other Countries" : "Select a country"
        }
        onChange={this.valueChanged}
        onBlur={this.props.validateAnswer}
        selected={this.state.localAnswer}
        outerClass={toggle(
          fixedTableStyles.fixedTableSelectOuter,
          styles.selectOuter,
          this.props.inTable
        )}
        inputClass={this.props.question.name}
      />
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer) {
      this.setState({ localAnswer: this.props.answer });
      this.props.validate(this.props.answer);
    }
  }

  valueChanged = (value) => {
    const { question, updateAnswer } = this.props;

    this.setState({
      localAnswer: value,
    });

    this.props.validate(value);
    this.props.updateAnswer(question, value);
  };
}

export const countries = [
  { value: "Afghanistan", key: "AF" },
  { value: "Akrotiri", key: "AX" },
  { value: "Albania", key: "AL" },
  { value: "Algeria", key: "AG" },
  { value: "American Samoa", key: "AQ" },
  { value: "Andorra", key: "AN" },
  { value: "Angola", key: "AO" },
  { value: "Anguilla", key: "AV" },
  { value: "Antarctica", key: "AY" },
  { value: "Antigua & Barbuda", key: "AC" },
  { value: "Argentina", key: "AR" },
  { value: "Armenia", key: "AM" },
  { value: "Aruba", key: "AA" },
  { value: "Ashmore and Cartier Islands", key: "AT" },
  { value: "Australia", key: "AS" },
  { value: "Austria", key: "AU" },
  { value: "Azerbaijan", key: "AJ" },
  { value: "Bahamas", key: "BF" },
  { value: "Bahrain", key: "BA" },
  { value: "Baker Island", key: "FQ" },
  { value: "Bangladesh", key: "BG" },
  { value: "Barbados", key: "BB" },
  { value: "Belarus", key: "BO" },
  { value: "Belgium", key: "BE" },
  { value: "Belize", key: "BH" },
  { value: "Benin", key: "BN" },
  { value: "Bermuda", key: "BD" },
  { value: "Bhutan", key: "BT" },
  { value: "Bolivia", key: "BL" },
  { value: "Bosnia-Herzegovina", key: "BK" },
  { value: "Botswana", key: "BC" },
  { value: "Bouvet Island", key: "BV" },
  { value: "Brazil", key: "BR" },
  { value: "British Indian Ocean Territory", key: "IO" },
  { value: "British Virgin Islands", key: "VI" },
  { value: "Brunei", key: "BX" },
  { value: "Bulgaria", key: "BU" },
  { value: "Burkina Faso", key: "UV" },
  { value: "Burma", key: "BM" },
  { value: "Burundi", key: "BY" },
  { value: "Cambodia", key: "CB" },
  { value: "Cameroon", key: "CM" },
  { value: "Canada", key: "CA" },
  { value: "Cape Verde", key: "CV" },
  { value: "Cayman Islands", key: "CJ" },
  { value: "Central African Republic", key: "CT" },
  { value: "Chad", key: "CD" },
  { value: "Chile", key: "CI" },
  { value: "China", key: "CH" },
  { value: "Christmas Island", key: "KT" },
  { value: "Clipperton Island", key: "IP" },
  { value: "Cocos (Keeling) Islands", key: "CK" },
  { value: "Colombia", key: "CO" },
  { value: "Comoros", key: "CN" },
  { value: "Congo (Brazzaville)", key: "CF" },
  { value: "Congo (Kinshasa)", key: "CG" },
  { value: "Cook Islands", key: "CW" },
  { value: "Coral Sea Islands", key: "CR" },
  { value: "Costa Rica", key: "CS" },
  { value: "Cote D'Ivoire (Ivory Coast)", key: "IV" },
  { value: "Croatia", key: "HR" },
  { value: "Cuba", key: "CU" },
  { value: "Curacao", key: "UC" },
  { value: "Cyprus", key: "CY" },
  { value: "Czech Republic", key: "EZ" },
  { value: "Denmark", key: "DA" },
  { value: "Dhekelia", key: "DX" },
  { value: "Djibouti", key: "DJ" },
  { value: "Dominica", key: "DO" },
  { value: "Dominican Republic", key: "DR" },
  { value: "East Timor", key: "TT" },
  { value: "Ecuador", key: "EC" },
  { value: "Egypt", key: "EG" },
  { value: "El Salvador", key: "ES" },
  { value: "Equatorial Guinea", key: "EK" },
  { value: "Eritrea", key: "ER" },
  { value: "Estonia", key: "EN" },
  { value: "Ethiopia", key: "ET" },
  { value: "Falkland Islands (Islas Malvinas)", key: "FK" },
  { value: "Faroe Islands", key: "FO" },
  { value: "Federated States of Micronesia", key: "FM" },
  { value: "Fiji", key: "FJ" },
  { value: "Finland", key: "FI" },
  { value: "France", key: "FR" },
  { value: "French Polynesia", key: "FP" },
  { value: "French Southern and Antarctic Lands", key: "FS" },
  { value: "Gabon", key: "GB" },
  { value: "The Gambia", key: "GA" },
  { value: "Georgia", key: "GG" },
  { value: "Germany", key: "GM" },
  { value: "Ghana", key: "GH" },
  { value: "Gibraltar", key: "GI" },
  { value: "Greece", key: "GR" },
  { value: "Greenland", key: "GL" },
  { value: "Grenada", key: "GJ" },
  { value: "Guam", key: "GQ" },
  { value: "Guatemala", key: "GT" },
  { value: "Guernsey", key: "GK" },
  { value: "Guinea", key: "GV" },
  { value: "Guinea-Bissau", key: "PU" },
  { value: "Guyana", key: "GY" },
  { value: "Haiti", key: "HA" },
  { value: "Heard Island and McDonald Islands", key: "HM" },
  { value: "Holy See", key: "VT" },
  { value: "Honduras", key: "HO" },
  { value: "Hong Kong", key: "HK" },
  { value: "Howland Island", key: "HQ" },
  { value: "Hungary", key: "HU" },
  { value: "Iceland", key: "IC" },
  { value: "India", key: "IN" },
  { value: "Indonesia", key: "ID" },
  { value: "Iran", key: "IR" },
  { value: "Iraq", key: "IZ" },
  { value: "Ireland", key: "EI" },
  { value: "Israel", key: "IS" },
  { value: "Italy", key: "IT" },
  { value: "Jamaica", key: "JM" },
  { value: "Jan Mayen", key: "JN" },
  { value: "Japan", key: "JA" },
  { value: "Jarvis Island", key: "DQ" },
  { value: "Jersey", key: "JE" },
  { value: "Johnston Atoll", key: "JQ" },
  { value: "Jordan", key: "JO" },
  { value: "Kazakhstan", key: "KZ" },
  { value: "Kenya", key: "KE" },
  { value: "Kingman Reef", key: "KQ" },
  { value: "Kiribati", key: "KR" },
  { value: "Korea, Democratic People's Republic of (North)", key: "KN" },
  { value: "Korea, Republic of (South)", key: "KS" },
  { value: "Kosovo", key: "KV" },
  { value: "Kuwait", key: "KU" },
  { value: "Kyrgyzstan", key: "KG" },
  { value: "Laos", key: "LA" },
  { value: "Latvia", key: "LG" },
  { value: "Lebanon", key: "LE" },
  { value: "Lesotho", key: "LT" },
  { value: "Liberia", key: "LI" },
  { value: "Libya", key: "LY" },
  { value: "Liechtenstein", key: "LS" },
  { value: "Lithuania", key: "LH" },
  { value: "Luxembourg", key: "LU" },
  { value: "Macau", key: "MC" },
  { value: "Macedonia", key: "MK" },
  { value: "Madagascar", key: "MA" },
  { value: "Malawi", key: "MI" },
  { value: "Malaysia", key: "MY" },
  { value: "Maldives", key: "MV" },
  { value: "Mali", key: "ML" },
  { value: "Malta", key: "MT" },
  { value: "Man, Isle of", key: "IM" },
  { value: "Marshall Islands", key: "RM" },
  { value: "Mauritania", key: "MR" },
  { value: "Mauritius", key: "MP" },
  { value: "Mexico", key: "MX" },
  { value: "Midway Islands", key: "MQ" },
  { value: "Moldova", key: "MD" },
  { value: "Monaco", key: "MN" },
  { value: "Mongolia", key: "MG" },
  { value: "Montenegro", key: "MJ" },
  { value: "Montserrat", key: "MH" },
  { value: "Morocco", key: "MO" },
  { value: "Mozambique", key: "MZ" },
  { value: "Namibia", key: "WA" },
  { value: "Nauru", key: "NR" },
  { value: "Navassa Island", key: "BQ" },
  { value: "Nepal", key: "NP" },
  { value: "Netherlands", key: "NL" },
  { value: "New Caledonia", key: "NC" },
  { value: "New Zealand", key: "NZ" },
  { value: "Nicaragua", key: "NU" },
  { value: "Niger", key: "NG" },
  { value: "Nigeria", key: "NI" },
  { value: "Niue", key: "NE" },
  { value: "Norfolk Island", key: "NF" },
  { value: "Northern Mariana Islands", key: "CQ" },
  { value: "Norway", key: "NO" },
  { value: "Oman", key: "MU" },
  { value: "Other Country", key: "OC" },
  { value: "Pakistan", key: "PK" },
  { value: "Palau", key: "PS" },
  { value: "Palmyra Atoll", key: "LQ" },
  { value: "Panama", key: "PM" },
  { value: "Papua-New Guinea", key: "PP" },
  { value: "Paracel Islands", key: "PF" },
  { value: "Paraguay", key: "PA" },
  { value: "Peru", key: "PE" },
  { value: "Philippines", key: "RP" },
  { value: "Pitcairn Islands", key: "PC" },
  { value: "Poland", key: "PL" },
  { value: "Portugal", key: "PO" },
  { value: "Puerto Rico", key: "RQ" },
  { value: "Qatar", key: "QA" },
  { value: "Romania", key: "RO" },
  { value: "Russia", key: "RS" },
  { value: "Rwanda", key: "RW" },
  { value: "Saint Barthelemy", key: "TB" },
  { value: "Saint Martin", key: "RN" },
  { value: "Samoa", key: "WS" },
  { value: "San Marino", key: "SM" },
  { value: "Sao Tome and Principe", key: "TP" },
  { value: "Saudi Arabia", key: "SA" },
  { value: "Senegal", key: "SG" },
  { value: "Serbia", key: "RI" },
  { value: "Seychelles", key: "SE" },
  { value: "Sierra Leone", key: "SL" },
  { value: "Singapore", key: "SN" },
  { value: "Sint Maarten", key: "NN" },
  { value: "Slovakia", key: "LO" },
  { value: "Slovenia", key: "SI" },
  { value: "Solomon Islands", key: "BP" },
  { value: "Somalia", key: "SO" },
  { value: "South Africa", key: "SF" },
  { value: "South Georgia and the South Sandwich Islands", key: "SX" },
  { value: "South Sudan", key: "OD" },
  { value: "Spain", key: "SP" },
  { value: "Spratly Islands", key: "PG" },
  { value: "Sri Lanka", key: "CE" },
  { value: "St. Helena", key: "SH" },
  { value: "St. Kitts and Nevis", key: "SC" },
  { value: "St. Lucia Island", key: "ST" },
  { value: "St. Pierre and Miquelon", key: "SB" },
  { value: "St. Vincent and the Grenadines", key: "VC" },
  { value: "Sudan", key: "SU" },
  { value: "Suriname", key: "NS" },
  { value: "Svalbard", key: "SV" },
  { value: "Swaziland", key: "WZ" },
  { value: "Sweden", key: "SW" },
  { value: "Switzerland", key: "SZ" },
  { value: "Syria", key: "SY" },
  { value: "Taiwan", key: "TW" },
  { value: "Tajikistan", key: "TI" },
  { value: "Tanzania", key: "TZ" },
  { value: "Thailand", key: "TH" },
  { value: "Togo", key: "TO" },
  { value: "Tokelau", key: "TL" },
  { value: "Tonga", key: "TN" },
  { value: "Trinidad and Tobago", key: "TD" },
  { value: "Tunisia", key: "TS" },
  { value: "Turkey", key: "TU" },
  { value: "Turkmenistan", key: "TX" },
  { value: "Turks and Caicos Islands", key: "TK" },
  { value: "Tuvalu", key: "TV" },
  { value: "Uganda", key: "UG" },
  { value: "Ukraine", key: "UP" },
  { value: "United Arab Emirates", key: "AE" },
  { value: "United Kingdom", key: "UK" },
  { value: "United States", key: "US" },
  { value: "Uruguay", key: "UY" },
  { value: "Uzbekistan", key: "UZ" },
  { value: "Vanuatu", key: "NH" },
  { value: "Venezuela", key: "VE" },
  { value: "Vietnam", key: "VM" },
  { value: "Virgin Islands", key: "VQ" },
  { value: "Wake Island", key: "WQ" },
  { value: "Wallis and Futuna", key: "WF" },
  { value: "Western Sahara", key: "WI" },
  { value: "Yemen (Aden)", key: "YM" },
  { value: "Zambia", key: "ZA" },
  { value: "Zimbabwe", key: "ZI" },
];
