import * as types from "./collection-analytics.types.js";
import { cloneDeep } from "lodash";

const defaultState = {
  defaultState: true,
  smeMode: false,
  report: {},
  answers: { FederalTaxesDue: null },
};
export default function reportReducer(state = defaultState, action) {
  if (action.type === types.LOADED_REPORT) {
    const newState = cloneDeep(state);
    return {
      ...newState,
      gettingReport: false,
      defaultState: false,
      report: action.load,
    };
  }
  if (action.type === types.TOGGLED_SME_MODE) {
    return { ...state, smeMode: action.load };
  }
  if (action.type === types.UPDATED_ANALYTICS_ANSWER) {
    const newState = cloneDeep(state);
    newState.answers = { ...newState.answers, ...action.load };
    return newState;
  }
  if (action.type === types.RESET_ANALYTICS_STATE) {
    return defaultState;
  }
  if (action.type === types.GETTING_REPORT) {
    return { ...state, gettingReport: true };
  }
  if (action.type === types.GOT_FEDERAL_TAXES) {
    return { ...state, answers: action.load };
  }
  return state;
}
