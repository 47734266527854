// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-tax-forms-tax-form-for-source-form-styles__mainContent {
  padding-right: 21rem;
  padding-left: 2.4rem;
  min-width: 1000px;
}

.src-tax-forms-tax-form-for-source-form-styles__taxFormHeader {
  position: relative;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/tax-forms/tax-form-for-source-form.styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":[".mainContent {\n  padding-right: 21rem;\n  padding-left: 2.4rem;\n  min-width: 1000px;\n}\n\n.taxFormHeader {\n  position: relative;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContent": `src-tax-forms-tax-form-for-source-form-styles__mainContent`,
	"taxFormHeader": `src-tax-forms-tax-form-for-source-form-styles__taxFormHeader`
};
export default ___CSS_LOADER_EXPORT___;
