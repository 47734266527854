import React from "react";
import { get, take } from "lodash";
import { ModularTaxFormPage } from "../tax-forms/modular-taxform-page.component.js";
import { getFormData } from "../tax-forms/tax-form-overrides.resource.js";
import Cancelable from "react-disposable-decorator";
import { fieldsByPage } from "../tax-forms/overrides.helper.js";
import { Scoped, k } from "kremling";

@Cancelable
export default class TaxFormPages extends React.Component {
  state = {
    visiblePages: [],
    overrides: [],
    svgData: {},
  };

  chunkSize = 2;

  componentDidMount() {
    const {
      formId,
      clientId,
      resolutionCaseId,
      versionId,
      revisionId,
      cancelWhenUnmounted,
      noticeId,
    } = this.props;

    cancelWhenUnmounted(
      this.loadOverrides(
        formId,
        clientId,
        resolutionCaseId,
        `${versionId}-${revisionId}`,
        () => {},
        noticeId
      )
    );
  }

  componentDidUpdate(prevProps) {
    const {
      formId,
      clientId,
      resolutionCaseId,
      versionId,
      revisionId,
      cancelWhenUnmounted,
      noticeId,
    } = this.props;

    if (prevProps.formId !== this.props.formId) {
      cancelWhenUnmounted(
        this.loadOverrides(
          formId,
          clientId,
          resolutionCaseId,
          `${versionId}-${revisionId}`,
          () => {},
          noticeId
        )
      );
    }
  }

  loadOverrides = (
    formId,
    clientId,
    resolutionCaseId,
    version,
    onCompleted = () => {},
    noticeId
  ) =>
    getFormData(
      clientId,
      resolutionCaseId,
      version,
      formId,
      noticeId
    ).subscribe((svgData) => {
      onCompleted();
      this.setState(
        {
          overrides: fieldsByPage(svgData),
          svgData,
        },
        () =>
          this.setState((prevS, props) => ({
            visiblePages: this.determineVisiblePages(prevS.visiblePages),
          }))
      );
    });

  loadMore = () =>
    this.setState((prevS, props) => ({
      visiblePages: this.determineVisiblePages(prevS.visiblePages, true),
    }));

  shouldLoadMore = () => {
    if (
      this.props.pages &&
      this.props.pages.length === this.state.visiblePages.length
    ) {
      return false;
    }
    const scrollTop = window.pageYOffset || document.body.scrollTop;
    const height = window.innerHeight || document.documentElement.offsetHeight;
    const docHeight = document.body.clientHeight;

    if (scrollTop + height >= docHeight - 100) {
      this.loadMore();
    }
  };

  determineVisiblePages = (prevVisiblePages, loadMore = false) => {
    const {
      formId,
      pages,
      clientId,
      resolutionCaseId,
      versionId,
      revisionId,
      noticeId,
    } = this.props;

    const { overrides, svgData } = this.state;
    const totalPages = get(svgData, "total-pages", 1);
    const pagesToLoad = take(pages, prevVisiblePages.length + this.chunkSize);

    return pagesToLoad.map((page, index) => {
      // Don't load more pages than what is specified in svgData.total-pages
      if (page.pageNumber + 1 > totalPages) {
        return null;
      }

      return (
        <div className="tax-form-page" key={`${formId}-${page.pageNumber}`}>
          <ModularTaxFormPage
            overrides={overrides[`page-${page.pageNumber + 1}`]}
            width={get(svgData, `page-width[${page.pageNumber}]`)}
            height={get(svgData, `page-height[${page.pageNumber}]`)}
            pageNumber={page.pageNumber + 1}
            clientId={clientId}
            resolutionCaseId={resolutionCaseId}
            noticeId={noticeId}
            versionId={versionId}
            revisionId={revisionId}
            formId={formId}
            getOverrides={this.loadOverrides}
          />
        </div>
      );
    });
  };

  render = () => (
    <Scoped css={css}>
      <div
        className="overlay-tax-forms"
        onScroll={this.shouldLoadMore.bind(this)}
      >
        {this.state.visiblePages}
      </div>
    </Scoped>
  );
}

const css = k`
  .overlay-tax-forms {
    overflow: auto;
    height: calc(100vh - 22rem);
  }

  .tax-form-page {
    margin-bottom: 6.1rem;
  }
`;
