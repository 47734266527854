import * as types from "./active-question.types.js";
import { getQuestion } from "./questions.resource.js";
import { handleError } from "src/handle-error";

export function getActiveQuestion(questionName) {
  return (dispatch, getState) => {
    const versionId = getState().smeVersion.version.id;
    const revisionId = getState().smeVersion.revision.id;
    const clientId = getState().reduxClient.id;
    const resolutionCaseId = getState().resolutionCase.id;

    const activeQuestionSubscription = getQuestion(
      clientId,
      resolutionCaseId,
      versionId,
      revisionId,
      questionName
    ).subscribe((question) => {
      dispatch({
        type: types.SET_ACTIVE_QUESTION,
        question,
      });
    }, handleError);

    dispatch({
      type: types.SET_ACTIVE_QUESTION_SUBSCRIPTION,
      subscription: activeQuestionSubscription,
    });
  };
}

export function clearActiveQuestion() {
  return (dispatch, getState) => {
    const activeQuestionSubscription =
      getState().activeQuestion.activeQuestionSubscription;

    if (activeQuestionSubscription) {
      activeQuestionSubscription.unsubscribe();
    }

    dispatch({
      type: types.CLEAR_ACTIVE_QUESTION,
    });
  };
}
