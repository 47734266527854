import React from "react";
import Question from "./question.component.js";
import InformationalText from "./informational-text.component.js";
import QuickLook from "./quick-look.component";
import SummaryTable from "../summary-table/summary-table.component.js";
import FixedTable from "../fixed-table/fixed-table.component.js";
import DynamicDropdown from "./dynamic-dropdown.component.js";
import BlockDisplay from "./block-display.component.js";

export default function QuestionType(props) {
  const {
    parentName,
    blockIndex,
    questionIndex,
    sectionId,
    question,
    serverAnswer,
    answers,
    fullName,
    summaryTable,
    clientId,
    alternateAnswerSets,
    overrideAnswerSet,
    questionsToShowRequiredWarnings,
    actions,
    setFirstInputRef,
    resolutionCaseId,
    noticeId,
    version,
    revision,
    folderIds,
    taskId,
    summaryTableIndex,
    taxPrep,
    sourceFormId,
    dynamicData,
    sections,
    numBlocks,
    parentServerAnswers,
    formName,
    sectionResize,
  } = props;

  if (question.type === "INFORMATIONAL_TEXT") {
    return <InformationalText question={question} />;
  } else if (question.type === "QUICK_LOOK") {
    return (
      <QuickLook
        question={question}
        clientId={clientId}
        resolutionCaseId={resolutionCaseId}
        noticeId={noticeId}
        version={version}
        revision={revision}
        sourceFormId={sourceFormId}
        folderIds={folderIds}
        taskId={taskId}
        actions={actions}
      />
    );
  } else if (question.type === "BLOCK_DISPLAY") {
    return (
      <BlockDisplay
        question={question}
        actions={actions}
        answers={answers}
        sectionId={sectionId}
        summaryTable={summaryTable}
        formName={formName}
        clientId={clientId}
        alternateAnswerSets={alternateAnswerSets}
        overrideAnswerSet={overrideAnswerSet}
        questionsToShowRequiredWarnings={questionsToShowRequiredWarnings}
        resolutionCaseId={resolutionCaseId}
        noticeId={noticeId}
        version={version}
        revision={revision}
        sourceFormId={sourceFormId}
        taskId={taskId}
        taxPrep={taxPrep}
        dynamicData={dynamicData}
        sectionResize={sectionResize}
      />
    );
  } else if (question.type === "SUMMARY_TABLE") {
    return (
      <SummaryTable
        summaryTableName={question.name}
        blockIndex={blockIndex}
        questionIndex={questionIndex}
        sectionId={sectionId}
        summaryTable={summaryTable}
        fullName={fullName}
        question={question}
        sectionResize={sectionResize}
        clientId={clientId}
        questionsToShowRequiredWarnings={questionsToShowRequiredWarnings}
        alternateAnswerSets={alternateAnswerSets}
        overrideAnswerSet={overrideAnswerSet}
        actions={actions}
        serverAnswer={serverAnswer}
        setFirstInputRef={setFirstInputRef}
        taxPrep={taxPrep}
        resolutionCaseId={resolutionCaseId}
        noticeId={noticeId}
        version={version}
        revision={revision}
        dynamicData={dynamicData}
        sourceFormId={sourceFormId}
        questionsInSurroundingBlock={props.totalQuestions}
        numBlocks={numBlocks}
        parentServerAnswers={parentServerAnswers || props.answers.serverAnswers}
      />
    );
  } else if (question.type === "FIXED_TABLE") {
    return (
      <FixedTable
        clientId={clientId}
        answers={answers}
        parentName={parentName}
        blockIndex={blockIndex}
        questionIndex={questionIndex}
        sectionId={sectionId}
        question={question}
        serverAnswer={serverAnswer}
        key={question.name}
        alternateAnswerSets={alternateAnswerSets}
        overrideAnswerSet={overrideAnswerSet}
        actions={actions}
        setFirstInputRef={setFirstInputRef}
        questionsToShowRequiredWarnings={questionsToShowRequiredWarnings}
        resolutionCaseId={resolutionCaseId}
        noticeId={noticeId}
        version={version}
        revision={revision}
        sourceFormId={sourceFormId}
        folderIds={folderIds}
        taskId={taskId}
        parentServerAnswers={parentServerAnswers}
      />
    );
  } else if (question.type === "DYNAMIC_DROPDOWN") {
    return (
      <DynamicDropdown
        clientId={clientId}
        answers={answers}
        parentName={parentName}
        blockIndex={blockIndex}
        questionIndex={questionIndex}
        sectionId={sectionId}
        question={question}
        serverAnswer={serverAnswer}
        key={question.name}
        alternateAnswerSets={alternateAnswerSets}
        overrideAnswerSet={overrideAnswerSet}
        actions={actions}
        setFirstInputRef={setFirstInputRef}
        questionsToShowRequiredWarnings={questionsToShowRequiredWarnings}
        version={version}
        revision={revision}
        resolutionCaseId={resolutionCaseId}
        noticeId={noticeId}
        paramSourceFormId={sourceFormId}
      />
    );
  } else {
    // Todo: only use Question component when question.type === 'QUESTION'. Currently waiting on backend.
    return (
      <Question
        clientId={clientId}
        answers={answers}
        parentName={parentName}
        blockIndex={blockIndex}
        questionIndex={questionIndex}
        sectionId={sectionId}
        question={question}
        serverAnswer={serverAnswer}
        key={question.name}
        alternateAnswerSets={alternateAnswerSets}
        overrideAnswerSet={overrideAnswerSet}
        questionsToShowRequiredWarnings={questionsToShowRequiredWarnings}
        actions={actions}
        setFirstInputRef={setFirstInputRef}
        summaryTableIndex={summaryTableIndex}
        dynamicData={dynamicData}
        sourceFormId={sourceFormId}
        parentServerAnswers={parentServerAnswers}
      />
    );
  }
  // uncomment when Form Service always provides the question.type
  // } else  {
  //    return <NotFound question={question} />
  // }
}
