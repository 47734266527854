import React from "react";
import { CpButton, CpLoader } from "canopy-styleguide!sofe";
import { bindActionCreators } from "redux";
import { warningToast } from "toast-service!sofe";
import canopyUrls from "canopy-urls!sofe";
import { connect } from "react-redux";
import { includes, get, chain, throttle, toLower, uniqueId } from "lodash";
import { handleError } from "src/handle-error";
import { parse } from "query-string";
import { UserTenantProps } from "cp-client-auth!sofe";

import indexInSequence from "./sequence.helper.js";
import { getTaxFormsForFolder } from "./tax-form.resource.js";
import TaxFormHeader from "./tax-form-header.component.js";
import FormViewSwitch from "../source-forms/form-view-switch.component.js";
import TaxForm from "./tax-form.component.js";
import { NavContent } from "primary-navbar!sofe";
import { formSetMap } from "./tax-return.helper.js";
import styles from "./tax-form-for-source-form.styles.css";
import Cancelable from "react-disposable-decorator";

import * as uiFormsActions from "../source-forms/ui-forms.actions.js";

@Cancelable
@connect((state) => ({
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
  uiFormsState: state.uiForms,
  sourceFormLayout: state.sourceFormLayout,
  formSet: state.smeVersion.formSet,
  sequenceFilter: state.smeVersion.sequenceFilter,
}))
@UserTenantProps({ permissions: { hasSmePermissions: "sme" } })
export default class TaxReturn extends React.Component {
  state = {
    forms: null,
    maxFormsToLoad: 3,
  };
  windowResize = throttle(windowResize.bind(this), 10);
  loading = false;
  componentDidMount() {
    if (this.el) {
      window.addEventListener("resize", this.windowResize);
      window.addEventListener("scroll", this.windowResize);
      this.windowResize();
    }
    if (this.props.version && this.props.revision) {
      this.getForms(this.props);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResize);
    window.removeEventListener("scroll", this.windowResize);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.formSet !== this.props.formSet || !this.state.forms) &&
      this.props.version &&
      this.props.revision
    ) {
      this.getForms(this.props);
    }
  }
  render() {
    const {
      version,
      revision,
      permissions,
      uiFormsState,
      sourceFormLayout,
      formSet,
      sequenceFilter,
    } = this.props;
    const { clientId, resolutionCaseId, taxFormId, sourceFormId } =
      this.props.match.params;
    const { sectionId } = parse(this.props.location.search);

    const actions = {
      ...bindActionCreators(uiFormsActions, this.props.dispatch),
    };

    const topInt = 264; //window.pageYOffset > topToStickTo ? contentTop : topToStickTo - window.pageYOffset + contentTop;

    const rootStyles = {
      position: "fixed",
      left: uiFormsState.cardLeft,
      width: uiFormsState.cardWidth,
      paddingBottom: window.pageYOffset > 25 ? "1.6rem" : "1.2rem",
      top: String(topInt) + "px",
    };

    const { forms, maxFormsToLoad, error, errorId } = this.state;

    const filteredForms = chain(forms)
      .filter((form) =>
        sequenceFilter
          ? true
          : form.visible === true ||
            includes(toLower(form.visible), "visible") ||
            includes(form.visible, true)
      )
      .filter((form, index) => indexInSequence(form.sequence, sequenceFilter))
      .filter((form, index) => index < maxFormsToLoad)
      .sortBy("sequence")
      .value();

    const isTaxPrep = get(this.props, "version.product") === "taxPrep";

    if (error) {
      return (
        <div id={errorId}>
          <div
            style={{
              marginLeft: "350px",
              paddingLeft: 26,
              marginTop: "240px",
              overflow: "auto",
              maxWidth: "1800px",
            }}
            className="cps-card"
          >
            <h4 className="cps-warning">
              We encountered an error when rendering the form
            </h4>
            {error !== "error" && <pre>{error}</pre>}
          </div>
        </div>
      );
    }

    return (
      <NavContent
        className={`wide-menu ${styles.mainContent}`}
        clientMenuPossible={true}
        hasTopnavSecondary={false}
      >
        <div className={"cps-fixed-focus"}>
          <TaxFormHeader
            taxReturn
            tempPrint={this.tempPrint.bind(this)}
            folderId={formSetMap[formSet]}
            clientId={clientId}
            taxFormId={""}
            uiFormsState={uiFormsState}
            formName={`Tax return`}
            versionId={version ? version.id : null}
            revisionId={revision ? revision.id : null}
          />
          <div ref={(el) => (this.el = el)}>
            {this.loading || !forms ? (
              <div style={{ height: 100, paddingTop: 45 }} className="cps-card">
                <CpLoader />
              </div>
            ) : (
              <div>
                {filteredForms.length ? (
                  filteredForms.map((form, index) => (
                    <TaxForm
                      key={form.name}
                      formName={form.name}
                      onLoad={() => this.windowResize()}
                      userIsSME={permissions.hasSmePermissions}
                      clientId={clientId}
                      versionId={version.id}
                      revisionId={revision.id}
                      actions={actions}
                    />
                  ))
                ) : (
                  <div style={rootStyles} className="cps-card cps-padding-32">
                    <center className="cps-warning cps-warning">
                      {!forms || !forms.length
                        ? "There are no forms in this folder!"
                        : "There are no forms in this sequence"}
                    </center>
                  </div>
                )}
                {!!filteredForms.length && (
                  <CpButton
                    btnType="flat"
                    onClick={() =>
                      this.setState((oldState) => ({
                        maxFormsToLoad: oldState.maxFormsToLoad + 3,
                      }))
                    }
                  >
                    Load more
                  </CpButton>
                )}
              </div>
            )}
          </div>
          <FormViewSwitch
            formSwitchLeft={uiFormsState.formSwitchLeft}
            formView={"taxForm"}
            forms={this.state.forms}
            isTaxPrep={isTaxPrep}
            resolutionCaseId={resolutionCaseId}
            clientId={clientId}
            taxFormId={taxFormId}
            sourceFormId={sourceFormId}
            sourceFormName={sourceFormLayout.name}
            sectionId={sectionId || 0}
            userIsSME={permissions.hasSmePermissions}
          />
        </div>
      </NavContent>
    );
  }

  tempPrint(action) {
    const { clientId, resolutionCaseId } = this.props.match.params;
    const { sequenceFilter } = this.props;

    if (!sequenceFilter) {
      alert("You must define a sequence!");
      return;
    }

    const versionId = this.props.version.id;
    const revisionId = this.props.revision.id;

    const filteredForms = chain(this.state.forms)
      .filter((form) => indexInSequence(form.sequence, sequenceFilter))
      .sortBy("sequence")
      .value();

    return filteredForms.map((form) =>
      window.open(
        `${canopyUrls.getAPIUrl()}/clients/${clientId}/resolution_cases/${resolutionCaseId}/tax-form-pdfs/${
          form.name
        }?version=${versionId}-${revisionId}&action=${action}`
      )
    );
  }

  getForms(props) {
    if (this.loading) return;

    this.loading = true;

    const { version, revision } = props;

    const { clientId, resolutionCaseId } = props.match.params;

    this.props.cancelWhenUnmounted(
      getTaxFormsForFolder(
        clientId,
        resolutionCaseId,
        version.id,
        revision.id,
        formSetMap[props.formSet]
      ).subscribe(
        (forms) => {
          this.loading = false;
          this.setState({
            forms,
          });
        },
        (error) => {
          if (this.props.permissions.hasSmePermissions) {
            warningToast(
              `We encountered an error while rendering the tax return: ${get(
                error,
                "data.errors"
              )}`
            );
            this.setState({
              error: JSON.stringify(get(error, "data.errors"), null, "  "),
              errorId: uniqueId(),
            });
          } else {
            handleError(error);
          }
        }
      )
    );
  }
}

function windowResize() {
  if (this.el) {
    const rect = this.el.getBoundingClientRect();
    this.props.dispatch(uiFormsActions.newCardPosition(rect.left, rect.width));
  }
}
