import React from "react";
import { CpSelectMultiPills } from "canopy-styleguide!sofe";
import { find, keyBy } from "lodash";
import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";

@CanopyInput()
export default class Pillbox extends React.Component {
  constructor(props) {
    super();
    this.state = {
      localAnswer: props.answer,
      searchOne: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer && !this.focused) {
      this.setState({ localAnswer: this.props.answer });
      this.props.validate(this.props.answer);
    }
  }

  updateLocalAnswer = (rawValues) => {
    const { question, updateAnswer } = this.props;
    const value = sortBySourceArray(rawValues, question.meta.options);
    this.setState({ localAnswer: value });
    updateAnswer(question, value);
    this.props.validate(value);
  };

  searchOnChange = (searchOne) => {
    this.setState({
      searchOne,
    });
  };

  render() {
    const { question, inTable } = this.props;
    const { localAnswer } = this.state;
    const initialSelectedItems = question.meta.options.filter((option) => {
      return !!find(localAnswer, (ans) => option.value === ans);
    });
    return (
      <div>
        <CpSelectMultiPills
          id="multi-select-focus-id"
          data={question.meta.options}
          insertSearch
          placeholder="Select..."
          onChange={this.updateLocalAnswer}
          searchOnChange={this.searchOnChange}
          searchValue={this.state.searchOne}
          transformData={(item) => ({
            ...item,
            id: item.value,
            name: item.label,
          })}
          value={initialSelectedItems}
          className={
            inTable
              ? fixedTableStyles.multiselectPillboxTable
              : styles.multiselectPillBox
          }
        />
      </div>
    );
  }
}

function sortBySourceArray(newArr, sourceArr) {
  const refTable = keyBy(newArr, "value");
  return sourceArr
    .filter((item) => {
      return !!refTable[item.value];
    })
    .map((item) => item.value);
}
