import React from "react";
import styles from "./report-utils.styles.css";
import SmeWrapper from "./sme-view-wrapper.component.js";
import { toMaskToValue } from "src/source-forms/inputs/currency.component.js";

export default function BigPrimary(props) {
  return (
    <div className={`${styles.bigPrimaryTextBox}`}>
      <div className={`cps-margin-top-8`}>{props.caption}</div>
      <SmeWrapper reportKey={props.reportKey} smeMode={props.smeMode}>
        <div className={`cps-margin-top-8 cps-subheader cps-color-primary`}>
          {(typeof props.value === "number" && !props.notCurrency
            ? toMaskToValue(String(props.value)).mask
            : props.value) ||
            (props.smeMode && "I'm a value")}
        </div>
      </SmeWrapper>
    </div>
  );
}
