import * as types from "./ui-forms.types.js";

export function newCardPosition(cardLeft, cardWidth) {
  return {
    type: types.NEW_CARD_POSITION,
    cardLeft,
    cardWidth,
  };
}

export function newSectionMessage(msg) {
  return {
    type: types.NEW_SECTION_MESSAGE,
    load: msg,
  };
}

export function resetSectionMessage() {
  return {
    type: types.NEW_SECTION_MESSAGE,
  };
}
