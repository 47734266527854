import React from "react";
import { Provider } from "react-redux";
import { get } from "lodash";
import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import { Route, HashRouter, BrowserRouter } from "react-router-dom";

import { getAndFetchVersion } from "sme-version!sofe";

import ClientSurveyWrapper from "../client-survey/client-survey.wrapper.js";
import CreateSurvey from "../client-survey/create-survey/create-survey.wrapper.js";
import CSSF_Sections from "../client-survey/source-form/cssf-sections.wrapper.js";
import CSSF_Subsection from "../client-survey/source-form/cssf-subsection.wrapper.js";
import SubmitSurvey from "../client-survey/submit/submit-survey.component.js";
import SurveyAllDone from "../client-survey/submit/all-done.component.js";
import SurveyClientWelcome from "../client-survey/client-portal/client-portal-welcome.component.js";
import CS_GetStarted from "../client-survey/create-survey/get-started.component.js";
import CS_SentSuccess from "../client-survey/create-survey/sent-success.component.js";
import SurveyFileUpload from "../client-survey/files/survey-file-upload.component.js";

import contextReducer from "../bootstrap/context.reducer.js";
import resolutionCaseReducer from "../resolution-cases/resolution-cases.reducer.js";
import reduxClientReducer from "../clients/redux-client.reducer.js";
import layoutReducer from "../source-forms/source-form-layout.reducer.js";
import sectionReducer from "../source-forms/sections/section.reducer.js";
import answerReducer from "../source-forms/answers/answer.reducer.js";
import versionReducer from "../source-forms/version.reducer.js";
import uiFormsReducer from "../source-forms/ui-forms.reducer.js";
import summaryTableReducer from "../source-forms/summary-table/summary-table.reducer.js";
import dynamicDataReducer from "src/source-forms/dynamic-data.reducer.js";
import clientSurveyReducer from "../client-survey/client-survey.reducer.js";
import activeQuestionReducer from "../source-forms/questions/active-question.reducer.js";
import resolutionCaseAnswerOrderReducer from "../resolution-cases/answer-order/answer-order.reducer.js";

import SurveyOptions from "./options/survey-options.component.js";

import { newLoggedInUser } from "../bootstrap/context.actions.js";
import * as versionActions from "../source-forms/version.actions.js";
import * as resolutionCaseActions from "src/resolution-cases/resolution-cases.actions.js";
import * as clientSurveyActions from "src/client-survey/client-survey.actions.js";
import * as reduxClientActions from "src/clients/redux-client.actions.js";

import { getSurveyGroup, getSurveySourceForm } from "./client-survey.helper";

export class ClientSurvey extends React.Component {
  state = {
    store: null,
  };

  componentDidMount() {
    const { loggedInUser, surveyType, surveyData } = this.props;

    const reducer = combineReducers({
      section: sectionReducer,
      answers: answerReducer,
      sourceFormLayout: layoutReducer,
      context: contextReducer,
      resolutionCase: resolutionCaseReducer,
      reduxClient: reduxClientReducer,
      smeVersion: versionReducer,
      uiForms: uiFormsReducer,
      summaryTable: summaryTableReducer,
      activeQuestion: activeQuestionReducer,
      dynamicData: dynamicDataReducer,
      clientSurvey: clientSurveyReducer,
      resolutionCaseAnswerOrder: resolutionCaseAnswerOrderReducer,
    });

    const store = createStore(reducer, applyMiddleware(thunk));
    store.dispatch(newLoggedInUser(loggedInUser));
    this.setupReduxState(store);

    this.setState({
      store,
      surveyMetaData: {
        surveyType:
          this.props.loggedInUser.role === "Client" ? null : surveyType,
        surveyData: surveyType === "taxPrep" ? surveyData : {},
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.version?.id !== this.props.version?.id ||
        prevProps.revision?.id !== this.props.revision?.id) &&
      this.state?.store
    ) {
      this.state.store.dispatch(
        versionActions.newSmeVersion(this.props.version, this.props.revision)
      );
    }
  }

  setupReduxState(store = this.state.store) {
    const { clientId, resolutionCaseId, surveyData, surveyType } = this.props;

    store.dispatch(reduxClientActions.setReduxClient({ id: clientId }));

    store.dispatch(
      resolutionCaseActions.fetchResolutionCase(
        clientId,
        resolutionCaseId,
        (resolutionCase) => {
          store.dispatch(
            clientSurveyActions.getClientSurveyAction(
              clientId,
              resolutionCaseId,
              (clientSurvey) => {
                this.smeVersionDisposable = getAndFetchVersion(
                  surveyType === "taxPrep"
                    ? surveyType
                    : clientSurvey.surveyType || "taxRes",
                  get(clientSurvey, "surveyData.returnYear", "2019")
                ).subscribe((smeVersion) => {
                  const surveyVersion = this.props.version
                    ? this.props.version
                    : smeVersion.version;
                  const surveyRevision = this.props.revision
                    ? this.props.revision
                    : smeVersion.revision;

                  store.dispatch(
                    versionActions.newSmeVersion(surveyVersion, surveyRevision)
                  );

                  if (clientSurvey.notCreated) {
                    store.dispatch(
                      clientSurveyActions.getClientSurveySourceFormId(
                        clientId,
                        resolutionCaseId,
                        surveyVersion?.id,
                        surveyRevision?.id,
                        getSurveySourceForm(
                          surveyType,
                          surveyData,
                          resolutionCase
                        ),
                        getSurveyGroup(surveyType)
                      )
                    );
                  } else {
                    store.dispatch(
                      clientSurveyActions.getClientSurveySourceFormId(
                        clientId,
                        resolutionCaseId,
                        surveyVersion?.id,
                        surveyRevision?.id,
                        getSurveySourceForm(
                          clientSurvey.surveyType,
                          clientSurvey.surveyData,
                          resolutionCase
                        ),
                        getSurveyGroup(clientSurvey.surveyType)
                      )
                    );

                    this.setState({
                      surveyMetaData: {
                        surveyType: clientSurvey.surveyType || "taxRes",
                        surveyData:
                          clientSurvey.surveyType === "taxPrep"
                            ? clientSurvey.surveyData
                            : {},
                        surveyRevision,
                      },
                    });
                  }
                });
              }
            )
          );
        }
      )
    );
  }

  componentWillUnmount() {
    if (this.smeVersionDisposable) this.smeVersionDisposable.unsubscribe();
  }

  render() {
    const {
      baseUrl,
      clientId,
      resolutionCaseId,
      shouldShowSecondaryNav,
      displayingInClientPortal,
      loggedInUser,
    } = this.props;
    const { surveyMetaData } = this.state;
    const Router =
      this.props.routerType === ClientSurvey.HASH ? HashRouter : BrowserRouter;
    const isHash = this.props.routerType === ClientSurvey.HASH;
    return (
      this.state.store && (
        <Provider store={this.state.store}>
          <Router>
            <Route
              path={`${baseUrl}`}
              render={(routeProps) => {
                return (
                  <ClientSurveyWrapper
                    clientId={clientId}
                    resolutionCaseId={resolutionCaseId}
                    baseUrl={baseUrl}
                    shouldShowSecondaryNav={shouldShowSecondaryNav}
                    hashRouting={isHash}
                    displayingInClientPortal={displayingInClientPortal}
                  >
                    <Route
                      exact
                      path={[
                        `${routeProps.match.url}/sections`,
                        `${routeProps.match.url}/sections/:sectionId/subsections/:subsectionIndex`,
                      ]}
                      render={(routeProps) => {
                        return (
                          <CSSF_Sections
                            {...routeProps}
                            loggedInUser={loggedInUser}
                            clientId={clientId}
                            resolutionCaseId={resolutionCaseId}
                            baseUrl={baseUrl}
                            surveyMetaData={surveyMetaData}
                            displayingInClientPortal={displayingInClientPortal}
                          >
                            <CSSF_Subsection
                              {...routeProps}
                              clientId={clientId}
                              resolutionCaseId={resolutionCaseId}
                              baseUrl={baseUrl}
                              isTaxPrep={
                                surveyMetaData.surveyType === "taxPrep"
                              }
                              displayingInClientPortal={
                                displayingInClientPortal
                              }
                            />
                          </CSSF_Sections>
                        );
                      }}
                    />
                    <Route
                      path={`${routeProps.match.url}/upload`}
                      render={(routeProps) => (
                        <CSSF_Sections
                          {...routeProps}
                          loggedInUser={loggedInUser}
                          clientId={clientId}
                          resolutionCaseId={resolutionCaseId}
                          baseUrl={baseUrl}
                          displayingInClientPortal={displayingInClientPortal}
                          surveyMetaData={surveyMetaData}
                        >
                          <SurveyFileUpload
                            {...routeProps}
                            clientId={clientId}
                            resolutionCaseId={resolutionCaseId}
                            baseUrl={baseUrl}
                          />
                        </CSSF_Sections>
                      )}
                    />
                    <Route
                      exact
                      path={`${routeProps.match.url}/file/:fileId`}
                      render={(routeProps) => (
                        <CSSF_Sections
                          {...routeProps}
                          loggedInUser={loggedInUser}
                          clientId={clientId}
                          resolutionCaseId={resolutionCaseId}
                          baseUrl={baseUrl}
                          displayingInClientPortal={displayingInClientPortal}
                          surveyMetaData={surveyMetaData}
                        >
                          <SurveyFileUpload
                            {...routeProps}
                            clientId={clientId}
                            resolutionCaseId={resolutionCaseId}
                            baseUrl={baseUrl}
                          />
                        </CSSF_Sections>
                      )}
                    />
                    <Route
                      path={`${routeProps.match.url}/submit`}
                      render={(routeProps) => (
                        <CSSF_Sections
                          {...routeProps}
                          loggedInUser={loggedInUser}
                          clientId={clientId}
                          resolutionCaseId={resolutionCaseId}
                          baseUrl={baseUrl}
                          displayingInClientPortal={displayingInClientPortal}
                          surveyMetaData={surveyMetaData}
                        >
                          <Route
                            render={(routeProps) => (
                              <SubmitSurvey
                                {...routeProps}
                                clientId={clientId}
                                resolutionCaseId={resolutionCaseId}
                                baseUrl={baseUrl}
                              />
                            )}
                          />
                        </CSSF_Sections>
                      )}
                    />
                    <Route
                      path={`${routeProps.match.url}/welcome`}
                      render={(routeProps) => (
                        <CSSF_Sections
                          {...routeProps}
                          loggedInUser={loggedInUser}
                          clientId={clientId}
                          resolutionCaseId={resolutionCaseId}
                          baseUrl={baseUrl}
                          displayingInClientPortal={displayingInClientPortal}
                          surveyMetaData={surveyMetaData}
                        >
                          <Route
                            render={(routeProps) => (
                              <SurveyClientWelcome
                                {...routeProps}
                                clientId={clientId}
                                resolutionCaseId={resolutionCaseId}
                                baseUrl={baseUrl}
                              />
                            )}
                          />
                        </CSSF_Sections>
                      )}
                    />
                    <Route
                      path={`${routeProps.match.url}/all-done`}
                      component={SurveyAllDone}
                    />
                    <Route
                      path={`${routeProps.match.url}/create-survey`}
                      render={(routeProps) => (
                        <CreateSurvey
                          {...routeProps}
                          resolutionCaseId={resolutionCaseId}
                          clientId={clientId}
                          baseUrl={`${baseUrl}/create-survey`}
                        >
                          <Route
                            path={`${routeProps.match.url}/get-started`}
                            render={(routeProps) => (
                              <CS_GetStarted
                                {...routeProps}
                                resolutionCaseId={resolutionCaseId}
                                clientId={clientId}
                                baseUrl={baseUrl}
                                surveyMetaData={surveyMetaData}
                              />
                            )}
                          />
                          <Route
                            path={`${routeProps.match.url}/sent-success`}
                            render={() => <CS_SentSuccess baseUrl={baseUrl} />}
                          />
                        </CreateSurvey>
                      )}
                    />
                  </ClientSurveyWrapper>
                );
              }}
            />
          </Router>
          <SurveyOptions />
        </Provider>
      )
    );
  }
}

ClientSurvey.defaultProps = {
  shouldShowSecondaryNav: true,
};

ClientSurvey.HASH = "HASH";
ClientSurvey.HTML5 = "HTML5";
