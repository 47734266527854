import React from "react";
import {
  bindActionCreators,
  applyMiddleware,
  createStore,
  combineReducers,
} from "redux";
import thunk from "redux-thunk";
import { connect, Provider } from "react-redux";
import { get, partial } from "lodash";
import Cancelable from "react-disposable-decorator";
import { fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import { CpLoader } from "canopy-styleguide!sofe";
import Section from "../source-forms/sections/section.component";
import * as uiFormsActions from "../source-forms/ui-forms.actions";
import * as answerActions from "../source-forms/answers/answer.actions";
import * as summaryTableActions from "../source-forms/summary-table/summary-table.actions";
import * as sectionActions from "../source-forms/sections/section.actions";
import { newLoggedInUser, newTenant } from "../bootstrap/context.actions";
import * as versionActions from "../source-forms/version.actions";
import { formSetMap } from "../tax-forms/tax-return.helper";
import contextReducer from "../bootstrap/context.reducer";
import resolutionCaseReducer from "../resolution-cases/resolution-cases.reducer";
import reduxClientReducer from "../clients/redux-client.reducer";
import layoutReducer from "../source-forms/source-form-layout.reducer";
import sectionReducer from "../source-forms/sections/section.reducer";
import answerReducer from "../source-forms/answers/answer.reducer";
import versionReducer from "../source-forms/version.reducer";
import uiFormsReducer from "../source-forms/ui-forms.reducer";
import summaryTableReducer from "../source-forms/summary-table/summary-table.reducer";
import activeQuestionReducer from "../source-forms/questions/active-question.reducer";
import { updateSourceFormSectionAnswers } from "../source-forms/sections/source-form-sections.resource";
import blockStyles from "../source-forms/blocks/block.styles.css";

export default class QuickLookSectionContainer extends React.Component {
  state = {
    store: null,
  };

  componentDidMount() {
    const { loggedInUser, tenant } = this.props;

    const reducer = combineReducers({
      section: sectionReducer,
      answers: answerReducer,
      sourceFormLayout: layoutReducer,
      context: contextReducer,
      resolutionCase: resolutionCaseReducer,
      reduxClient: reduxClientReducer,
      smeVersion: versionReducer,
      uiForms: uiFormsReducer,
      summaryTable: summaryTableReducer,
      activeQuestion: activeQuestionReducer,
    });

    const store = createStore(reducer, applyMiddleware(thunk));
    store.dispatch(newLoggedInUser(loggedInUser));
    store.dispatch(newTenant(tenant));
    this.dispatchVersion(store);

    this.setState({ store });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.version &&
      (prevProps.version !== this.props.version ||
        prevProps.revision !== this.props.revision)
    ) {
      this.dispatchVersion();
    }
  }

  dispatchVersion(store = this.state.store) {
    store.dispatch(
      versionActions.newSmeVersion(this.props.version, this.props.revision)
    );
  }

  render() {
    const {
      clientId,
      resolutionCaseId,
      noticeId,
      sourceFormId,
      sectionId,
      folderIds,
      taskId,
      groupId,
      isQuickLookOpen,
      closeQuickLook,
    } = this.props;

    return (
      this.state.store && (
        <Provider store={this.state.store}>
          <QuickLookSection
            resolutionCaseId={resolutionCaseId}
            noticeId={noticeId}
            clientId={clientId}
            sourceFormId={sourceFormId}
            sectionId={sectionId}
            folderIds={folderIds}
            taskId={taskId}
            groupId={groupId}
            isQuickLookOpen={isQuickLookOpen}
            closeQuickLook={closeQuickLook}
          />
        </Provider>
      )
    );
  }
}

@Cancelable
@connect((state) => ({
  section: state.section.activeSection,
  answers: state.answers,
  sourceFormLayout: state.sourceFormLayout,
  resolutionCase: state.resolutionCase,
  loggedInUser: state.context.loggedInUser,
  tenant: state.context.tenant,
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
  uiFormsState: state.uiForms,
  summaryTable: state.summaryTable,
}))
class QuickLookSection extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actions = {
      ...bindActionCreators(uiFormsActions, dispatch),
      ...bindActionCreators(answerActions, dispatch),
      ...bindActionCreators(summaryTableActions, dispatch),
      ...bindActionCreators(sectionActions, dispatch),
    };

    this.state = {};
  }

  componentDidMount() {
    this.keyUpSubscription = fromEvent(document, "keyup")
      .pipe(
        filter(
          (evt) => evt.key === "Tab" && !this.container.contains(evt.target)
        )
      )
      .subscribe(
        () => this.props.isQuickLookOpen && this.props.closeQuickLook()
      );
  }

  componentWillUnmount() {
    this.keyUpSubscription && this.keyUpSubscription.unsubscribe();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isQuickLookOpen !== this.props.isQuickLookOpen &&
      this.props.isQuickLookOpen
    ) {
      this.props.cancelWhenUnmounted(
        updateSourceFormSectionAnswers({
          clientId: this.props.clientId,
          resolutionCaseId: this.props.resolutionCaseId,
          versionId: this.props.version,
          revisionId: this.props.revision,
          sourceFormId: this.props.sourceFormId,
          sectionId: this.props.sectionId,
          answers: {},
          groupId: this.props.groupId,
          noticeId: this.props.noticeId,
        }).subscribe((result) => {
          this.props.dispatch(answerActions.newAnswersRetrieved(result));
        })
      );
    }
  }

  setRef = (container) => {
    return (this.container = container);
  };

  render() {
    return this.props.section ? (
      <div ref={this.setRef}>
        {this.props.isQuickLookOpen && (
          <Section
            actions={{
              ...this.actions,
              updateAnswer: partial(this.actions.updateAnswer, {
                clientId: this.props.clientId,
                resolutionCaseId: this.props.resolutionCaseId,
                noticeId: this.props.noticeId,
                sourceFormId: this.props.sourceFormId,
                sectionId: this.props.sectionId,
                versionId: this.props.version ? this.props.version : null,
                revisionId: this.props.revision ? this.props.revision : null,
                answers: this.props.answers,
                groupId: this.props.groupId,
              }),
            }}
            scrollJump={this.props.scrollJump}
            answers={this.props.answers}
            clientId={this.props.clientId}
            formName={this.props.sourceFormLayout.name}
            resolutionCaseId={this.props.resolutionCaseId}
            noticeId={this.props.noticeId}
            sourceFormId={this.props.sourceFormId}
            taxFormId={this.props.sourceFormLayout.taxForm}
            activeSection={this.props.section}
            questionsToShowRequiredWarnings={
              this.props.questionsToShowRequiredWarnings
            }
            sections={this.props.sourceFormLayout.sections}
            summaryTable={this.props.summaryTable}
            alternateAnswerSets={null}
            overrideAnswerSet={null}
            version={this.props.version}
            revision={this.props.revision}
            folderIds={get(this.props, "folderIds").map(
              (val) => formSetMap[val]
            )}
            taskId={get(this.props, "taskId", null)}
            taxPrep={true}
          />
        )}
      </div>
    ) : (
      <div className={blockStyles.quickLookLoader}>
        <CpLoader />
      </div>
    );
  }
}
