import React from "react";
import PropTypes from "prop-types";
import { CpTooltip } from "canopy-styleguide!sofe";
import styles from "./report-utils.styles.css";

export default function Alert({ priority, title, description }) {
  return (
    <div className={`${styles.alertRow}`} data-testid="alert">
      <div className={`cps-slat-lg__badge cps-padding-top-24`}>
        <i
          data-testid={`flagColor_${priority}`}
          className={`cps-icon +round ${
            styles[`flagColor_${priority}`]
          } cps-icon-flag cps-white`}
        />
      </div>
      <div
        className={`cps-slat-lg__content`}
        style={{
          maxWidth: "calc(100% - 8.8rem)",
          overflow: "visible",
          whiteSpace: "normal",
        }}
      >
        <div className={`cps-slat-lg__content__title cps-color-primary-text`}>
          {title}
        </div>
        <CpTooltip text={description} delay={500}>
          <span
            style={{
              whiteSpace: "nowrap",
              color: "var(--cps-color-secondary-text)",
            }}
          >
            {description}
          </span>
        </CpTooltip>
      </div>
    </div>
  );
}

Alert.propTypes = {
  priority: PropTypes.oneOf(["danger", "warning", "info"]),
};
