const initialState = {};

export default function dynamicDataReducer(state = initialState, action) {
  switch (action.type) {
    case "SELECT":
      const sourceKey = `${action.payload.formId}-${action.payload.sectionId}`;
      return {
        ...state,
        [sourceKey]: { ...state[sourceKey], ...action.payload },
      };
    default:
      return state;
  }
}
