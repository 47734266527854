import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function sendResolutionCaseAnswer(requestObj, name, answer) {
  return fetchAsObservable(
    `/clients/${requestObj.clients}/resolution_cases/${requestObj.resolutionCases}/versions/${requestObj.versions}/answers`,
    {
      method: "POST",
      body: {
        answers: {
          [name]: answer,
        },
      },
    }
  ).pipe(pluck("answers"));
}

export function getResolutionCaseAnswers(requestObj, name, answer) {
  return fetchAsObservable(
    `/clients/${requestObj.clients}/resolution_cases/${requestObj.resolutionCases}/answers`
  ).pipe(pluck("answers"));
}
