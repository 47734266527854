import React from "react";
import { CpButton } from "canopy-styleguide!sofe";
import indexInSequence from "./sequence.helper.js";

export default class SequenceFilter extends React.Component {
  state = {
    isEditing: false,
    value: this.props.sequenceFilter,
  };

  componentDidUpdate(prevProps) {
    if (this.props.sequenceFilter !== prevProps.sequenceFilter) {
      this.setState({
        value: this.props.sequenceFilter,
      });
    }
  }

  render() {
    const { isEditing, value } = this.state;

    return isEditing ? (
      <div>
        <input
          className="cps-form-control"
          value={value}
          onChange={this.changeValue}
          type="text"
          autoComplete="off"
          data-testid="change-value-input"
        />
        <div style={{ marginTop: 8 }}>
          <CpButton btnType="flat" onClick={this.cancel}>
            Cancel
          </CpButton>
          <CpButton btnType="primary" onClick={this.apply}>
            Apply
          </CpButton>
        </div>
      </div>
    ) : (
      <a onClick={this.startEditing} data-testid="edit-sequence">
        {value ? `Sequence: ${value}` : "Edit form sequence"}
      </a>
    );
  }

  startEditing = () => this.setState({ isEditing: true });

  apply = () => {
    try {
      indexInSequence(0, this.state.value);
      this.props.onChange(this.state.value);
      this.setState({ isEditing: false });
    } catch (error) {
      // if there is an error parsing the value, ignore and don't save!
      this.cancel();
    }
  };

  cancel = () =>
    this.setState({
      value: this.props.sequenceFilter,
      isEditing: false,
    });

  changeValue = (e) => this.setState({ value: e.target.value });
}
