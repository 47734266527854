// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-analytics-collection-analytics-styles__topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 0;
}

.src-analytics-collection-analytics-styles__controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.src-analytics-collection-analytics-styles__loader {
  display: flex;
  justify-content: center;
  margin: 60px auto;
}

.src-analytics-collection-analytics-styles__loader span {
  width: 30px;
  height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/analytics/collection-analytics.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".topRow {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 24px;\n  border-bottom: 0;\n}\n\n.controls {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.loader {\n  display: flex;\n  justify-content: center;\n  margin: 60px auto;\n}\n\n.loader span {\n  width: 30px;\n  height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topRow": `src-analytics-collection-analytics-styles__topRow`,
	"controls": `src-analytics-collection-analytics-styles__controls`,
	"loader": `src-analytics-collection-analytics-styles__loader`
};
export default ___CSS_LOADER_EXPORT___;
