import * as types from "./section.types.js";
import { resetSourceFormLayout } from "../source-form-layout.actions.js";

export function updateSourceFormSection(section) {
  return {
    type: types.SOURCE_FORM_SECTION_UPDATED,
    section,
  };
}

export function resetSourceFormSection() {
  return (dispatch, getState) => {
    dispatch(updateSourceFormSection(null));
    dispatch(resetSourceFormLayout());
  };
}

export function scrollJumpFinished() {
  return {
    type: types.SCROLL_JUMP_FINISHED,
  };
}

export function setSectionAnswer(answer, mappings, section) {
  return {
    type: "SET_SECTION_ANSWER",
    payload: {
      answer,
      mappings,
      section,
    },
  };
}
