import React from "react";
import { isEmpty } from "lodash";
import { SUMMARY_TABLE_ANSWER } from "./summary-table.props.js";
import { updateAnswer } from "src/source-forms/answers/answer.actions.js";
import styles from "./summary-table.styles.css";

import SummaryTableBlocks from "./summary-table-blocks.component.js";
import Table from "./table.component.js";
import PasteColumnForm from "src/source-forms/bulk-insert/paste-column-form.component.js";
import { QuickLookDispatcher } from "src/source-forms/bulk-insert/quicklook-dispatcher.js";
import { containsTabularSupportedQuestion } from "src/source-forms/bulk-insert/clipboard-matrix-helper.js";
import { Scoped } from "kremling";

export default class SummaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pastepocalypseShow: false,
      showSummaryTableInScratchPad: false,
      editMode:
        !isEmpty(this.props.serverAnswer) &&
        this.props.serverAnswer.editingIndex,
    };
    this.buttonRef = React.createRef();
  }

  static propTypes = {
    answer: SUMMARY_TABLE_ANSWER,
  };

  componentDidMount() {
    setTimeout(this.props.sectionResize);

    if (this.props.blockIndex === 0 && this.props.questionIndex === 0) {
      this.props.setFirstInputRef(this.buttonRef.current);
    }
  }

  render() {
    const {
      summaryTableName,
      question,
      serverAnswer,
      sectionId,
      fullName,
      clientId,
      alternateAnswerSets,
      overrideAnswerSet,
      questionsToShowRequiredWarnings,
      actions,
      taxPrep,
      resolutionCaseId,
      noticeId,
      version,
      revision,
      dynamicData,
      questionsInSurroundingBlock,
      numBlocks,
      sectionResize,
      parentServerAnswers,
    } = this.props;

    const {
      summary: { columns, buttonText, questionText },
      blocks,
    } = question;
    //For the summary of the summary table we always want whatever the server has
    //There are no inputs to conflict with
    const { summary, answers, editingIndex } = serverAnswer || {};

    return (
      <QuickLookDispatcher.Consumer>
        {(context) => {
          const summaryTableIsVisible =
            numBlocks > 1 ||
            questionsInSurroundingBlock > 1 ||
            this.state.showSummaryTableInScratchPad ||
            !context;

          const isScratchPad =
            numBlocks === 1 &&
            questionsInSurroundingBlock === 1 &&
            context &&
            context.quickLookOpen;

          // isScratchPad prevents this from pastepocalypsisising on summary tables outside of quicklook
          const canAddTabularly =
            containsTabularSupportedQuestion(blocks) && isScratchPad;

          return (
            <>
              {summaryTableIsVisible && (
                <Scoped css={css}>
                  <div className="table-container">
                    <div
                      id={`summary-table-${question.name}`}
                      ref={(summaryTable) => (this.summaryTable = summaryTable)}
                      className={styles.wrapper}
                    >
                      <Table
                        context={context}
                        columns={columns}
                        summaryTableName={summaryTableName}
                        summary={summary}
                        serverAnswer={serverAnswer}
                        question={question}
                        fullName={fullName}
                        actions={actions}
                        blocks={blocks}
                        summaryRef={this.summaryTable}
                        questionText={questionText}
                        buttonText={buttonText}
                        editingIndex={editingIndex}
                        sectionResize={sectionResize}
                        canAddTabularly={canAddTabularly}
                        showTable={
                          isScratchPad
                            ? () =>
                                this.setState({
                                  showSummaryTableInScratchPad: false,
                                })
                            : this.toggleBulkSlider.bind(this, true)
                        }
                        setEditing={this.setEditing.bind(this)}
                      >
                        <SummaryTableBlocks
                          summaryRef={this.summaryTable}
                          parentName={summaryTableName}
                          blocks={blocks}
                          sectionResize={this.props.sectionResize}
                          serverAnswer={serverAnswer}
                          sectionId={sectionId}
                          parentQuestion={question}
                          clientId={clientId}
                          alternateAnswerSets={alternateAnswerSets}
                          overrideAnswerSet={overrideAnswerSet}
                          questionsToShowRequiredWarnings={
                            questionsToShowRequiredWarnings
                          }
                          actions={actions}
                          fullName={fullName}
                          taxPrep={taxPrep}
                          resolutionCaseId={resolutionCaseId}
                          noticeId={noticeId}
                          version={version}
                          revision={revision}
                          dynamicData={dynamicData}
                          isEditing={this.state.isEditing}
                          setEditing={this.setEditing.bind(this)}
                          parentServerAnswers={parentServerAnswers}
                        />
                      </Table>
                    </div>
                  </div>
                </Scoped>
              )}
              {canAddTabularly && !this.state.showSummaryTableInScratchPad && (
                <PasteColumnForm
                  toggle={this.toggleBulkSlider.bind(this)}
                  switchToSummaryTable={this.switchToSummaryTableView.bind(
                    this
                  )}
                  open={this.state.pastepocalypseShow}
                  updateAnswer={updateAnswer}
                  serverAnswer={serverAnswer}
                  fullName={fullName}
                  parentQuestion={question}
                  initialAnswers={
                    !isEmpty(answers)
                      ? answers.filter((a) => !a._isDeleted)
                      : answers
                  }
                  context={context}
                  isScratchPad={isScratchPad}
                  {...this.props}
                />
              )}
            </>
          );
        }}
      </QuickLookDispatcher.Consumer>
    );
  }

  setEditing(isEditing) {
    return this.setState({
      isEditing,
    });
  }

  switchToSummaryTableView() {
    this.setState({
      showSummaryTableInScratchPad: true,
      pastepocalypseShow: false,
    });
  }

  toggleBulkSlider(status) {
    this.setState({
      showSummaryTableInScratchPad: !status,
      pastepocalypseShow: status,
    });
  }
}

const css = `
& .table-container {
  background: var(--cps-color-bumble);
  border-radius: 4px;
  border: 1px solid var(--cps-color-silver);
  padding: 16px 16px 8px 16px;
  margin-top: 16px;
}

& table,
& .cps-card-table,
& .cps-card {
  border-color: transparent;
}

& .cps-card-table {
  border-bottom: 1px solid var(--cps-color-silver);
}

& th {
  color: var(--cps-color-primary-text);
  font-size: 13px;
  font-weight: 600;
  padding: 8px;
}`;
