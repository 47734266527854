import React from "react";
import { m } from "kremling";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

import { property, negate, partial } from "lodash";
import { getSectionById } from "./cssf.helpers.js";
import SectionHeaderForSourceForm from "src/source-forms/sections/section-header-for-source-form.component.js";
import Section from "src/source-forms/sections/section.component.js";
import SubsectionFooter from "src/source-forms/sections/subsection-footer.component.js";
import AsyncLoader from "src/common/async-loader.component.js";
import Subheader from "src/source-forms/sections/subheader.component.js";
import {
  SURVEY_ANSWER_SET,
  SURVEY_ANSWER_ORDER,
} from "./cssf-subsection.helpers.js";
import styles from "../../source-forms/sections/section.style.css";

export default function CSSF_Subsection(props) {
  const {
    uiForms,
    sourceFormLayout,
    subsection,
    answers,
    clientSurvey,
    actions,
    version,
    revision,
    questionsToShowRequiredWarnings,
    summaryTable,
    scrollJump,
    mobile,
    clientId,
    resolutionCaseId,
    baseUrl,
    isTaxPrep,
  } = props;
  const { sectionId } = props.match.params;
  const subsectionIndex = Number(props.match.params.subsectionIndex);
  const [user] = useWithUserAndTenant();

  const sectionSummary = getSectionById(sourceFormLayout.sections, sectionId);
  const sectionName = sectionSummary ? sectionSummary.name : "";

  const updateAnswer = partial(actions.updateAnswer, {
    clientId,
    resolutionCaseId,
    versionId: version.id,
    revisionId: revision.id,
    sourceFormId: sourceFormLayout.formId,
    sectionId,
    subsectionIndex,
    answerSet: SURVEY_ANSWER_SET,
    answerOrder: SURVEY_ANSWER_ORDER,
  });

  const showHeader =
    (uiForms.cardLeft && !mobile) || props.displayingInClientPortal;

  return (
    <div
      className={m("cps-fixed-focus", !mobile).a(`${styles.subsectionWrapper}`)}
    >
      {showHeader ? (
        <SectionHeaderForSourceForm
          hasTopnavSecondary={user?.role !== "Client"}
          width={uiForms.cardWidth}
          left={uiForms.cardLeft}
          formName={sectionName}
          sectionMessage={uiForms.sectionMessage}
          displayingInClientPortal={props.displayingInClientPortal}
        />
      ) : null}
      <div className={`cps-card +thin`}>
        {showHeader ? (
          <Subheader
            name={subsection?.name}
            link={subsection?.link}
            linkIcon={subsection?.link_icon}
            linkText={subsection?.link_text}
          />
        ) : null}
        <AsyncLoader
          asyncChecks={asyncChecks}
          customStyle={{
            height: "66px",
          }}
          {...props}
        >
          <Section
            activeSection={subsection}
            sections={sourceFormLayout.sections}
            clientId={clientId}
            resolutionCaseId={resolutionCaseId}
            sourceFormId={clientSurvey.clientSurveySourceFormId}
            taxFormId={null}
            answers={answers}
            summaryTable={summaryTable}
            formName={sourceFormLayout.name}
            scrollJump={scrollJump}
            actions={{
              ...actions,
              updateAnswer,
            }}
            questionsToShowRequiredWarnings={questionsToShowRequiredWarnings}
          />
          {!mobile ? (
            <SubsectionFooter
              urlPrefix={`${baseUrl}`}
              displayingInClientPortal={props.displayingInClientPortal}
              sections={sourceFormLayout.sections}
              sectionId={sectionId}
              subsectionIndex={subsectionIndex}
              actions={actions}
              isTaxPrep={isTaxPrep}
            />
          ) : null}
        </AsyncLoader>
      </div>
    </div>
  );
}

export const asyncChecks = [
  property("clientSurvey.clientSurveySourceFormId"),
  negate(property("subsection.isDefaultSection")),
  (props) => {
    if (
      props.sourceFormLayout &&
      props.sourceFormLayout.sections.length &&
      props.subsection
    ) {
      const { sections } = props.sourceFormLayout;
      const section = sections.find((section, index) => {
        if (props.match.params.sectionId === "0") return index === 0;
        return props.match.params.sectionId === section.id;
      });
      return (
        section.subSections[props.match.params.subsectionIndex].name ===
        props.subsection.name
      );
    }
  },
];
