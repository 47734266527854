// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-source-forms-blocks-block-styles__blockName {
  width: 33%;
  right: 0;
  padding: 0 0 1.6rem 2.4rem !important;
  text-align: right;
  color: var(--cps-color-primary-text);
  font-weight: 600;
}

@media (min-width: 1024px) {
  .src-source-forms-blocks-block-styles__blockName {
    width: 20%;
  }
}

.src-source-forms-blocks-block-styles__block {
  padding-top: 0rem;
}

.src-source-forms-blocks-block-styles__block:first-child {
  padding-top: 0;
}

.src-source-forms-blocks-block-styles__loaderWrapper {
  padding: 40px;
}

.src-source-forms-blocks-block-styles__loader {
  position: relative;
  left: calc(50% - 48px);
  top: -4px;
}

.src-source-forms-blocks-block-styles__blockContent {
  padding-right: 24px;
}

.src-source-forms-blocks-block-styles__anotherDataSource {
  padding-left: 8px;
}

.src-source-forms-blocks-block-styles__noOtherDataSource {
  padding-left: 24px;
}

.src-source-forms-blocks-block-styles__sectionNotLoaded {
  display: none;
}

.src-source-forms-blocks-block-styles__quickLookLoader {
  padding: 100px 0;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/source-forms/blocks/block.styles.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,QAAQ;EACR,qCAAqC;EACrC,iBAAiB;EACjB,oCAAoC;EACpC,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".blockName {\n  width: 33%;\n  right: 0;\n  padding: 0 0 1.6rem 2.4rem !important;\n  text-align: right;\n  color: var(--cps-color-primary-text);\n  font-weight: 600;\n}\n\n@media (min-width: 1024px) {\n  .blockName {\n    width: 20%;\n  }\n}\n\n.block {\n  padding-top: 0rem;\n}\n\n.block:first-child {\n  padding-top: 0;\n}\n\n.loaderWrapper {\n  padding: 40px;\n}\n\n.loader {\n  position: relative;\n  left: calc(50% - 48px);\n  top: -4px;\n}\n\n.blockContent {\n  padding-right: 24px;\n}\n\n.anotherDataSource {\n  padding-left: 8px;\n}\n\n.noOtherDataSource {\n  padding-left: 24px;\n}\n\n.sectionNotLoaded {\n  display: none;\n}\n\n.quickLookLoader {\n  padding: 100px 0;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockName": `src-source-forms-blocks-block-styles__blockName`,
	"block": `src-source-forms-blocks-block-styles__block`,
	"loaderWrapper": `src-source-forms-blocks-block-styles__loaderWrapper`,
	"loader": `src-source-forms-blocks-block-styles__loader`,
	"blockContent": `src-source-forms-blocks-block-styles__blockContent`,
	"anotherDataSource": `src-source-forms-blocks-block-styles__anotherDataSource`,
	"noOtherDataSource": `src-source-forms-blocks-block-styles__noOtherDataSource`,
	"sectionNotLoaded": `src-source-forms-blocks-block-styles__sectionNotLoaded`,
	"quickLookLoader": `src-source-forms-blocks-block-styles__quickLookLoader`
};
export default ___CSS_LOADER_EXPORT___;
