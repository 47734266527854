// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-create-survey-get-started-styles__getStarted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify: center;
  padding: 32px 40px;
}

.src-client-survey-create-survey-get-started-styles__icon {
  /*Manual Green*/
  border: 2px solid var(--cps-color-primary);
  border-radius: 24px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.src-client-survey-create-survey-get-started-styles__textBody {
  text-align: center;
  font-size: 13px;
  line-height: 24px;
  width: 100%; /* Needed for IE11. See https://canopytax.atlassian.net/browse/TRFORMS-562 */
}

.src-client-survey-create-survey-get-started-styles__getStarted > .src-client-survey-create-survey-get-started-styles__skipThis {
  margin-left: 0; /* styleguide has a pretty specific selector that needs to be overwritten */
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/create-survey/get-started.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,0CAA0C;EAC1C,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,WAAW,EAAE,4EAA4E;AAC3F;;AAEA;EACE,cAAc,EAAE,2EAA2E;AAC7F","sourcesContent":[".getStarted {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify: center;\n  padding: 32px 40px;\n}\n\n.icon {\n  /*Manual Green*/\n  border: 2px solid var(--cps-color-primary);\n  border-radius: 24px;\n  width: 48px;\n  height: 48px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.textBody {\n  text-align: center;\n  font-size: 13px;\n  line-height: 24px;\n  width: 100%; /* Needed for IE11. See https://canopytax.atlassian.net/browse/TRFORMS-562 */\n}\n\n.getStarted > .skipThis {\n  margin-left: 0; /* styleguide has a pretty specific selector that needs to be overwritten */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"getStarted": `src-client-survey-create-survey-get-started-styles__getStarted`,
	"icon": `src-client-survey-create-survey-get-started-styles__icon`,
	"textBody": `src-client-survey-create-survey-get-started-styles__textBody`,
	"skipThis": `src-client-survey-create-survey-get-started-styles__skipThis`
};
export default ___CSS_LOADER_EXPORT___;
