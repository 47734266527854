// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-source-forms-summary-table-summary-table-styles__wrapper {
  margin: 16px 0 0 0;
}

.src-source-forms-summary-table-summary-table-styles__actionBlock {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.src-source-forms-summary-table-summary-table-styles__tableWrapper {
  box-shadow: none;
  border: 1px solid var(--cps-color-silver);
  overflow: auto;
}

.src-source-forms-summary-table-summary-table-styles__tableWrapper td {
  white-space: nowrap;
}

.src-source-forms-summary-table-summary-table-styles__tableWrapper::-webkit-scrollbar {
  -webkit-appearance: none;
}

.src-source-forms-summary-table-summary-table-styles__tableWrapper::-webkit-scrollbar:vertical {
  width: 11px;
}

.src-source-forms-summary-table-summary-table-styles__tableWrapper::-webkit-scrollbar:horizontal {
  height: 11px;
}

.src-source-forms-summary-table-summary-table-styles__tableWrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid var(--cps-color-bumble); /* should match background, can't be transparent */
  background: var(--cps-color-silver);
}

.src-source-forms-summary-table-summary-table-styles__tableWrapper::-webkit-scrollbar-thumb:hover {
  background: var(--cps-color-athens);
}

.src-source-forms-summary-table-summary-table-styles__tableWrapper::-webkit-scrollbar-track {
  background-color: var(--cps-color-bumble);
  border-radius: 8px;
}

.src-source-forms-summary-table-summary-table-styles__deletedRow {
  background-color: var(--cps-color-chrome);
}
`, "",{"version":3,"sources":["webpack://./src/source-forms/summary-table/summary-table.styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,yCAAyC;EACzC,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,yCAAyC,EAAE,kDAAkD;EAC7F,mCAAmC;AACrC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[":local(.wrapper) {\n  margin: 16px 0 0 0;\n}\n\n:local(.actionBlock) {\n  margin-top: 8px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n:local(.tableWrapper) {\n  box-shadow: none;\n  border: 1px solid var(--cps-color-silver);\n  overflow: auto;\n}\n\n:global :local(.tableWrapper) td {\n  white-space: nowrap;\n}\n\n:local(.tableWrapper)::-webkit-scrollbar {\n  -webkit-appearance: none;\n}\n\n:local(.tableWrapper)::-webkit-scrollbar:vertical {\n  width: 11px;\n}\n\n:local(.tableWrapper)::-webkit-scrollbar:horizontal {\n  height: 11px;\n}\n\n:local(.tableWrapper)::-webkit-scrollbar-thumb {\n  border-radius: 8px;\n  border: 2px solid var(--cps-color-bumble); /* should match background, can't be transparent */\n  background: var(--cps-color-silver);\n}\n\n:local(.tableWrapper)::-webkit-scrollbar-thumb:hover {\n  background: var(--cps-color-athens);\n}\n\n:local(.tableWrapper)::-webkit-scrollbar-track {\n  background-color: var(--cps-color-bumble);\n  border-radius: 8px;\n}\n\n.deletedRow {\n  background-color: var(--cps-color-chrome);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `src-source-forms-summary-table-summary-table-styles__wrapper`,
	"actionBlock": `src-source-forms-summary-table-summary-table-styles__actionBlock`,
	"tableWrapper": `src-source-forms-summary-table-summary-table-styles__tableWrapper`,
	"deletedRow": `src-source-forms-summary-table-summary-table-styles__deletedRow`
};
export default ___CSS_LOADER_EXPORT___;
