import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function getSvgPages(
  clientId,
  resolutionCaseId,
  versionId,
  revisionId,
  taxFormId,
  noticeId
) {
  let url = `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${versionId}-${revisionId}/tax-forms/${taxFormId}/svg-pages`;

  if (noticeId) {
    url = `/clients/${clientId}/notices/${noticeId}/versions/${versionId}-${revisionId}/tax-forms/${taxFormId}/svg-pages`;
  }

  return fetchAsObservable(url).pipe(pluck("svg-pages"));
}
