import React from "react";
import styles from "./selectable-card.style.css";

export default function SelectableCard(props) {
  const { iconName, title, description, isChecked, clickAction, marginBottom } =
    props;

  return (
    <div
      onClick={clickAction}
      className={`${styles.card} ${isChecked ? styles.cardChecked : ""}`}
      style={{ marginBottom: marginBottom || 0 }}
    >
      <div className={`${styles.grid}`}>
        <div className={`${styles.gridLeft}`}>
          <i className={`cps-icon ${iconName} ${styles.icon}`} />
          <div>
            <div className={`cps-wt-bold`}>{title}</div>
            <div className={`${styles.description}`}>{description}</div>
          </div>
        </div>
        {isChecked && (
          <i className={`cps-icon cps-icon-lg-check ${styles.checkmark}`} />
        )}
      </div>
    </div>
  );
}
