import React from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import Cancelable from "react-disposable-decorator";
import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";

import { UserTenantProps } from "cp-client-auth!sofe";
import { ErrorBoundary } from "error-logging!sofe";
import { redirectOrCatch } from "fetcher!sofe";
import { CpLoader } from "canopy-styleguide!sofe";

import ClientMenu from "./clients/client-menu.component.js";
import ResolutionCaseWrapper from "./resolution-cases/resolution-case-wrapper.component.js";
import SourceFormForTaxForm from "./source-forms/source-form-for-tax-form.component.js";
import TaxFormForSourceForm from "./tax-forms/tax-form-for-source-form.component.js";
import CollectionAnalytics from "./analytics/collection-analytics-wrapper.component.js";
import ClientPortalSurveysList from "./client-survey/client-portal/client-portal-surveys.wrapper.js";
import { ClientSurvey } from "./client-survey/client-survey.component.js";
import TaxReturn from "./tax-forms/tax-return.component.js";

import contextReducer from "./bootstrap/context.reducer.js";
import resolutionCaseReducer from "./resolution-cases/resolution-cases.reducer.js";
import reduxClientReducer from "./clients/redux-client.reducer.js";
import layoutReducer from "./source-forms/source-form-layout.reducer.js";
import sectionReducer from "./source-forms/sections/section.reducer.js";
import answerReducer from "./source-forms/answers/answer.reducer.js";
import versionReducer from "./source-forms/version.reducer.js";
import uiFormsReducer from "./source-forms/ui-forms.reducer.js";
import summaryTableReducer from "./source-forms/summary-table/summary-table.reducer.js";
import dynamicDataReducer from "src/source-forms/dynamic-data.reducer.js";
import reportReducer from "./analytics/collection-analytics.reducer.js";
import resolutionCaseProgramSectionReducer from "./resolution-cases/program-sections/program-section.reducer.js";
import clientSurveyReducer from "./client-survey/client-survey.reducer.js";
import activeQuestionReducer from "./source-forms/questions/active-question.reducer.js";
import resolutionCaseAnswerOrderReducer from "./resolution-cases/answer-order/answer-order.reducer.js";

import { newLoggedInUser, newTenant } from "./bootstrap/context.actions.js";
import { prepSentryWithReduxState } from "./error-handling/sentry-redux-state.js";
import { getClientById } from "./clients/client.resource.js";

let store;

export function initialize(loggedInUser, tenant) {
  const reducer = combineReducers({
    section: sectionReducer,
    answers: answerReducer,
    sourceFormLayout: layoutReducer,
    context: contextReducer,
    resolutionCase: resolutionCaseReducer,
    reduxClient: reduxClientReducer,
    resolutionCaseProgramSection: resolutionCaseProgramSectionReducer,
    smeVersion: versionReducer,
    uiForms: uiFormsReducer,
    summaryTable: summaryTableReducer,
    analytics: reportReducer,
    clientSurvey: clientSurveyReducer,
    activeQuestion: activeQuestionReducer,
    resolutionCaseAnswerOrder: resolutionCaseAnswerOrderReducer,
    dynamicData: dynamicDataReducer,
  });

  store = createStore(reducer, applyMiddleware(thunk));

  // Always log the redux state with all toast errors
  const originalOnError = window.onerror;
  window.onerror = function () {
    prepSentryWithReduxState(store);
    if (originalOnError) return originalOnError.apply(this, arguments);
  };

  store.dispatch(newLoggedInUser(loggedInUser));

  store.dispatch(newTenant(tenant));
}

@ErrorBoundary({ featureName: "end-user-forms-ui" })
@UserTenantProps({
  permissions: {
    hasResolutionCases: "tasks_resolution_cases",
    hasAllClients: "clients_not_assigned",
  },
  waitForData: true,
})
@Cancelable
export default class Root extends React.Component {
  constructor() {
    super();
    this.state = {
      clientLoaded: false,
      hasAccessToClient: false,
    };
  }

  componentDidMount() {
    let clientId = window.location.hash
      .split("clients/")[1]
      .split("/resolution-cases")[0];
    if (
      !this.props.permissions.hasAllClients &&
      this.props.loggedInUser.role !== "Client" &&
      this.props.permissions.hasResolutionCases &&
      clientId
    ) {
      this.props.cancelWhenUnmounted(
        getClientById(clientId).subscribe((client) => {
          this.setState({ hasAccessToClient: !!client, clientLoaded: true });
        }, redirectOrCatch())
      );
    } else {
      this.setState({ clientLoaded: true });
    }
  }

  render() {
    if (window.location.href.includes("engagements")) {
      // Remove this when we add engagements routes
      window.location.replace(
        window.location.href.replace("engagements", "resolution-cases")
      );
    }
    return (
      <Provider store={store}>
        {this.state.clientLoaded ? (
          <Router>
            <Route
              path="/forms/clients/:clientId"
              render={(routeProps) => (
                <ClientMenu {...routeProps}>
                  <>
                    <Route
                      path={`/forms/clients/:clientId/surveys`}
                      component={ClientPortalSurveysList}
                    />
                    <Route
                      path={`/forms/clients/:clientId/resolution-cases/:resolutionCaseId`}
                      render={(routeProps) => (
                        <ResolutionCaseWrapper {...routeProps}>
                          <>
                            {this.props.loggedInUser.role !== "Client" &&
                              !this.props.permissions.hasResolutionCases &&
                              !this.props.hasAccessToClient && (
                                <Redirect to="/403" />
                              )}
                            <Route
                              path="/forms/clients/:clientId/resolution-cases/:resolutionCaseId/sourceforms/:sourceFormId/sections/:sectionId"
                              render={(routeProps) => (
                                <SourceFormForTaxForm {...routeProps} />
                              )}
                              mountParcel={this.props.mountParcel}
                            />
                            <Route
                              path="/forms/clients/:clientId/resolution-cases/:resolutionCaseId/sourceforms/:sourceFormId/taxforms/:taxFormId"
                              component={TaxFormForSourceForm}
                            />
                            <Route
                              path="/forms/clients/:clientId/resolution-cases/:resolutionCaseId/sourceforms/:sourceFormId/tax-prep/:formSet"
                              component={TaxReturn}
                            />
                            <Route
                              path="/forms/clients/:clientId/resolution-cases/:resolutionCaseId/collections/analytics/:report"
                              component={CollectionAnalytics}
                            />
                            <Route
                              path="/forms/clients/:clientId/resolution-cases/:resolutionCaseId/survey"
                              render={(props) => (
                                <ClientSurvey
                                  {...props}
                                  loggedInUser={this.props.loggedInUser}
                                  surveyType="taxRes"
                                  routerType={ClientSurvey.HASH}
                                  baseUrl={`/forms/clients/${props.match.params.clientId}/resolution-cases/${props.match.params.resolutionCaseId}/survey`}
                                  clientId={props.match.params.clientId}
                                  resolutionCaseId={
                                    props.match.params.resolutionCaseId
                                  }
                                />
                              )}
                            />
                          </>
                        </ResolutionCaseWrapper>
                      )}
                    />
                  </>
                </ClientMenu>
              )}
            />
          </Router>
        ) : (
          <CpLoader />
        )}
      </Provider>
    );
  }
}
