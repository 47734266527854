// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-tax-forms-tax-form-preview-style__rootEl {
  margin-top: 6.1rem;
}

.src-tax-forms-tax-form-preview-style__image {
  width: 100%;
}

.src-tax-forms-tax-form-preview-style__page {
  margin-bottom: 1.6rem;
  position: relative;
  height: 100%;
}

.src-tax-forms-tax-form-preview-style__loading {
  font-size: 1.6rem;
  font-weight: bold;

  height: 112.847rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.src-tax-forms-tax-form-preview-style__pageError {
  width: 100%;
  height: 112.847rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.src-tax-forms-tax-form-preview-style__taxFormHeaderSkirt {
  position: absolute;
  bottom: -0.4rem;
  height: 0.7rem;
  left: 0;
  width: 100%;
  background: var(--cps-color-bumble);
  border-bottom: 1px solid var(--cps-color-silver);
}

.src-tax-forms-tax-form-preview-style__formIcons {
  display: flex;
  align-items: center;
}

.src-tax-forms-tax-form-preview-style__caseActivated {
  font-style: italic;
  margin-right: 2.4rem;
}

.src-tax-forms-tax-form-preview-style__saveToCanopyFilesIcon {
  color: var(--cps-color-secondary-text);
}

.src-tax-forms-tax-form-preview-style__remainingCases {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/tax-forms/tax-form-preview.style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;;EAEjB,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,OAAO;EACP,WAAW;EACX,mCAAmC;EACnC,gDAAgD;AAClD;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[":local(.rootEl) {\n  margin-top: 6.1rem;\n}\n\n:local(.image) {\n  width: 100%;\n}\n\n:local(.page) {\n  margin-bottom: 1.6rem;\n  position: relative;\n  height: 100%;\n}\n\n:local(.loading) {\n  font-size: 1.6rem;\n  font-weight: bold;\n\n  height: 112.847rem;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n:local(.pageError) {\n  width: 100%;\n  height: 112.847rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n:local(.taxFormHeaderSkirt) {\n  position: absolute;\n  bottom: -0.4rem;\n  height: 0.7rem;\n  left: 0;\n  width: 100%;\n  background: var(--cps-color-bumble);\n  border-bottom: 1px solid var(--cps-color-silver);\n}\n\n.formIcons {\n  display: flex;\n  align-items: center;\n}\n\n.caseActivated {\n  font-style: italic;\n  margin-right: 2.4rem;\n}\n\n.saveToCanopyFilesIcon {\n  color: var(--cps-color-secondary-text);\n}\n\n.remainingCases {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootEl": `src-tax-forms-tax-form-preview-style__rootEl`,
	"image": `src-tax-forms-tax-form-preview-style__image`,
	"page": `src-tax-forms-tax-form-preview-style__page`,
	"loading": `src-tax-forms-tax-form-preview-style__loading`,
	"pageError": `src-tax-forms-tax-form-preview-style__pageError`,
	"taxFormHeaderSkirt": `src-tax-forms-tax-form-preview-style__taxFormHeaderSkirt`,
	"formIcons": `src-tax-forms-tax-form-preview-style__formIcons`,
	"caseActivated": `src-tax-forms-tax-form-preview-style__caseActivated`,
	"saveToCanopyFilesIcon": `src-tax-forms-tax-form-preview-style__saveToCanopyFilesIcon`,
	"remainingCases": `src-tax-forms-tax-form-preview-style__remainingCases`
};
export default ___CSS_LOADER_EXPORT___;
