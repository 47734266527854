import React from "react";
import { get } from "lodash";
import * as sentryErrorLogging from "error-logging!sofe";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";

import SingleLineText from "../inputs/single-line-text.component.js";
import SSN from "../inputs/ssn.component.js";
import SelectList from "../inputs/select-list.component.js";
import Radio from "../inputs/radio.component.js";
import State from "../inputs/state.component.js";
import Country from "../inputs/country.component.js";
import Checkbox from "../inputs/checkbox.component.js";
import DateComp from "../inputs/date.component.js";
import NumberComp from "../inputs/number.component.js";
import Percent from "../inputs/percent.component.js";
import EIN from "../inputs/ein.component.js";
import CAF from "../inputs/caf.component.js";
import Currency from "../inputs/currency.component.js";
import PhoneNumber from "../inputs/phone-number.component.js";
import ZipCode from "../inputs/zip-code.component.js";
import Paragraph from "../inputs/paragraph.component.js";
import County from "../inputs/county.component.js";
import PillBox from "../inputs/pillbox.component.js";
import NotFound from "../inputs/not-found.component.js";
import Practitioner from "../inputs/practitioner.component.js";
import AnswerSetViewer from "../questions/answer-set-viewer.component.js";
import CalculatedValue from "../inputs/calculated-value.component.js";
import Uri from "../inputs/uri.component.js";
import Decimal from "../inputs/decimal.component.js";
import OtherCountries from "../inputs/various-countries.component.js";
import FileUpload from "../inputs/file-upload.component.js";
import SpecialAllocationsButton from "../../special-allocations/special-allocations-button.component";

import styles from "./question.styles.css";
import { isRequired } from "./required.helpers.js";
import { propsAreDifferent } from "src/common/perf.helpers.js";
import { resolveDynamicData } from "src/source-forms/questions/dynamic-dropdown.helpers.js";

export const questionMetaTypeMap = {
  SINGLE_LINE_TEXT: SingleLineText,
  SSN: SSN,
  SELECT_LIST: SelectList,
  MULTIPLE_CHOICE: Radio,
  STATE: State,
  COUNTRY: Country,
  CHECKBOX: Checkbox,
  DATE: DateComp,
  NUMBER: NumberComp,
  PERCENTAGE: Percent,
  EIN: EIN,
  CAF: CAF,
  AMOUNT: Currency,
  PHONE_NUMBER: PhoneNumber,
  ZIP: ZipCode,
  PARAGRAPH_TEXT: Paragraph,
  COUNTY: County,
  PILLBOX: PillBox,
  PRACTITIONER: Practitioner,
  CALCULATED_VALUE: CalculatedValue,
  URI: Uri,
  DECIMAL: Decimal,
  OTHER_COUNTRIES: OtherCountries,
  FILE_UPLOAD: FileUpload,
  SPECIAL_ALLOCATIONS: SpecialAllocationsButton,
};

export default class Question extends React.Component {
  state = {
    valid: true,
  };

  inputRef = React.createRef();

  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = propsAreDifferent(nextProps, this.props, [
      "question.visible",
      "question.isOverridden",
      "alternateAnswerSets",
      "clientId",
      "blockIndex",
      "overrideAnswerSet",
      "parentName",
      "questionIndex",
      "sectionId",
      "serverAnswer",
      "reduxAnswer",
      "question.meta.stateReference",
      "dynamicData",
      "sourceFormId",
      "actions.updateAnswer",
    ]);

    if (
      nextState.valid !== this.state.valid ||
      this.props.question.meta.stateReference
    )
      return true;

    if (propsChanged) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.props.questionIndex === 0) {
      this.props.setFirstInputRef(this.inputRef.current);
    }
  }

  render() {
    const { props } = this;
    const { question, actions, summaryTableIndex } = props;

    const Input =
      questionMetaTypeMap[question.meta.type] ||
      questionMetaTypeMap[question.type] ||
      NotFound;

    const showAnswerSetViewer =
      props.alternateAnswerSets && props.overrideAnswerSet;
    const showRequiredWarning = !this.state.valid;

    const fancyUpdateAnswer = (...args) => {
      actions.updateAnswer.call(this, props.serverAnswer, ...args);
    };
    return props.noLabel ? (
      this.getInput(Input, fancyUpdateAnswer)
    ) : (
      <div
        className={`cps-form-group ${styles.formGroup} ${
          showAnswerSetViewer ? styles.formGroupWithAnswerSet : ""
        } ${showRequiredWarning ? "cps-has-error" : ""}`}
      >
        <div className={`${styles.answerSetButtonIcon}`}>
          <AnswerSetViewer
            question={question}
            overrideAnswerSet={props.overrideAnswerSet}
            alternateAnswerSets={props.alternateAnswerSets}
            actions={{
              ...actions,
              updateAnswer: fancyUpdateAnswer,
            }}
          />
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", paddingBottom: ".2rem" }}
            htmlFor={question.meta.question}
          >
            {isRequired(question) && (
              <span>
                <span
                  className="cps-input-required"
                  style={{ fontSize: "16px", lineHeight: "12px" }}
                />
                &nbsp;
              </span>
            )}
            {question.meta.question || question.label || question.name}
            {!!question.meta.description?.length && (
              <CpTooltip text={question.meta.description}>
                <CpIcon
                  name="information-circle-filled-small"
                  fill="var(--cp-color-app-inactive-text)"
                  style={{ marginTop: "-2px" }}
                />
              </CpTooltip>
            )}
          </label>
          {this.getInput(Input, fancyUpdateAnswer, summaryTableIndex)}
        </div>
      </div>
    );
  }

  getInput = (Input, fancyUpdateAnswer, summaryTableIndex) => {
    const answer = resolveDynamicData(this.props, this.props.serverAnswer);
    const { parentServerAnswers, question } = this.props;
    const characterLimit = get(question, "meta.characterLimit");
    const roundCurrency = get(question, "meta.roundCurrency");
    let xsdType = get(question, "meta.xsdType");
    if (xsdType) {
      try {
        xsdType = JSON.parse(xsdType);
      } catch (err) {
        sentryErrorLogging.captureException(err);
      }
    }

    if (answer !== this.props.serverAnswer && !this.props.serverAnswer) {
      fancyUpdateAnswer(this.props.question, answer);
    }

    return (
      <div className={`${styles.inputBox}`}>
        <Input
          {...this.props}
          ref={this.inputRef}
          setValid={this.setValid}
          setInvalid={this.setInvalid}
          answer={answer}
          updateAnswer={fancyUpdateAnswer}
          summaryTableIndex={summaryTableIndex}
          autoComplete="off"
          parentServerAnswers={parentServerAnswers}
          roundCurrency={roundCurrency}
          characterLimit={characterLimit}
          xsdType={xsdType}
        />
      </div>
    );
  };

  setValid = () => this.setState({ valid: true });
  setInvalid = () => this.setState({ valid: false });
}
