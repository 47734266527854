import React from "react";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { isNumber, isString, isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import { CpButton } from "canopy-styleguide!sofe";
import { connect } from "react-redux";

import styles from "./subsection-footer.style.css";
import { handleValidate } from "../questions/required.helpers.js";

import {
  getNextSectionUrl,
  getPrevSectionUrl,
  id,
  hasPrevious,
  hasNext,
} from "./section.helper.js";

export { SubsectionFooter };

const SubsectionFooter = connect((state) => ({
  sections: state.sourceFormLayout.sections,
}))(_SubsectionFooter);

export function _SubsectionFooter(props) {
  let { urlPrefix, sections, sectionId, subsectionIndex, isTaxPrep } = props;

  if (sectionId === "0") {
    if (!sections || sections.length <= 0) {
      return null;
    }
    sectionId = sections[0].id;
  }

  if (!isString(sectionId) || isEmpty(sectionId)) {
    throw new Error(`sectionId must be a non-empty string`);
  }

  const section = sections.find(id(sectionId));
  const sectionIndex = sections.findIndex(id(sectionId));
  if (!section) return null;

  if (!isNumber(subsectionIndex)) {
    throw new Error(`subsectionIndex must be a number`);
  }
  const hasNextSection = hasNext(sections, sectionIndex, subsectionIndex);
  const isClient = window.loggedInUser.role === "Client";
  const previousHref = () =>
    `${urlPrefix}${getPrevSectionUrl(sections, sectionId, subsectionIndex)}`;
  const nextHref = () =>
    `${urlPrefix}${getNextSectionUrl(sections, sectionId, subsectionIndex)}`;
  const lastSection = isTaxPrep ? `${urlPrefix}/upload` : `${urlPrefix}/submit`;

  return (
    <div
      className={`${styles.root} cps-card__footer`}
      style={{ display: "flex", justifyContent: "center" }}
    >
      {hasPrevious(sections, sectionIndex, subsectionIndex) && (
        <CpButton
          btnType="secondary"
          onClick={(e) =>
            handleValidate(e, () => props.history.push(previousHref()))
          }
        >
          Previous section
        </CpButton>
      )}
      {hasNextSection ? (
        <CpButton
          className={`cp-ml-24`}
          onClick={(e) =>
            handleValidate(e, () => props.history.push(nextHref()))
          }
        >
          Next section
        </CpButton>
      ) : (
        isClient && (
          <CpButton
            className={`cp-ml-24`}
            onClick={(e) =>
              handleValidate(e, () => props.history.push(lastSection))
            }
          >
            Next section
          </CpButton>
        )
      )}
    </div>
  );
}

export default withRouter(SubsectionFooter);
