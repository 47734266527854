// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-client-portal-client-portal-welcome-styles__welcomeWrapper {
  margin-left: 21px;
}

.src-client-survey-client-portal-client-portal-welcome-styles__notification {
  padding: 40px;
}

.src-client-survey-client-portal-client-portal-welcome-styles__welcomeText {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 24px;
}

.src-client-survey-client-portal-client-portal-welcome-styles__messageText {
  font-size: 16px;
  color: var(--cps-color-primary-text);
  line-height: 24px;
}

.src-client-survey-client-portal-client-portal-welcome-styles__body {
  margin-bottom: 24px;
  font-family: inherit;
}

.src-client-survey-client-portal-client-portal-welcome-styles__closing {
  font-weight: bold;
  font-style: italic;
}

.src-client-survey-client-portal-client-portal-welcome-styles__footer {
  height: 64px;
  border-top: 1px solid var(--cps-color-silver);
  background-color: var(--cps-color-ash);
  display: flex;
  justify-content: center;
  align-items: center;
}

.--responsive .src-client-survey-client-portal-client-portal-welcome-styles__welcomeWrapper {
  width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/client-portal/client-portal-welcome.styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,oCAAoC;EACpC,iBAAiB;AACnB;;AAEA;EAEE,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EAEE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,6CAA6C;EAC7C,sCAAsC;EACtC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".welcomeWrapper {\n  margin-left: 21px;\n}\n\n.notification {\n  padding: 40px;\n}\n\n.welcomeText {\n  font-weight: bold;\n  font-size: 24px;\n  margin-bottom: 24px;\n}\n\n.messageText {\n  font-size: 16px;\n  color: var(--cps-color-primary-text);\n  line-height: 24px;\n}\n\n.body {\n  composes: messageText;\n  margin-bottom: 24px;\n  font-family: inherit;\n}\n\n.closing {\n  composes: messageText;\n  font-weight: bold;\n  font-style: italic;\n}\n\n.footer {\n  height: 64px;\n  border-top: 1px solid var(--cps-color-silver);\n  background-color: var(--cps-color-ash);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n:global(.--responsive) .welcomeWrapper {\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"welcomeWrapper": `src-client-survey-client-portal-client-portal-welcome-styles__welcomeWrapper`,
	"notification": `src-client-survey-client-portal-client-portal-welcome-styles__notification`,
	"welcomeText": `src-client-survey-client-portal-client-portal-welcome-styles__welcomeText`,
	"messageText": `src-client-survey-client-portal-client-portal-welcome-styles__messageText`,
	"body": `src-client-survey-client-portal-client-portal-welcome-styles__body src-client-survey-client-portal-client-portal-welcome-styles__messageText`,
	"closing": `src-client-survey-client-portal-client-portal-welcome-styles__closing src-client-survey-client-portal-client-portal-welcome-styles__messageText`,
	"footer": `src-client-survey-client-portal-client-portal-welcome-styles__footer`
};
export default ___CSS_LOADER_EXPORT___;
