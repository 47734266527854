import { cloneDeep } from "lodash";

export function prepSentryWithReduxState(store) {
  if (window.Raven) {
    const clonedState = cloneDeep(store.getState());
    window.Raven.setExtraContext(redactState(clonedState));
  }
}

export function redactState(state) {
  if (state.answers) {
    state.answers.reduxAnswers = redactAnswers(state.answers.reduxAnswers);
    state.answers.serverAnswers = redactAnswers(state.answers.serverAnswers);
  }

  return state;
}

export function redactAnswers(answers) {
  for (let questionName in answers) {
    answers[questionName] = redactValue(answers[questionName]);
  }

  return answers;
}

export function redactValue(value) {
  if (!value) {
    return value;
  } else if (Array.isArray(value)) {
    return `Array of length ${value.length}`;
  } else if (value instanceof Date) {
    return `Date`;
  } else if (typeof value === "object") {
    return `Object with ${Object.keys(value).length} keys`;
  } else if (typeof value === "string") {
    return `String of length ${value.length}`;
  } else if (typeof value === "number") {
    const str = String(value);
    const hasDecimal = str.indexOf(".") >= 0;
    const digits = hasDecimal ? str.length - 1 : str.length;
    return `Number with ${digits} digits${
      hasDecimal ? " and a decimal point" : ""
    }`;
  } else if (typeof value === "function") {
    return value;
  } else if (typeof value === "boolean") {
    return value;
  } else {
    return `An unhandled value type (typeof is '${typeof value}')`;
  }
}
