import * as types from "./ui-forms.types.js";

const switchGap = 24;
const defaultSectionMessage = "All changes saved";

const defaultUi = {
  sectionMessage: "All changes saved",
  formSwitchLeft: "-200px",
  cardWidth: "0px",
};

export default function uiReducer(state = defaultUi, action) {
  if (action.type === types.NEW_CARD_POSITION) {
    return {
      ...state,
      formSwitchLeft: action.cardLeft + action.cardWidth + switchGap + "px",
      cardWidth: action.cardWidth + "px",
      cardLeft: action.cardLeft,
    };
  }
  if (action.type === types.NEW_SECTION_MESSAGE) {
    return { ...state, sectionMessage: action.load || defaultSectionMessage };
  }
  return state;
}
