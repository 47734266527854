import React from "react";
import styles from "./section.style.css";
import WithContentTop from "src/common/navbar-height/with-content-top.decorator.js";
import { debounce } from "lodash";

@WithContentTop((props) => ({
  hasTopnavSecondary: props.hasTopnavSecondary || false,
  clientMenuPossible: props.clientMenuPossible,
}))
export default class SectionHeaderForSourceForm extends React.Component {
  constructor() {
    super();
    this.scrollListener = debounce(() => {
      this.forceUpdate();
    }, 10);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.scrollListener);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollListener);
  }
  render() {
    const {
      formName,
      sectionMessage,
      width,
      displayingInClientPortal,
      formView,
    } = this.props;
    const rootStyles = {
      width,
      opacity: width !== "0px" ? 1 : 0,
      top: displayingInClientPortal ? "96px" : formView ? "120px" : "80px",
    };

    return (
      <div
        className={`cps-card__header cps-card ${styles.sectionHeaderWrapper}`}
        id="section-header-for-source-form"
        style={rootStyles}
      >
        <div className={`${styles.invisibleBox}`} />
        <div className={`${styles.sectionHeader}`}>
          <span className={`cps-subheader`}>{formName}</span>
          <span className="cps-pull-right">
            <i className="cps-color-cool-gray">{sectionMessage}</i>
          </span>
        </div>
      </div>
    );
  }
}

function atTop() {
  return window.pageYOffset > 25;
}
