import React from "react";
import { get, take } from "lodash";
import { ModularTaxFormPage } from "./modular-taxform-page.component.js";
import { getFormData } from "./tax-form-overrides.resource.js";
import Cancelable from "react-disposable-decorator";
import { fieldsByPage } from "./overrides.helper.js";
import { fromEvent } from "rxjs";
import styles from "./tax-form-preview.style.css";

@Cancelable
export default class TaxFormViewPortOrchestrator extends React.Component {
  state = {
    visiblePages: [],
    overrides: [],
    svgData: {},
  };

  chunkSize = 2;

  componentDidMount() {
    const {
      formId,
      clientId,
      resolutionCaseId,
      versionId,
      revisionId,
      cancelWhenUnmounted,
    } = this.props;

    cancelWhenUnmounted(
      this.loadOverrides(
        formId,
        clientId,
        resolutionCaseId,
        `${versionId}-${revisionId}`
      ),
      fromEvent(window, "scroll").subscribe(
        (e) => this.shouldLoadMore() && this.loadMore()
      )
    );
  }

  loadOverrides = (
    formId,
    clientId,
    resolutionCaseId,
    version,
    onCompleted = () => {}
  ) =>
    getFormData(clientId, resolutionCaseId, version, formId).subscribe(
      (svgData) => {
        onCompleted();
        this.setState(
          {
            overrides: fieldsByPage(svgData),
            svgData,
          },
          () =>
            this.setState((prevS, props) => ({
              visiblePages: this.determineVisiblePages(prevS.visiblePages),
            }))
        );
      }
    );

  render = () => this.state.visiblePages;

  loadMore = () =>
    this.setState((prevS, props) => ({
      visiblePages: this.determineVisiblePages(prevS.visiblePages, true),
    }));

  shouldLoadMore = () => {
    if (
      this.props.pages &&
      this.props.pages.length === this.state.visiblePages.length
    ) {
      return false;
    }
    const scrollTop = window.pageYOffset || document.body.scrollTop;
    const height = window.innerHeight || document.documentElement.offsetHeight;
    const docHeight = document.body.clientHeight;
    return scrollTop + height >= docHeight - 100;
  };

  determineVisiblePages = (prevVisiblePages, loadMore = false) => {
    const {
      formId,
      pages,
      clientId,
      resolutionCaseId,
      versionId,
      revisionId,
      isSme,
    } = this.props;

    const { overrides, svgData } = this.state;

    const newPages = take(pages, prevVisiblePages.length + this.chunkSize);

    return newPages.map((page, i) => {
      return (
        <div key={`${page.name}-${page.pageNumber}`} className={styles.rootEl}>
          <ModularTaxFormPage
            key={`${formId}-${i}`}
            overrides={overrides[`page-${i + 1}`]}
            width={get(svgData, `page-width[${i}]`)}
            height={get(svgData, `page-height[${i}]`)}
            pageNumber={i + 1}
            clientId={clientId}
            resolutionCaseId={resolutionCaseId}
            versionId={versionId}
            revisionId={revisionId}
            formId={formId}
            getOverrides={this.loadOverrides.bind(this)}
            isSme={isSme}
          />
        </div>
      );
    });
  };
}
