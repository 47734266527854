import React from "react";
import ClientPortalSurveySlat from "./client-portal-survey-slat.component.js";
import { NavContent } from "primary-navbar!sofe";

export default function ClientPortalSurveys(props) {
  const { surveyList } = props;

  if (surveyList === "default") return null;

  return (
    <NavContent
      style={{ marginLeft: "auto", marginRight: "auto" }}
      hasTopnavSecondary={false}
    >
      <div className={`cps-card`}>
        {surveyList.length === 0 ? (
          <cps-empty-state
            icon-src="es_client_portal_survey"
            head-text="No surveys"
            subhead-text="Surveys will be here when you receive them."
          />
        ) : (
          <div>
            <div className={`cps-card__header cps-subheader`}>Surveys</div>
            {surveyList.map((survey, i) => (
              <ClientPortalSurveySlat survey={survey} key={i} />
            ))}
          </div>
        )}
      </div>
    </NavContent>
  );
}
