import React from "react";
import Block from "../blocks/block.component.js";
import styles from "./section.style.css";

export default class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowResize: windowResize.bind(this),
    };
  }

  componentDidMount() {
    setTimeout(this.state.windowResize);
    window.addEventListener("resize", this.state.windowResize);
    window.addEventListener("scroll", this.state.windowResize);
  }

  componentWillUnmount() {
    if (
      document.activeElement &&
      typeof document.activeElement.blur === "function"
    ) {
      document.activeElement.blur(); //Force a save of whichever input they were working on.
    }
    window.removeEventListener("resize", this.state.windowResize);
    window.removeEventListener("scroll", this.state.windowResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeSection.id !== this.props.activeSection.id) {
      this.state.windowResize();
    }
  }

  render() {
    const {
      activeSection,
      clientId,
      resolutionCaseId,
      noticeId,
      sourceFormId,
      answers,
      summaryTable,
      formName,
      actions,
      alternateAnswerSets,
      overrideAnswerSet,
      questionsToShowRequiredWarnings,
      version,
      revision,
      folderIds,
      taskId,
      taxPrep,
      dynamicData,
    } = this.props;

    if (activeSection.blocks && activeSection.subsections) {
      throw new Error(
        `A section can only have either subsections or blocks, but not both`
      );
    }
    return (
      <div
        ref={(section) => (this.section = section)}
        className={styles.sectionPadding}
      >
        {renderChecks.call(
          this,
          activeSection,
          answers,
          formName,
          summaryTable,
          clientId,
          alternateAnswerSets,
          overrideAnswerSet,
          questionsToShowRequiredWarnings,
          actions,
          resolutionCaseId,
          version,
          revision,
          folderIds,
          taskId,
          taxPrep,
          sourceFormId,
          dynamicData,
          noticeId
        )}
      </div>
    );
  }
}

function renderChecks(
  activeSection,
  answers,
  formName,
  summaryTable,
  clientId,
  alternateAnswerSets,
  overrideAnswerSet,
  questionsToShowRequiredWarnings,
  actions,
  resolutionCaseId,
  version,
  revision,
  folderIds,
  taskId,
  taxPrep,
  sourceFormId,
  dynamicData,
  noticeId
) {
  if (activeSection.blocks && activeSection.blocks.length > 0) {
    return activeSection.blocks
      .filter((block) => block.visible)
      .map((block, i) => {
        return (
          <Block
            parentName={"SectionRoot"}
            blockIndex={i}
            sectionId={activeSection.id}
            summaryTable={summaryTable}
            block={block}
            formName={formName}
            answers={answers}
            sectionResize={this.state.windowResize}
            clientId={clientId}
            key={`${block.name}-${block.id}`}
            alternateAnswerSets={alternateAnswerSets}
            overrideAnswerSet={overrideAnswerSet}
            questionsToShowRequiredWarnings={questionsToShowRequiredWarnings}
            actions={actions}
            resolutionCaseId={resolutionCaseId}
            noticeId={noticeId}
            version={version}
            revision={revision}
            sourceFormId={sourceFormId}
            folderIds={folderIds}
            taskId={taskId}
            taxPrep={taxPrep}
            dynamicData={dynamicData}
            numBlocks={activeSection.blocks.length}
          />
        );
      });
  } else if (activeSection.subSections) {
    return (
      <div className="cps-subheader cps-margin-24 cps-warning">
        Subsections are not yet supported. Please contact Canopy to report this
        error.
      </div>
    );
  } else {
    //waiting for section to load
    return <div />;
  }
}

function windowResize() {
  if (this.section) {
    let rect = this.section.getBoundingClientRect();
    this.props.actions.newCardPosition(rect.left - 1, rect.width + 2);
  }
}
