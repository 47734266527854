import React from "react";
import CanopySelect from "cpr-select";
import "cpr-select/src/select.css";
import CanopyInput from "./canopy-input.decorator.js";
import { get } from "lodash";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import styles from "./inputs.style.css";
import { toggle, maybe } from "kremling";

@CanopyInput()
export default class State extends React.Component {
  constructor(props) {
    super();
    this.state = {
      localAnswer: props.answer,
    };
    this.inputRef = React.createRef();
  }

  focusInput = () => {
    const input = get(this, "inputRef.current.el");
    if (input) {
      if (input.querySelector("input")) input.querySelector("input").focus();
      else console.warn("no input to focus on!");
    } else console.warn("no ref to focus on!");
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer) {
      this.setState({ localAnswer: this.props.answer });
      this.props.validate(this.props.answer);
    }
  }

  render() {
    const { question, updateAnswer, inTable } = this.props;
    const { localAnswer } = this.state;
    return (
      <CanopySelect
        ref={this.inputRef}
        zIndex={2}
        options={states}
        placeholder="Select a state"
        onBlur={this.props.validateAnswer}
        selected={localAnswer}
        onChange={(value) => {
          this.setState({ localAnswer: value });
          updateAnswer(question, value);
          this.props.validate(value);
          this.focusInput();
        }}
        outerClass={toggle(
          fixedTableStyles.fixedTableSelectOuter,
          styles.selectOuter,
          inTable
        )}
        selectClass={maybe(fixedTableStyles.fixedTableSelect, inTable)}
        inputClass={question.name}
      />
    );
  }
}

export const states = [
  {
    value: null,
    key: "",
  },
  {
    value: "Alabama",
    key: "AL",
  },
  {
    value: "Alaska",
    key: "AK",
  },
  {
    value: "American Samoa",
    key: "AS",
  },
  {
    value: "Arizona",
    key: "AZ",
  },
  {
    value: "Arkansas",
    key: "AR",
  },
  {
    value: "California",
    key: "CA",
  },
  {
    value: "Colorado",
    key: "CO",
  },
  {
    value: "Connecticut",
    key: "CT",
  },
  {
    value: "Delaware",
    key: "DE",
  },
  {
    value: "District Of Columbia",
    key: "DC",
  },
  {
    value: "Federated States Of Micronesia",
    key: "FM",
  },
  {
    value: "Florida",
    key: "FL",
  },
  {
    value: "Georgia",
    key: "GA",
  },
  {
    value: "Guam",
    key: "GU",
  },
  {
    value: "Hawaii",
    key: "HI",
  },
  {
    value: "Idaho",
    key: "ID",
  },
  {
    value: "Illinois",
    key: "IL",
  },
  {
    value: "Indiana",
    key: "IN",
  },
  {
    value: "Iowa",
    key: "IA",
  },
  {
    value: "Kansas",
    key: "KS",
  },
  {
    value: "Kentucky",
    key: "KY",
  },
  {
    value: "Louisiana",
    key: "LA",
  },
  {
    value: "Maine",
    key: "ME",
  },
  {
    value: "Marshall Islands",
    key: "MH",
  },
  {
    value: "Maryland",
    key: "MD",
  },
  {
    value: "Massachusetts",
    key: "MA",
  },
  {
    value: "Michigan",
    key: "MI",
  },
  {
    value: "Minnesota",
    key: "MN",
  },
  {
    value: "Mississippi",
    key: "MS",
  },
  {
    value: "Missouri",
    key: "MO",
  },
  {
    value: "Montana",
    key: "MT",
  },
  {
    value: "Nebraska",
    key: "NE",
  },
  {
    value: "Nevada",
    key: "NV",
  },
  {
    value: "New Hampshire",
    key: "NH",
  },
  {
    value: "New Jersey",
    key: "NJ",
  },
  {
    value: "New Mexico",
    key: "NM",
  },
  {
    value: "New York",
    key: "NY",
  },
  {
    value: "North Carolina",
    key: "NC",
  },
  {
    value: "North Dakota",
    key: "ND",
  },
  {
    value: "Northern Mariana Islands",
    key: "MP",
  },
  {
    value: "Ohio",
    key: "OH",
  },
  {
    value: "Oklahoma",
    key: "OK",
  },
  {
    value: "Oregon",
    key: "OR",
  },
  {
    value: "Palau",
    key: "PW",
  },
  {
    value: "Pennsylvania",
    key: "PA",
  },
  {
    value: "Puerto Rico",
    key: "PR",
  },
  {
    value: "Rhode Island",
    key: "RI",
  },
  {
    value: "South Carolina",
    key: "SC",
  },
  {
    value: "South Dakota",
    key: "SD",
  },
  {
    value: "Tennessee",
    key: "TN",
  },
  {
    value: "Texas",
    key: "TX",
  },
  {
    value: "Utah",
    key: "UT",
  },
  {
    value: "Vermont",
    key: "VT",
  },
  {
    value: "Virgin Islands",
    key: "VI",
  },
  {
    value: "Virginia",
    key: "VA",
  },
  {
    value: "Washington",
    key: "WA",
  },
  {
    value: "West Virginia",
    key: "WV",
  },
  {
    value: "Wisconsin",
    key: "WI",
  },
  {
    value: "Wyoming",
    key: "WY",
  },
  {
    value: "Americas",
    key: "AA",
  },
  {
    value: "Europe",
    key: "AE",
  },
  {
    value: "Pacific",
    key: "AP",
  },
];
