import * as types from "./summary-table.types.js";

export function openRemoveRowDialog(table, closeAction) {
  return {
    type: types.REMOVE_TABLE_ROW_DIALOG_OPENED,
    table,
    closeAction,
  };
}

export function closeRemoveRowDialog(table) {
  return {
    type: types.REMOVE_TABLE_ROW_DIALOG_CLOSED,
    table,
  };
}
