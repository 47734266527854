import * as actionTypes from "./context.types.js";

const defaultState = {
  loggedInUser: null,
  tenant: null,
};

export default function reducer(state = defaultState, action) {
  if (action.type === actionTypes.SET_LOGGED_IN_USER) {
    return {
      ...state,
      loggedInUser: action.loggedInUser,
    };
  }

  if (action.type === actionTypes.SET_TENANT) {
    return {
      ...state,
      tenant: action.tenant,
    };
  }

  return state;
}
