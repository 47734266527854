import React from "react";
import styles from "./survey-data.styles.css";
import { partial } from "lodash";

export default function SurveyData(props) {
  const { resolutionCaseAnswerOrder, actions } = props;

  const pushingClientSurveyData = !!resolutionCaseAnswerOrder.find(
    (answerSet) => answerSet.name === "clientSurveys"
  );

  return (
    <div className={`${styles.root}`}>
      <div className={`${styles.pushGrid}`}>
        <div className={`${styles.pushExplanation}`}>
          Push the client survey data to each of the relevant tax forms.
        </div>
        <label className={`cps-toggle ${styles.toggle}`}>
          <input
            type="checkbox"
            checked={pushingClientSurveyData}
            onChange={partial(
              toggleChanged,
              resolutionCaseAnswerOrder,
              actions
            )}
          />
          <span />
        </label>
      </div>
      <div className={`${styles.note}`}>
        Note: With this option disabled, no data from the client survey will
        flow onto relevant tax forms.
      </div>
    </div>
  );
}

function toggleChanged(oldAnswerOrder, actions, evt) {
  const reducingFunc = evt.target.checked
    ? insertingClientSurvey
    : removingClientSurvey;
  const newAnswerOrder = oldAnswerOrder.reduce(reducingFunc, []);
  actions.setSurveyOptionsAnswerOrder(newAnswerOrder);
}

function insertingClientSurvey(arr, answerSet) {
  // Inserts clientSurveys answer set right after the taxForms answer set
  return answerSet.name === "taxForms"
    ? arr.concat(answerSet, { name: "clientSurveys" })
    : arr.concat(answerSet);
}

function removingClientSurvey(arr, answerSet) {
  // Remove client survey from array
  return answerSet.name === "clientSurveys" ? arr : arr.concat(answerSet);
}
