// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-source-forms-form-view-switch-style__link {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--cps-color-primary-text);
}

.src-source-forms-form-view-switch-style__formSwitch {
  position: fixed;
  height: 100%;
}

.src-source-forms-form-view-switch-style__formSwitchImage {
  width: 160px;
  cursor: pointer;
}

.src-source-forms-form-view-switch-style__formSwitchSpan {
  padding: 8px;
  font-size: 1.3rem;
  font-weight: bold;
}

.src-source-forms-form-view-switch-style__taxFormIndex {
  margin-top: 16px;
  max-height: calc(100% - 300px);
  overflow: auto;
  left: -12px;
  position: relative;
}

.src-source-forms-form-view-switch-style__taxFormLink {
  padding-left: 8px;
  height: 32px;
  display: flex;
  align-items: center;
  border-left: 4px solid transparent;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
  color: inherit;
}

.src-source-forms-form-view-switch-style__taxFormLink:hover {
  border-left: 4px solid var(--cps-color-slate);
  background-color: var(--cps-color-athens);
  text-decoration: none;
}

.src-source-forms-form-view-switch-style__taxFormLink.src-source-forms-form-view-switch-style__active {
  border-left: 4px solid var(--cps-color-primary);
  color: var(--cps-color-primary);
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/source-forms/form-view-switch.style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,8BAA8B;EAC9B,cAAc;EACd,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kCAAkC;EAClC,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,6CAA6C;EAC7C,yCAAyC;EACzC,qBAAqB;AACvB;;AAEA;EACE,+CAA+C;EAC/C,+BAA+B;EAC/B,iBAAiB;AACnB","sourcesContent":[":local(.link) {\n  width: 180px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: var(--cps-color-primary-text);\n}\n\n:local(.formSwitch) {\n  position: fixed;\n  height: 100%;\n}\n\n:local(.formSwitchImage) {\n  width: 160px;\n  cursor: pointer;\n}\n\n:local(.formSwitchSpan) {\n  padding: 8px;\n  font-size: 1.3rem;\n  font-weight: bold;\n}\n\n:local(.taxFormIndex) {\n  margin-top: 16px;\n  max-height: calc(100% - 300px);\n  overflow: auto;\n  left: -12px;\n  position: relative;\n}\n\n:local(.taxFormLink) {\n  padding-left: 8px;\n  height: 32px;\n  display: flex;\n  align-items: center;\n  border-left: 4px solid transparent;\n  cursor: pointer;\n  white-space: nowrap;\n  overflow: hidden;\n  min-width: 0;\n  text-overflow: ellipsis;\n  color: inherit;\n}\n\n:local(.taxFormLink:hover) {\n  border-left: 4px solid var(--cps-color-slate);\n  background-color: var(--cps-color-athens);\n  text-decoration: none;\n}\n\n:local(.taxFormLink.active) {\n  border-left: 4px solid var(--cps-color-primary);\n  color: var(--cps-color-primary);\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `src-source-forms-form-view-switch-style__link`,
	"formSwitch": `src-source-forms-form-view-switch-style__formSwitch`,
	"formSwitchImage": `src-source-forms-form-view-switch-style__formSwitchImage`,
	"formSwitchSpan": `src-source-forms-form-view-switch-style__formSwitchSpan`,
	"taxFormIndex": `src-source-forms-form-view-switch-style__taxFormIndex`,
	"taxFormLink": `src-source-forms-form-view-switch-style__taxFormLink`,
	"active": `src-source-forms-form-view-switch-style__active`
};
export default ___CSS_LOADER_EXPORT___;
