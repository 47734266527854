import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function getFiles(clientId) {
  return fetchAsObservable(`api/docs/clients/${clientId}/folders/0/files`).pipe(
    pluck("folder_files")
  );
}

export function getFileObject(fileId) {
  return fetchAsObservable(`/api/docs/files/${fileId}`).pipe(pluck("file"));
}

export function deleteFile(fileId) {
  return fetchAsObservable(`/api/docs/files/${fileId}`, {
    method: "DELETE",
  });
}
