// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-create-survey-sent-success-styles__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.src-client-survey-create-survey-sent-success-styles__container > * {
  padding: 8px;
}

.src-client-survey-create-survey-sent-success-styles__readyToSend {
  font-size: 24px;
}

.src-client-survey-create-survey-sent-success-styles__circle {
  stroke: var(--cp-color-app-primary);
}

.src-client-survey-create-survey-sent-success-styles__triangle {
  fill: var(--cp-color-app-primary);
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/create-survey/sent-success.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 24px;\n}\n\n.container > * {\n  padding: 8px;\n}\n\n.readyToSend {\n  font-size: 24px;\n}\n\n.circle {\n  stroke: var(--cp-color-app-primary);\n}\n\n.triangle {\n  fill: var(--cp-color-app-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-client-survey-create-survey-sent-success-styles__container`,
	"readyToSend": `src-client-survey-create-survey-sent-success-styles__readyToSend`,
	"circle": `src-client-survey-create-survey-sent-success-styles__circle`,
	"triangle": `src-client-survey-create-survey-sent-success-styles__triangle`
};
export default ___CSS_LOADER_EXPORT___;
