import React from "react";
import { noop, get } from "lodash";
import * as sentryErrorLogging from "error-logging!sofe";

import SingleLineText from "../inputs/single-line-text.component.js";
import SSN from "../inputs/ssn.component.js";
import SelectList from "../inputs/select-list.component.js";
import Radio from "../inputs/radio.component.js";
import State from "../inputs/state.component.js";
import Country from "../inputs/country.component.js";
import Checkbox from "../inputs/checkbox.component.js";
import DateComp from "../inputs/date.component.js";
import NumberComp from "../inputs/number.component.js";
import Percent from "../inputs/percent.component.js";
import EIN from "../inputs/ein.component.js";
import CAF from "../inputs/caf.component.js";
import Currency from "../inputs/currency.component.js";
import PhoneNumber from "../inputs/phone-number.component.js";
import ZipCode from "../inputs/zip-code.component.js";
import Paragraph from "../inputs/paragraph.component.js";
import County from "../inputs/county.component.js";
import PillBox from "../inputs/pillbox.component.js";
import NotFound from "../inputs/not-found.component.js";
import Practitioner from "../inputs/practitioner.component.js";
import CalculatedValue from "../inputs/calculated-value.component.js";
import Uri from "../inputs/uri.component.js";
import Decimal from "../inputs/decimal.component.js";
import OtherCountries from "../inputs/various-countries.component.js";
import FileUpload from "../inputs/file-upload.component.js";

export const questionMetaTypeMap = {
  SINGLE_LINE_TEXT: SingleLineText,
  SSN: SSN,
  SELECT_LIST: SelectList,
  MULTIPLE_CHOICE: Radio,
  STATE: State,
  COUNTRY: Country,
  CHECKBOX: Checkbox,
  DATE: DateComp,
  NUMBER: NumberComp,
  PERCENTAGE: Percent,
  EIN: EIN,
  CAF: CAF,
  AMOUNT: Currency,
  PHONE_NUMBER: PhoneNumber,
  ZIP: ZipCode,
  PARAGRAPH_TEXT: Paragraph,
  COUNTY: County,
  PILLBOX: PillBox,
  PRACTITIONER: Practitioner,
  CALCULATED_VALUE: CalculatedValue,
  URI: Uri,
  DECIMAL: Decimal,
  OTHER_COUNTRIES: OtherCountries,
  FILE_UPLOAD: FileUpload,
};

export default class PasteQuestion extends React.Component {
  render() {
    const Input = questionMetaTypeMap[this.props.question.type] || NotFound;
    let xsdType = get(this.props.question, "xsdType");
    if (xsdType) {
      try {
        xsdType = JSON.parse(xsdType);
      } catch (err) {
        sentryErrorLogging.captureException(err);
      }
    }

    return (
      <Input
        answer={this.props.answer}
        updateAnswer={this.props.onChange}
        summaryTableIndex={0}
        question={this.props.question}
        validate={noop}
        setValid={noop}
        style={this.props.style}
        onPaste={this.props.onPaste}
        datax={this.props.datax}
        datay={this.props.datay}
        className={this.props.className}
        roundCurrency={this.props.question.roundCurrency}
        characterLimit={this.props.question.characterLimit}
        xsdType={xsdType}
      />
    );
  }
}
