import * as actionTypes from "./resolution-cases.types.js";

const defaultState = {
  isDefaultResolutionCase: true,
  name: "",
  program_data: {
    programs: [],
  },
};

export default function reducer(state = defaultState, action) {
  if (action.type === actionTypes.NEW_ENGAGEMENT) {
    return action.resolutionCase;
  }

  if (action.type === actionTypes.RESET_ENGAGEMENT) {
    return defaultState;
  }

  return state;
}
