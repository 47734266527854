import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function getSourceFormGroups(
  clientId,
  resolutionCaseId,
  versionId,
  revisionId,
  surveyType,
  surveyVersion,
  noticeId
) {
  if (!clientId) throw new Error(`clientId is required`);
  if (!resolutionCaseId && !noticeId)
    throw new Error(`resolutionCaseId or noticeId is required`);
  if (!versionId) throw new Error(`versionId is required`);
  if (!revisionId) throw new Error(`revisionId is required`);

  let url = `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${versionId}-${revisionId}/groups`;

  if (noticeId) {
    url = `/clients/${clientId}/notices/${noticeId}/versions/${versionId}-${revisionId}/groups`;
  }

  return fetchAsObservable(url).pipe(pluck("groups"));
}
