// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-secondarynav-survey-secondary-nav-style__link {
  color: var(--cps-color-vibrant-ocean) !important;
}

.src-client-survey-secondarynav-survey-secondary-nav-style__link:hover {
  color: var(--cps-color-vibrant-ocean) !important;
  cursor: pointer !important;
}

.src-client-survey-secondarynav-survey-secondary-nav-style__leftLi {
  margin-top: -2px;
}

.src-client-survey-secondarynav-survey-secondary-nav-style__linkText {
  font-weight: 400;
}

.src-client-survey-secondarynav-survey-secondary-nav-style__linkTextBlue {
  color: var(--cps-color-vibrant-ocean) !important;
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/secondarynav/survey-secondary-nav.style.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;AAClD;;AAEA;EACE,gDAAgD;EAChD,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gDAAgD;AAClD","sourcesContent":[".link {\n  color: var(--cps-color-vibrant-ocean) !important;\n}\n\n.link:hover {\n  color: var(--cps-color-vibrant-ocean) !important;\n  cursor: pointer !important;\n}\n\n.leftLi {\n  margin-top: -2px;\n}\n\n.linkText {\n  font-weight: 400;\n}\n\n.linkTextBlue {\n  color: var(--cps-color-vibrant-ocean) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `src-client-survey-secondarynav-survey-secondary-nav-style__link`,
	"leftLi": `src-client-survey-secondarynav-survey-secondary-nav-style__leftLi`,
	"linkText": `src-client-survey-secondarynav-survey-secondary-nav-style__linkText`,
	"linkTextBlue": `src-client-survey-secondarynav-survey-secondary-nav-style__linkTextBlue`
};
export default ___CSS_LOADER_EXPORT___;
