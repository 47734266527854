import React from "react";
import { connect } from "react-redux";
import CollectionAnalytics from "./collection-analytics.component.js";
import { bindActionCreators } from "redux";
import Loader from "src/common/async-loader.component.js";

import * as programSectionActions from "../resolution-cases/program-sections/program-sections.actions.js";
import * as analyticsActions from "./collection-analytics.actions.js";

const asyncChecks = [
  function (props) {
    return props.version;
  },
  function (props) {
    return props.revision;
  },
  function (props) {
    return !props.resolutionCase.isDefaultResolutionCase;
  },
];
@connect((state) => ({
  analytics: state.analytics,
  resolutionCase: state.resolutionCase,
  reduxClient: state.reduxClient,
  resolutionCaseProgramSection: state.resolutionCaseProgramSection,
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
  context: state.context,
}))
export default class CollectionAnalyticsWrapper extends React.Component {
  render() {
    const actions = {
      ...bindActionCreators(programSectionActions, this.props.dispatch),
      ...bindActionCreators(analyticsActions, this.props.dispatch),
    };
    return (
      <Loader {...this.props} asyncChecks={asyncChecks}>
        <CollectionAnalytics
          {...this.props}
          smeMode={this.props.analytics.smeMode}
          answers={this.props.analytics.answers}
          report={this.props.analytics.report}
          actions={actions}
        />
      </Loader>
    );
  }
}
