import { isEmpty, get } from "lodash";

export function toPercentString(number) {
  let stringNum = String(number);
  let index = stringNum.indexOf(".");
  if (index === -1) return String(number * 100);
  else {
    let arr = stringNum.split("").filter((num) => num !== ".");
    arr = arr.concat(["0", "0"]); // in case there aren't enough 0's after the decimal to turn into zeros before the decimal
    arr.splice(index + 2, 0, ".");
    arr = arr.map((num) => (num === undefined ? 0 : num)).join("");
    if (arr[arr.length - 1] === ".") arr = arr.slice(0, arr.length - 1);
    return String(Number(arr));
  }
}

export function divideNumberBy100(string) {
  const decimalIndex =
    string.indexOf(".") > -1 ? string.indexOf(".") : string.length;
  let stringArr = string.replace(".", "").split("");
  if (decimalIndex > 1) {
    stringArr.splice(decimalIndex - 2, 0, ".");
  } else if (decimalIndex === 1) {
    stringArr.splice(0, 0, ".", "0");
  } else {
    stringArr.splice(0, 0, ".", "0", "0");
  }
  return Number(stringArr.join(""));
}

export function validateWithXsdRegex(xsdType, value) {
  const regexList = get(xsdType, "pattern");

  if (!regexList || isEmpty(regexList)) {
    return true;
  }

  const failedRegex = regexList.filter((reg) => {
    const regex = new RegExp(reg);
    return !regex.test(value);
  });

  if (isEmpty(failedRegex)) {
    return true;
  } else {
    return false;
  }
}
