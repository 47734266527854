import { countries } from "src/source-forms/inputs/country.component.js";
import { states } from "src/source-forms/inputs/state.component.js";

const variousCountriesOpt = [{ value: "Other Countries", key: "OC" }];

export const getOptions = (type) => {
  switch (type) {
    case "COUNTRY":
      return countries;
    case "STATE":
      return states;
    case "OTHER_COUNTRIES":
      return [...variousCountriesOpt, ...countries];
    default:
      return [];
  }
};
