import React from "react";
import styles from "./client-portal-survey-slat.styles.css";
import { DateTime } from "luxon";

export default function ClientPortalSurveySlat(props) {
  const { survey } = props;

  return (
    <a
      href={`/#/forms/clients/${survey.clientId}/resolution-cases/${survey.resolutionCaseId}/survey`}
      className={`cps-slat-lg`}
    >
      <div className={`cps-slat-lg__content`}>
        <div className={`cps-slat-lg__content__title`}>
          <span>
            Client{" "}
            {`${survey.surveyType === "taxPrep" ? "Organizer" : "Survey"}`}
          </span>
          {survey.sentDate && (
            <span className={`cp-color-app-inactive-text ${styles.surveyName}`}>
              {" "}
              {DateTime.fromMillis(survey.sentDate).toLocaleString(
                DateTime.DATE_SHORT
              )}
            </span>
          )}
        </div>
        {`This ${
          survey.surveyType === "taxPrep" ? "organizer" : "survey"
        } covers all the information your practitioner needs to understand your
        tax scenario.`}
      </div>
    </a>
  );
}
