import React from "react";
import { getCounties } from "./county.resources.js";
import CanopySelect from "cpr-select";
import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";
import { of, ReplaySubject } from "rxjs";
import AsyncDecorator from "async-decorator/rx6";
import { tap, switchMap } from "rxjs/operators";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import { always, toggle } from "kremling";

@AsyncDecorator
@CanopyInput()
export default class County extends React.Component {
  constructor(props) {
    super();

    this.state = {
      counties: [],
      state: null,
      localAnswer: props.answer,
    };

    this.inputRef = React.createRef();
  }
  componentDidMount() {
    this.props.cancelWhenUnmounted(
      this.props
        .stream(
          (props) =>
            props.answers.serverAnswers[props.question.meta.stateReference]
        )
        .pipe(
          tap((state) => this.setState({ state })),
          switchMap((state) => {
            if (!state) return of([]);
            else return getCountiesFromCache(state);
          })
        )
        .subscribe((counties) =>
          this.setState({
            counties,
          })
        )
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.answer !== this.props.answer) {
      this.setState({ localAnswer: this.props.answer });
      this.props.validate(this.props.answer);
    }
  }
  render() {
    if (!this.state.counties.length || !this.state.state) {
      return (
        <input
          disabled={true}
          className={always(
            `cps-form-control ${styles["source-form-200"]}`
          ).maybe(fixedTableStyles.fixedTableInput, this.props.inTable)}
          autoComplete="off"
          placeholder={`${
            !this.state.state
              ? "Select a state to see counties"
              : "Not Applicable"
          }`}
        />
      );
    }
    return (
      <CanopySelect
        zIndex={2}
        options={[{ key: null, value: null }].concat(this.state.counties)}
        placeholder="Select a county"
        ref={this.inputRef}
        selected={this.state.localAnswer}
        onChange={this.saveNewValue}
        outerClass={toggle(
          fixedTableStyles.fixedTableSelectOuter,
          styles.selectOuter,
          this.props.inTable
        )}
        inputClass={this.props.question.name}
      />
    );
  }

  saveNewValue = (value) => {
    this.setState({ localAnswer: value });
    this.props.validate(value);
    this.props.updateAnswer(this.props.question, value);
  };
}

const countyRequests = {};

function getCountiesFromCache(state) {
  if (countyRequests[state]) return countyRequests[state];

  countyRequests[state] = new ReplaySubject(1);

  getCounties(state).subscribe((counties) => {
    countyRequests[state].next(
      counties.map((county) => ({
        value: county,
        key: county,
      }))
    );
  });

  return countyRequests[state];
}
