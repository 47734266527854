import { infoToast } from "toast-service!sofe";
import { scrollToElement } from "src/common/scroll.helpers.js";

/* Right now the required property is an unevaluated rules engine string that is "true", but that might
 * change if the SME tool needs to support fancier required rules.
 */
export function isRequired(question, navigate) {
  return question.required === "true" || question.required === true;
}

export function handleValidate(evt, navigate) {
  _validate(() => {
    if (navigate) {
      navigate();
    } else {
      const { href } = evt.currentTarget;
      const { to } = evt.currentTarget;

      evt.preventDefault();
      window.location.href = href || to;
    }
  });
}

export function summaryTableValidate(callback, element) {
  _validate(callback, element);
}

function _validate(callback, element = document) {
  window.dispatchEvent(
    new CustomEvent("end-user-forms-ui:validate", { detail: {} })
  );

  setTimeout(() => {
    if (element.querySelectorAll(".cps-has-error").length === 0) {
      window.dispatchEvent(
        new CustomEvent("end-user-forms-ui:validate", {
          detail: { reset: true },
        })
      );
      callback();
    } else {
      let errorElement = element.querySelector(".cps-has-error");

      scrollToElement(errorElement);

      infoToast(`Please fill out all required questions before continuing.`);
    }
  });
}
