import React from "react";
import styles from "./report-utils.styles.css";
import { handleValue } from "./value-converter.helper.js";
import SmeWrapper from "./sme-view-wrapper.component.js";

export default function Value(props) {
  return (
    <div style={{ width: props.width }}>
      <SmeWrapper
        divStyles={{
          display: "inline-block",
        }}
        smeMode={props.smeMode}
        reportKey={props.reportKey}
      >
        <div
          style={{
            color: props.isHeader ? "var(--cps-color-primary-text)" : "inherit",
            fontWeight: props.isHeader || props.boldText ? "bold" : "inherit",
          }}
          className={`${styles.rowBox}`}
        >
          {handleValue(
            props.value,
            props.prefix,
            props.smeMode,
            !props.notCurrency
          )}
        </div>
      </SmeWrapper>
    </div>
  );
}
