import React from "react";
import { CpSelectSingle } from "canopy-styleguide!sofe";

export default function TaxPrepPicker({ formSet, onChange }) {
  return (
    <div>
      <CpSelectSingle
        placeholder="Select form set"
        value={formSet}
        onChange={onChange}
        data={[
          {
            name: "Federal",
            id: "federal",
          },
          {
            name: "Alabama",
            id: "alabama",
          },
          {
            name: "Alaska",
            id: "alaska",
          },
          {
            name: "Arizona",
            id: "arizona",
          },
          {
            name: "Arkansas",
            id: "arkansas",
          },
          {
            name: "California",
            id: "california",
          },
          {
            name: "Colorado",
            id: "colorado",
          },
          {
            name: "Connecticut",
            id: "connecticut",
          },
          {
            name: "Delaware",
            id: "delaware",
          },
          {
            name: "Florida",
            id: "florida",
          },
          {
            name: "Georgia",
            id: "georgia",
          },
          {
            name: "Hawaii",
            id: "hawaii",
          },
          {
            name: "Idaho",
            id: "idaho",
          },
          {
            name: "Illinois",
            id: "illinois",
          },
          {
            name: "Indiana",
            id: "indiana",
          },
          {
            name: "Iowa",
            id: "iowa",
          },
          {
            name: "Kansas",
            id: "kansas",
          },
          {
            name: "Kentucky",
            id: "kentucky",
          },
          {
            name: "Louisiana",
            id: "louisiana",
          },
          {
            name: "Maine",
            id: "maine",
          },
          {
            name: "Maryland",
            id: "maryland",
          },
          {
            name: "Massachusetts",
            id: "massachusetts",
          },
          {
            name: "Michigan",
            id: "michigan",
          },
          {
            name: "Minnesota",
            id: "minnesota",
          },
          {
            name: "Mississippi",
            id: "mississippi",
          },
          {
            name: "Missouri",
            id: "missouri",
          },
          {
            name: "Montana",
            id: "montana",
          },
          {
            name: "Nebraska",
            id: "nebraska",
          },
          {
            name: "Nevada",
            id: "nevada",
          },
          {
            name: "New Hampshire",
            id: "new hampshire",
          },
          {
            name: "New Jersey",
            id: "new jersey",
          },
          {
            name: "New Mexico",
            id: "new mexico",
          },
          {
            name: "New York",
            id: "new york",
          },
          {
            name: "North Carolina",
            id: "north carolina",
          },
          {
            name: "North Dakota",
            id: "north dakota",
          },
          {
            name: "Ohio",
            id: "ohio",
          },
          {
            name: "Oklahoma",
            id: "oklahoma",
          },
          {
            name: "Oregon",
            id: "oregon",
          },
          {
            name: "Pennsylvania",
            id: "pennsylvania",
          },
          {
            name: "Rhode Island",
            id: "rhode island",
          },
          {
            name: "South Carolina",
            id: "south carolina",
          },
          {
            name: "South Dakota",
            id: "south dakota",
          },
          {
            name: "Tennessee",
            id: "tennessee",
          },
          {
            name: "Texas",
            id: "texas",
          },
          {
            name: "Utah",
            id: "utah",
          },
          {
            name: "Vermont",
            id: "vermont",
          },
          {
            name: "Virginia",
            id: "virginia",
          },
          {
            name: "Washington",
            id: "washington",
          },
          {
            name: "West Virginia",
            id: "west virginia",
          },
          {
            name: "Wisconsin",
            id: "wisconsin",
          },
          {
            name: "Wyoming",
            id: "wyoming",
          },
        ]}
      />
    </div>
  );
}
