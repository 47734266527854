import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { partial } from "lodash";
import { CpButton, CpIcon, CpCard } from "canopy-styleguide!sofe";

import styles from "./submit-survey.styles.css";
import * as clientSurveyActions from "../client-survey.actions.js";
import { checkFetchSubsection } from "../source-form/cssf-subsection.helpers.js";

const sectionIdToFetch = "0";
const subsectionIndexToFetch = 0;

@withRouter
@connect((state) => ({
  clientSurvey: state.clientSurvey,
  sourceFormLayout: state.sourceFormLayout,
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
}))
export default class SubmitSurvey extends React.Component {
  constructor(props) {
    super();
    this.boundActions = {
      ...bindActionCreators(clientSurveyActions, props.dispatch),
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.clientSurvey.survey.status === "submitted") {
      whenDone(this.props);
    }

    checkFetchSubsection(
      prevProps,
      this.props,
      false,
      sectionIdToFetch,
      subsectionIndexToFetch
    );
  }
  render() {
    const { props } = this;

    if (props.sourceFormLayout.sections.length === 0) {
      // We don't know whether they've finished all the sections in the survey or not
      return <CpCard className={styles.subsectionWrapper} />;
    }

    const incompleteSections = props.sourceFormLayout.sections.filter(
      (section) => {
        return !section.completed && section.visible;
      }
    );

    return (
      <CpCard className={styles.subsectionWrapper}>
        {incompleteSections.length > 0 ? (
          <div className={`${styles.container}`}>
            <div className="cp-color-app-primary">
              You're not quite done yet...
            </div>
            <div className={`${styles.explanation}`}>
              There are still a few sections that haven't been completed yet.
              Take a look at these sections and see if there's any important
              information you would like to add.
            </div>
            <div className={`${styles.incompleteSectionList}`}>
              {incompleteSections.map((section, index) => {
                return (
                  <div key={section.id} className={`${styles.section}`}>
                    <CpIcon
                      className="cp-mr-8"
                      name="shape-circle-open"
                      fill="var(--cp-color-button-secondary-border)"
                    />
                    <span>{section.name}</span>
                  </div>
                );
              })}
            </div>
            <CpButton
              onClick={partial(submitAndRedirect, this.boundActions, props)}
            >
              Skip these sections
            </CpButton>
          </div>
        ) : (
          <div className={`${styles.container}`}>
            <img src="https://cdn.canopytax.com/static/end-user-forms-ui/submit_survey-2.svg" />
            <div className="cps-color-primary">
              Ready for us to review the survey?
            </div>
            <div className={`${styles.explanation}`}>
              If you're happy with your answers, then hit "Submit" below. We
              will then review the survey, and let you know if there's any
              additional information that we need.
            </div>
            <CpButton
              onClick={partial(submitAndRedirect, this.boundActions, props)}
            >
              Submit
            </CpButton>
          </div>
        )}
      </CpCard>
    );
  }
}

function submitAndRedirect(boundActions, props) {
  boundActions.patchClientSurveyAction(
    props.clientSurvey.survey.id,
    "status",
    "submitted",
    partial(whenDone, props)
  );
}

function whenDone(props) {
  props.history.replace(`${props.baseUrl}/all-done`);
}
