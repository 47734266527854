import React from "react";
import CprMask from "cpr-mask";
import { get, noop } from "lodash";
import { always, maybe } from "kremling";

import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import { validateWithXsdRegex } from "./input.helper";

@CanopyInput()
export default class SSN extends React.Component {
  constructor(props) {
    super();
    this.state = {
      localAnswer: props.answer,
      invalidMsg: "Must be a valid SSN",
    };
    this.inputRef = React.createRef();
  }

  focused = false;

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answer !== this.props.answer && !this.focused) {
      this.updateLocalAnswer(this.props.answer);
    }
  }

  render() {
    const { question } = this.props;
    const { invalidMsg, localAnswer } = this.state;

    return (
      <CprMask
        ref={this.inputRef}
        inputClass={always(
          `cps-form-control ${styles["source-form-200"]} ${question.name} ${
            this.props.className || ""
          }`
        )
          .maybe(fixedTableStyles.fixedTableInput, this.props.inTable)
          .toString()}
        invalidClass="cps-has-error"
        nonValidMsgClass="cps-error-block"
        nonValidMsg={invalidMsg}
        initialValue={localAnswer}
        onChange={this.updateLocalAnswer}
        onBlur={this.blur}
        onFocus={this.focus}
        masks={[
          {
            condition: (value) => true,
            pattern: "111-11-1111",
          },
        ]}
        validateMethod={this.validateMethod}
        inputProps={{
          autoComplete: "off",
          style: this.props.style ? this.props.style : {},
          datax: this.props.datax,
          datay: this.props.datay,
          onPaste: this.props.onPaste || noop,
        }}
      />
    );
  }

  focusInput = () => {
    const input = get(this, "inputRef.current.input");
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  validateMethod = (value) => {
    const xsdType = get(this.props, "xsdType");

    if (value && xsdType) {
      return validateWithXsdRegex(xsdType, value);
    }

    if (value && value.length < 9) {
      return false;
    }

    return true;
  };

  updateLocalAnswer = (value) => {
    const xsdType = get(this.props, "xsdType");
    let invalidMsg = "Must be a valid SSN";
    this.props.validate(value);

    if (value && xsdType) {
      const isValid = validateWithXsdRegex(xsdType, value);

      if (!isValid && xsdType.customDescription) {
        invalidMsg = xsdType.customDescription;
      } else if (!isValid && xsdType.irsDescription) {
        invalidMsg = xsdType.irsDescription;
      }
    } else if (value && value.length < 9) {
      invalidMsg = "This field requires 9 digits";
    }

    this.setState({
      localAnswer: value,
      invalidMsg,
    });
  };

  focus = () => (this.focused = true);
  blur = (answer) => {
    this.props.validate(answer);
    this.focused = false;
    this.props.updateAnswer(this.props.question, answer);
  };
}
