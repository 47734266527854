import React from "react";
import { get, noop } from "lodash";
import CprMask from "cpr-mask";
import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import { always } from "kremling";
import { validateWithXsdRegex } from "./input.helper";

@CanopyInput()
export default class NumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      localAnswer: props.answer,
      characterLimit: null,
      nonValidMsg: "Must be a valid number",
    };

    this.inputRef = React.createRef();
    this.focused = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { answer } = this.props;
    const { localAnswer } = this.state;

    if (
      this.props.characterLimit &&
      prevState.localAnswer !== localAnswer &&
      (!answer ||
        !localAnswer ||
        answer.toString().length <= this.props.characterLimit)
    ) {
      // dont use character limit if an old return already has value outside limit until that field reaches blank or if new return
      this.setState({ characterLimit: this.props.characterLimit });
    }

    if (prevProps.answer !== answer && !this.focused) {
      this.updateLocalAnswer(answer);
    }
  }

  render() {
    const { characterLimit, localAnswer } = this.state;

    return (
      <CprMask
        ref={this.inputRef}
        inputClass={always(
          `cps-form-control ${styles["source-form-200"]} ${
            this.props.question.name
          } ${this.props.className || ""}`
        )
          .maybe(fixedTableStyles.fixedTableInput, this.props.inTable)
          .toString()}
        validChars={/[0-9\.\-]/}
        decoder={(string) => {
          if (string === "" || string === null) return "";
          return Number(string);
        }}
        encoder={String}
        initialValue={localAnswer ? localAnswer.toString() : ""}
        onChange={this.updateLocalAnswer}
        nonValidMsgClass="cps-error-block"
        invalidClass="cps-has-error"
        validateMethod={this.validateMethod}
        nonValidMsg={this.state.nonValidMsg}
        onBlur={this.blur}
        onFocus={this.focus}
        inputProps={{
          autoComplete: "off",
          style: this.props.style ? this.props.style : {},
          maxLength: characterLimit || "",
          datax: this.props.datax,
          datay: this.props.datay,
          onPaste: this.props.onPaste || noop,
        }}
      />
    );
  }

  blur = (answer) => {
    this.props.validate(answer);
    this.focused = false;

    if (isNaN(Number(answer))) return;
    this.props.updateAnswer(this.props.question, answer);
  };

  focus = () => (this.focused = true);

  focusInput = () => {
    const input = get(this, "inputRef.current.input");
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  validateMethod = (value) => {
    const { characterLimit } = this.props;
    const xsdType = get(this.props, "xsdType");

    if (isNaN(Number(value))) {
      return false;
    } else if (
      !this.state.characterLimit &&
      value &&
      value.toString().length > characterLimit
    ) {
      return false;
    } else if (value && xsdType) {
      return validateWithXsdRegex(xsdType, value);
    }

    return true;
  };

  updateLocalAnswer = (value) => {
    const { characterLimit } = this.props;
    const xsdType = get(this.props, "xsdType");
    const invalidNumMsg = "Current input is not a valid number";
    const charLimitMsg = `Can\'t be more than ${characterLimit} characters`;
    let nonValidMsg = "Must be a valid number";

    this.props.validate(value);

    if (isNaN(Number(value))) {
      nonValidMsg = invalidNumMsg;
    } else if (
      !this.state.characterLimit &&
      value &&
      value.toString().length > characterLimit
    ) {
      nonValidMsg = charLimitMsg;
    } else if (value && xsdType) {
      const isValid = validateWithXsdRegex(xsdType, value);

      if (!isValid && xsdType.customDescription) {
        nonValidMsg = xsdType.customDescription;
      } else if (!isValid && xsdType.irsDescription) {
        nonValidMsg = xsdType.irsDescription;
      }
    }

    this.setState({
      localAnswer: value,
      nonValidMsg,
    });
  };
}
