// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-analytics-collection-analytics-header-styles__header {
  display: flex;
  justify-content: center;
  border-bottom: 0;
}

.src-analytics-collection-analytics-header-styles__headerItems {
  cursor: pointer;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-right: 0.8rem;
  font-size: 1.3rem;
  height: 5.2rem;
  padding-top: 18px;
  color: var(--cps-color-primary-text);
}

.src-analytics-collection-analytics-header-styles__headerItems:hover {
  text-decoration: none;
  color: var(--cps-color-monsoon);
}
`, "",{"version":3,"sources":["webpack://./src/analytics/collection-analytics-header.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,qBAAqB;EACrB,oBAAoB;EACpB,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EACjB,oCAAoC;AACtC;;AAEA;EACE,qBAAqB;EACrB,+BAA+B;AACjC","sourcesContent":[".header {\n  display: flex;\n  justify-content: center;\n  border-bottom: 0;\n}\n\n.headerItems {\n  cursor: pointer;\n  padding-left: 1.6rem;\n  padding-right: 1.6rem;\n  margin-right: 0.8rem;\n  font-size: 1.3rem;\n  height: 5.2rem;\n  padding-top: 18px;\n  color: var(--cps-color-primary-text);\n}\n\n.headerItems:hover {\n  text-decoration: none;\n  color: var(--cps-color-monsoon);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `src-analytics-collection-analytics-header-styles__header`,
	"headerItems": `src-analytics-collection-analytics-header-styles__headerItems`
};
export default ___CSS_LOADER_EXPORT___;
