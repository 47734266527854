import React from "react";
import { Provider } from "react-redux";
import { Scoped, k } from "kremling";
import { CpButton, CpIcon } from "canopy-styleguide!sofe";
import { UserTenantProps } from "cp-client-auth!sofe";
import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import { taxResVersionAsObservable } from "sme-version!sofe";
import Cancelable from "react-disposable-decorator";
import { func, string, object, array } from "prop-types";

import contextReducer from "../bootstrap/context.reducer.js";
import resolutionCaseReducer from "../resolution-cases/resolution-cases.reducer.js";
import reduxClientReducer from "../clients/redux-client.reducer.js";
import layoutReducer from "../source-forms/source-form-layout.reducer.js";
import sectionReducer from "../source-forms/sections/section.reducer.js";
import answerReducer from "../source-forms/answers/answer.reducer.js";
import versionReducer from "../source-forms/version.reducer.js";
import uiFormsReducer from "../source-forms/ui-forms.reducer.js";
import summaryTableReducer from "../source-forms/summary-table/summary-table.reducer.js";
import dynamicDataReducer from "src/source-forms/dynamic-data.reducer.js";
import activeQuestionReducer from "../source-forms/questions/active-question.reducer.js";

import { newLoggedInUser, newTenant } from "../bootstrap/context.actions.js";
import OverlaySection from "./overlay-section.component.js";
import OverlayLeftNav from "./overlay-left-nav.component";
import * as versionActions from "../source-forms/version.actions.js";

@Cancelable
@UserTenantProps()
export class FormsOverlayContainer extends React.Component {
  state = {
    store: null,
    showTaxForm: false,
    selectedForm: this.props.form,
  };

  componentDidMount() {
    const { loggedInUser, tenant } = this.props;

    const reducer = combineReducers({
      section: sectionReducer,
      answers: answerReducer,
      sourceFormLayout: layoutReducer,
      context: contextReducer,
      resolutionCase: resolutionCaseReducer,
      reduxClient: reduxClientReducer,
      smeVersion: versionReducer,
      uiForms: uiFormsReducer,
      summaryTable: summaryTableReducer,
      activeQuestion: activeQuestionReducer,
      dynamicData: dynamicDataReducer,
    });

    const store = createStore(reducer, applyMiddleware(thunk));
    store.dispatch(newLoggedInUser(loggedInUser));
    store.dispatch(newTenant(tenant));

    this.setState({ store });

    this.props.cancelWhenUnmounted(
      taxResVersionAsObservable.subscribe(({ version, revision }) => {
        this.dispatchVersion(store, version.id, revision.id);
      })
    );
  }

  dispatchVersion(store = this.state.store, version, revision) {
    store.dispatch(versionActions.newSmeVersion(version, revision));
  }

  toggleShowTaxForm = () => {
    this.setState((prevState) => {
      return { showTaxForm: !prevState.showTaxForm };
    });
  };

  updateSelectedForm = (selectedForm) => {
    this.setState({ selectedForm });
  };

  render() {
    // todo remove contact after migration
    const { client, contact, resolutionCaseId, folderIds, noticeId, formList } =
      this.props;

    return (
      this.state.store && (
        <Provider store={this.state.store}>
          <Scoped css={css}>
            <div className="forms-overlay-container">
              <div className="foc-top-nav">
                <div className="top-nav-left">
                  <div className="form-title">
                    <CpIcon
                      name="file-pdf"
                      style={{
                        marginRight: "8px",
                        marginTop: "-4px",
                      }}
                    />
                    Forms
                  </div>
                </div>
                <div className="top-nav-right">
                  <div className="top-nav-right-btn">
                    <CpButton
                      icon="close-large"
                      aria-label="close form"
                      btnType="icon-dark"
                      onClick={this.props.onClose}
                    />
                  </div>
                </div>
              </div>
              <div className="forms-overlay-container-body">
                <OverlayLeftNav
                  showTaxForm={this.state.showTaxForm}
                  updateSelectedForm={this.updateSelectedForm}
                  formList={formList}
                  selectedForm={this.state.selectedForm}
                />
                <div className="foc-right">
                  <OverlaySection
                    resolutionCaseId={resolutionCaseId}
                    // todo remove contact after migration
                    client={client || contact}
                    selectedForm={this.state.selectedForm}
                    folderIds={folderIds}
                    noticeId={noticeId}
                    showTaxForm={this.state.showTaxForm}
                    toggleShowTaxForm={this.toggleShowTaxForm}
                  />
                </div>
              </div>
            </div>
          </Scoped>
        </Provider>
      )
    );
  }
}

FormsOverlayContainer.propTypes = {
  client: object,
  // todo remove contact after migration
  contact: object,
  noticeId: string,
  resolutionCaseId: string,
  onClose: func.isRequired,
  form: object.isRequired,
  formList: array.isRequired,
};

FormsOverlayContainer.defaultProps = {
  folderIds: [],
  formList: [],
  form: {},
};

const css = k`
  .forms-overlay-container {
   position: fixed;
   width: 100vw;
   height: 100vh;
   background: var(--cps-color-blue-smoke);
   top: 0;
   left: 0;
   z-index: 10000;
  }

  .forms-overlay-container-body {
    display: flex;
    height: 100%;
  }

  .foc-right {
    max-height: calc(100vh - 12rem);
    overflow: auto;
    width: 100%;
    margin-right: 24px;
    margin-top: 24px;
    margin-left: 24px;
    max-width: 105rem;
  }

  .foc-top-nav {
    background: #3c464d;
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .top-nav-left {
    display: flex;
    height: 100%;
  }

  .form-title {
    color: var(--cps-color-bumble);
    font-size: 24px;
    margin-left: 24px;
    margin-top: 16px;
  }

  .top-nav-right {
    display: flex;
    height: 100%;
  }

  .top-nav-right-btn {
    margin-right: 24px;
    margin-top: 16px;
    font-size: 24px;
    color: var(--cps-color-bumble);
  }
`;
