import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get, partial, isEqual } from "lodash";
import Cancelable from "react-disposable-decorator";
import { UserTenantProps } from "cp-client-auth!sofe";

import { CpLoader } from "canopy-styleguide!sofe";
import Section from "../source-forms/sections/section.component.js";
import blockStyles from "../source-forms/blocks/block.styles.css";

import * as uiFormsActions from "../source-forms/ui-forms.actions.js";
import * as answerActions from "../source-forms/answers/answer.actions.js";
import * as summaryTableActions from "../source-forms/summary-table/summary-table.actions.js";
import * as sectionActions from "../source-forms/sections/section.actions.js";
import * as dynamicDataActions from "src/source-forms/dynamic-data.actions.js";
import { getSourceForm } from "../source-forms/source-form-layout.actions.js";
import { formSetMap } from "../tax-forms/tax-return.helper.js";
import {
  answerLoadingAsObservable,
  forceFetchSectionAsObservable,
} from "../source-forms/answers/answer.actions";

const ID_OF_FIRST_SECTION = 1;

@Cancelable
@connect((state) => ({
  section: state.section.activeSection,
  answers: state.answers,
  sourceFormLayout: state.sourceFormLayout,
  resolutionCase: state.resolutionCase,
  loggedInUser: state.context.loggedInUser,
  tenant: state.context.tenant,
  version: state.smeVersion.version,
  revision: state.smeVersion.revision,
  uiFormsState: state.uiForms,
  summaryTable: state.summaryTable,
  dynamicData: state.dynamicData,
}))
@UserTenantProps({ permissions: { hasSmePermissions: "sme" } })
export default class TaxPrepSection extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    this.actions = {
      ...bindActionCreators(uiFormsActions, dispatch),
      ...bindActionCreators(answerActions, dispatch),
      ...bindActionCreators(summaryTableActions, dispatch),
      ...bindActionCreators(sectionActions, dispatch),
      ...bindActionCreators(dynamicDataActions, dispatch),
    };

    this.state = {
      fetchingSection: false,
      fetchingNewSourceForm: false,
      lastFetchTime: new Date().getTime(),
      lastFetchParams: null,
    };
  }

  componentDidMount() {
    this.mounted = true;

    this.props.cancelWhenUnmounted(
      answerLoadingAsObservable.subscribe((res) => {
        if (this.state.fetchingNewSourceForm !== res) {
          this.setState({ fetchingNewSourceForm: res });
        }
      })
    );

    forceFetchSectionAsObservable.subscribe(() => {
      this.fetchSection(this.props);
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    this.props.dispatch(sectionActions.resetSourceFormSection());
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.sectionId &&
      !window.location.hash.endsWith("/sections/0")
    ) {
      // We don't know which section to show. Show section 0 (the first section in the source form).
      prevProps.dispatch(sectionActions.resetSourceFormSection());
      window.location.hash += "/sections/0";
      return false;
    }

    let activeSectionLoaded =
      this.props.sectionId &&
      this.props.sectionId === this.props.section.id &&
      this.props.sourceFormId === prevProps.sourceFormId;

    if (
      this.props.section.id &&
      this.props.sectionId === "0" &&
      prevProps.sectionId === this.props.sectionId &&
      this.props.sourceFormId === prevProps.sourceFormId
    ) {
      activeSectionLoaded = true;
    }

    if (
      !activeSectionLoaded &&
      this.props.version &&
      this.props.revision &&
      !this.state.fetchingSection
    ) {
      this.mounted &&
        this.setState({
          fetchingSection: true,
        });
      this.fetchSection(this.props);
    }

    if (this.state.fetchingSection && activeSectionLoaded) {
      this.mounted &&
        this.setState(
          {
            fetchingSection: false,
          },
          () => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }
        );
    }
  }

  fetchSection(props) {
    const params = {
      groupId: props.groupId,
      clientId: props.clientId,
      resolutionCaseId: props.resolutionCaseId,
      versionId: props.version,
      revisionId: props.revision,
      sourceFormId: props.sourceFormId,
      sectionId: props.sectionId || "0",
    };

    if (
      isEqual(params, this.state.lastFetchParams) &&
      new Date().getTime() - this.state.lastFetchTime < 1000
    ) {
      /* No need to retrieve the same source form more than once in a row
       */
      return;
    }

    this.mounted &&
      this.setState({
        fetchingSection: true,
        lastFetchParams: params,
        lastFetchTime: new Date().getTime(),
      });

    props.dispatch(
      getSourceForm(
        params,
        fetchSectionErr.bind(this),
        fetchSectionSuccess.bind(this),
        false
      )
    );

    function fetchSectionErr(err) {
      this.mounted &&
        this.setState({
          fetchingSection: false,
        });
    }

    function fetchSectionSuccess() {
      this.mounted &&
        this.setState({
          fetchingSection: false,
        });
    }
  }

  render() {
    const sectionId = this.props.activeSection
      ? this.props.section.id
      : this.props.sectionId;

    // Section hasn't loaded yet
    const sectionNotLoaded =
      !this.props.section.name ||
      this.state.fetchingSection ||
      this.state.fetchingNewSourceForm;
    const alternateAnswerSets = null;
    const overrideAnswerSet = null;

    return (
      <React.Fragment>
        {sectionNotLoaded && (
          <div className={`${blockStyles.loaderWrapper}`}>
            <CpLoader />
          </div>
        )}
        <div className={sectionNotLoaded ? blockStyles.sectionNotLoaded : null}>
          <Section
            actions={{
              ...this.actions,
              updateAnswer: partial(this.actions.updateAnswer, {
                clientId: this.props.clientId,
                resolutionCaseId: this.props.resolutionCaseId,
                sourceFormId: this.props.sourceFormId,
                sectionId,
                versionId: this.props.version ? this.props.version : null,
                revisionId: this.props.revision ? this.props.revision : null,
                answers: this.props.answers,
                groupId: this.props.groupId,
              }),
            }}
            scrollJump={this.props.scrollJump}
            answers={this.props.answers}
            clientId={this.props.clientId}
            formName={this.props.sourceFormLayout.name}
            resolutionCaseId={this.props.resolutionCaseId}
            sourceFormId={this.props.sourceFormId}
            taxFormId={this.props.sourceFormLayout.taxForm}
            activeSection={this.props.section}
            questionsToShowRequiredWarnings={
              this.props.questionsToShowRequiredWarnings
            }
            sections={this.props.sourceFormLayout.sections}
            summaryTable={this.props.summaryTable}
            alternateAnswerSets={alternateAnswerSets}
            overrideAnswerSet={overrideAnswerSet}
            version={this.props.version}
            revision={this.props.revision}
            folderIds={get(this.props, "folderIds").map(
              (val) => formSetMap[val]
            )}
            taskId={get(this.props, "taskId", null)}
            taxPrep={true}
            dynamicData={this.props.dynamicData}
          />
        </div>
      </React.Fragment>
    );
  }
}
