// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-survey-client-portal-client-portal-survey-slat-styles__surveyName {
  font-weight: 300;
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/client-survey/client-portal/client-portal-survey-slat.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".surveyName {\n  font-weight: 300;\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"surveyName": `src-client-survey-client-portal-client-portal-survey-slat-styles__surveyName`
};
export default ___CSS_LOADER_EXPORT___;
