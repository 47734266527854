import React from "react";
import CprMask from "cpr-mask";
import { get, noop } from "lodash";
import { always } from "kremling";

import styles from "./inputs.style.css";
import CanopyInput from "./canopy-input.decorator.js";
import fixedTableStyles from "../fixed-table/fixed-table.style.css";
import { validateWithXsdRegex } from "./input.helper";

@CanopyInput()
export default class PhoneNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localAnswer: props.answer,
      characterLimit: null,
      invalidMsg: "Not a valid phone number",
    };
    this.inputRef = React.createRef();
    this.focused = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { answer } = this.props;
    const { localAnswer } = this.state;

    if (
      this.props.characterLimit &&
      prevState.localAnswer !== localAnswer &&
      (!answer ||
        !localAnswer ||
        answer.toString().length <= this.props.characterLimit)
    ) {
      // dont use character limit if an old return already has value outside limit until that field reaches blank or if new return
      this.setState({ characterLimit: this.props.characterLimit });
    }

    if (prevProps.answer !== answer && !this.focused) {
      this.updateLocalAnswer(answer);
    }
  }

  render() {
    return (
      <CprMask
        ref={this.inputRef}
        inputClass={always(
          `cps-form-control ${styles["source-form-200"]} ${
            this.props.question.name
          } ${this.props.className || ""}`
        )
          .maybe(fixedTableStyles.fixedTableInput, this.props.inTable)
          .toString()}
        invalidClass="cps-has-error"
        nonValidMsgClass="cps-error-block"
        nonValidMsg={this.state.invalidMsg}
        initialValue={this.state.localAnswer}
        filler=" "
        onChange={this.updateLocalAnswer}
        onBlur={this.blur}
        onFocus={this.focus}
        masks={[
          {
            condition: (value) => (value ? value.length < 11 : true),
            pattern: "(111) 111-1111",
          },
          {
            condition: (value) => true,
            pattern: this.createCharacterLimitedPattern(),
          },
        ]}
        validateMethod={this.validateMethod}
        inputProps={{
          autoComplete: "off",
          style: this.props.style ? this.props.style : {},
          datax: this.props.datax,
          datay: this.props.datay,
          onPaste: this.props.onPaste || noop,
        }}
      />
    );
  }

  blur = (answer) => {
    this.props.validate(answer);
    this.focused = false;
    this.props.updateAnswer(this.props.question, answer);
  };

  createCharacterLimitedPattern = () => {
    const { characterLimit } = this.state;
    let text = "";

    if (characterLimit) {
      for (let charCount = 0; charCount < characterLimit; charCount++) {
        text += "1";
      }
    } else {
      text = "11111111111111111111";
    }

    return text;
  };

  focus = () => (this.focused = true);

  focusInput = () => {
    const input = get(this, "inputRef.current.input");
    if (input) input.focus();
    else console.warn("no input to focus on!");
  };

  validateMethod = (value) => {
    const { characterLimit } = this.props;
    const xsdType = get(this.props, "xsdType");

    if (characterLimit && value && value.toString().length > characterLimit) {
      return false;
    }

    if (value && xsdType) {
      return validateWithXsdRegex(xsdType, value);
    }

    return true;
  };

  updateLocalAnswer = (value) => {
    const { characterLimit } = this.props;
    const xsdType = get(this.props, "xsdType");
    let invalidMsg = "Not a valid phone number";

    this.props.validate(value);

    if (characterLimit && value && value.toString().length > characterLimit) {
      invalidMsg = `Number should be at least ${characterLimit} digits`;
    } else if (value && xsdType) {
      const isValid = validateWithXsdRegex(xsdType, value);

      if (!isValid && xsdType.customDescription) {
        invalidMsg = xsdType.customDescription;
      } else if (!isValid && xsdType.irsDescription) {
        invalidMsg = xsdType.irsDescription;
      }
    }

    this.setState({
      localAnswer: value,
      invalidMsg,
    });
  };
}
