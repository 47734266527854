import * as types from "./source-form-layout.types.js";

const defaultState = {
  name: null,
  id: null,
  sections: [],
  savingAnswer: false,
};

export default function reducer(state = defaultState, action) {
  if (action.type === types.NEW_SOURCE_FORM_LAYOUT) {
    return {
      ...(action.sourceFormLayout ? action.sourceFormLayout : defaultState),
      savingAnswer: state.savingAnswer,
    };
  }

  if (action.type === types.SAVING_ANSWER) {
    return {
      ...state,
      savingAnswer: true,
    };
  }

  if (action.type === types.DONE_SAVING_ANSWER) {
    return {
      ...state,
      savingAnswer: false,
    };
  }

  return state;
}
