import * as types from "./program-section.types.js";

const defaultState = {
  sectionBeingFetched: null,
  activeSection: {
    id: null,
    status: "in_progress",
  },
};

export default function ProgramSectionReducer(state = defaultState, action) {
  if (action.type === types.UPDATE_ACTIVE_PROGRAM_SECTION) {
    return {
      sectionBeingFetched: null,
      activeSection: {
        ...action.section,
        status: action.section.status || "in_progress",
      },
    };
  }

  if (action.type === types.FETCHING_ACTIVE_PROGRAM_SECTION) {
    return {
      ...state,
      sectionBeingFetched: action.sectionId,
    };
  }

  if (action.type === types.CANCEL_FETCH_ACTIVE_PROGRAM_SECTION) {
    return {
      ...state,
      sectionBeingFetched: null,
    };
  }

  return state;
}
